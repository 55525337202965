import React from 'react';
import './GigCategory.styles.css';
import categoryImg from '../../../assets/image/logogig_background.svg';
import categoryWeb from '../../../assets/image/category-webdev.png';
import draw from "./draw.jpeg";
import writing from "./writing.jpeg";
import tophill from "./tophill.jpeg";
import { Link } from 'react-router-dom';


const GigCategory = () => {

    return (

        <div className='gig_category'>
    <h1>Popular professional gigs</h1>

    {/* The cards */}
    <div className='category_cardwrapper'>
        <div style={{width:"95%", marginLeft:"5%", borderRadius:"5px"}} >
    <div className='card category_card'
     style={{ backgroundImage: `url(${draw})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:"cover",
            backgroundPosition: '-100px', width:"100%", borderRadius:"5px"
        }}></div>
    <p style={{color:"black", marginTop:"20px"}}>Build your brand</p>
    <h5>Logo/Graphics/</h5>
    <h5>Branding design</h5>
    </div>

        <div style={{width:"95%", marginLeft:"5%", borderRadius:"5px"}}>
    <div className='card category_card'
    style={{ backgroundImage: `url(${writing})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:"cover",
            backgroundPosition: 'right', width:"100%", borderRadius:"5px"
        }}></div>
    <p style={{color:"black", marginTop:"20px"}}>Build  your  business & brand  </p>
    <p style={{color:"black"}}> content strategy </p>
    <h5>Creative writers/Brand strategist</h5>

    </div>

        <div style={{ width:"95%", marginLeft:"5%", borderRadius:"5px"}}>
    <div className='card category_card'
    style={{ backgroundImage: `url(${tophill})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:"cover",
            backgroundPosition: 'left', width:"100%", borderRadius:"5px"
        }}></div>
    <p style={{color:"black", marginTop:"20px"}}>Access to audio/visual experts </p>
    <h5>Voice Overs/ Translators/ Animators</h5>
    {/* <h4>Translators/</h4>
    <h4>Animators</h4> */}
    </div>

        <div style={{ width:"95%", marginLeft:"5%", borderRadius:"5px"}}>
    <div className='card category_card'
    style={{ backgroundImage: `url(${categoryWeb})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right', width:"100%"
        }}></div>
    <p style={{color:"black", marginTop:"20px"}}>Build your website</p>
    <h5>Web development</h5>
    </div>

    </div>
    {/* End of the cards */}
    <div className='button_wrapper'>
        <Link to="/login"><button className='explore_but' 
        type='button'>Explore all gigs</button></Link>
    </div>

        </div>
    )
}



export default GigCategory;