import React, {useState} from 'react';
import './AddEducation.styles.css';
import ModalInputComponent from '../../../ModalInput/ModalInput.component';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/image/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addEducation } from '../../../../store/action/publicProfileAction/addEducation';
import Loader from 'react-loader-spinner';
import EditEducation from '../EditEducation/EditEducation.component';





const AddEducation = ({countries,userEducation}) => {
    const [displayContents, setDisplay] = useState(false);
    const showContent = () => setDisplay(true);
    const closeCard = () => setDisplay(false);
    const EducationDetail = useSelector(state=>state)

    // console.log(EducationDetail)
  
    const getYears = ()=>{
      let currentYear = new Date().getFullYear(), years = [];
      let startYear = 1960;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    return years;
    }
    getYears()
    let initialState = {
      country_id:"",
      title:"",
      from_year :"",
      to_year :"",
    }
     console.log(userEducation)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [state, setstate] = useState(initialState)

    const handleChange = (e) => {
      const {name, value} = e.target;
      // console.log(value)
      setstate({...state,[name]: value})
      //  console.log('this is the state', state)
    }
    
    const handleSubmit= async (e) =>{
      e.preventDefault();
      // console.log(state);
      setLoading(true)
      await dispatch(addEducation(state))
      setLoading(false)
      setDisplay(false)
      // setShow(false)
    
    }


    return (
        <div>
        <div className="edit-desc">
        <p>Education <span>(Optional)</span></p>
        <button type="button"  onClick={showContent}>
        Add
        </button>
        </div>
        {/* {userEduction.title} - {userEduction.to_year} */}
        { displayContents ?
    
          <div className="desc-content">
          <form onSubmit={handleSubmit}>       
          <div className="form-group-des">
          <label>What country is the institution?</label><br/>
           <select name="country_id" onChange={handleChange}>
           <option value="select country" >Select Country</option>
            {
            countries?.map((country,idx) => {
                return <option key={country.id} value={country.id} >{country.name}</option>
            })
            } 
           </select>
          </div>
          <div className="form-group-des">
          <ModalInputComponent
           label="Name of Institution:"
           name="title"
           type="text"
           onChange={handleChange}
          />
          </div>
          {/* <div className="form-group-des">
          <ModalInputComponent
           label="Major:"
           name=""
           type="text"
           onChange={handleChange}
          />
          </div> */}
          <div className="form-group-des">
          <label>Year of entry:</label><br/>
           <select name="from_year" onChange={handleChange}>
           <option value="select country" >Select Year</option>
            {
            getYears().map((year,idx) => {
                return <option key={idx} value={year} >{year}</option>
            })
            } 
            </select>
            </div>
          <div className="form-group-des">
          <label>Year of graduation:</label><br/>
           <select name="to_year" onChange={handleChange}>
           <option value="select country" >Select Year</option>
            {
            getYears().map((year,idx) => {
                return <option key={idx} value={year} >{year}</option>
            })
            } 
           </select>
          {/* <ModalInputComponent
           label="Year of graduation:"
           name="yearofgraduation"
           type="date"
           onChange={handleChange}
          /> */}
          {/* <ModalInputComponent
           label="Year of graduation:"
           name="to_year"
           type="date"
           onChange={handleChange}
          /> */}
          </div>
          <div className="but">    
        <button type="button" onClick={closeCard}>Cancel</button>
        <button type="submit">
        {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={50}
           width={50}
         /> :"Add"
            }
          </button>
        </div>  
          </form>
           
           </div>
          : null 
        }
        <EditEducation userEducation={userEducation} countries={countries}/>

        </div>
    )
}


export default AddEducation;