export const NavData = [
{
    id: 1,
    title: "Creative thinkers",
    path: "/creativethinkers",
    cName: "nav-item"
},
{
    id: 2,
    title: "Creative writers",
    path: "/writers",
    cName: "nav-item"
},
{
    id: 3,
    title: "Audio/visuals",
    path: "/creativethinkers",
    cName: "nav-item"
},
{
    id: 4,
    title: "Voiceover experts",
    path: "/voiceovers",
    cName: "nav-item"
},
{
    id: 5,
    title: "Creative visualizers",
    path: "/creativevisualizers",
    cName: "nav-item"
},
{
    id: 6,
    title: "Tech visual experts",
    path: "/techvisual",
    cName: "nav-item"
},
{
    id: 7,
    title: "Branding",
    path: "/branding",
    cName: "nav-item"
},
{
    id: 8,
    title: "Experiential",
    path: "/experiential",
    cName: "nav-item"
}
]



export const skillsDropdown = [
    {
        id: 1,
        title: "Copywriting",
        path: "./copywriting",
        cName: "submenu-item"
    },
    {
        id: 2,
        title: "creative thinkers-1",
        path: "./copywriting",
        cName: "submenu-item"
    },
    {
        id: 3,
        title: "creative thinkers-2",
        path: "./creativethinkers",
        cName: "submenu-item"
    },
    {
        id: 4,
        title: "voiceover artist",
        path: "./voiceover",
        cName: "submenu-item"
    },
]

