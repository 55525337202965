import React, {useRef, useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import "./viewpastjobs.css";
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import TalentFooter from "../../Footer/TalentFooter/Footer.component";
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import Loader from 'react-loader-spinner';
import talvid from "./talvid.mp4";
import test from "./background.png";
import { uploadPastJobs, getPastJobs } from "../../../store/action/talent/applyJobAction";


const Viewpastjobs = () => {
    const dispatch = useDispatch();
    const refForm = useRef(null);

    useEffect(() =>{
        dispatch(getPastJobs())
    }, [])

    const pastJobs = useSelector(state => state.talentApplyForJobReducer.getPastJobs);
    console.log('getPast jobs', pastJobs)

        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        const [loading, setLoading] = useState(false)
        const [state, setstate] = useState()
        const [gigPix, setGigPix] = React.useState(null);
        const [image, setImage] = React.useState(null);
      
        const handleSubmit= async (e) =>{
        e.preventDefault();
        const formData = new FormData();
        const data = new FormData(refForm.current);
        console.log('state data', state, image);
        formData.append("title", state.title);
        formData.append("description", state.description);
        formData.append("file", image);

        setLoading(true);
            await dispatch(uploadPastJobs(formData)) 
            setLoading(false)
            setShow(false)
    
        }
    
        const handleChange = (e) => {
            const {name, value} = e.target;
            setstate({...state,[name]: value})
            
        }
        const handleChangeImage = (e) => {
            let img = e.target.files[0]
            // console.log(img)
            setImage(img)
            setGigPix(URL.createObjectURL(img))
        }
      
      
            

    return(
        <div>
            <NavTalent/>
            <main>
            <button className="viewpastjobs_button" onClick={handleShow}>Upload past jobs</button>
            <Modal 
        size="md"
        show={show} 
        onHide={handleClose}
        dialogClassName="modal-500w"
        >
        <div className="title-cus">
        <img src={close} alt="closeimage"
        onClick={handleClose} />
        <h3>Kindly upload your past jobs</h3>
        </div>
        <Modal.Body>
        <form className="form-update"ref={refForm} onSubmit={handleSubmit}>

        <div className="form-group-update">
        <ModalInputComponent
         label="Title:"
         name="title"
         onChange={handleChange}
         type="text"
        />
        </div>
        <div className="form-group-update">
        <ModalInputComponent
         label="Description:"
         name="description"
         onChange={handleChange}
         type="text"
        />
        </div>
        <div className="form-group-update">
        <ModalInputComponent
         label="Upload past jobs (Not greater than 5MB)"
         name="file"
         type="file"
        //  defaultValue={profile_image}
         onChange={handleChangeImage}
        />
        </div>
        <button type="submit">
        {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={50}
           width={50}
         /> :"Update story"

            }
          </button>
        </form>
        </Modal.Body>
        </Modal>
        <div className="talent_bars">
            {
            pastJobs?.map(data => {

           return <div className='image_list'>
                    {data.file?.includes(".jpg")  || data.file?.includes(".png")  ?
                    <a href={`https://api.solvateafrica.com/public/images/usergallery/${data?.file}`} target='_blank'>
                        <img src={`https://api.solvateafrica.com/public/images/usergallery/${data?.file}`} alt='mages' 
                    style={{width: '100%'}}/> 
                    </a> :
                    <video width="100%" height="250" controls>
                    <source src={`https://api.solvateafrica.com/public/images/usergallery/${data?.file}`} type="video/mp4"></source>
                    </video>
            }
                    <div >
                    <p><strong>Title</strong>: {data?.title}</p>
                    <p style={{margin: "0"}}><strong>Description</strong>: {data?.description}</p><br />
                    </div>
            </div>
             })
            }
     
       </div>
            </main>
            <TalentFooter/>
        </div>
    )
}

export default Viewpastjobs;