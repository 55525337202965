import React, { useState } from 'react';
import './SubmittedOrderHunterTable.styles.css';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
// import JobRequestModal from '../JobRequestModal/JobRequestModal.component';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import AcceptOrderModal from '../AcceptOrderModal/AcceptOrderModal.component';
// import CompleteOrderModal from '../CompleteOrderModal/CompleteOrderModal.component';

const SubmittedOrderHunterTable = ({ getSubmittedOrder }) => {
  const [show, setShow] = useState(false);
  const [selectOrder, setSelectOrder] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (order) => {
    console.log('get submitted order project', order);
    setSelectOrder(order);
    setShow(true);
  };

  const category = useSelector((state) => state.categoriesReducer.categories);

  return (
    <div>
      <Table className='table_size mt-4' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Talent Name</th>
            <th className='active_head_bg'>Job title </th>
            <th className='active_head_bg'>start date</th>
            <th className='active_head_bg'>due date</th>
            <th className='active_head_bg'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Modal */}
          {/* <CompleteOrderModal
            handleClose={handleClose}
            selectOrder={selectOrder}
            show={show}
          /> */}

          {/* End of Modal */}
          {/* <tr>
            <td>1</td>
            <td>Jan 10, 2022</td>
            <td>I need someone who can deliver the best
            of article writing</td>
            <td>Copywriting</td>
            <td>5,000</td>
            <td>4 milestones</td>   
      
        <td className="action_icons">
       <button
        onClick={handleShow} type='button'>Apply for job</button>
        </td>
          </tr>   */}
          {getSubmittedOrder.map((each, index) => {
            return (
              <tr key={index}>
                <td>{1 + index}</td>
                <td>{each.creative.name}</td>
                <td>{each.job !== null && each.job .title}</td>
                <td>{each.job !== null && each.job.start_date}</td>
                <td>{each.job !== null && each.job.end_date}</td>

                <td className='action_icons'>
                  <Link
                    to={`/hunterDashboard/SubmittedProjects/detail/${each.job_id}`}
                  >
                    <button type='button' onClick={() => handleShow(each)}>
                      View
                    </button>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SubmittedOrderHunterTable;
