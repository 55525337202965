const initialState = {
    countries:[],
    states:[],
    cities:[],
    userCountry:undefined

}

export const countriesAndStatesReducer =(state=initialState, action)=>{
    if(action.type === "COUNTRIES"){
        console.log(action.payload)
        // const userCountryId = action.countryId
        // let userCountry = action.payload.find((each,id)=>each.id === Number(userCountryId))
        // console.log(userCountry)
        return Object.assign({}, state, {
            countries: action.payload,
            // userCountry:userCountry
        });
    }
    else if(action.type === "STATES"){
        return Object.assign({}, state, {
            states: action.payload,
        });
    }
    

    return state;
}
