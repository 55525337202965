import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import { getUpdateStatus } from '../../../pages/Auth/getUpdateProfile';
import axios from 'axios';

export function updateDescription (userDetails) {
    
      return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
        console.log(userDetails)
        console.log(getUpdateStatus().firstname)
        const firstname = getUpdateStatus().firstname
        const formData = new FormData()
        formData.append("firstname",firstname)
        formData.append("lastname",getUpdateStatus().lastname)
        formData.append("gender",getUpdateStatus().gender)
        formData.append("phone_number",getUpdateStatus().phone_number)
        formData.append("countryId",getUpdateStatus().countryId)
        formData.append("stateId",getUpdateStatus().stateId)
        formData.append("accounttypeId",getUpdateStatus().accounttypeId)
        formData.append("description",userDetails.description)

         return axiosInstance.post('updateorcreateprofile' ,formData).then ( async (response) => {
           console.log(response.data)
            if(response.data.success === true){
              await dispatch({ type: "UPDATE_CREATE_PROFILE", payload: response.data });
          }
        
          })
          .catch(
              error => {
                console.log(error.response)
                // console.log(error)
                // if(error.response !== undefined){

                //   if(error.response.status === 422){
                //     if(error.response.data.errors.email){
                //       toast.error(error.response.data.errors.email[0], {
                //           className: css({
                //             background: "#00FF00 !important",
                //             color: "white !important",
                //             fontWeight: "bold"
                //           }),
                //           position: toast.POSITION.TOP_CENTER,
                //         });
                //     }
                //     else if(error.response.data.errors.password){
                //       toast.error(error.response.data.errors.password[0], {
                //           className: css({
                //             background: "#00FF00 !important",
                //             color: "white !important",
                //             fontWeight: "bold"
                //           }),
                //           position: toast.POSITION.TOP_CENTER,
                //         });
                //     }
                //   }

                // }
              }
          )

      }

}
