import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import axios from 'axios';

export function landingFaq () {
  return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
         
          return axiosInstance.get('website-faq').then ( async (response) => {
            console.log('landing faq response', response)
          if(response.data.success === true){
            // console.log(response)
            await dispatch({ type: "GET_LANDING_FAQ", payload: response.data.faqs })
          }
          })
          .catch(
              error => {
                console.log(error.response)
                console.log(error)
              }
          )
  
      }
  
  }