import React, {useState} from 'react';
import './EditSkills.styles.css';
import edit from '../../../../assets/image/editTalent.svg';
import deleteIcon from '../../../../assets/image/delete.svg';
import { useSelector } from 'react-redux';
import ModalInputComponent from '../../../ModalInput/ModalInput.component';
import { addUserSkill, deleteUserSkill } from '../../../../store/action/publicProfileAction/skillAction';
import { useDispatch } from 'react-redux';

const EditSkills = () => {
const [displayContents, setDisplay] = useState(false);
const userSkill = useSelector(state=>state.skillReducer.userSkills)
const [loading, setLoading] =  useState(false);
const showContent = () => setDisplay(true);
const closeCard = () => setDisplay(false);
const [state, setState] = useState(userSkill)

console.log('user skill editing', userSkill)
const dispatch = useDispatch()
const handleChange = (e) => {
  e.preventDefault();
  const {name, value} = e.target;
  setState({...state,[name]: value})
  
}

const handleSubmit= async (e) =>{
  e.preventDefault();
  // const name = allSkill.find(skill => skill.id === Number(state.skillId))
  // const newDate = {...state,name:name.name}
 
  setLoading(true)
  await dispatch(addUserSkill(state))
  setLoading(false)
  setDisplay(false)
  // setShow(false)

}

const handleDelete= async (e) =>{
  e.preventDefault();
  // const name = allSkill.find(skill => skill.id === Number(state.skillId))
  // const newDate = {...state,name:name.name}
 
  setLoading(true)
  await dispatch(deleteUserSkill())
  setLoading(false)
  

}


    return (
        <div>
        <div className="total-lang">
        { displayContents ?
    
        <div className="desc-content-edit">
          <form onSubmit={handleSubmit}>
        <div className="form-group-des">
        {/* <ModalInputComponent
         label="Enter Skill:"
         name="skill"
         type="text"
        /> */}
        <ModalInputComponent
         label="Enter Skill:"
         name="skills"
         type="text"
         defaultValue={userSkill}
         onChange={(e) => handleChange(e)}
        />
        </div>
        {/* <div className="form-group-des">
          <label>Select level of proficiency:</label><br/>
          <select>
          <option>Beginner</option>
          <option>Intermediate</option>
          <option>Advance</option>
          </select>
        </div> */}
        <div class="but">    
        <button type="button" onClick={closeCard}>Cancel</button>
        <button type="submit">Add</button>
        </div>  
        </form>           
           </div>
            : null 
          } 
          
              
            

            <div className="lang" >

              <h5>{userSkill}</h5>
              
              <div className="edit-images">
              <img src={edit} alt="edit"
              onClick={showContent}/>
              {/* <img src={deleteIcon} alt="delete" onClick={handleDelete}/> */}
              </div>
              </div>        
        </div>
        </div>
    )
}




export default EditSkills;

