import React, { useState } from 'react';
import './frequently.css';


const Frequently = ({faqs}) => {

    const [freqword, setFreqword] = useState(false)
    const [freqword1, setFreqword1] = useState(false)
    const [freqword2, setFreqword2] = useState(false)
    const [freqword3, setFreqword3] = useState(false)
console.log('faqs inside faq', faqs)


    const handleClick = () => {
        setFreqword(!freqword);
    }

    const handleClick1 = () => {
        setFreqword1(!freqword1);
    }

    const handleClick2 = () => {
        setFreqword2(!freqword2);
    }

    const handleClick3 = () => {
        setFreqword3(!freqword3);
    }
    return(
        <div className='freq'>
            <div className=' freq_all'>
                <div className='freq_sec1'>
                <h2 className='freq_h2'><strong>Frequently asked questions</strong></h2>
                </div>
                <div className='freq_sec2'>
                    <p className='freq_sec2'>Still not clear about the whole process? Please go through the FAQ below.</p>
                </div>
            </div>
            <div>
                <div className='plus_side1'>
                <div className='plus_side'>
                    <p className='plus_word'>{faqs[0]?.faq}</p>
                    <button onClick={handleClick}>{freqword? '-':'+'}</button>
                </div>
                {freqword ? <React.Fragment>
                <p className='plus_word'>{faqs[0]?.answer}</p></React.Fragment>:''}
                </div>
                <div className='plus_side1'>
                <div className='plus_side'>
                    <p className='plus_word'>{faqs[1]?.faq}</p>
                    <button onClick={handleClick1}>{freqword1? '-':'+'}</button>
                </div>
                {freqword1 ? <React.Fragment>
                <p className='plus_word'>{faqs[1]?.answer}</p></React.Fragment>:''}
                </div>
                <div className='plus_side1'>
                <div className='plus_side'>
                    <p className='plus_word'>{faqs[2]?.faq}</p>
                    <button onClick={handleClick2}>{freqword2? '-':'+'}</button>
                </div>
                {freqword2 ? <React.Fragment>
                <p className='plus_word'>{faqs[2]?.answer}</p></React.Fragment>:''}
                </div>
                
            </div>
        </div>
    )
}

export default Frequently;