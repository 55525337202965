import React from 'react';
import "./NavLanding.styles.css";

const NavSubLanding = () => {
  return (
    <nav className='sublanding'>
        <ul className='sublanding_list'>
            <li className='homenav_listword'>Creative visualizers</li>
            <li>Tech visual experts</li>
            <li>Animators</li>
            <li>Creative writers</li>
            <li>Voice overs/ language experts</li>
        </ul>
    </nav>
  )
}

export default NavSubLanding