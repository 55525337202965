import React, { useState } from 'react';
import './ResetPassword.styles.css';
import NavBar from '../../../components/Navs/NavBar/NavBar.component';
import leftLogo from '../../../assets/image/auth-image.svg';
import FormInput from '../../../components/FormInput/FormInput.component';
import CustomButton from '../../../components/CustomButton/CustomButton.component';
import { Link } from 'react-router-dom';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { forgetPassword } from '../../../store/action/authAction/forgetPassword';
import Loader from 'react-loader-spinner';
import { resetPassword } from '../../../store/action/authAction/restPassword';



const ResetPassword = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <div className="container-fluid signup-wrapper">
        <div className="row signup-wrapper-child-1">  
        <div className="left-forgot col-md-5">
        <NavBar/>
        <div>
       {/*The heading*/}
        <h1>Join the great minds republic</h1>
        <img className="img-fluid" src={leftLogo} alt="workImage"/>
        </div>
        </div>
        <div className="right-forgot col-md-7">
        <h1>Reset password</h1>
        <h6>Kindly enter new password</h6>
        <Formik initialValues= {{

            code: '',
            password: '',
            password_confirmation: ''

            }} 
            validationSchema={yup.object({

            code: yup.string()
            .required('Please Enter The Code Sent to Your Email'),
            password: yup.string()
            .required("please Enter a new password")
            .min(8, "Password must be at least 8 character"),
            password_confirmation: yup.string()
            .required("Please Confirm Your New Password")
            .min(8, "Password must be at least 8 character")
            .oneOf([yup.ref("password"), null], 'Password must match')

            })} 
            onSubmit= {async values => {
                console.log(values)
                setLoading(true)
                await dispatch(resetPassword(values,history))
                setLoading(false)

            }}>
            {
            props=>(

        <Form >
        <div className="form-input">
        <FormInput
         label="New password:"
         name="password"
         type="password"
        />
         <ErrorMessage  name="password">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        <div className="form-input">
        <FormInput
         label="Repeat password:"
         name="password_confirmation"
         type="password"
        />
         <ErrorMessage  name="password_confirmation">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
        <div className="form-input">
        <FormInput
         label="Enter code received:"
         name="code"
         type="text"
        />
         <ErrorMessage  name="code">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
        </div>
        <CustomButton>
            {
                loading ?
                <Loader
                    type="Rings"
                    color="white"
                    height={50}
                    width={50}
                    //    timeout={3000} //3 secs
                /> :null

            }
            Update password</CustomButton>
        </Form>
          )
        }
        </Formik>
        <p>Remember your password? <span><Link to="/login">Login</Link></span></p>
        </div>       
        </div>
        </div>
    )
} 



export default ResetPassword;