import React, { useEffect } from 'react';
import NavTalent from '../../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import './ProjectTrackerTalentLists.styles.css';
import { useHistory } from 'react-router-dom';
import ProjectTrackerTalentTable from '../../../../components/Talents/ProjectTrackerTalentTable/ProjectTrackerTalentTable.component';
import { useDispatch } from 'react-redux';
import { getAddedOrder } from '../../../../store/action/hunter/addOrder';

const ProjectTrackerTalentLists = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    getTrackedProjects();
  }, []);
  const getTrackedProjects = async () => {
    await dispatch(getAddedOrder());
  };

  return (
    <div className='Projects_wrapper'>
      <NavTalent />
      <h3>List of Projects being tracked by creative hunter</h3>
      <div className='projects_button d-flex justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='projects_button--back'
          type='button'
        >
          Go back
        </button>
      </div>

      {/* TABLE */}
      <ProjectTrackerTalentTable />
    </div>
  );
};

export default ProjectTrackerTalentLists;
