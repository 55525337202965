import React from 'react';
import './SelectInput.styles.css';
import { Field } from 'formik';

const SelectInput = ({label, ...otherprops}) => {

    return (
        <div className="select-input">
        <label>
        {label}
        </label><br></br>
        <Field {...otherprops}/>
        </div>
    )
}


export default SelectInput;