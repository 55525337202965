import React, {useState, useEffect} from 'react';
import profileImage from '../../../../assets/image/message_profile_pic.png';
import './MessageHunterList.styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetConversations} from '../../../../store/action/hunter/hunterMessages';
import {Link, Route, BrowserRouter, Switch} from 'react-router-dom';
import MessageHunterDetail from '../MessageHunterDetail/MessageHunterDetail.component';
import {IoSearchOutline} from 'react-icons/io5'; 
import {HiOutlineUser} from 'react-icons/hi';
import {BiMessageSquareDetail} from 'react-icons/bi';
import moment from 'moment';

const MessageHunterSent = () => {
    const dispatch = useDispatch();
   
     useEffect(() => {
        dispatch(GetConversations())
    }, []);
    const userLoggedIn = useSelector(state => state.authReducer.user.id);
    const sentMessages = useSelector(state => state.hunterReducer.getConversations);
    console.log('sent messages', sentMessages )
  
   
    return (
      
        <>
        <BrowserRouter>
        
          <section className='messages'>
              <section className='messages__listcontainer'>
              {/* <form className='messages__form'>
                  <input type='search' placeholder='message' />
                  <span><IoSearchOutline /></span>
              </form> */}
              <ul className='messages__list'>
                  {sentMessages.length === 0 ?  <div className='nomessage'>No message available</div>
                  : sentMessages.map((message) => {
                      return  <Link to={`/hunterDashboard/messageHunter/details/${message.anotheruser}`}>
                          <li className='messages__item'>
                          <div className='messages__itemcontent'>
                              <div className='messages__userimage'>
                              <img src={profileImage} alt='profileIcon'/>
                               </div>
                              <div className='messages__preview'>
                              <p>{message?.username}</p>
                              </div>
                          </div>
                          <div className='messages__date'>
                          <p>{moment(message?.created_at).format('LLL')}</p>
                          </div>
                      </li>
                      </Link>
                  }
                )}
                  
              </ul>
              </section>
              <section className='messages__conversationcontainer'>
              
               <Switch>
              <Route exact path='/hunterDashboard/messageHunter' render={() =>
                   <div className='messages__content'>
                  <BiMessageSquareDetail />
                  </div>} 
              />
              <Route exact path='/hunterDashboard/messageHunter/details/:id' component={MessageHunterDetail} />
               </Switch>
              
              </section>
         </section>
               </BrowserRouter>
      </>
    
    )
}

export default MessageHunterSent;