import { Button, Modal } from 'react-bootstrap';
import './EditModal.styles.css';
import { BiCommentDetail } from 'react-icons/bi';
import { ImAttachment } from 'react-icons/im';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTask } from '../../../../store/action/hunter/addOrder';
import moment from 'moment';
import Loader from 'react-loader-spinner';

export default function EditModal({ showEdit, handleCloseEdit, selectedId }) {
  const [isLoading, setIsLoading] = useState(false);
  const task = useSelector((state) => state.TrackerOrderReducer.task);
  // console.log(task);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchTaskDetail();
    return () => {};
  }, [selectedId]);
  const fetchTaskDetail = async () => {
    setIsLoading(true);
    console.log(selectedId);
    await dispatch(getSelectedTask(selectedId));
    setIsLoading(false);
  };
  return (
    <>
      <Modal size='lg' show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className='d-flex justify-content-center'>
              <Loader type='Rings' color='blue' height={50} />
            </div>
          ) : task === null ? (
            'No Data'
          ) : (
            <div className='modal__container'>
              <section className='modal__description'>
                <div className='modal__title'>
                  <span>
                    <BiCommentDetail />
                  </span>{' '}
                  Description
                </div>
                <div className='modal__details'>
                  <p>{task.description}</p>
                </div>
              </section>

              <section className='modal__activity'>
                <div className='modal__activity-title'>
                  <div className='modal__title'>
                    <span>
                      <HiOutlineViewGrid />
                    </span>
                    Activities
                  </div>
                  <div className='modal__activity-button'>
                    <button>Show Details</button>
                  </div>
                </div>
                <div className='modal__detail-content'>
                  <div className='modal__detail-left'>
                    <p>Name</p>
                    <p>Start Date</p>
                    <p>End Date</p>
                    <p>Status</p>
                  </div>
                  <div className='modal__detail-right'>
                    <p>{task.name}</p>
                    <p>{moment(task.start_date).format('ll')}</p>
                    <p>{moment(task.end_date).format('ll')}</p>
                    <p>{task.status}</p>
                  </div>
                </div>
                <section className='modal__form'>
                  <form>
                    <textarea placeholder='write a comment...' />
                    <div className='modal__form-option'>
                      <div className='modal__form-submit'>
                        <button>Save</button>
                      </div>
                      <div className='modal__form-attachment'>
                        <label htmlFor='modal-attachment'>
                          <ImAttachment />
                        </label>
                        <input type='file' id='modal-attachment' />
                      </div>
                    </div>
                  </form>
                </section>
              </section>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant='primary' onClick={handleCloseEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
