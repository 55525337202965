import React, {useState} from 'react';
import './AddSkils.styles.css';
import ModalInputComponent from '../../../ModalInput/ModalInput.component';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/image/close.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { addUserSkill, deleteUserSkill } from '../../../../store/action/publicProfileAction/skillAction';


const AddSkills = () => {
  const [displayContents, setDisplay] = useState(false);
  const showContent = () => setDisplay(true);
  const closeCard = () => setDisplay(false);
  // const allSkill = useSelector(state=>state.skillReducer.allSkills)
  // const userSkill = useSelector(state=>state.skillReducer.userSkills)

  // console.log(userSkill)
  const userSkill = useSelector(state=>state.skillReducer.userSkills)
console.log('user skills in edit');
  let initialState = {
    skills: ''
  }

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [state, setstate] = useState(initialState)

  const handleChange = (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    setstate({...state,[name]: value})
    
  }
  
  const handleSubmit= async (e) =>{
    e.preventDefault();
    // const name = allSkill.find(skill => skill.id === Number(state.skillId))
    // const newDate = {...state,name:name.name}
   
    setLoading(true)
    await dispatch(addUserSkill(state))
    setLoading(false)
    setDisplay(false)
    // setShow(false)
  
  }

  
    return (
        <div>
        <div className="edit-desc">
        <p>Skills</p>
       { userSkill ? ''  : <button type="button"  onClick={showContent}>
           Add
        </button>
}
        </div>

        { displayContents ?
    
          <div className="desc-content">
          <form onSubmit={handleSubmit}>
        {/* <div className="form-group-des">
        <label>Select language:</label><br/>
           <select name="skillId" onChange={handleChange}>
           <option value="select language" >Select language</option>
            {
            allSkill.map((each,idx) => {
                return <option key={idx} value={each.id} >{each.name}</option>
            })
            } 
           </select>
        </div> */}
        <div className="form-group-des">
        {/* <label>Select level of proficiency:</label><br/> */}
         <input  type='text' name="skills" className='input_publicprofile' onChange={(e) => handleChange(e)} />
         
         {/* <option value="level" >Select proficiency Level</option>
         <option>Beginner</option>
         <option>Intermediate</option>
         <option>Advance</option>
         </select> */}
        </div>
        <div className="but">    
        <button type="button" onClick={closeCard}>Cancel</button>
        <button type="submit">
        {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={50}
           width={50}
         /> :"Add"
            }
        </button>
        </div>  
        </form>           
           </div>
          : null 
        }        
        </div>
    )
}



export default AddSkills;