import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import axios from 'axios';

export function getTalentReview(id) {
  const axiosInstance = axios.create({
    baseURL: PATH,
    timeout: 200000,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      //    'X-CSRF-TOKEN: " "'
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });
  return function (dispatch) {
    return axiosInstance
      .get(`review/getreceivedreviews/${id}`)
      .then(async (response) => {
        console.log(response);
        await dispatch({
          type: 'GET_USER_REVIEW',
          payload: response.data.received_reviews,
        });
      })
      .catch((error) => {
        // console.log(error.response)
        // console.log(error.response)
        // if(error.response !== undefined){
        //   if(error.response.status === 422){
        //     if(error.response.data.errors.email){
        //       toast.error(error.response.data.errors.email[0], {
        //           className: css({
        //             background: "#00FF00 !important",
        //             color: "white !important",
        //             fontWeight: "bold"
        //           }),
        //           position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        //     else if(error.response.data.errors.password){
        //       toast.error(error.response.data.errors.password[0], {
        //           className: css({
        //             background: "#00FF00 !important",
        //             color: "white !important",
        //             fontWeight: "bold"
        //           }),
        //           position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        //   }
        // }
      });
  };
}

export function getReviewForJob(id) {
  const axiosInstance = axios.create({
    baseURL: PATH,
    timeout: 200000,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      //    'X-CSRF-TOKEN: " "'
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  });
  return function (dispatch) {
    return axiosInstance
      .get(`review/getjobreview/${id}`)
      .then(async (response) => {
        console.log(response);
        await dispatch({
          type: 'GET_HUNTER_REVIEW_FOR_PROJECT',
          payload: response.data.job_reviews,
        });
      })
      .catch((error) => {
        // console.log(error)
        // console.log(error.response)
      });
  };
}
