import React, { useEffect, useState } from 'react';
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import './JobRequest.styles.css';
import Tab from 'react-bootstrap/Tabs';
import JobRequestTable from '../../../components/Talents/JobRequestTable/JobRequestTable.component';
import CloseJobRequestTable from '../../../components/Talents/JobRequestTable/CloseJobRequestTable.component';
import { useDispatch, useSelector } from 'react-redux';
import { getAllJobForTalent, getAllCloseJobsForTalent } from '../../../store/action/hunter/postJob';
import Loader from 'react-loader-spinner';
import SentJobRequestTable from '../../../components/Talents/SentJobRequestTable/SentJobRequestTable.component';

const JobRequest = () => {
  const [openJobs, setOpenJobs] = useState(null);
  const [closeJobs, setCloseJobs] = useState(null);

  const dispatch = useDispatch();
  const gethHunterOpenJobs = useSelector(
    (state) => state.scoutJobReducer.gethHunterOpenJobs
  );
  const getHunterCloseJobs = useSelector(
    (state) => state.scoutJobReducer.getHunterCloseJobs
  );
  console.log("gethHunterCloseJobs",getHunterCloseJobs);

  useEffect(() => {
    getOpenJobs();
    return () => {};
  }, []);

  const getOpenJobs = async () => {
    await dispatch(getAllJobForTalent());
    await dispatch(getAllCloseJobsForTalent());
    setOpenJobs(gethHunterOpenJobs);
    setCloseJobs(getHunterCloseJobs)
  };
  return (
    <section className='job_request'>
      <NavTalent />
      <h3>Job request</h3>

      {/* The content */}
      <div className='tab_job_wrapper'>
        <Tab
          defaultActiveKey='home'
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          <Tab eventKey='home' title='Open jobs'>
            {/* Table presentational component */}
            {/* {openJobs === null ? (
              <div className='d-flex justify-content-center'>
                <Loader type='Rings' color='blue' height={50} width={50} />
              </div>
            ) : ( */}
            <JobRequestTable gethHunterOpenJobs={gethHunterOpenJobs} />
            {/* )} */}
          </Tab>

          {/* closed jobs */}

          <Tab eventKey='invitation' title='Closed jobs'>
           
            <CloseJobRequestTable getHunterCloseJobs={getHunterCloseJobs} /> 
          </Tab>
          {/*Description tab*/}
          <Tab eventKey='description' title='Jobs applied for'>
            {/* <JobRequestTable /> */}
            <SentJobRequestTable />
          </Tab>
        </Tab>
      </div>
    </section>
  );
};

export default JobRequest;
