import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavBar from '../../../components/Navs/NavBar/NavBar.component';
import './Login.styles.css';
import leftLogo from '../../../assets/image/auth-image.svg';
import FormInput from '../../../components/FormInput/FormInput.component';
import CustomButton from '../../../components/CustomButton/CustomButton.component';
import google from '../../../assets/image/google.svg';
import facebook from '../../../assets/image/facebook.svg';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { login, loginSocial } from '../../../store/action/authAction/login';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';

import * as yup from 'yup';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { toast } from "react-toastify";
import { css } from 'glamor';


const Login = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    let initialState = {
        email : "",
        password : "",
    }
    const [state, setstate] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(false)
    const [picture, setPicture] = useState(false)
    // const [loginSocial, setLoginSocial] = useState(false)

    const responseFacebook = (response) => {
        console.log(response);
        console.log(response);
        setData(response);
        // setPicture(response.picture.url);
        // if (response.accessToken) {
        //         setLoginSocial(true);
        //     } else {
        //         setLoginSocial(false);
        //     }
      }
    
    const responseGoogle = (response) => {
    console.log(response);
    if (response.profileObj) {
        const data = {email: response.profileObj.email,
            password: response.profileObj.googleId
            }
        dispatch(loginSocial(data,history))
    } else {
        toast.error("Registration Not Successful, Please Try Again", {
            className: css({
              background: "#00FF00 !important",
              color: "white !important",
              fontWeight: "bold"
            }),
            position: toast.POSITION.TOP_CENTER,
          });
    }
    }

    return (
        <div className="container-fluid signup-wrapper">
        <div className="row signup-wrapper-child-1">  
        <div className="left col-md-5">
        <NavBar/>
        <div>
       {/*The heading*/}
        <h1>Join the GREAT MINDS REPUBLIC</h1>
        <img className="img-fluid" src={leftLogo} alt="workImage"/>
        </div>
        </div>
        <div className="right col-md-7">
        <h1>Login</h1>
        <h6>Kindly enter your email and password to login</h6>
        <div className="social-buttons">
        {/* <GoogleLogin
            clientId="1049340476924-pfld0v3e71vkm95tjf6oo2tmkumc545e.apps.googleusercontent.com"
            buttonText="Login with google"
            onSuccess={responseGoogle}
            // onFailure={responseGoogle}
            render={renderProps => (
                <button type="button" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <img src={google} alt="googleImage"/>
                    Login with google
                </button>
                )}
            cookiePolicy={'single_host_origin'}
        /> */}

        {/* <FacebookLogin
            appId="542844437010923"
            // autoLoad={true}
            fields="name,email,picture"
            callback={responseFacebook}
            render={renderProps => (
                <button type="button" onClick={renderProps.onClick} >
                <img src={facebook} alt="facebookImage"/>
                    Login with facebook
                </button>
                    )}
            /> */}
        </div>
        {/* <div className="line"><hr/>OR<hr/></div> */}
        <Formik initialValues= {{

            email: '',

            password:'',

            }} 
            validationSchema={yup.object({

            email: yup.string()

            .email('Invalid email address')

            .required('Please Enter Email Address'),
            password: yup.string()
            .required("please enter password")
            .min(8, "Password must be at least 8 character"),
            })} 
            onSubmit= {async values => {
                // console.log(values)
                setLoading(true)
                await dispatch(login(values,history))
                setLoading(false)
            }}>
                 {
                props=>(
        <Form >       
        <div>
        <FormInput
         label="Email:"
         name="email"
         type="email"
        //  value = {state.email}
        //  onChange = {handleChange}
        />
        <ErrorMessage  name="email">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
        <div>
        <FormInput
         label="Password:"
         name="password"
         type="password"
        //  value = {state.password}
        //  onChange = {handleChange}
        />
        <ErrorMessage  name="password">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        <p><Link to="/forgotPassword">Forgot password</Link></p>
        </div>        
        <CustomButton>
        {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={50}
           width={50}
         /> :null

            }
        Login</CustomButton>
        <p className="mobile-center">Don't have an account? <span><Link to="/signup">Signup</Link></span></p>
        </Form>
           )
        }
        </Formik>
        </div>       
        </div>
        </div>
    )
}




export default Login;