import React, {useState} from 'react';
import './AddCertification.styles.css';
import ModalInputComponent from '../../../ModalInput/ModalInput.component';
import { useDispatch, useSelector } from 'react-redux';
import { addCertification } from '../../../../store/action/publicProfileAction/addCertification';
import Loader from 'react-loader-spinner';
// import { Modal } from 'react-bootstrap';
// import close from '../../../../assets/image/close.svg';





const AddCertification = () => {
  const [displayContents, setDisplay] = useState(false);
  // const EducationDetail = useSelector(state=>state)
  const showContent = () => setDisplay(true);
  const closeCard = () => setDisplay(false);

  // console.log(EducationDetail)

  const getYears = ()=>{
    let currentYear = new Date().getFullYear(), years = [];
    let startYear = 1960;  
  while ( startYear <= currentYear ) {
      years.push(startYear++);
  }   
  return years;
  }
  getYears()
  let initialState = {
    title:"",
    year:"",
    conferring_organization :"",
    description :"Description for certificate",
  }

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [state, setstate] = useState(initialState)

  const handleChange = (e) => {
    const {name, value} = e.target;
    // console.log(value)
    setstate({...state,[name]: value})
    // console.log(state)
  }
  
  const handleSubmit= async (e) =>{
    e.preventDefault();
    // console.log(state);
    setLoading(true)
    await dispatch(addCertification(state))
    setLoading(false)
    setDisplay(false)
    // setShow(false)
  
  }



    return (
        <div>
        <div className="edit-desc">
        <p>Certification <span>(Optional)</span></p>
        <button type="button"  onClick={showContent}>
        Add
        </button>
        </div>
        { displayContents ?
    
          <div className="desc-content">
          <form onSubmit={handleSubmit}>
          <div className="form-group-des">
          <ModalInputComponent
           label="Enter name of certificate or award:"
           name="title"
           onChange={handleChange}
           type="text"
          />
          </div>
          <div className="form-group-des">
          <ModalInputComponent
           label="Certified from:"
           onChange={handleChange}
           name="conferring_organization"
           type="text"
          />
          </div>
          <div className="form-group-des">
          <label>Year of graduation:</label><br/>
           <select name="year" onChange={handleChange}>
           <option value="select country" >Select Year</option>
            {
            getYears().map((year,idx) => {
                return <option key={idx} value={year} >{year}</option>
            })
            } 
           </select>
          </div>          
          <div className="but">    
          <button type="button" onClick={closeCard}>Cancel</button>
          <button type="submit">
          {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={50}
           width={50}
         /> :"Add"
            }
            </button>
          </div> 
          </form>           
           </div>
          : null 
        }        
        </div>
    )
}


export default AddCertification;