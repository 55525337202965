import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';

export const initiateAgreement = (agreementDetails, history) => {
  // Define type constants here
  const INITIATE_AGREEMENT = 'INITIATE_AGREEMENT';

  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(agreementDetails);
    return axiosInstance
      .post('job/acceptagreement', agreementDetails)
      .then(async (response) => {
        console.log(response);
        if (response.status >= 200) {
          
          await dispatch({
            type: INITIATE_AGREEMENT,
            payload: response.data.data,
          });
         
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
};

export const rejectAgreement = (agreementDetails, id) => {
  // Define type constants here
  // const INITIATE_AGREEMENT = "INITIATE_AGREEMENT";

  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(agreementDetails);
    return axiosInstance
      .put(`job/rejectbid/${id}`, agreementDetails)
      .then(async (response) => {
        console.log(response);
        if (response.status >= 200) {
          console.log(response);
          await dispatch({
            type: 'REJECT_AGREEMENT',
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
};


export const getOfferForPayment = (id) => {
  // Define type constants here
  // const INITIATE_AGREEMENT = "INITIATE_AGREEMENT";

  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`job/getallsentjoboffer/${id}`)
      .then(async (response) => {
        console.log(response);
        if (response.status >= 200) {
          console.log(response);
          await dispatch({
            type: 'GET_EACH_BID_OFFER',
            payload: response.data.joboffer[0],
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
};


export const offerPayment = (paymentDetail, history) => {
  // Define type constants here
  // const INITIATE_AGREEMENT = 'INITIATE_AGREEMENT';

  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(paymentDetail);
    return axiosInstance
      .post('payment/pay', paymentDetail)
      .then(async (response) => {
        console.log('my payment response', response);
        if (response.status == 200) {
          console.log('now 200', response)
          // history.push(response.data.authorization_url)
          window.location.replace(response.data.authorization_url)
          // await dispatch({
          //   // type: INITIATE_AGREEMENT,
          //   payload: response.data.data,
          // });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
};


// payment verification

export const VerifyPayment = (extract) => {
  
 console.log('extracted value', extract)
 console.log('my token', sessionStorage.getItem('token'))
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    
    return axiosInstance
      .get(`payment/verifypayment/${extract}`)
      .then(async (response) => {
        console.log('verify payment response',response);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            // type: INITIATE_AGREEMENT,
            // payload: response.data.data,
          });
          
        }
        else{
          await dispatch({
            type: 'PAYMENT_ERROR',
            payload: response
          })
        }
      })
      .catch((error) => {
      });
  };
};


// check if net_pay is available for hunter

export function getHunterNetPayCheck() {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    return axiosInstance
      .get('payment/pending-payback')
      .then(async (response) => {
        console.log(response);
        if (response.data.success === true) {
          console.log('hunter net-pay check', response.data);
          await dispatch({
            type: 'GET_HUNTER_WITHDRAWL',
            payload: response.data,
          });
          // await dispatch({
          //   type: 'GET_COMPLETED_PAYHISTORY',
          //   payload: response.data,
          // });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
