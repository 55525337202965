import React from 'react'
import "./policy.styles.css";

const Policy = () => {
  return (
    <div className='policy' id='#policy'>
        <h1 className='policy_header'>SOLVATE AFRICA – PRIVATE POLICY DOCUMENTATION</h1>
        <p className='policy__paragraph'>
            Solvate Craft Company  provides this Privacy Policy to let you know our policies and procedures regarding the collection, use and disclosure of information through www.solvateafrica.com (the “Site ”), and any other websites, features, applications, widgets or online services that are owned or controlled by Solvate Africa as well as any information Solvate Africa  collects offline in connection with the Service. It also describes the choices available to you regarding the use of, your access to, and how to update and correct your personal information. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact us through our official email: solvateafrica@gmail.com <p className='policy__bold'>Mobile No;09035756431</p>
         </p>
         <p className='policy__paragraph'>
         Please note that certain features or services referenced in this Privacy Policy may not be offered on the Service at all times. Please also review our Terms of Service/User agreement, which governs your use of our Service, and which is accessible at the segment titled legal on the landing page. We’ve provided short highlights in this Privacy Policy to help you understand what information we collect, how we use it, and what choices or rights you may have. While these summaries help explain some of these concepts in a simple and clearway.
         </p>
         <p className='policy__paragraph'>
         At www.solvateafrica.com, accessible from solvateafrica.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by www.solvateafrica.com and how we use it.If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
         </p>
         <p className='policy__paragraph'>
         This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in www.solvateafrica.com. This policy is not applicable to any information collected offline or via channels other than this website. By using our website, you hereby consent to our Privacy Policy and agree to its terms
         </p>
         <h4 className='policy_subhead'>INFORMATION WE COLLECT</h4>
         <p className='policy__paragraph'>
         The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
         </p>
         <p className='policy__paragraph'>
         If you contact us directly, we may receive additional information about you such as your name, email address, phone number, and the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
         </p>
         <p className='policy__paragraph'>
         When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
         </p>
         <h4 className='policy_subhead'>HOW WE USE YOUR INFORMATION</h4>
         <p className='policy__paragraph'>We use the information we collect in various ways, including to:</p>
         <ul className='policy_info'>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>Communicate with you, either directly or through one of our partners, including for customer   service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
         </ul>
         <p>
         Information Collected automatically Like other online companies, we receive technical information when you use our Services. We use these technologies to analyze how people use the Service, to improve how our Site functions, to save your log-in information for future sessions, and we may serve you with advertisements that may interest you. We and our third party service providers, including analytics and third party content providers, may automatically collect certain information from you whenever you access or interact with the Service.
         </p>
         <p>
         This information may include, among other information, the browser and operating system you are using, the URL or advertisement that referred you to the Service, the search terms you entered into a search engine that led you to the Service, areas within the Service that you visited, which links you clicked on, which pages or content you viewed and for how long, other similar information and statistics about your interactions, such as content response times, download errors and length of visits to certain pages and other information commonly shared when browsers communicate with websites. We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, and to improve marketing, analytics, and site functionality. The information we collect also includes the Internet Protocol (“IP”) address or other unique device identifier (“Device Identifier”) for any device (computer, mobile phone, tablet, etc.) used to access the Service. 
         </p>
         <p>
         Solvate Africa and its partners use cookies or similar technologies to analyze trends, administer the website, track users’ movement around the website, the desktop app, and the mobile app, and to gather demographic information about our user base as a whole. The technology used to collect information automatically from Solvate Africa Users may include the following: Cookies: Like many websites, we and our marketing partners, affiliates, analytics, and service providers use “cookies” to collect information. A cookie is a small data file that we transfer to your computer’s hard disk for record-keeping purposes. We use both persistent cookies that remain on your computer or similar device (such as to save your registration ID and login password for future logins to the Service and to track your compliance with the Solvate Africa’s  Terms of Service) and session ID cookies, which expire at the end of your browser session (for example, to enable certain features of the Service, to better understand how Solvate Africa  Users interact with the Service and to monitor aggregate usage by Solvate Africa Users and web traffic routing on the Service).
         </p>

         <p className='policy_desc'>
         <span className='policy__bold'>Web Beacons: </span>We and our marketing partners, affiliates, analytics, and service providers may also employ software technology known as “web beacons” and/or “tracking tags” to help us keep track of what content on our Service is effective and to serve relevant advertising to you. Web beacons are small graphics with a unique identifier that may be invisible to you, and which are used to track the online activity of Internet users. Web beacons are embedded in the web pages you review or email messages you receive. Web beacons or similar technologies may be used for a number of purposes, including, without limitation, to count visitors to our Service, to monitor how Solvate Africa Users navigate the Service, to count how many emails that were sent were actually opened, or to count how many particular articles or links were actually viewed. Embedded Scripts: We and our marketing partners, affiliates, analytics, and service providers may also employ software technology known as an 
         </p>

         <p  className='policy_desc'>
         <span className='policy__bold'>Embedded Scripts: </span>
         We and our marketing partners, affiliates, analytics, and service providers may also employ software technology known as an Embedded Script. An Embedded Script is programming code that is designed to collect information about your interactions with the Service, such as the links you click on. The code is temporarily downloaded onto your computer or other device and is deactivated or deleted when you disconnect from the Service.
         </p>
         <p className='policy__paragraph'>
         In addition, we and our marketing partners, affiliates, analytics, and service providers may use a variety of other technologies (such as tags) that collect similar information for security and fraud detection purposes and we may use third parties to perform these services on our behalf.
         </p>
         <p className='policy__paragraph'>
         Also, User Profiles Freelancers may create a profile, with certain or all information publicly available. Clients and Agencies may also create organization profiles as well. You may have the opportunity to create a profile, which consists of information about you, and may include Personal Information, photographs, examples of your  which can  be removed by contacting us?????? Freelancer can complete registration on the Service, or at any time thereafter, we may request or re-request identity verification through specified means.
         </p>
         <p className='policy__paragraph'>
         Solvate Africa may use the information obtained from Identity Verification for purposes of verifying your identity, enforcing our Terms of Service and other agreements, and preventing fraud. Messaging Freelancers, Agencies, and Clients may communicate with each other through the Service. For example, Freelancers, Agencies, and Clients may wish to discuss Client needs and Freelancer work proposals. If you communicate with an Agency or Client, that Agency or Client will also be a “data controller” with respect to such communications.
         </p>
         <p className='policy_desc'>
         <span className='policy__bold'>Testimonials</span> We may display personal testimonials of satisfied customers on our Service, in addition to other endorsements. With your consent we may post your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us at ??????
         </p>
         <p className='policy__paragraph'>
         Work Listings through the Service If you choose to post a work listing via the Service as a Client, the contents of such listing will be viewable publicly, unless you designate the listing as only viewable through the Service or as private using the settings available on the applicable website. Work listings include information such as budget, location, history of work listing(s) by the Client, the names of other Freelancers performing work for the Client, Client feedback and rating information and timing of project performance.
         </p>
         <h4  className='policy_subhead'>LOG FILES</h4>
         <p className='policy__paragraph'>
         www.solvateafrica.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
         </p>
         <h4 className='policy_subhead'>DATA REQUEST PROTOCOL</h4>
         <p className='policy__paragraph'>
         Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
         </p>
         <p className='policy__paragraph'>
         Request that a business delete any personal data about the consumer that a business has collected.
         </p>
         <p className='policy__paragraph'>
         Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
         </p>
         <p className='policy__paragraph'>
         If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us directly.
         </p>
         <h4 className='policy_subhead'>DATA SUBJECT RIGHTS.</h4>
         <p className='policy__paragraph'>In accordance with applicable law, you may have the right to: </p>
         <p className='policy__paragraph'>Access Personal Information about you, including: (i) confirming whether we are processing your personal information; (ii) obtaining access to or a copy of your personal information. </p>
         <p className='policy__paragraph'>Request Correction of your personal information where it is inaccurate, incomplete or outdated. In some cases, you can update your personal information within your Solvate Africa account by logging in and visiting settings/user settings. </p>
         <p className='policy__paragraph'>Request Deletion, Anonymization or Blocking of your personal information when processing is based on your consent or when processing is unnecessary, excessive or noncompliant. Note that if your information is deleted, then your account may become deactivated. If your account is deactivated or you ask to close your account, you will no longer be able to use the Service. If you would like to close your account in our system, you can do so through the Solvate Service or contact us(</p>
         <p className='policy__paragraph'>
         Request Restriction of or Object to our processing of your personal information when processing is noncompliant. Withdraw your Consent to our processing of your personal information (All of these can be done by contacting us). 
         </p>
         <p className='policy__paragraph'>If you refrain from providing personal information or withdraw your consent to processing, some features of our Service may not be available. Request data portability and receive an electronic copy of personal information that you have provided to us. Be informed about third parties with which your personal information has been shared.</p>
         <h4 className='policy_subhead'>INFORMATION SHARING AND DISCLOSURE</h4>
         <p className='policy__paragraph'>
         We may share information about you to provide the Services, for legal and investigative purposes or if we are part of a merger or acquisition. We may also share non-identifying information with third parties. You have choices as to whether we share your personal information with third parties for their own marketing purposes. We may share aggregated Non-Identifying Information and we may otherwise disclose Non-Identifying Information (including, without limitation, Hashed Information) to third parties.
         </p>

         <p className='policy__paragraph'>
         We do not share your Personal Information with third parties for those third parties’ marketing purposes unless we first provide you with the opportunity to opt-in to or opt-out of such sharing.
         </p>
         <p className='policy__paragraph'>
         We may also share the information we have collected about you, including Personal Information, as disclosed at the time you provide your information, with your consent, as otherwise described in this Privacy Policy, or in the following circumstances: Information about Freelancers Shared with Clients, Agencies,
         </p>
         <p  className='policy__paragraph'>
         We can share information regarding Freelancers who have entered into a contract with a Client including information in Work Diaries and work history, with Clients within the Solvate Africa platform.
         </p>
         <p className='policy__paragraph'>
         Please note that if a Freelancer is suspended from the Solvate Africa Service, we may share that information with Clients for whom that Freelancer has worked or submitted proposals for work. We may also share information with Agencies to whom Freelancers are associated for a particular work project. If you choose to view a job post or submit a proposal for work as a Freelancer via the Service, we may share relevant information with the applicable Client(s), including, but not limited to, the information contained in your Freelancer Profile.
         </p>
         <p className='policy__paragraph'>
         We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services (e.g., without limitation, maintenance services, database management, web analytics and online advertising, payment processing, fraud detection and improvement of Solvate Africa features) or to assist us in analyzing how our Service is used. These third parties may have access to your Personal Information in order to perform these tasks on our behalf. What Happens If You Agree to Receive Information from Third Parties or Request that We Share Your Information: You may be presented with an opportunity to receive information and/or marketing offers from one or more third parties. If you agree at that time to have your Personal Information shared, your Personal Information will be disclosed to that third party (or parties) and will be subject to the privacy policy and practices of that third party.
         </p>

         <p className='policy__paragraph'>
         We are not responsible for the privacy policies and practices of third parties, and, if you later decide that you no longer want to receive communications from a third party, you will need to contact that third party directly. You also may request, sometimes through your use of an SNS or similar interactive feature or third party application, that we share information about you with a third party and we will typically do so under those circumstances. Legal and Investigative Purposes: Solvate Africa will share information with government agencies as required by law.
         </p>
         <h4 className='policy_subhead'>CHILDREN'S INFORMATION</h4>
         <p className='policy__paragraph'>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
         <p className='policy__paragraph'>
         www.solvateafrica.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly act.
         </p>
         <h4 className='policy_subhead'>PUBLIC PROFILE </h4>
         <p className='policy__paragraph'>The profile you create on our Site will be publicly accessible unless otherwise indicated. You may change the privacy settings of your profile through your account portal.</p>

         <h4 className='policy_subhead'>CHANGES TO THIS POLICY</h4>
         <p className='policy__paragraph'>
         We may change this Privacy Policy. If we make substantial changes, we will provide notice. Solvate Africa may update this Privacy Policy at any time and any changes will be effective upon posting. In the event that there are substantial changes to the way we treat your Personal Information, we will contact you through the Services prior to the change becoming effective. We may also notify you by email, in our discretion. However, we will use your Personal Information in a manner consistent with the Privacy Policy in effect at the time you submitted the information, unless you consent to the new or revised policy
         </p>
         <h4 className='policy_subhead'>CONTACTING US</h4>
         <p className='policy__paragraph1'>If you have any questions about this Privacy Policy, please contact us at solvateafrica@gmail.com  Or call: <span className='policy__bold'>Mobile No;09035756431</span></p>

    </div>
  )
}

export default Policy