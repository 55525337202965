import React from 'react';
import './TalentsGig.styles.css';
import talentCardimg from '../../../assets/image/talent-card-image.svg';
import star from '../../../assets/image/star.png';


const TalentsGig = () => {

    return (

    <section className='talents_gig'>
    <h1>Some creative Talents gig</h1>

    {/* The card */}
    <div className='gigs_card_wrapper'>

        <div className='card gigs_card'>
            <div>
            <img src={talentCardimg} alt="cardImg" width="258px"/>
            <h6>Shola </h6>
            <p>I will write you an exciting and beautiful content for all your brand engagement.</p>
            </div>

            <div className='copy_landing'>
                <img src={star} alt="starImage"/>
                <p>5.0</p>
                <span>(10)</span>
            </div>
        </div>

        <div className='card gigs_card'>
            <div>
            <img src={talentCardimg} alt="cardImg" width="258px"/>
            <h6>Elizabeth</h6>
            <p>I develop award winning brand logo designs that gives your business a fresh look and appeal.</p>
            </div>

            <div className='copy_landing'>
                <img src={star} alt="starImage"/>
                <p>5.0</p>
                <span>(10)</span>
            </div>
        </div>

        <div className='card gigs_card'>
            <div>
            <img src={talentCardimg} alt="cardImg" width="258px"/>
            <h6>Bukkie</h6>
            <p>I am  an excellent Voice Over Artist and translator expert with ability to deliver value. </p>
            </div>

            <div className='copy_landing'>
                <img src={star} alt="starImage"/>
                <p>5.0</p>
                <span>(10)</span>
            </div>
        </div>
        
        <div className='card gigs_card'>
            <div>
            <img src={talentCardimg} alt="cardImg" width="258px"/>
            <h6>Jay</h6>
            <p>My  Animation skill is brilliant I do solid and awesome job.</p>
            </div>

            <div className='copy_landing'>
                <img src={star} alt="starImage"/>
                <p>5.0</p>
                <span>(10)</span>
            </div>
        </div>
    </div>
        </section>
    )
}

export default TalentsGig;