import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./CreateJob.styles.css";
import close from '../../../assets/image/close.svg';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



const Milestonemodal = ({handleCloseMilestone, milestones, showmilestone}) =>{

  return (
    <>
      <Modal show={showmilestone} onHide={handleCloseMilestone}>
        {/* <Modal.Header closeButton>
          <Modal.Title className='milestones_modaltitle'>Milestones</Modal.Title>
        </Modal.Header> */}
         <div className='title-cus'>
                    <img src={close} alt='closeimage' onClick={handleCloseMilestone} />
                    <h3>List of milestones</h3>
          </div>
        <Modal.Body>
        <Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
          <th className='active_head_bg'>No</th>
                    <th className='active_head_bg'>Title</th>
                    <th className='active_head_bg'>Description</th>
                    <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Amount to be deducted when milestone is missed
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                                          
                        <div>Penalty(%)</div>
                        {/* <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">View</button> */}
                      </OverlayTrigger>
                    ))}
                  </></th>
                  <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Numbers of days the project will be due when active.
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                                          
                        <div>Due Day(s)</div>
                        {/* <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">View</button> */}
                      </OverlayTrigger>
                    ))}
                  </></th>
          </tr>
        </thead>
        <tbody>
         
              {/* <table className='milestone_table'>
                <thead>
                  <tr >
                    <th>No</th>
                    <th>Title</th>
                    <th>Due Day</th>
                    <th>Description</th>
                    <th>Penalty</th>
                  </tr>
                </thead>
                <tbody> */}
                {
            milestones?.map((milestone, index )=> { 
                   return  <>
                            <tr key={index}>
                              <td>{index+1}</td>
                            <td>{milestone.title}</td>
                            <td>{milestone.description}</td>
                            <td>{milestone.penalty}</td>
                            <td>{milestone.due}</td>
                            </tr>
                            </>
                  })
                }
                {/* </tbody>
              </table> */}
             </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Milestonemodal;