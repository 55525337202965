import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
   submitmilestone: null,
   submittedmilestone: [],
   editedmilestone: {},
   loading: false
}
// SUBMITED_MILESTONE
export const MilestoneReducer =(state=initialState, action)=>{
    if(action.type === "SUBMIT_MILESTONE"){
        toast.success("milestone successfully submitted",
        {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'newTest',
            bodyClassName: "grow-font-size",
            progressClassName: 'fancy-progress-bar'
        }
            
        )
        console.log(action)
        return Object.assign({}, state, {
            submitmilestone: {...action.payload},
           
        });
    }

    // submitted milestone

    if(action.type === "SUBMITTED_MILESTONE"){
       
        return Object.assign({}, state, {
            submittedmilestone: {...action.payload},
           
        });
    }

    if(action.type === "EDITED_MILESTONE"){
        toast.info("milestone successfully edited",
        {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'newTest',
            bodyClassName: "grow-font-size",
            progressClassName: 'fancy-progress-bar'
        }
            
        )
        return Object.assign({}, state, {
            editedmilestone: {...action.payload},
           
        });
    }


return state;
}
