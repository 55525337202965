import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';

export function postJob(userDetails, history) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(userDetails);
    return axiosInstance
      .post('job/store', userDetails)
      .then(async (response) => {
        console.log(response);
        if (response.status >= 200) {
          // console.log(response)
          await dispatch({ type: 'ADD_OPEN_JOB', payload: response.data.data });
          history.push('/DashboardHomeContent');
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getScoutOpenJobs() {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('job')
      .then(async (response) => {
        console.log('open', response);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            type: 'GET_SCOUT_OPEN_JOBS',
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

// get all close jobs

export function getScoutCloseJobs() {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('job/hunterclosedjobs')
      .then(async (response) => {
        console.log('closed jobs', response.data);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            type: 'GET_SCOUT_CLOSE_JOBS',
            payload: response.data.jobs,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
       
      });
  };
}

export function editPostJob(userDetails, postId) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(userDetails);
    return axiosInstance
      .put(`job/${postId}`, userDetails)
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            type: 'EDIT_POST_JOB',
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function deletePostedJob(jobId) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(jobId);
    return axiosInstance
      .delete(`job/${jobId}`)
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          await dispatch({ type: 'DELETE_POST_JOB', payload: jobId });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getAllJobForTalent() {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('getalljobs')
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            type: 'GET_HUNTER_OPEN_JOBS',
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}


// get all close job invite for talent
export function getAllCloseJobsForTalent() {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('job/closedjobinvites')
      .then(async (response) => {
        console.log('close job invite', response);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            type: 'GET_HUNTER_CLOSE_JOBS',
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}


export function getTalentSentOffer(Talent_id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`job/getappliedjobs/${Talent_id}`)
      .then(async (response) => {
        console.log('get talent sent job', response);
        if (response.status === 200) {
          console.log(response);
          await dispatch({
            type: 'GET_SENT_OFFER',
            payload: response.data.appliedjobs,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getTalentAppliedJob(applicationId) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`job/getjobapplication/${applicationId}`)
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          await dispatch({
            type: 'GET_VIEWED_SENT_OFFER',
            payload: response.data.appliedjob,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getOpenJobWithBid() {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('job/getopenjobs')
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            type: 'GET_OPENJOB_WITH_BID',
            payload: response.data.openjobs,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getAllSentofferForJob(job_Id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`job/getallsentjoboffers/${job_Id}`)
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            type: 'GET_OPENJOB_WITH_BID',
            payload: response.data.openjobs,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}


// close job talent invite


export function closeJobInvite(invite) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(invite);
    return axiosInstance
      .post('job/invite', invite)
      .then(async (response) => {
        console.log('close job invite', response);
       
        if (response.data.success === true) {
          // console.log(response)
          await dispatch({ type: 'CLOSE_JOB_INVITE', payload: response.data.data });
          
        }
      })
      .catch((error) => {
        console.log(error.response);
        if(error.response.status >= 400){
        dispatch({ type: 'CLOSE_JOB_INVITE_ERROR', payload: error.response.data});
        }
      });
  };
}
