import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';

export function postHunterMessages(message) {
 
  return function (dispatch) {
    dispatch({type: 'REQUEST_HUNTER_MESSAGE'});
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log('message sent', message);
    return axiosInstance.post('chat/sendmessage', message)
      .then(async (response) => {
        console.log('hunter message response', response);
        if (response.status >= 200) {
          await dispatch({ type: 'ADD_TALENT_MESSAGE', payload: response.data.chat});
          // console.log(response)
          // await dispatch({ type: 'ADD_HUNTER_MESSAGE', payload: response.data.data });
        
        }
      })
      .catch((error) => {
        console.log(error.response);
  
      });
  };
}


export function GetConversations() {
  return function (dispatch) {
    
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance.get('chat/getconversations')
      .then(async (response) => {
        
        if (response.status >= 200) {
        
        await dispatch({ type: 'GET_CONVERSATIONS', payload: response.data.chats });
        }
      })
      .catch((error) => {
        
        console.log(error);
        dispatch({ type: 'GET_CONVERSTIONS_ERRORMSG', payload: error.message });
      });
  };
}



export  function GetConversation(id) {
  return async function (dispatch) {
    
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    
    return await axiosInstance.get(`chat/getconversation/${id}`)
      .then(async (response) => {
        console.log('hunter  btw conversation  response', response.data);
        if (response.status >= 200) {
           console.log(response)
          await dispatch({ type: 'GET_CONVERSATION', payload: response.data.messages });
        
        }
      })
      .catch((error) => {
        console.log(error.response);

         dispatch({ type: 'GET_CONVERSATION_ERRORMSG', payload:error.message});

        console.log(error);
      });
  };
}

// export function getScoutOpenJobs() {
//   return function (dispatch) {
//     const axiosInstance = axios.create({
//       baseURL: PATH,
//       timeout: 200000,
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         //    'X-CSRF-TOKEN: " "'
//         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
//       },
//     });
//     return axiosInstance
//       .get('job')
//       .then(async (response) => {
//         console.log(response);
//         if (response.status === 200) {
//           // console.log(response)
//           await dispatch({
//             type: 'GET_SCOUT_OPEN_JOBS',
//             payload: response.data.data,
//           });
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         console.log(error);
//       });
//   };
// }

