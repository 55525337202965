import React, { useState } from "react";
// import './JobRequestTable.styles.css';
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
// import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from "../../../assets/image/close.svg";
// import JobRequestModal from '../JobRequestModal/JobRequestModal.component';

import { useSelector } from "react-redux";
import TalentPitchModalModal from "../../../components/Hunter/TalentPitchModal/TalentPitchModal.component";
import AcceptBidModal from "../../../components/Hunter/AcceptBidModal/AcceptBidModal.component";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";

import CurrencyFormat from "react-currency-format";

const MainTalentBidTable = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showAccept, setShowAccept] = useState(false);
  const handleCloseAccept = () => setShowAccept(false);
  const handleShowAccept = () => setShowAccept(true);

  const category = useSelector((state) => state.categoriesReducer.categories);
  const getOpenJobWithBid = useSelector(
    (state) => state.scoutJobReducer.getOpenJobWithBid
  );
  console.log(getOpenJobWithBid);
  console.log("getOpenJobWithBid");

  return (
    <div>
      <Table className="table_size" striped bordered hover responsive>
        <thead>
          <tr>
            <th className="active_head_bg">#</th>
            <th className="active_head_bg">Description</th>
            {/* <th className="active_head_bg">Skills</th> */}
            <th className="active_head_bg">Milestone</th>
            <th className="active_head_bg">Price budget</th>
            <th className="active_head_bg">Date created</th>
            <th className="active_head_bg">Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Modal */}
          {/* <TalentPitchModalModal handleClose={handleClose} show={show} />
          <AcceptBidModal handleClose={handleCloseAccept} show={showAccept} /> */}

          {/* End of Modal */}
          {getOpenJobWithBid === null || getOpenJobWithBid == undefined ? (
            <div className="d-flex justify-content-center">
              <Loader type="Rings" color="white" height={50} />
            </div>
          ) : (
            getOpenJobWithBid.map((each, id) => {
              const { price_budget } = each;

              let new_price_budget = price_budget.toLocaleString();

              console.log(each.price_budget);
              console.log(new_price_budget);

              if (each.job_bids.length != 0) {
                return (
                  <tr>
                    <td>1</td>
                    <td dangerouslySetInnerHTML={{__html: each.job_description}}></td>
                    <td>{each.milestone}</td>
                    <td>{each.price_budget}</td>
                    {/* <td>{new_price_budget}</td> */}

                    <td>{moment(each.created_at).format("ll")}</td>
                    <td className="action_icons">
                      <Link to={`/hunterDashboard/talentBid/${each.id}`}>
                        <button onClick={handleShow} type="button">
                          View talent bid
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              }
            })
          )}
          {/* {
      gethHunterOpenJobs.map((each,index)=>{
        return(
          
            <tr key={index}>
              <td>{1+index}</td>
              <td>Jan 10, 2022</td>
              <td>
                {each.user.name}
              </td>
              <td>{each.job_description}</td>
              <td>{category.map(categ=>{
                    if (categ.id == each.category) {
                      return categ.skill.map(skil=>{
                        if (skil.id == each.skill) {
                          // skillTable = skil.name
                          return skil.name
                        }
                      })
                    }
                  } )}</td>
              <td>{each.price_budget}</td>
              <td>{each.milestone}</td>   
        
            <td className="action_icons">
              <button type='button' onClick={handleShow}>Apply for job</button>
            </td>
            </tr>  
        

        )
      })
    } */}
        </tbody>
      </Table>
    </div>
  );
};

export default MainTalentBidTable;
