// import { css } from "glamor";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const initialState = {
  getReviews: [],
  getReviewForAJob: null,
};

export const userReviewReducer = (state = initialState, action) => {
  if (action.type === 'GET_USER_REVIEW') {
    console.log(action.payload);
    // state.getReviews = [];
    return Object.assign({}, state, {
      getReviews: action.payload,
    });
  }
  if (action.type === 'GET_HUNTER_REVIEW_FOR_PROJECT') {
    console.log(action.payload);
    // state.getReviews = [];
    return Object.assign({}, state, {
      getReviewForAJob: action.payload[0],
    });
  }

  return state;
};
