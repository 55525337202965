import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';


export function UserNotification() {
    return function (dispatch) {
      const axiosInstance = axios.create({
        baseURL: PATH,
        timeout: 200000,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          //    'X-CSRF-TOKEN: " "'
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
   
      return axiosInstance
        .get('notification')
        .then(async (response) => {
          console.log(' gotten user notification', response);
          if (response.data.success === true) {
            await dispatch({ type: 'USER_NOTIFICATION', payload: response.data.notification });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    };
  }


  export function DetailNotification(id) {
    return function (dispatch) {
      const axiosInstance = axios.create({
        baseURL: PATH,
        timeout: 200000,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          //    'X-CSRF-TOKEN: " "'
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
   
      return axiosInstance
        .get(`notification/${id}`)
        .then(async (response) => {
          console.log(' detailuser notification', response);
          if (response.status >= 200) {
            await dispatch({ type: 'DETAIL_NOTIFICATION', payload: response.data });
            
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    };
  }


  export function MarkNotification(id) {
    return function (dispatch) {
    console.log("id pointttt", id)

      const axiosInstance = axios.create({
        baseURL: PATH,
        timeout: 200000,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          //    'X-CSRF-TOKEN: " "'
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
   
      return axiosInstance
        .put(`notification/mark/${id}`)
        .then(async (response) => {
          console.log(' marked notification', response);
          if (response.data.success === true) {
            await dispatch({ type: 'MARK_NOTIFICATION', payload: response.data.notification });
            
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    };
  }


  export function NotificationCount() {
    return function (dispatch) {
    

      const axiosInstance = axios.create({
        baseURL: PATH,
        timeout: 200000,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          //    'X-CSRF-TOKEN: " "'
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
   
      return axiosInstance
        .get('notification/unread')
        .then(async (response) => {
          console.log('notification count', response.data);
          if (response.data.success === true) {
            await dispatch({ type: 'NOTIFICATION_COUNT', payload: response.data
          });
            
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    };
  }