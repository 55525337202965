import React,{useState, useEffect} from 'react';
import './Overview.styles.css';
import GigsInput from '../../../../../components/GigsInput/GigsInput.component';
import info from '../../../../../assets/image/info.svg';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../../../FormInput/FormInput.component';
import { Chip } from '@material-ui/core';
import { editOverview, postOverview } from '../../../../../store/action/creategigAction/overview';
import Loader from 'react-loader-spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Overview = ({showSecondStep,selectedGig,param}) => {
//   let initialState = {
//     email : "",  
//     password : "",
// }
  const dispatch = useDispatch()
  // const [state, setstate] = useState(initialState)
    const [availableSkill, setAvailableSkill] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(false)
    const category = useSelector(state=>state.categoriesReducer.categories)
    const gig = useSelector(state=>state)

    const handleChangeCategory = (id)=>{
      console.log(id)
      const skillList = category.find(each=>each.id===Number(id)).skill
      console.log(skillList)
      setAvailableSkill(skillList)
      // dispatch(allState(id))
      // setCountryId(id)
  }
  
    return (
    <div>
      <Formik 
        enableReinitialize
        initialValues= { param? {
        title: selectedGig.title,
        description: selectedGig.description,
        categoryId: selectedGig.categoryId,
        skillId: selectedGig.skillId,
        tags: selectedGig.tags
        } : 
        {
          title: "",
          description: "",
          categoryId: "",
          skillId: "",
          tags:[{tag:'talent'}]
        }
      } 
        validationSchema={yup.object({

        title: yup.string()
        .required('Please enter Title'),
        description: yup.string()
        .required("please enter Description"),
        // categoryId: yup.string()
        // .required("Please select a category"),
        // skillId: yup.string()
        // .required("Please enter skill(s)")
        })} 
        onSubmit= {async values => {
            console.log(values)
            setLoading(true)
            param? await dispatch(editOverview(values,param)) :
            await dispatch(postOverview(values)) 
            setLoading(false)
            showSecondStep()
        }}>
            {
            ({props,handleChange,setFieldValue,values})=>(
        <Form> 
        <div className="form-group-gigs">
        {/* Tooltip */}
        <>
        {['top'].map((placement) => (
          <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
              <Tooltip id={`tooltip-${placement}`}>
                Enter a captivating title for your gig
              </Tooltip>
            }
            >
            <img src={info} alt="infoIcon"/>  
            </OverlayTrigger>
          ))
        }
          </>
        <div className="gig-input"> 
        <FormInput
        label="What is your skill or talent:"
        name="title"
        // defaultValue={selectedGig.title}
        type="text"/>
        </div>
        <ErrorMessage  name="title">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>

        <div className="form-group-gigs text">
        <img src={info} alt="infoIcon"/>   
        <label>Briefly describe your skill or talent</label><br/>  
        <Field     
        name="description" as='textarea'></Field>
        <ErrorMessage  name="description">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
              
        {/* <div className="form-group-gigs top-space">
        <label>Select category:</label><br/>
        <Field name="categoryId" as="select" onChange={(e)=>{handleChange(e); setFieldValue("cate",handleChangeCategory(e.target.value));}} >
        <option value="">Select category</option>
        {
          category.map((each,id)=>{
            return(
              <option key={id} value={each.id}>{each.name}</option>
            )
          })
        }     
        </Field>
        <ErrorMessage  name="categoryId">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div> */}
        {/* <div className="gig-input">
        <label>Enter skills:</label><br/>
        <Field name='skillId' as="input" >
       
        </Field>
        <ErrorMessage  name="skillId">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div> */}
        {/*<div className="form-group-gigs top-space">
        <Chip 
        value={"chips"}
        // onChange={() => setChips(chips)}
        suggestions={["Your", "Data", "Here"]}
      />
        </div>*/}
        <div className="gigs-button">
        <button type="button"
        >Cancel</button>
        <button type="submit"
        >
          {
              loading ?
              <Loader
                type="Rings"
                color="white"
                height={50}
                width={50}
              /> :"Next"
            }
        </button>
        </div>
        </Form> 
             )
            }
        </Formik>
        </div>
        )
}




export default Overview;