import React, { useState, useEffect } from "react";
import "./AcceptBidModal.styles.css";
import { Modal } from "react-bootstrap";
import ModalInputComponent from "../../ModalInput/ModalInput.component";
import close from "../../../assets/image/close.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { initiateAgreement } from "../../../store/action/hunter/initiateAgreementAction";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";

const AcceptBidModal = ({ handleClose, bid, show }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState();
  const handleEditorChange = (state) => {
    console.log("pre state", state);
    setEditorState(state);
    convertContentToHTML();
  };
  console.log("talent bid not showing close job", bid);

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  console.log("contentt", convertedContent);
  console.log(bid);
  let previousState = {
    start_date: "",
    end_date: "",
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setstate] = useState(previousState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setstate({ ...state, [name]: value });
    console.log(state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newstate = {
      ...state,
      terms: convertedContent,
      id: bid.job_id,
      creative_id: bid.user_id,
      job_type: bid.job.job_type,
    };
    setLoading(true);
    console.log(newstate);
    await dispatch(initiateAgreement(newstate));
    setLoading(false);
    handleClose();
  };

  return (
    <section>
      {!show ? (
        ""
      ) : (
        <Modal
          size="md"
          show={show}
          onHide={handleClose}
          dialogClassName="modal-500w"
        >
          <div className="title-cus">
            <img src={close} alt="closeimage" onClick={handleClose} />
            <h3>Initiate agreement</h3>
          </div>
          {/* <Link to={`/hunterDashboard/paymentbid/${talentBids.id}`}>Payment bid</Link> */}
          <Modal.Body>
            <form className="form-update" onSubmit={handleSubmit}>
              <div className="form-group-update">
                <ModalInputComponent
                  label="Start date"
                  name="start_date"
                  type="date"
                  onChange={handleChange}
                />
              </div>

              <div className="form-group-update">
                <ModalInputComponent
                  label="End date"
                  name="end_date"
                  type="date"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-update text-cover">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />
              </div>
              {/* <div className="form_job">
         <label>Do you accept the price budget?</label><br/>
         <select name="price">
         <option value="yes">Yes</option>
         <option value="no">No</option>
         </select>
        </div>
        <div className="form-group-update">
        <ModalInputComponent
         label="If no, what price will you accept?"
         name="price"
         type="text"
        />
        </div> */}
              <div className="job_button text-center">
                <button
                  type="submit"
                  disabled={!state.start_date || !state.end_date}
                >
                  {loading ? (
                    <Loader type="Rings" color="white" height={50} />
                  ) : (
                    "Initiate agreement"
                  )}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </section>
  );
};

export default AcceptBidModal;
