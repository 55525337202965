import {axiosInstance} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import {store} from "../../reduxStore/store"

export function country (countryId) {
    axiosInstance.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`
 
        return config;
      })
      return function(dispatch){
         return axiosInstance.get('get_countries').then ( async (response) => {
           console.log(response.data)
           console.log(response)
           if(response.status === 200){
              // console.log(response.data)
              await dispatch({ type: "COUNTRIES", payload: response.data,countryId });
          }
          })
          .catch(
              error => {
                
              }
          )

      }

}

export function allState (value) {
    axiosInstance.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`
 
        return config;
      })
      return function(dispatch){
          // console.log(value)
         return axiosInstance.get(`get_states/${value}`).then ( async (response) => {
             console.log(response)
           console.log(response.data)
            if(response.status === 200){
              await dispatch({ type: "STATES", payload: response.data });
          }
          })
          .catch(
              error => {
                
              }
          )

      }

}
