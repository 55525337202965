import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Aside from './aside';
import { FaBars } from 'react-icons/fa';
import { ToastContainer } from 'react-toastify';
import DashboardHomeContent from '../../../components/Hunter/DashboardHomeContent/DashboardHomeContent.component';
import CreateJob from '../CreateJob/CreateJob.component';
import TalentBid from '../TalentBid/TalentBid.component';
import MainTalentBid from '../TalentBid/MainTalentBid';
import ProjectTrackerHunter from '../ProjectTrackerHunter/ProjectTrackerHunter/ProjectTrackerHunter.component';
import MessageHunterDashboard from '../MessageHunter/MessageDashboard/MessageHunterDashboard';
import MessageHunterDetail from '../MessageHunter/MessageHunterDetail/MessageHunterDetail.component';
import ProjectTrackerSettingsHunter from '../ProjectTrackerHunter/ProjectTrackerSettingsHunter/ProjectTrackerSettingsHunter.component';
import PendingProject from '../PendingProject/PendingProject';
import ActiveProjects from '../ActiveProject/ActiveProject';
import CompletedProject from '../CompletedProject/CompletedProject';
import SubmittedProject from '../SubmittedProject/SubmittedProject';
import EscrowHome from '../Escrow/EscrowHome/EscrowHome.component';
import SubmittedProjectDetailPage from '../SubmittedProject/SubmittedProjectDetailPage';
import CompletedProjectDetailPage from '../CompletedProject/CompletedProjectDetailPage';
import GigDetails from '../../Talent/GigDetails/GigDetails.component';
import PublicProfileHunter from '../PublicWorkProfile/PublicProfileHunter.component';
import PaymentBid from '../../../components/Hunter/PaymentBid/PaymentBid';
import { VerifyingPayment } from '../ActiveProject/VerifyingPayment';
import NotificationHome from '../Notification/NotificationHome';
import MilestoneEdit from '../../../components/Hunter/JobsTable/MilestoneEdit';

const HunterDashboard = () => {
  const [rtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image] = useState(true);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <BrowserRouter>
      <ToastContainer />
      <div
        className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}
        style={{ position: 'relative !important' }}
      >
        <div style={{ postion: 'sticky', top: ' 0 !importat' }}>
          <Aside
            image={image}
            collapsed={collapsed}
            rtl={rtl}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
          />
        </div>
        <div className='btn-toggle' onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <Switch>
          <Route
            exact
            path='/hunterDashboard'
            component={DashboardHomeContent}
          />
          <Route
            exact
            path='/hunterDashboard/createJob'
            component={CreateJob}
          />
          <Route
            exact
            path='/hunterDashboard/TalentBid'
            component={MainTalentBid}
          />
          <Route
            exact
            path='/hunterDashboard/user/:id'
            component={PublicProfileHunter}
          />
          <Route
            exact
            path='/hunterDashboard/gigDetails/:id'
            component={GigDetails}
          />

          <Route
            exact
            path='/hunterDashboard/TalentBid/:id'
            component={TalentBid}
          />

          <Route
            exact
            path='/hunterDashboard/PendingProjects'
            component={PendingProject}
          />
          <Route
            exact
            path='/hunterDashboard/ActiveProjects'
            component={ActiveProjects}
          />

          <Route
            exact
            path='/hunterDashboard/payment/verify'
            component={VerifyingPayment}
          />
          <Route
            exact
            path='/hunterDashboard/SubmittedProjects'
            component={SubmittedProject}
          />
          {/* to='/milestoneedit */}
          <Route
            exact
            path='/hunterDashboard/milestoneedit'
            component={MilestoneEdit}
          />
          <Route
            exact
            path='/hunterDashboard/SubmittedProjects/detail/:id'
            component={SubmittedProjectDetailPage}
          />
          <Route
            exact
            path='/hunterDashboard/SubmittedProjects'
            component={SubmittedProject}
          />
          <Route
            exact
            path='/hunterDashboard/CompletedProjects'
            component={CompletedProject}
          />
          <Route
            exact
            path='/hunterDashboard/CompletedProjects/detail/:id'
            component={SubmittedProjectDetailPage}
          />
          <Route
            exact
            path='/hunterDashboard/projecttrackerhunter'
            component={ProjectTrackerHunter}
          />
           <Route
            exact
            path='/hunterDashboard/notification'
            component={NotificationHome}
          />
          <Route
            exact
            path='/hunterDashboard/trackersettingshunter/:id'
            component={ProjectTrackerSettingsHunter}
          />
          <Route exact
           path='/hunterDashboard/paymentbid/:id' 
           component={PaymentBid} />

           {/* verify payment */}

           {/* <Route exact
           path='/hunterDashboard/paymentbid/:id?:id' 
           component={PaymentBid} /> */}
           <Route exact
           path='/hunterDashboard/paymentbid/payment/verify' 
           component={PaymentBid} />
          <Route
            exact
            path='/hunterDashboard/messageHunter'
            component={MessageHunterDashboard}
          />
          {/* <Route exact path='/escrowHome' component={EscrowHome} /> */}
          {/* <Route
          <Route exact path='/escrowHome' component={EscrowHome} />
          
          {/* <MessageHunterDetail /> */}
          {/* <Route
            exact
            path='/hunterDashboard/messagedetail/:id'
            component={MessageHunterDetail}
          /> */}
           
           <DashboardHomeContent  />
          
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default HunterDashboard;
