import React from 'react';
import './CustomButton.style.css';



const CustomButton = ({children}) => {
    
    return (
      <div>
      <button type="submit">{children}</button>
      </div>
    )
    }


export default CustomButton;