import React, {useState} from "react";
import { Modal } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import close from '../../../assets/image/close.svg';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useLocation } from "react-router";
import editIcon from '../../../assets/image/edit-icon.svg';
import Loader from 'react-loader-spinner';
import { EditMilestone } from "../../../store/action/milestoneAction/Milestone";
import './milestoneedit.styles.css';
import { useHistory } from "react-router";



const MilestoneEdit= () => {
  const [showedit, setShowedit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [milestoneedit, setMilestoneEdit] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
    const location = useLocation();
  const { milestones} = location.state || {}
console.log('ilestonesss now', milestones);
   const handleShow = (data) => {
        setMilestoneEdit(data);
        setShowedit(true)
   }
   const handleClose = () => setShowedit(false);
   
   const handlemy = (e) => {
    const {name, value} = e.target;
    setMilestoneEdit({...milestoneedit, [name]: value });
    
     }
    
     console.log('ilssss', milestoneedit)
   const handleSubmit = async (e) => {
    e.preventDefault()
    
    const data = {
        description: milestoneedit.description,
        due: milestoneedit.due,
        id:milestoneedit.id,
        job_id: milestoneedit.job_id,
        penalty: milestoneedit.penalty,
        title : milestoneedit.title
    }
    setLoading(true);
     await dispatch(EditMilestone(data, history));
     setLoading(false);
     setShowedit(false);

   }
    return(
       
<div className="milestone-edit" onSubmit={handleSubmit}>
<Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Title</th>
            <th className='active_head_bg'>Description</th>
            <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Amount to be deducted when milestone is missed
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                                          
                        <div>Penalty(%)</div>
                        {/* <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">View</button> */}
                      </OverlayTrigger>
                    ))}
                  </></th>
                  <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Numbers of days the project will be due when active.
                          </Tooltip>
                        }
                      >                     
                        <div>Due Day(s)</div>
                      
                      </OverlayTrigger>
                    ))}
                  </></th>
                  <th className='active_head_bg'>Edit</th>
          </tr>
        </thead>
        <tbody>
          
            { milestones?.map((data, index) => {
          return <>
               <tr>
                <td>{index+1}</td>
                <td>{data.title}</td>
                <td>{data.description}</td>
                 <td>{data.penalty}</td> 
                <td>{data.due}</td>
                <td className='action_icons'>
                  <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Edit milestone
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <img
                          onClick={() => handleShow(data)}
                          className='edit_icon'
                          src={editIcon}
                          alt='editIcon'
                        />
                      </OverlayTrigger>
                    ))}
                  </>
                </td>

                <Modal
                  size='md'
                  show={showedit}
                  onHide={handleClose}
                  dialogClassName='modal-500w'
                >
                  <div className='title-cus'>
                    <img src={close} alt='closeimage' onClick={handleClose} />
                    <h3>Edit job</h3>
                  </div>
                  <Modal.Body>
                    <form className='edit_milestone'  onSubmit={handleSubmit}>
                <div className='job_field_card'>
                <label className='milestone_label' for='milestone_label'>Milestone Title- This can read like
            ("Job or Project Delivery)</label><br/>
            <input type='text' placeholder='enter a milestone title'
         name='title' id='milestone_label' onChange={handlemy} 
             defaultValue={data?.title} className="milestone_input" required/>
             </div>
             <div className='job_field_card'>
            <label className='milestone_label'>Due Date(Enter the number of days required from the date
            when talent bid is accepted and work starts up to completion)</label><br/>
            <input type='number' placeholder='enter number days required'
            name='due' onChange={handlemy} defaultValue={data?.due} className="milestone_input"  required/>
            </div>
            <div className='job_field_card'>
             <label className='milestone_label2'>Description- This can read like (share first design for review)<br/>
            <span style={{fontSize: '1.2rem'}}>N.B</span>The description is to help you state your expectations on when the Talent should revert with dates up 
            until completion of task but please plan within the total number of days you have indicated in the "Due Date" box</label><br/>
            <textarea  placeholder='milestone description' required
            name='description' onChange={handlemy} defaultValue={data?.description} className="milestone_input"/>
            </div>
            <div className='job_field_card'>
         <label className='milestone_label2'>Penalty ( % Amount to be deducted when milestone is missed)</label><br/>
            <input type='number' placeholder='enter amount to be deducted in percentage' required
            name='penalty' onChange={handlemy} defaultValue={data?.penalty} className="milestone_input"/>
        </div>
      
      <button type="submit" className="milestone_add">
      {loading ? (
                          <Loader
                            type='Rings'
                            color='white'
                            height={50}
                            width={50}
                          />
                        ) : (
                          'Edit'
                        )}
      </button>
     
                    </form>
                  </Modal.Body>
                </Modal>
                </tr>
                </>
                  }) 
      }
        </tbody>
        </Table>
        </div>
        
    )
}

export default MilestoneEdit;