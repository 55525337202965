
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './Notification.styles.css';
import {FaLink} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import close from '../../../assets/image/close.svg';
import {GiFlowerTwirl} from 'react-icons/gi';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MarkNotification } from '../../../store/action/notifications/notification';

const NotificationHome = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notificationReducer.getnotification);
  
  const handleRead = async(id) => {
    await dispatch(MarkNotification(id))
  }
  console.log('notification component', notifications);

  
  return (
    <section className='notification'>
     
     <table className='notification__table' >
        <thead>
            <tr>
                <th>S/N</th>
                <th >Title</th>
                {/* <th>Sender</th> */}
                <th >Description</th>
                <th>View page</th>
            </tr>
        </thead>
        <tbody>
          {  
          notifications?.length === 0 ? "No available notification"
          :     notifications?.data.map((notify, index) => {

              return <tr key={notify.id}>
                    <td ><GiFlowerTwirl/></td>
                    <td >{notify?.title}</td>
                    {/* <td>{notify?.user?.name}</td> */}

                    <td style={{width: '20rem'}}>{notify?.description}</td>
                    <td  onClick={() => handleRead(notify?.id)}>
                    <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                           view page
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <Link to={notify?.link}><FaLink /></Link>
                      </OverlayTrigger>
                    ))}
                  </>
                    </td>
                     </tr>
                    })
            }
        </tbody>
     </table>
    </section>
  );
};

export default NotificationHome;
