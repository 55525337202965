import React, { useState } from "react";
import "./JobRequestTable.styles.css";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import ModalInputComponent from "../../ModalInput/ModalInput.component";
import moment from "moment";
import close from "../../../assets/image/close.svg";
import JobRequestModal from "../JobRequestModal/JobRequestModal.component";
import { useSelector } from "react-redux";
import { bool } from "yup";
import JobRequestMilestonemodal from "../Milestones/Milestones.modal";
import Milestonemodal from "../../../pages/Hunter/CreateJob/Milestonemodal.component";

const JobRequestTable = ({ gethHunterOpenJobs }) => {
  const [show, setShow] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [showmilestone, setShowmilestone] = useState(false);
  const handleClose = () => setShow(false);
  const [applyJobId, setApplyJobId] = useState(null);
  const handleShow = (job) => {
    setShow(true);
    setApplyJobId(job);
  };

  // view milestone
  const handleCloseMilestone = () => {
    setShowmilestone(false);
  };
  const handleShowMilestone = ({ milestones }) => {
    console.log("each point in job table", milestones);
    setMilestones(milestones);
    setShowmilestone(true);
  };

  const category = useSelector((state) => state.categoriesReducer.categories);
  

  return (
    <div>
      <Table className="table_size" striped bordered hover responsive>
        <thead>
          <tr>
            <th className="active_head_bg">#</th>
            <th className="active_head_bg">Date</th>
            <th className="active_head_bg">Username</th>
            <th className="active_head_bg">Job Description</th>
            {/* <th  className='active_head_bg'>Job Type</th> */}
            <th className="active_head_bg">Price budgets</th>
            <th className="active_head_bg">Net pay</th>
            <th className="active_head_bg">Milestone</th>
            <th className="active_head_bg">Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Modal */}
          <JobRequestModal
            handleClose={handleClose}
            show={show}
            applyJob={applyJobId}
          />
          <Milestonemodal
            showmilestone={showmilestone}
            milestones={milestones}
            handleCloseMilestone={handleCloseMilestone}
          />

          {/* <JobRequestMilestonemodal showmilestone={showmilestone} handleCloseMilestone={handleCloseMilestone}/> */}
          {/* End of Modal */}
          {/* <tr>
            <td>1</td>
            <td>Jan 10, 2022</td>
            <td>I need someone who can deliver the best
            of article writing</td>
            <td>Copywriting</td>
            <td>5,000</td>
            <td>4 milestones</td>   
      
        <td className="action_icons">
       <button
        onClick={handleShow} type='button'>Apply for job</button>
        </td>
          </tr>   */}
          {gethHunterOpenJobs.map((each, index) => {
            const { price_budget, net_pay } = each;
            let new_price_budget = price_budget
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            let new_net_pay = net_pay
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            console.log(new_net_pay);
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{moment(each.created_at).format("ll")}</td>
                {/* <td>{1 + index}</td>
                <td>{moment(each.user.created_at).format("MMMM Do YYYY")}</td> */}

                <td>{each.user.name}</td>
                <td dangerouslySetInnerHTML={ {__html: each.job_description } }></td>
                {/* <td>
                  <div
                    dangerouslySetInnerHTML={{ __html: each.job_description }}
                  ></div>
                </td> */}

                {/* <td>
                  {category.map((categ) => {
                    if (categ.id == each.category) {
                      return categ.skill.map((skil) => {
                        if (skil.id == each.skill) {
                          // skillTable = skil.name
                          return skil.name;
                        }
                      });
                    }
                  })}
                </td> */}
                {/* <td>{each.price_budget}</td>
                <td>{each?.net_pay}</td> */}

                <td>₦{new_price_budget}</td>
                {/* <td>{each?.net_pay}</td> */}
                <td>₦{new_net_pay}</td>
                <td className="action_icons">
                  <button
                    type="button"
                    onClick={() => handleShowMilestone(each)}
                  >
                    Milestones
                  </button>
                </td>

                <td className="action_icons">
                  <button type="button" onClick={() => handleShow(each)}>
                    Apply for jobs
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default JobRequestTable;
