import React, { useEffect, useState } from 'react';
import './EditGig.styles.css';
import NavTalent from '../../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import OverviewWrapper from '../../../../components/Talents/GigSetup/CreateGig/OverviewWrapper/OverviewWrapper.component';
import FAQWrapper from '../../../../components/Talents/GigSetup/CreateGig/FAQWrapper/FAQWrapper.component';
import ScopePricingWrapper from '../../../../components/Talents/GigSetup/CreateGig/ScopePricingWrapper/ScopePricingWrapper.component';
import ScopePricingPackage from '../../../../components/Talents/GigSetup/CreateGig/ScopePricingPackage/ScopePricingPackage.component';
import GalleryWrapper from '../../../../components/Talents/GigSetup/CreateGig/GalleryWrapper/GalleryWrapper.component';
import RequirementsWrapper from '../../../../components/Talents/GigSetup/CreateGig/RequirementsWrapper/RequirementsWrapper.component';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import EditOverviewWrapper from '../../../../components/Talents/GigSetup/EditGig/EditOverviewWrapper/EditOverviewWrapper.component';
import EditGalleryWrapper from '../../../../components/Talents/GigSetup/EditGig/EditGalleryWrapper/EditGalleryWrapper.component';
import EditFAQWrapper from '../../../../components/Talents/GigSetup/EditGig/EditFAQWrapper/EditFAQWrapper.component';
import EditRequirementsWrapper from '../../../../components/Talents/GigSetup/EditGig/EditRequirementsWrapper/EditRequirementsWrapper.component';
import EditScopePricingWrapper from '../../../../components/Talents/GigSetup/EditGig/EditScopePricingWrapper/EditScopePricingWrapper.componenet';







const EditGig = () => {
    const [chips, setChips] = useState([]);
    const param = useParams()
    const userGigs = useSelector(state=>state.createGigReducer.userGigs)
    const [selectedGig, setSelectedGig] = useState({})
    const dispatch = useDispatch()
    useEffect(() => {
      findSelectedGig()

      return () => {
        
      };
    }, [])
    let pick = {}
    const findSelectedGig = ()=>{
      const selected = userGigs.find(each=>each.id === Number(param.id))
      console.log(selected)
      dispatch({ type: "ADD_EDIT_GIG_FAQ", payload: selected })
      setSelectedGig(selected)
    }

  console.log(param)
    const [showOverview, setOverview] = useState (true);
    const [showFAQ, setFAQ] = useState (false);
    const [showQuestion, setQuestion] = useState (false);
    const [showScope, setScope] = useState (false);
    const [showGallery, setGallery] = useState (false);
    const showSecondStep = (value) =>{ 
      setOverview(false)
      setFAQ(true)
    }
    const showThirdStep = (value) =>{ 
      setFAQ(false)
      setQuestion(true)
    }
    // const showFourthStep = (value) =>{ 
    //   setQuestion(false)
    //   setScope(true)
    // }
    // const showFifthStep = (value) =>{ 
    //   setScope(false)
    //   setGallery(true)
    // }
    
    // const showFourthStep = (value) =>{ 
    //   setScope(false)
    //   setGallery(true)
    // }
    const showFourthStep = (value) =>{ 
      setOverview(false)
      setGallery(true)
    }
    // METHODS TO GO BACK THE MULTISTEP FORM
    const backFourthStep = (value) => {
      setGallery(false)
      setScope(true)
    }
    const backThirdStep = (value) => {
      setQuestion(true)
      setScope(false)
    }
    const backSecondStep = (value) => {
      setFAQ(true)
      setQuestion(false)
    }
    const backFirstStep = (value) => {
      setOverview(true)
      setGallery(false)
    }


    return (
        <div>
     <NavTalent/>
     <section>
        <div className="gig-title">
        <h1>Edit Gig</h1>
        <h6>
        Kindly take out time to 
        fill this form, inorder to edit your gig
        </h6>
        </div>
        {/*Overview and description*/}
        {
          showOverview ? 
          <EditOverviewWrapper
          showFourthStep ={showFourthStep}
          selectedGig={selectedGig}
          param= {param.id}
          />     
          
          :""
        }
    
         {/*FAQ*/}
         {/* {
          showFAQ ? 
          <EditFAQWrapper 
          showThirdStep = {showThirdStep}
          backFirstStep= {backFirstStep}
          selectedGig={selectedGig}
          param= {param.id}
          /> :
          ""  
         } */}

         {/*REQUIREMENTS*/}
         {/* {
          showQuestion ?
          <EditRequirementsWrapper 
          showFourthStep={showFourthStep}
          backSecondStep={backSecondStep}
          selectedGig={selectedGig}
          param= {param.id}
          /> :""
         } */}
        {/*END OF REQUIREMENTS*/}

        {/*SCOPE AND PRICING*/}
        {/* {
          showScope?
          <EditScopePricingWrapper 
          showFifthStep={showFifthStep}
          backThirdStep={backThirdStep}
          selectedGig={selectedGig}
          param= {param.id}
          />
          :
          ""
        } */}
        
          {/*PRICING PACKAGES*/}
          {/*<ScopePricingPackage/>*/}

          
          {/*GALLERY*/}
          {
            showGallery?
            // <GalleryWrapper backFirstStep={backFirstStep}/> :""
            <EditGalleryWrapper backFirstStep={backFirstStep} selectedGig={selectedGig}
            param= {param.id}  /> : ''

          }

          {/*END GALLERY*/}

 


        </section>
        </div>
    )
}

export default EditGig;