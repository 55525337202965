import React, { useState, useEffect } from 'react';
import NavLanding from '../../../components/Navs/NavLanding/NavLanding.component';
import './HomeLanding.styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {searchGig} from '../../../store/action/searchAction/searchGigAction'
import { useHistory } from 'react-router';
import Loader from 'react-loader-spinner';
import Slider from "react-slick";
import accessLogo from '../../../assets/image/access-removebg-preview 1.svg';
import visaLogo from '../../../assets/image/visa-removebg-preview 1.svg';
import fordLogo from '../../../assets/image/ford-removebg-preview 1.svg';
import novavaxLogo from '../../../assets/image/novavax-removebg-preview 1.svg';
import TalentsGig from '../../../components/Landing/TalentsGig/TalentsGig.component';
import TalentFooter from '../../../components/Footer/TalentFooter/Footer.component';
import GigCategory from '../../../components/Landing/GigCategory/GigCategory.component';
import BrandsTrustUs from '../../../components/Landing/BrandsTrustUs/BrandsTrustUs.component';
import Howitworks from '../../../components/Landing/howitworks/Howitworks';
import Knowmore from '../../../components/Landing/knowmore/knowmore';
import Trysolvate from '../../../components/Landing/trysolvate/trysolvate';
import Frequently from '../../../components/Landing/frequently/frequently';
import newone from  '../../../assets/image/newthree.png';
import one from  '../../../assets/image/onermv.png';
import two from  '../../../assets/image/twormv.png';
import three from  '../../../assets/image/threermv.png';
import four from  '../../../assets/image/fourrmv.png';
import five from  '../../../assets/image/fivermv.png';


// import yellowtop from "../../../assets/image/blackwoman.jpg";
import blueshirt from "./blueshirt.png";
import {BiSearch} from "react-icons/bi"
import { landingFaq } from '../../../store/action/landingFaq/Faq';
import NavSubLanding from '../../../components/Navs/NavLanding/NavSubLanding';




const HomeLanding = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState();
    const dispatch = useDispatch();
    const histroy = useHistory();

    const handleChange = (e)=>{
        let name = e.target.name;
        let value = e.target.value;
        setData({...data, [name]:value})
        console.log(data)
    }
    useEffect(async() =>{
      await dispatch(landingFaq())
    }, []); 
    const faqs = useSelector(state => state.landingFaqReducer.landingfaq);
     console.log('faq component result', faqs)

    const handleSubmit = async(e) =>{
        e.preventDefault()
        setLoading(true)
        await dispatch(searchGig(data));
        setLoading(false)
        histroy.push(`/gigSearchResult/${data.title}`)

    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 550,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
              speed: 500,
              arrows: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              speed: 500,
              arrows: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              speed: 500,
              arrows: true,
            }
          }
        ]
      };

    return (

        <section>
        {/* Navbar */}
        <NavLanding/>
        <NavSubLanding />

        <div className='hero_section'>
        <div className='hero_section--left'>
        <h1>Africa's largest market place
        for creative professionals</h1>
        <p>Join millions of businesses and independent
         creative gurus, who do awesome work together. 
         Find exceptional creative talents & gigs here.</p>
         {/* <form onSubmit={handleSubmit} className='hero_section--left-inputSearch '>
         <input 
         className='hero_search' type="search" name='title' onChange={handleChange}/>
         <button className='search_but' type="submit">
         {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={30}
           width={30}
         /> :'Search'

            }</button>
        </form> */}
        {/* <form onSubmit={handleSubmit} className='home_searchhome'>
         <input 
         className='home_searchinput' type="search" name='title' onChange={handleChange}/>
         <div style={{display: "flex !important"}} className=".search_butholder">
         <button className='search_but' type="submit">
         {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={30}
           width={30}
         /> :'Search'

            }</button>
            <button className='search_buticon' type="submit">
         {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={30}
           width={30}
         /> :<BiSearch/>

            }</button>
            </div>
        </form> */}
        </div>
        
        {/* Right hero section */}
        <div className="hero_section--right">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
          {/* <div className="carousel-inner">
            <div className="carousel-item active carousel_img">
              <img 
              src="https://res.cloudinary.com/peterdbrainy/image/upload/c_scale,w_339/v1643881831/pexels-kampus-production-5940856_1.png" 
              className="d-block w-100" alt="firstImage"/>
            </div>
            <div className="carousel-item">
              <img 
              src="https://res.cloudinary.com/peterdbrainy/image/upload/c_scale,w_340/v1643888543/pexels-photo-6147070_3.png" 
              className="d-block w-100" alt="heroImage"/>
            </div>
            <div className="carousel-item">
              <img
              src="https://res.cloudinary.com/peterdbrainy/image/upload/c_scale,w_339/v1643881831/pexels-kampus-production-5940856_1.png"
                className="d-block w-100"
                alt="heroImage"/>
            </div>
          </div> */}
          <div className="carousel-inner">

          <div className="carousel-item active carousel_img">
              <img 
              src={two}
              // style={{width: '100%'}} 
               alt="firstImage"/>
            </div>

             <div className="carousel-item">
              <img  
              src={five}
               alt="heroImage"/>
            </div>
            {/* <div className="carousel-item">
              <img  
              src={blueshirt}
               alt="heroImage"/>
            </div> */}
            {/* <div className="carousel-item active carousel_img">
              <img 
              src={yellowtop} 
              className="d-block w-100" alt="firstImage"/>
            </div>
            <div className="carousel-item">
              <img 
              src={blueshirt}
              className="d-block w-100" alt="heroImage"/>
            </div> */}
          </div>
          {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button> */}
        </div>
        </div>
        </div>

         {/*TRUSTED COMPANY LOGO SECTION */}
         {/* <div className='wrapper_carousel'>
         <h5>Trusted by:</h5>
         <div className='auto_carouselSlide'>
        <Slider {...settings}>
       
          <div className='visa'>
          <img src={accessLogo} alt="accessLogo"/>
          </div>
          <div className='visa'>
          <img src={visaLogo} alt="visaLogo"/>
          </div>
          <div className='visa'>
          <img src={fordLogo} alt="visaLogo"/>
          </div>
          <div className='visa'>
          <img src={novavaxLogo} alt="visaLogo"/>
          </div>
          <div className='visa'>
          <img src={accessLogo} alt="accessLogo"/>
          </div>
          <div >
          <img src={visaLogo} alt="visaLogo"/>
          </div>
        </Slider>
      </div>
         </div> */}
        
      {/*END OF TRUSTED COMPANY LOGO SECTION */}
      
        {/* TALENTS GIG CARDS */}
        <TalentsGig/>
        {/*END OF TALENTS GIG CARDS */}

        {/* GIG CATEGORIES */}
         <GigCategory/>
        {/*END OF GIG CATEGORIES */}

         {/* WHY LEADING BRANDS TRUST US SECTION */}
         <BrandsTrustUs/>
         {/* END OF WHY LEADING BRANDS TRUST US SECTION */}
         
         {/* {How it works} */}
         <Howitworks/>
         {/* END OF HOW IT WORKS */}
         {/* Know more */}
         {/* <Knowmore/> */}
         {/* End of know more */}
         {/* Try more */}
         <Trysolvate/>
         {/* End of Try more */}
         {/* Frequently asked */}
         <Frequently faqs={faqs} />
         {/* End of frequently asked */}
          {/* Footer */}
          <TalentFooter/>
        </section>
    )
}


export default HomeLanding;