import { createStore,applyMiddleware} from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/rootReducer';


const persistConfig = { // configuration object for redux-persist
    key: 'root',
    storage, 
  }

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store);
export {
    store,
    persistor
}