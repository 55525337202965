import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';
import { css } from 'glamor';
import { store } from '../../reduxStore/store';

// Store our types in global variables
// const GET_ADDED_ORDER = "GET_ADDED_ORDER";

export function addOrder(userDetails) {
  // Define type constants here
  const ADD_ORDER = 'ADD_ORDER';

  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(userDetails);
    return axiosInstance
      .post(`trackorder/store/${userDetails}`)
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          // let trackedOrder =
          //   store.getState().TrackerOrderReducer.getAddedOrders;
          // let index = trackedOrder.findIndex()
          // console.log(trackedOrder);
          console.log(response);
          await dispatch({ type: ADD_ORDER, payload: response.data.order });
          // history.push('/DashboardHomeContent');
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getAddedOrder() {
  const GET_ADDED_ORDER = 'GET_ADDED_ORDER';

  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('trackorder/get')
      .then(async (response) => {
        if (response.status === 200) {
          console.log(response);
          await dispatch({
            type: GET_ADDED_ORDER,
            payload: response.data.orders,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export const GET_TALENT_TRACk_ORDER = 'GET_TALENT_TRACL_ORDER';
export function getTalentTrackOders(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`task/getall/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          console.log(response);
          await dispatch({
            type: GET_TALENT_TRACk_ORDER,
            payload: response.data.tasks,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
export const GET_TALENT_TASK = 'GET_TALENT_TASK';

export function getSelectedTask(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`task/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          console.log(response);
          await dispatch({
            type: GET_TALENT_TASK,
            payload: response.data.task,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export const POST_TASK = 'POST_TASK';
export function postTrackerTask(data) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .post(`task/store`, data)
      .then(async (response) => {
        if (response.status === 200) {
          console.log(response);
          await dispatch({
            type: POST_TASK,
            payload: response.data.task,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
export const UPDATE_TASK = 'UPDATE_TASK';
export function changeTaskStatus(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .put(`task/settodoing/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          console.log(response);
          let task = store.getState().TrackerOrderReducer.getTask;
          console.log(task);
          let index = task.findIndex((x) => x.id == id);
          task[index] = response.data.task;
          console.log(task);
          await dispatch({
            type: UPDATE_TASK,
            payload: task,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
export function changeTaskStatusToDone(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .put(`task/settodone/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          console.log(response);
          let task = store.getState().TrackerOrderReducer.getTask;
          console.log(task);
          let index = task.findIndex((x) => x.id == id);
          task[index] = response.data.task;
          console.log(task);
          await dispatch({
            type: UPDATE_TASK,
            payload: task,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
export const DELETE_TASK = 'DELETE_TASK';
export function deleteTask(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .delete(`task/${id}`)
      .then(async (response) => {
        if (response.status === 200) {
          console.log(response);
          let task = store.getState().TrackerOrderReducer.getTask;
          console.log(task);
          let index = task.findIndex((x) => x.id == id);
          task.splice(index, 1);
          console.log(task);
          await dispatch({
            type: DELETE_TASK,
            payload: task,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
