import React, { useState } from 'react';
// import ProjectTrackerTable from '../../../../components/Hunter/ProjectTracker/ProjectTrackerTable/ProjectTrackerTable.component';
import './PendingProject.styles.css';
import { useHistory } from 'react-router-dom';
import PendingProjectTable from '../../../components/Hunter/JobsTable/PendingProjectTable';

const PendingProject = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory();

  return (
    <div className='Projects_wrapper container-fluid'>
      <h3>List of pending projects</h3>

      <div className='projects_button d-flex justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='projects_button--back'
          type='button'
        >
          Go back
        </button>
      
      </div>

      {/* Table */}
      {/* Pass all the important modal method as props to the child(table)*/}
      <PendingProjectTable
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </div>
  );
};

export default PendingProject;
