import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaUser, FaEnvelope, FaHome } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import { AiOutlineTransaction } from 'react-icons/ai';
// import { ImHistory } from 'react-icons/im';
import {Logout} from '../../../store/action/authAction/login';
import {IoIosNotificationsOutline} from 'react-icons/io'
// import { ReactComponent as Logo } from '../../../assets/footer-logo.svg'

import sidebarBg from '../../../assets/image/bg1.jpg';
import { Link } from 'react-router-dom';
import { NotificationCount, UserNotification } from '../../../store/action/notifications/notification';
import { useEffect } from 'react';
// import { userActions } from '../../../redux/user/user.actions';

const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  logout,
  handleCollapsedChange,
}) => {
  const dispatch = useDispatch();
  const handleSignOut = async() => {
    await dispatch(Logout())
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.reload();
  };

  const handleNotification = async() => {
        
    await dispatch(UserNotification())
  }

  useEffect(async () => {
    await dispatch(NotificationCount())
  }, []);
   const count = useSelector(state => state.notificationReducer.notificationcount);

   console.log('my count', count)
  // const logOut = () => {
  //   console.log("working");
  //   dispatch(userActions.logout())
  // }
  return (
    <ProSidebar
      className='pro-sidebar'
      // image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint='md'
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {/* <Logo/> */}
        </div>

        <div className='block'>
          <Switch
            height={16}
            width={30}
            toggled={toggled}
            collapsed={collapsed}
            handleCollapsedChange={handleCollapsedChange}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={handleCollapsedChange}
            checked={collapsed}
            onColor='#219de9'
            offColor='#bbbbbb'
          />
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape='circle'>
          {/* <MenuItem icon={<FaHome />}>
            <Link to='/landing'>Home</Link>
          </MenuItem> */}

          <MenuItem icon={<FaHome />}>
          <Link to='/hunterDashboard'>Home</Link>
          </MenuItem>
          {/* <MenuItem icon={<FaUser />}>
            <Link to='/jj'> Profile</Link>
          </MenuItem> */}
          <SubMenu
            suffix={<span className='badge yellow'>3</span>}
            title='Jobs'
            icon={<AiOutlineTransaction />}
          >
            <MenuItem>
              <Link to='/hunterDashboard/createJob'> Create jobs</Link>
            </MenuItem>
            <MenuItem>
              <Link to='/hunterDashboard'> List of jobs </Link>
            </MenuItem>
            <MenuItem>
              <Link to='/hunterDashboard/TalentBid'> Talent bid </Link>
            </MenuItem>
            <MenuItem>
              <Link to='/hunterDashboard/PendingProjects'>
                {' '}
                Pending Projects{' '}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to='/hunterDashboard/ActiveProjects'>
                {' '}
                Active Projects{' '}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to='/hunterDashboard/SubmittedProjects'>
                {' '}
                Submitted Projects{' '}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to='/hunterDashboard/CompletedProjects'>
                {' '}
                Completed Projects{' '}
              </Link>
            </MenuItem>
          </SubMenu>
          <MenuItem icon={<Badge color="primary" badgeContent={count?.unreadNotification}>
          <IoIosNotificationsOutline />
            </Badge>} onClick={handleNotification}>
            <Link to='/hunterDashboard/notification'> Notification
            </Link>
          </MenuItem>
          <MenuItem icon={<FaEnvelope />}>
            <Link to='/hunterDashboard/messageHunter'> Messages </Link>
          </MenuItem>
        </Menu>

        <Menu iconShape='circle' className='bottom-link'>
          <MenuItem icon={<MdSettings />}>
            <Link to='/hunterDashboard/projecttrackerhunter'>
              {' '}
              Project tracker
            </Link>
          </MenuItem>
          <MenuItem onClick={handleSignOut} icon={<FiLogOut />}>
            <Link to='/'> Logout</Link>
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className='sidebar-btn-wrapper'
          style={{
            padding: '20px 24px',
          }}
        ></div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
