import React, { lazy, Suspense } from 'react';
import './App.css';
import { Switch, Route, Router, BrowserRouter } from 'react-router-dom';
import SignUp from './pages/Auth/SignUp/SignUp.component';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Login from './pages/Auth/Login/Login.component';
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword.component';
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword.component';
import UpdateProfile from './pages/Talent/UpdateProfile/UpdateProfile.component';
import PublicWorkProfile from './pages/Talent/PublicWorkProfile/PublicWorkProfile.component';
import { getIsLogin } from './pages/Auth/getLogin';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import CreateGig from './pages/Talent/GigSetup/CreateGig/CreateGig.component';
import { injectStyle } from 'react-toastify/dist/inject-style';
import EditGig from './pages/Talent/GigSetup/EditGig/EditGig.component';
import GigDetails from './pages/Talent/GigDetails/GigDetails.component';
import SearchGigResult from './pages/Home/SearchGigResult/SearchGigResult.component';
// import Layout from './pages/Hunter/DashboardHome/DashboardHome';
import './pages/Hunter/dashboard-home/aside.styles.scss';
import CreateJob from './pages/Hunter/CreateJob/CreateJob.component';
import JobRequest from './pages/Talent/JobRequest/JobRequest.component';
import TalentBid from './pages/Hunter/TalentBid/TalentBid.component';
import HunterDashboard from './pages/Hunter/dashboard-home/hunterDashboard';
import MessageDashboard from './pages/Talent/Message/MessageDashboard/MessageDashboard.component';
import TalentOrders from './pages/Talent/Orders/Order.component';
import MessageDetails from './pages/Talent/Message/MessageDetails/MessageDetails.component';
import ProjectTrackerHunter from './pages/Hunter/ProjectTrackerHunter/ProjectTrackerHunter/ProjectTrackerHunter.component';
import ProjectTrackerTalentHome from './pages/Talent/ProjectTrackerTalent/ProjectTrackerTalentHome/ProjectTrackerTalentHome.component';
import EscrowHome from './pages/Hunter/Escrow/EscrowHome/EscrowHome.component';
import MessageHunterDetail from './pages/Hunter/MessageHunter/MessageHunterDetail/MessageHunterDetail.component';
import ProjectTrackerTalentLists from './pages/Talent/ProjectTrackerTalent/ProjectTrackerTalentLists/ProjectTrackerTalentLists.component';
import HomeLanding from './pages/Home/HomeLanding/HomeLanding.component';
import TalentEarning from './pages/Talent/TalentEarning/TalentEarning.component';
import SubmittedProjectDetailPage from './pages/Talent/Submitted Project Detail/SubmittedProjectTalentDetailPage';
import AppliedProjectDetailPage from './pages/Talent/Applied Project Detail/AppliedProjectTalentDetailPage';
import PaymentBid from './components/Hunter/PaymentBid/PaymentBid';
import Viewpastjobs from './components/Talents/Viewpastjobs/Viewpastjobs';
import Agreement from './pages/Home/HomeLanding/Agreement.component';
import Policy from './pages/Home/HomeLanding/Policy.component';
import TalentNotification from './pages/Talent/TalentNotification/TalentNotification';


// CALL IT ONCE IN YOUR APP
injectStyle();

// Using Lazy loading to chunk up our component and improve performance
// const UpdateProfile = lazy(() => import('./pages/Talent/UpdateProfile/UpdateProfile.component'))

// "dev": "react-scripts start",
//     "start": "serve -s build",
//     "build": "react-scripts build",
//     "test": "react-scripts test --env=jsdom",
//     "eject": "react-scripts eject",
//     "heroku-postbuild": "npm run build"

const requireLogin = (to, from, next) => {
  if (to.meta.auth) {
    // console.log(to)
    if (getIsLogin()) {
      next();
    }
    next.redirect('/login');
  } else {
    next();
  }
};

function App() {
  return (
    <div className='App'>
      <ToastContainer
        // className="newTest"
        hideProgressBar={true}
        autoClose={true}
      />
      <GuardProvider
        guards={[requireLogin]}
        // loading={Loading} error={NotFound}
      >
        <Switch>
          <GuardedRoute exact path='/signup' component={SignUp} />
          <GuardedRoute exact path='/policy' component={Policy} />
          <GuardedRoute exact path='/agreement' component={Agreement} />


          {/* <GuardedRoute exact path='/' component={Login} /> */}

          <GuardedRoute exact path='/login' component={Login} />
          <GuardedRoute
            exact
            path='/forgotPassword'
            component={ForgotPassword}
          />
          <GuardedRoute exact path='/resetPassword' component={ResetPassword} />
          <GuardedRoute
            exact
            path='/updateProfile'
            component={UpdateProfile}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path='/publicProfile'
            component={PublicWorkProfile}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path='/earning'
            component={TalentEarning}
            meta={{ auth: true }}
          />
           <GuardedRoute
            exact
            path='/talentnotification'
            component={TalentNotification}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path='/creategig'
            component={CreateGig}
            meta={{ auth: true }}
          />
          {/* <Route exact
           path='/paymentbid' 
           component={PaymentBid} /> */}

          <GuardedRoute
            exact
            path='/gigDetails/:id'
            component={GigDetails}
            meta={{ auth: true }}
          />
          <GuardedRoute exact path='/gigDetails' component={GigDetails}
          meta={{ auth: true }}  />
          <GuardedRoute
            exact
            path='/editGig/:id'
            component={EditGig}
            meta={{ auth: true }}
          />
          <Route
            exact
            path='/gigSearchResult/:search_query'
            component={SearchGigResult}
            meta={{ auth: true }}
          />
          <Route
            exact
            path='/gigSearchResult'
            component={SearchGigResult}
            meta={{ auth: true }}
          />
          <GuardedRoute path='/hunterDashboard' component={HunterDashboard} meta={{ auth: true }} />
          <GuardedRoute
            path='/hunterDashboard/messageHunter'
            component={HunterDashboard}
            meta={{ auth: true }}
          />
          {/* <Route exact path='/DashboardHomeContent/createJob' component={CreateJob}/>
      <Route exact path='/DashboardHomeContent/TalentBid' component={TalentBid}/> */}
          <GuardedRoute
            exact
            path='/jobrequest'
            component={JobRequest}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path='/order'
            component={TalentOrders}
            meta={{ auth: true }}
          />
          <GuardedRoute exact path='/messagedashboard' component={MessageDashboard} meta={{ auth: true }} />
          <GuardedRoute exact path='/viewpastjobs' component={Viewpastjobs} meta={{ auth: true }} />
          <GuardedRoute
            exact
            path='/talentMessageDetails/:id'
            component={MessageDetails}
            meta={{ auth: true }}
          />
          <GuardedRoute exact path='/talentEarning' component={TalentEarning} meta={{ auth: true }}/>
          {/* <Route exact path='/talentMessageDetails' component={MessageDetails} /> */}
          <GuardedRoute
            exact
            path='/trackerTalent/:id/:title/:start/:end'
            component={ProjectTrackerTalentHome}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path='/order/submittedProject/:id'
            component={SubmittedProjectDetailPage}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path='/jobrequest/detail/:id'
            component={AppliedProjectDetailPage}
            meta={{ auth: true }}
          />

          <GuardedRoute
            exact
            path='/order/completedProject/:id'
            component={SubmittedProjectDetailPage}
            meta={{ auth: true }}
          />
          <GuardedRoute
            exact
            path='/trackerTalentList'
            component={ProjectTrackerTalentLists}
            meta={{ auth: true }}
          />
          <GuardedRoute exact path='/escrowHome' component={EscrowHome} />
          {/* <Route exact path='/landing' component={Landing} /> */}
          <Route exact path='/home' component={HomeLanding} />
          <Route
            exact
            path='/projecttrackerhunter'
            component={ProjectTrackerHunter}
          />
          <Route exact path='' component={HomeLanding} meta={{ auth: true }} />

          
          {/* <Route exact path ="/createJob" component={CreateJob} /> */}
          {/* <GuardedRoute exact path='/gigSearchResult/:search_query' component={SearchGigResult} meta={{auth: true}} />
          <Route
            exact
            path='/jobrequest'
            component={JobRequest}
            meta={{ auth: true }}
          />
          <Route
            exact
            path='/order'
            component={TalentOrders}
            meta={{ auth: true }}
          />
          <Route
            exact
            path='/order/submittedProject/:id'
            component={SubmittedProjectDetailPage}
            meta={{ auth: true }}
          />
          <Route
            exact
            path='/order/completedProject/:id'
            component={SubmittedProjectDetailPage}
            meta={{ auth: true }}
          />
          <Route exact path='/messagedashboard' component={MessageDashboard} />
          <Route
            exact
            path='/talentMessageDetails/:id'
            component={MessageDetails}
          />
          {/* <Route exact path='/talentMessageDetails' component={MessageDetails} /> */}
          <Route
            exact
            path='/trackerTalent/:id'
            component={ProjectTrackerTalentHome}
            meta={{ auth: true }}
          />
          <Route
            exact
            path='/trackerTalentList'
            component={ProjectTrackerTalentLists}
            meta={{ auth: true }}
          />
          <Route exact path='/escrowHome' component={EscrowHome} />
          <Route exact path='/home' component={HomeLanding} />

          <GuardedRoute
            exact
            path='/projecttrackerhunter'
            component={ProjectTrackerHunter}
            meta={{ auth: true }}
          />
          <Route exact path='' component={HomeLanding} meta={{ auth: true }} />
          {/* <Route exact path ="/createJob" component={CreateJob} /> */}
          {/* <GuardedRoute exact path='/gigSearchResult/:search_query' component={SearchGigResult} meta={{auth: true}} />
      <GuardedRoute exact path='/gigSearchResult' component={SearchGigResult} meta={{auth: true}} />
      <GuardedRoute exact path='/hunterDashboardHome' component={DashboardHome}/>
      <GuardedRoute exact path='/DashboardHomeContent/createJob' component={DashboardHome}/>
      <GuardedRoute exact path='/jobrequest' component={JobRequest} meta={{auth: true}} />
      <GuardedRoute exact path='' component={Landing} meta={{auth: true}} /> */}
        </Switch>
      </GuardProvider>
    </div>
  );
}

export default App;
