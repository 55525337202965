import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import SubmittedOrderHunterTable from '../../../components/Hunter/SubmittedOrderTable/SubmittedOrderHunterTable.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptSubmittedProject,
  getAllSubmittedOrder,
  getSubmittedOrderDetail,
} from '../../../store/action/talent/applyJobAction';

import { SiInformatica } from 'react-icons/si';
import { BsPersonCircle } from 'react-icons/bs';
import { AiFillFileText } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';
import Loader from 'react-loader-spinner';
import { useParams } from 'react-router';
import moment from 'moment';
import { getReviewForJob } from '../../../store/action/publicProfileAction/addReview';
import { Rating } from '@mui/material';

const SubmittedProjectDetailPage = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsIsloading] = useState(false);
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const user = useSelector((state) => state.authReducer.user);
  const getReviewForAJob = useSelector(
    (state) => state.userReviewReducer.getReviewForAJob
  );
  const getSubmittedDetail = useSelector(
    (state) => state.talentApplyForJobReducer.getSubmittedDetail
  );
  const params = useParams();
  console.log(params.id);
  console.log('submitted detail',getSubmittedDetail);
  const history = useHistory();
  useEffect(() => {
    getTalentSubmittedOrder();
    fetchReviews();

    return () => {};
  }, []);
  const getTalentSubmittedOrder = async () => {
    setIsFetching(true);
    await dispatch(getSubmittedOrderDetail(params.id));
    setIsFetching(false);
    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };
  const fetchReviews = async () => {
    setIsFetching(true);
    await dispatch(getReviewForJob(params.id));
    setIsFetching(false);
  };
  const handleAcceptProject = async () => {
    setIsIsloading(true);
    await dispatch(acceptSubmittedProject(params.id));
    setIsIsloading(false);
  };

  return (
    <div className='Projects_wrapper container-fluid'>
      <h3>submitted projects Detail</h3>

      <div className='projects_button d-flex justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='projects_button--back'
          type='button'
        >
          Go back
        </button>
      </div>
      {/* getSubmittedDetail === null */}
      {isFetching ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        <div className='submitted'>
          <div className='submitted__left'>
            <div
              className={
                getSubmittedDetail?.status === 'accepted'
                  ? 'completed__left--task'
                  : 'submitted__left--task'
              }
            >
              {getSubmittedDetail?.status === 'accepted' ? (
                <h4>Order Completed!</h4>
              ) : (
                <h4>Order Submitted!</h4>
              )}
              {/* <p>You earned $5 for this order. Great, job.</p> */}
              {getSubmittedDetail?.status === 'accepted' ? (
                <p>
                  You earned N{getSubmittedDetail?.price_budget} for this order.
                  Great, job.
                </p>
              ) : (
                <p>
                  Please wait while {getSubmittedDetail?.user.name} review, then
                  Accept or Decline.
                </p>
              )}
            </div>

            <div className='submitted__details'>
              <div className='submitted__details--left'>
                <div className='details-icon'>
                  <SiInformatica />
                </div>
              </div>
              <div className='submitted__details--right'>
                <h5>Your order details!</h5>
                <div>
                  <p>
                    Talent:{' '}
                    <span className='ordered__talent'>
                      {getSubmittedDetail?.creative.name}
                    </span>
                    <span className='date__ordered'>
                      Date ordered:{' '}
                      {moment(getSubmittedDetail?.start_date).format('ll')}
                    </span>
                  </p>
                </div>
                <p>{getSubmittedDetail?.title}</p>
                <div className='submitted__table-container'>
                  <table className='submitted__table'>
                    <thead>
                      <tr>
                        <th>ITEM</th>
                        {/* <th>MILESTONE.</th> */}
                        <th>DUE DATE</th>
                        <th>PRICE</th>
                        <th>Net pay</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='tr-body'>{getSubmittedDetail?.title}</td>
                        {/* <td className='tr-body'>
                          {getSubmittedDetail?.milestone}
                        </td> */}
                        <td className='tr-body'>
                          {moment(getSubmittedDetail?.end_date).format('ll')}
                        </td>
                        <td className='tr-body'>
                          {getSubmittedDetail?.price_budget}
                        </td>
                        <td className='tr-body'>
                          {getSubmittedDetail?.net_pay}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className='tr-foot'>TOTAL</td>
                        <td className='tr-foot'></td>
                        <td className='tr-foot'></td>
                        <td className='tr-foot'></td>
                        <td className='tr-foot'>
                          {getSubmittedDetail?.net_pay}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

            <div className='delivered__details'>
              <div className='delivered__details--left'>
                <div className='details-icon'>
                  <SiInformatica />
                </div>
              </div>

              <div className='delivered__details--right'>
                <h5>
                  You delivered the order{' '}
                  <span>
                    {moment(
                      getSubmittedDetail?.job_submissions.created_at
                    ).format('lll')}
                  </span>
                </h5>
                <div className='delivery__sub'>
                  <div className='delivery__sub-left'>
                    <BsPersonCircle className='delivery__user' />
                  </div>
                  <div className='delivery__sub-right'>
                    <p>{getSubmittedDetail?.job_submissions.comment}</p>

                    <div className='attachments'>
                      <h6>Attachments</h6>
                      <div className='delivered__attachment'>
                        <div className='delivered__attached'>
                          <div className='attached-icon'>
                            {/* <a  href={`https://api.solvateafrica.com/api/storage/app/${getSubmittedDetail?.job_submissions[0].path}`} */}
                            {getSubmittedDetail?.job_submissions[0].path === null || 
                            getSubmittedDetail?.job_submissions[0].path === undefined ? 'No submitted job' :
                              <a  href={`https://api.solvateafrica.com/public/jobs/submissions/${getSubmittedDetail?.job_submissions[0].path}`}
                           target="_blank">
                              <AiFillFileText />
                            </a>
                              }
                            
                          </div>
                          <div className='attachment__download'>
                            <p>
                              File (38kB){' '}
                              <span>
                                <HiDownload />
                              </span>
                            </p>
                          </div>
                        </div>

                        {/* <div className='delivered__attached'>
                          <div className='attached-icon'>
                            <AiFillFileText />
                          </div>
                          <div className='attachment__download'>
                            <p>
                              PRIVACY...doc (38kB){' '}
                              <span>
                                <HiDownload />
                              </span>
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getReviewForAJob === undefined ||
            getReviewForAJob === null ? null : (
              <div className='delivered__details'>
                {/* <h6>Your review for this job</h6> */}
                <div className='delivery__sub-left'>
                  <BsPersonCircle className='delivery__user' />
                </div>
                <div className='delivery__sub-right'>
                  <p>
                    <span>{getReviewForAJob.reviewee.name}</span>{' '}
                    <span
                      style={{ padding: '0 0 0 1.0rem' }}
                      className='review-wrapper'
                    >
                      <Rating
                        name='simple-controlled'
                        size='small'
                        readOnly
                        // style={{ color: 'red' }}
                        // value={each.rating}
                        value={getReviewForAJob.rating}
                      />
                    </span>
                  </p>
                  <p className='words'>{getReviewForAJob.comment}</p>
                  <p className='year-pub' style={{ fontSize: '0.8rem' }}>
                    Published{' '}
                    {moment(getReviewForAJob.created_at)
                      .startOf('day')
                      .fromNow()}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className='submitted__right'>
            <div className='submitted__right--order'>
              {/* <h5>Order Details</h5> */}
              <h5>Summary</h5>
              <div className='order'>
                {/* <h6>Ordered by</h6> */}
                <h6>Delivered by</h6>
                <p>{getSubmittedDetail?.creative.name}</p>
              </div>

              <div className='delivery'>
                <h6>Delivery date</h6>
                <p>
                  {' '}
                  {moment(getSubmittedDetail?.job_submissions.created_at).format(
                    'lll'
                  )}
                </p>
              </div>

              <div className='total'>
                <h6>Price budget</h6>
                <p>{getSubmittedDetail?.price_budget}</p>
              </div>
              <div className='total'>
                <h6>Net pay</h6>
                <p>{getSubmittedDetail?.net_pay}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmittedProjectDetailPage;
