import React from 'react';
import './BrandsTrustUs.styles.css';
import talentImg from '../../../assets/image/talent-img.png';
import timeImage from '../../../assets/image/time 1.png';
import paymentImage from '../../../assets/image/payment-method 1.png';
import dudeImage from '../../../assets/image/black-dude.png';
import { Link } from 'react-router-dom';


const BrandsTrustUs = () => {

    return (
        <div className='trustus_wrapper'>
        <div className='trustus_wrapperTop'>
        {/*Top Left */}
        <div className='trustus_wrapper__left'>
        <h1>Why leading brands trust us?</h1>
       
        </div>
        {/*Top Right */}
        <div className='trustus_wrapper__right'>
        <p className='trustus_wrapper__copy'>
        We have built a platform that will showcase 
        Africa’s most talented creatives, and connect talent hunters to them. We strive 
        to ensure you get value for your money.
        </p>
        </div>
        </div>

            {/* BOTTOM */}
        <div className='bottom_copytalent'>
        <div className='bottom_copytalent--wrapper'>

            <div className='bottom_copytalent--left'>
            <li>
                <img src={talentImg} alt="talentImage"/>
                <h6>Top talents only</h6>
            </li>
            <p>
            Solvate Africa is powered by thousands of experts 
            from the creative community. Our platform provides
             an enabling environment for them to compete easily for work. 
            This promotes the onboarding of only top rated talents.
            </p>

            </div>
            <div className='bottom_copytalent--left second'>
            <li>
                <img src={timeImage} alt="timeImage"/>
                <h6>Save time and money</h6>
            </li>
            <p>
            Hiring reliable creative experts can be time consuming and exhausting. 
            This is where we come in. We provide a seamless experience when it comes to hiring 
            the best creative talents that will work with your project timeline and budget.
            </p>
            </div>
            <div className='bottom_copytalent--left second'>
            <li>
                <img src={paymentImage} alt="paymentImage"/>
                <h6>Guaranteed Payment to creatives</h6>
            </li>
            <p>
            We have an escrow feature integrated into our system. 
            Creatives are 100% sure of getting paid after successfully
            completing a gig.
            </p>

            </div>

            </div>
            <div className='bottom_copytalent--right'>
            <img src={dudeImage} alt='dudeImage'className='brandimg' />
            </div>
            </div>
            <div className='button_bottomtrust'>
            <Link to="/login"><button className='explore_but' 
        type='button'>Become a talent</button></Link>
            {/* <button type='button'>Become a talent</button> */}
            </div>
        </div>
    )
}

export default BrandsTrustUs;