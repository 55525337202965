import React, { useEffect, useState } from 'react';
import './NavTalent.styles.css';
import logo from '../../../../assets/image/brand-logo.svg';
import { Link } from 'react-router-dom';
import profile from '../../../../assets/image/profile.svg';
import DropdownMenu from '../../../Navs/NavTalentComp/Dropdown/Dropdown.component';
// import { NavData } from '../NavItems';
import { useDispatch } from 'react-redux';
import { getTalentCategory } from '../../../../store/action/categoryAction/category';
import { useSelector } from 'react-redux';
import {IoIosNotificationsOutline} from 'react-icons/io';
import { NotificationCount, UserNotification} from '../../../../store/action/notifications/notification';
import Badge from '@mui/material/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const NavTalent = () => {
  const [dropdown, setDropdown] = useState(false);
  const CategoriesData = useSelector(
    (state) => state.categoriesReducer.categories
  );
  // console.log(CategoriesData)
  const dispatch = useDispatch();

  useEffect( () => {
     getCategory();
 
  }, []);
  
  useEffect( () => {
     dispatch(NotificationCount())
  }, []);
   const count = useSelector(state => state.notificationReducer.notificationcount);

  const handleTalentNotification = async() => {
        
    await dispatch(UserNotification())
  }

  const handleSignOut = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.reload();
  };

  const getCategory = () => {
    dispatch(getTalentCategory());
  };

  return (
    <header>
      <nav className='navbar navbar-expand-lg navbar-light'>
        <div className='container-fluid'>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarTogglerDemo03'
            aria-controls='#navbarTogglerDemo03'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <img src={logo} alt='BrandLogo' />

          <div className='collapse navbar-collapse ' id='navbarTogglerDemo03'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              {/* <li className='nav-item'>
                <Link className='nav-link active' to='#'>
                  Home
                </Link>
              </li> */}
              {/* <li className='nav-item'>
                <Link className='nav-link' to='#'>
                  Find talent
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link ' to='#'>
                  Find gig
                </Link>
              </li> */}
              <li className='nav-item' onClick={handleTalentNotification}>
              <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                           view notifications
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <Link className='nav-link ' to='/talentnotification'>
                    <Badge color="primary" badgeContent={count?.unreadNotification}>
                            Notification
                   </Badge>
                
                </Link>
                      </OverlayTrigger>
                    ))}
                  </>
              </li>
              <li className='nav-item' >
                {/* was moved to this menu */}
                <Link className='nav-link ' to='/jobrequest'>
                  Job Request
                </Link>
              </li>
              <li className='nav-item' >
                <Link className='nav-link ' to='/earning'>
                  Earning
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link ' to='/order'>
                  Orders
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/messagedashboard'>
                  Messages
                </Link>
              </li>
              <li className='nav-item img-profile'></li>
              <li className='btn-group nav-item' style={{ cursor: 'pointer' }}>
                {/*eslint-disable-next-line jsx-a11y/role-supports-aria-props*/}
                <img
                  className='dropdown-toggle img-fluid'
                  src={profile}
                  alt='profileImage'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                />
                <ul className='dropdown-menu'>
                  <li>
                    <Link className='dropdown-item' to='#'>
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/publicProfile'>
                      Profile
                    </Link>
                  </li>
                  {/* <li>
                    <Link className='dropdown-item' to='#'>
                      Become a Creative hunter
                    </Link>
                  </li> */}
                  <li>
                    <Link className='dropdown-item' to='/trackerTalentList'>
                      Project tracker
                    </Link>
                  </li>
                  {/* <li>
                    <Link className='dropdown-item' to='/jobrequest'>
                      See job request
                    </Link>
                  </li> */}
                  <li>
                    <hr className='dropdown-divider' />
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to='#'
                      onClick={handleSignOut}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <hr />

      {/*Bottom Navbar*/}
      <nav className='navbar navbar-expand-lg navbar-light'>
        <div className='container-fluid'>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarTogglerDemo04'
            aria-controls='#navbarTogglerDemo03'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse ' id='navbarTogglerDemo04'>
            <ul className='navbar-nav mb-2 mb-lg-0 bottom-nav'>
              {CategoriesData.map((item) => {
                if (item.name === 'Experiential') {
                  return (
                    <li
                      className='nav-item'
                      key={item.id}
                      onMouseEnter={() => setDropdown(true)}
                      onMouseLeave={() => setDropdown(false)}
                    >
                      <Link className='nav-link' to='#'>
                        {item.name}
                      </Link>
                      {dropdown && <DropdownMenu item={item} />}
                    </li>
                  );
                }
                return (
                  <li
                    className='nav-item'
                    key={item.id}
                    onMouseEnter={() => setDropdown(true)}
                    onMouseLeave={() => setDropdown(false)}
                  >
                    <Link className='nav-link' to='#'>
                      {item.name}
                    </Link>
                  </li>
                );
              })}
              {/*<li className="nav-item">
  <Link className="nav-link active" to='#'>Creative thinkers</Link>
  </li>
  <li className="nav-item">
  <Link className="nav-link " to='#'>Creative writers</Link>
  </li>
  <li className="nav-item">
  <Link className="nav-link " to='#'>Audio/Visual</Link>
  </li>
  <li className="nav-item">
  <Link className="nav-link " to='#'>Voiceovers experts</Link>
  </li>
  <li className="nav-item">
  <Link className="nav-link " to='#'>Creative visualizers</Link>
  </li>
  <li className="nav-item">
  <Link className="nav-link " to='#'>Tech visual experts</Link>
  </li>
   <li className="nav-item">
  <Link className="nav-link " to='#'>Branding</Link>
  </li>
  <li className="nav-item">
  <Link className="nav-link " to='#'>Experiential</Link>
  </li>*/}
            </ul>
          </div>
        </div>
      </nav>
      {/*<DropdownMenu/>*/}
      <hr />
    </header>
  );
};

export default NavTalent;
