import React from 'react';
import './FAQTab.styles.css';
import tick from '../../../../../assets/image/tick.svg';
import Tab from 'react-bootstrap/Tabs';
import { each } from 'jquery';

const FAQTab = ({ myGig }) => {
  return (
    <section>
      <div className='container-fluid overview-cont'>
        <div className='row overview-wrapper'>
          <div className='col-md-7 left-side'>
            <h3>FAQ</h3>
            <div className='container faq'>
              <div class='accordion faq-1' id='accordionExample'>
                {myGig === null
                  ? null
                  : myGig.faqs.map((each, id) => {
                      return (
                        <div key={id} class='accordion-item faq-2 faq-margin'>
                          <h2 class='accordion-header' id='headingTwo'>
                            <button
                              class='accordion-button collapsed title'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseTwo'
                              aria-expanded='false'
                              aria-controls='collapseTwo'
                            >
                              {each.faq}
                            </button>
                          </h2>
                          <div
                            id='collapseTwo'
                            class='accordion-collapse collapse acc'
                            aria-labelledby='headingTwo'
                            data-bs-parent='#accordionExample'
                          >
                            <div class='accordion-body acc-2'>
                              {each.answer}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                {/* <div class="accordion-item faq-2">
    <h2 class="accordion-header fa-3" id="headingOne">
      <button class="accordion-button title" type="button" data-bs-toggle="collapse" 
      data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        How many revisions do you offer?
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse acc" 
    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body acc-2">
        We offer an unlimited revision with an aim to give our clients
        the maximum satisfaction they deserve
      </div>
    </div>
  </div> */}
              </div>
            </div>
          </div>

          {/* <div className="col-md-5 right-side">
  <div className="right-gigcontent card">
  <div className="basic-content">
     <div className="heading-copy d-flex justify-content-between">
    <h6>Gig offers</h6>
    <p>Verified</p>
  </div>
  <li>{myGig === null ? null : myGig.scopepackage[0].offers}</li>
  <li>Basic proofreading</li>
  <li>{myGig === null ? null : myGig.scopepackage[0].revisions} Revisions</li>
  </div>
  </div>
   <button type="button">Contact creative talent</button>
  </div> */}

          <div className='col-md-5 right-side'>
            <div className='right-gigcontent card'>
              <div className='basic-content'>
                <div className='heading_copy d-flex justify-content-between'>
                  <h6>Gig offers</h6>
                  <div>Verified</div>
                </div>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].offers}
                  </p>
                </li>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].revisions}{' '}
                    Revisions
                  </p>
                </li>
              </div>
            </div>
            <div className='d-flex button_wrapper justify-content-center'>
              <button type='button' className='contact_button'>
                Contact creative talent
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQTab;
