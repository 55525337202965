import React from "react";
import { Modal } from 'react-bootstrap';
import close from '../../../assets/image/close.svg';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



const ViewMilestone= ({milestone, handleClosemilestone, milestonedata}) => {
    return(
        <Modal
        size='md'
        show={milestone}
        onHide={handleClosemilestone}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClosemilestone} />
          <h3>Milestone</h3>
        </div>
        <Modal.Body>
        {/* <div className='editjob_milestone' >
<div className='job_field_card'>
<label className='milestone_label'>Milestone title</label><br/>
<input type='text' placeholder='enter a milestone title'
name='title'  className="milestone_input"/>
</div>
<div className='job_field_card'>
<label className='milestone_label'>Due date(days)</label><br/>
<input type='text' placeholder='enter number days required'
name='due'  className="milestone_input"/>
</div>
<div className='job_field_card'>
<label className='milestone_label'>Description</label><br/>
<textarea  placeholder='milestone description'
name='description' className="milestone_input"/>
</div>
<div className='job_field_card'>
<label className='milestone_label'>Penalty (amount to be deducted)</label><br/>
<input type='text' placeholder='enter amount to be deducted in percentage'
name='penalty'  className="milestone_input"/>
</div>
<button className="milestone_add">Edit</button>
</div> */}
<Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Title</th>
            <th className='active_head_bg'>Description</th>
            {/* <th className='active_head_bg'>Penalty</th> */}
            <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Amount to be deducted when milestone is missed
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                                          
                        <div>Penalty(%)</div>
                        {/* <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">View</button> */}
                      </OverlayTrigger>
                    ))}
                  </></th>
                  <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Numbers of days the project will be due when active.
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                                          
                        <div>Due Day(s)</div>
                        {/* <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">View</button> */}
                      </OverlayTrigger>
                    ))}
                  </></th>
          </tr>
        </thead>
        <tbody>
          
            { milestonedata && milestonedata.map((data, index) => {
          return <>
               <tr>
                <td>{index+1}</td>
                <td>{data.title}</td>
                <td>{data.description}</td>
                 <td>{data.penalty}</td> 
                <td>{data.due}</td>
                </tr>
                </>
                  }) 
      }
        </tbody>
        </Table>
        </Modal.Body>
      </Modal>
    )
}

export default ViewMilestone;