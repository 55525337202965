
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {FaLink} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import close from '../../../assets/image/close.svg';
import {GiFlowerTwirl} from 'react-icons/gi';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MarkNotification } from '../../../store/action/notifications/notification';
import '../../Hunter/Notification/Notification.styles.css';
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';

const TalentNotification = () => {
  
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notificationReducer.getnotification);
  
  const handleRead = async(id) => {
    await dispatch(MarkNotification(id))
  }
  console.log('talent notification component', notifications);

  
  return (
    <section className='notification'>
        <NavTalent />
     
     <table className='notification__table' >
        <thead>
            <tr>
                <th>S/N</th>
                <th>Title</th>
                <th>Description</th>
                <th>View page</th>
            </tr>
        </thead>
        <tbody>
          {  
          notifications.length === 0 ? 
            <div>No available notification</div>
          : notifications.data?.map((notify, index) => {
              return <tr key={notify.id}>
                    <td><GiFlowerTwirl/></td>
                    <td>{notify?.title}</td>
                    <td>{notify?.description}</td>
                    <td  onClick={() => handleRead(notify?.id)}>
                    <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                           view page
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <Link to={notify?.link}><FaLink /></Link>
                      </OverlayTrigger>
                    ))}
                  </>
                    </td>
                     </tr>
                    })
            }
        </tbody>
     </table>
    </section>
  );
};

export default TalentNotification;
