import React, {useState} from 'react';
import './EditEducation.styles.css';
import ModalInputComponent from '../../../ModalInput/ModalInput.component';
import edit from '../../../../assets/image/editTalent.svg';
import deleteIcon from '../../../../assets/image/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEducation, editUserEducation } from '../../../../store/action/publicProfileAction/addEducation';
import Loader from 'react-loader-spinner';


const EditEducation = ({countries}) => {
    const [displayContents, setDisplay] = useState(false);
    const [editContent, setEditContent] = useState({});
    const [selectedEdu, setSelectedEdu] = useState(null);
    const dispatch = useDispatch()
    const closeCard = () => setDisplay(false);
    const EducationDetail = useSelector(state=>state.educationReducer.userEducation)
    
    let initialState = {
      country_id:"",
      title:"",
      from_year :2009,
      to_year :"",
    }
   console.log('educational detail', EducationDetail)

    const showContent = (eduItem) =>{ 
      setDisplay(true)
      console.log('edu item', eduItem)
      const newEduData={
        country_id:eduItem.country !==null && eduItem.country,
        countryName:eduItem.country !==null && eduItem.country.name,
        title:eduItem.title,
        from_year :eduItem.from_year,
        to_year :eduItem.to_year,

      }
      setSelectedEdu(eduItem.id)
      setEditContent(newEduData)
      
    };

    const getYears = ()=>{
      let currentYear = new Date().getFullYear(), years = [];
      let startYear = 1960;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    return years;
    }
    getYears()
    const [loading, setLoading] = useState(false)
    const [state, setstate] = useState(initialState)

    const handleChange = (e) => {
      const {name, value} = e.target;
      console.log(value)
      setEditContent({...editContent,[name]: value})
      // console.log(state)
    }
    

    const handleSubmit= async (e) =>{
      e.preventDefault();
      setLoading(true)
      await dispatch(editUserEducation(editContent,selectedEdu))
      setLoading(false)
      setDisplay(false)
      // setShow(false)
    
    }

    const handleDelete = (selectedEdu)=>{
      // alert("Are sure you want to delete "+selectedEdu.title+" ?")
      dispatch(deleteEducation(selectedEdu.id))
    }

    return (
        <div>
        <div className="total-lang">
        { displayContents ?
    
            <div className="desc-content-edit">
          <form onSubmit={handleSubmit}>       
          <div className="form-group-des">
          <label>What country is the institution?</label><br/>
          <select name="country_id" onChange={handleChange} defaultValue={editContent.country_id}>
           <option value="select country" >{editContent.countryName}</option>
            {
            countries?.map((country,idx) => {
                return <option key={country.id} value={country.id} >{country.name}</option>
            })
            } 
           </select>
          </div>
          <div className="form-group-des">
          <ModalInputComponent
           label="Name of Institution:"
           name="title"
           type="text"
           onChange={handleChange}
           defaultValue={editContent?.title}
          />
          </div>
          {/* <div className="form-group-des">
          <ModalInputComponent
           label="Major:"
           name="institutionName"
           type="text"
          />
          </div> */}
          <div className="form-group-des">
          <label>Year of graduation:</label><br/>
           <select name="to_year" defaultValue={editContent?.to_year} onChange={handleChange}>
           <option value="select country" >Select Year</option>
            {
            getYears().map((year,idx) => {
                return <option key={idx} value={year} >{year}</option>
            })
            } 
           </select>
          </div>
          <div className="but">    
        <button type="button" onClick={closeCard}>Cancel</button>
        <button type="submit">
        {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={50}
           width={50}
         /> :"Edit"
            }
          </button>
        </div>  
          </form>
           
           </div>
            : null 
          } 

{EducationDetail?.map((each,i)=>{
          return(
            <div key={i}>
               <div className="lang-edu">
              <h5>{each.title}</h5> 
              <div className="edit-images">
              <img src={edit} alt="edit"
              onClick={()=>showContent(each)}/>
              <img src={deleteIcon} alt="delete"
                onClick={()=>handleDelete(each)}
              />
              </div>
              </div>  
              <div className="lang-bottom">
              <span>Year of Graduation - {each.to_year}</span>        
              </div>
            </div>
          )
        })}

        {/* <div className="lang-edu">
        <h5>BSc. Biochemistry</h5> 
        <div className="edit-images">
        <img src={edit} alt="edit"
        onClick={showContent}/>
        <img src={deleteIcon} alt="delete"/>
        </div>
        </div>  
        <div className="lang-bottom">
        <p>Uniben</p>
        Graduated
        <span>2016</span>        
        </div>   */}
            
        </div>
        </div>
    )
}


export default EditEducation;