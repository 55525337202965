import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../assets/image/close.svg';
import './TalentPaymentRequest.styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { postReview } from '../../../store/action/talent/applyJobAction';
import { toast } from 'react-toastify';
import axios from 'axios';
import { css } from 'glamor';
import Loader from 'react-loader-spinner';
import { verifyBankDetails, TransferReceipt, InitiateTransfer } from '../../../store/action/talent/talentPayment';

const TalentPaymentRequest = ({ handleClose, show, saveProj, getBanks, info }) => {
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState({});
  const [jobdetail, setJobDetail] = useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value});
    setJobDetail({ ...data, [name]: value, job_id: info?.id });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    await dispatch(verifyBankDetails(data))
    .then(async (response) => {
        setIsLoading(false);
        console.log('response paymenttt', response.data.status)
        if (response.data.status === true) {
          await dispatch({
            type: 'GET_VERIFYBANKDETAILS',
            payload: response.data,
          });

          await dispatch(TransferReceipt(jobdetail)).
          then(async(response) =>{

            if(response.data.status === true){
                await dispatch({
                    type: 'POST_TRANSFERRECEIPT',
                    payload: response.data
                });
                let receipientcode = response.data.data.recipient_code
                await dispatch(InitiateTransfer(
                  {"transfer_recipient_code": receipientcode,
                  "job_id": info?.id }))
            }
          })
        }

        else {
            toast.error('Invalid Credentials', {
              className: css({
                background: '#00FF00 !important',
                color: 'white !important',
                fontWeight: 'bold',
              }),
              position: toast.POSITION.TOP_CENTER,
            });
          
          }
      })
    handleClose();
  };

  return (
    <section>
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>Payment Request</h3>
        </div>
        <Modal.Body>
          <div className='rating-body_wrapper'>
            <form onSubmit={handleSubmit}>
              <div className='form_job'>
                <label>Select bank name</label>
                <br />
                <select
                  className='rating_select'
                  onChange={handleChange}
                  name='bank_code'
                >
                  <option value=''>select Option</option>
                  {getBanks &&  getBanks .map(bank => {
                  return <>
                  <option value={bank.code} key={bank.id}>{bank.name}</option>
                  </>
                  }
                )}
                </select>
              </div>
              <div className='form_job-rating'>
                <label>Account number</label>
                <br />
                <input type='text'  placeholder='Enter account number'
                onChange={handleChange}
                name='account_number' className='order_modal'/>
              </div>
              
              <div className='job_button text-center'>
                <button type='submit'>
                  {isLoading ? (
                    <Loader type='Rings' color='white' height={50} />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </div>
          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default TalentPaymentRequest;
