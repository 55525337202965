import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ProjectTrackerTalentTable.styles.css';

import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdVisibility } from 'react-icons/md';
// import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
// import ProjectTrackerJobsModal from '../ProjectTrackerJobsModal/ProjectTrackerJobsModal.component';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAddedOrder } from '../../../../store/action/hunter/addOrder';
import moment from 'moment';
import Loader from 'react-loader-spinner';
const ProjectTrackerTalentTable = () => {
  const [isloading, setIsLoading] = useState(false);
  const getAddedTrackedOrders = useSelector(
    (state) => state.TrackerOrderReducer.getAddedOrders
  );
console.log(getAddedTrackedOrders)
  return (
    <div>
      <Table className='table_tracker'>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Order</th>
            <th className='active_head_bg'>Category</th>
            <th className='active_head_bg'>Milestone</th>
            <th className='active_head_bg'>Date created</th>
            {/* <th></th> */}
            <th className='active_head_bg'>Actions</th>
          </tr>
        </thead>
        {isloading ? (
          <div className='d-flex justify-content-center'>
            <Loader type='Rings' color='blue' height={50} />
          </div>
        ) : (
          <tbody>
            { getAddedTrackedOrders.map((each, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{each.job !==null && each.job.title}</td>
                  <td>{ each.job !==null && each.job.milestone}</td>
                  <td>{moment( each.job !==null && each.job.start_date).format('lll')}</td>
                  <td>{moment( each.job !==null && each.job.end_date).format('lll')}</td>
                  <td>
                    <>
                      {['top'].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              View tracker
                            </Tooltip>
                          }
                        >
                          {/* <a>Tooltip on {placement}</a> */}
                          <Link
                            to={`/trackerTalent/${each.id}/${ each.job.title}/${each.job.start_date }/${each.job.end_date}`}
                          >
                            <MdVisibility className='view_icon_tracker' />
                          </Link>
                        </OverlayTrigger>
                      ))}
                    </>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default ProjectTrackerTalentTable;
