import React, { useState } from 'react';
import edit from '../../../assets/image/edit-icon.svg';
import './UpdateStory.styles.css';
import elipse from '../../../assets/image/elipse.svg';
import { Modal } from 'react-bootstrap';
import location from '../../../assets/image/location.svg';
import maleProfile from '../../../assets/image/male-profile.svg';
import { useDispatch } from 'react-redux';
import { updateStory } from '../../../store/action/publicProfileAction/StoryProfileImage';
import Loader from 'react-loader-spinner';
import { postTalentMessages } from '../../../store/action/talent/talentMessages';


const UpdateStoryHunter = ({
  firstname,
  lastname,
  country,
  joinedSince,
  storyLine,
  profile_image,
  userPubProfile,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
 
  // const [state, setstate] = useState(initialState);
  const [gigPix, setGigPix] = React.useState(null);
  const [image, setImage] = React.useState(null);
 
  const [isSending,setIsSending] = useState(false)
  const [msgData, setMsgData] = useState("");
  


const handleChange = (e)=>{
  setMsgData(e.target.value)
}

const handleSubmitMsg = async (e) =>{
  e.preventDefault();
  const formData = new FormData()
  formData.append("recipient_id",  userPubProfile.userdetail.userId)
  formData.append("message", msgData);
  setIsSending(true);
  await dispatch(postTalentMessages(formData)); 
  setIsSending(false);
  setMsgData("")
}

console.log('fresssghhh profile',  userPubProfile);


  return (
    <div className='description'>
      <Modal
        size="md"
        show={show} 
        onHide={handleClose}
        dialogClassName="modal-500w"
        >
        <div className="title-cus">
        {/* <img src={close} alt="closeimage"
        onClick={handleClose} /> */}
        <h3 className="modal-message" >Enter your message and click on send</h3>
        </div>
        <Modal.Body className='modal-body1'>
        <form className="form-update1"
        onSubmit={handleSubmitMsg}
         >
        {/* <div className="form-group-update">
        <TalentFormInput
         label="Full Name:"
         name="fullname"
         type="text"
        />
        </div> */}
        <div className="form-group-update1">
        <label>Your message:</label><br/>
          <textarea 
          placeholder="Enter a message"
          onChange={handleChange}
          style={{outline:"none", padding:"8px 10px", width:'100%'}}
          >

          </textarea>
     
        </div>
        <div className="modal-button">
        <button type="submit"> 
        {
                isSending ?
                <Loader
                type="Rings"
                color="white"
                height={50}
                width={50}
                 /> :"Send message"
                   }        
          </button>
          </div>
        </form>
        </Modal.Body>
        </Modal>

      <div className='status-online ms-auto'>online</div>
      <div className='text-center elipse-img' onClick={handleShow}>
        {profile_image === null ? (
          <img className='img-fluid' src={elipse} alt='elipse' />
        ) : (
          <img
            className='img-fluid'
            style={{ borderRadius: '50%', height: '10rem', width: '10rem' }}
            src={`https://api.solvateafrica.com/public/images/profiles/${profile_image}`}
            alt='elipse'
          />
        )}
      </div>
      <h6>
        {userPubProfile?.userdetail.firstname}{' '}
        {userPubProfile?.userdetail.lastname}
      </h6>
      <div className='text-center edit' onClick={handleShow}>
        {userPubProfile?.userdetail.storyLine}
      </div>
      <div className='text-center preview-story'>
        <button type='button' onClick={handleShow}>Contact me</button>
      </div>
      {/* <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>Kindly fill in your short story and Profile picture</h3>
        </div>
        <Modal.Body>
          <form className='form-update' onSubmit={handleSubmit}>
            <div className='form-group-update'>
              <ModalInputComponent
                label='Your short story in one line:'
                name='storyline'
                onChange={handleChange}
                type='text'
                defaultValue={storyLine}
              />
            </div>
            <div className='form-group-update'>
              <ModalInputComponent
                label='Upload profile pic:'
                name='profile_image'
                type='file'
                //  defaultValue={profile_image}
                onChange={handleChangeImage}
              />
            </div>
            <button type='submit'>
              {loading ? (
                <Loader type='Rings' color='white' height={50} width={50} />
              ) : (
                'Update story'
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal> */}
      <hr />
      <div className='location'>
        <li>
          <img src={location} alt='location' />
          <p>From</p>
        </li>
        {country === undefined ? 'Na' : country.name}
      </div>
      <div className='location pro'>
        <li>
          <img src={maleProfile} alt='Profile' />
          <p>Joined since:</p>
        </li>
        {new Date(userPubProfile?.created_at).toDateString()}
      </div>
    </div>
  );
};

export default UpdateStoryHunter;
