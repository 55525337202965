import React from "react";
import './howitworks.css';
import itworks from './guy smiling with glass 1.png';


const Howitworks= () => {
    return(
        <div className='generalhow'>
            <h1 className="howitworks"><strong>How it works</strong></h1>
            <p className="works_paragraph">Get the best hands to work on your projects within your budget and deadline. Get paid for delivering top notch services.</p>
            <div className="howpacked">
            {/* <div className="row" style={{marginTop:"30px"}}> */}
                <div>
                {/* <div className="col-lg-6 coloredback"> */}
                    <div className="emptydiv">
                        <div className="empty"></div>
                        <p style={{color:"black", margin:"0 10px", color:'white'}}>Register on the platform</p>
                        
                    </div>
                    <div className="emptydiv" >
                        <div className="empty"></div>
                        <p style={{color:"black", margin:"0 10px", color:'white'}}>Project brief gets uploaded by Client</p>
                        
                    </div>
                    <div className="emptydiv">
                        <div className="empty"></div>
                        <p style={{color:"black", margin:"0 10px", color:'white'}}>Talents accept or decline conditions</p>
                        
                    </div>
                </div>
                <div className="coloredback2">
                {/* <div className="col-lg-6" style={{marginBottom:"30px", padding:"0", width:"990px"}}> */}
                    <img src={itworks} className='how_img' alt="how it works" width="480" height="350" />
                </div>
            </div>
        </div>
    )
}

export default Howitworks;