import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import axios from 'axios';


export const talentBidForJobAction = (id) => {
    const TALENT_BID_FORJOB = "TALENT_BID_FOR_JOB";
    return function (dispatch) {
        const axiosInstance = axios.create({
            baseURL: PATH,
            timeout: 200000,
            headers: {
                        'Content-Type': 'application/json', 
                         'Accept': 'application/json',
                      //    'X-CSRF-TOKEN: " "'
                         'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        console.log(id)
        return axiosInstance.get(`job/getallsentjoboffers/${id}`).then ( async (response) => {
console.log(response)
            await dispatch({ type: TALENT_BID_FORJOB , payload: response.data })
        
        })
        .catch(
            error => {
                console.log(error.response);
                
            }
        )
}

}