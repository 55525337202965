import React, { useState } from 'react';
import './EditCertification.styles.css';
import edit from '../../../../assets/image/editTalent.svg';
import deleteIcon from '../../../../assets/image/delete.svg';
import ModalInputComponent from '../../../ModalInput/ModalInput.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCertification,
  editUserCertification,
} from '../../../../store/action/publicProfileAction/addCertification';
import Loader from 'react-loader-spinner';

const EditCertificationHunter = ({ userPubProfile }) => {
  const dispatch = useDispatch();
  const [displayContents, setDisplay] = useState(false);
  const [editContent, setEditContent] = useState({});
  const [selectedCert, setSelectedCert] = useState(null);
  // const showContent = () => setDisplay(true);
  const closeCard = () => setDisplay(false);
  const certificationDetail = useSelector(
    (state) => state.certificateReducer.userCertification
  );

  let initialState = {
    country_id: '',
    title: '',
    to_year: '',
    conferring_organization: '',
    description: 'Description for certificate',
  };

  const showContent = (certItem) => {
    setDisplay(true);
    // console.log(certItem)
    const newEduData = {
      title: certItem.title,
      year: certItem.year,
      conferring_organization: certItem.conferring_organization,
      description: 'Description for certificate',
    };
    setSelectedCert(certItem.id);
    setEditContent(newEduData);
  };

  const getYears = () => {
    let currentYear = new Date().getFullYear(),
      years = [];
    let startYear = 1960;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  };
  getYears();
  const [loading, setLoading] = useState(false);
  const [state, setstate] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value)
    setEditContent({ ...editContent, [name]: value });
    // console.log(state)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(editContent);
    setLoading(true);
    await dispatch(editUserCertification(editContent, selectedCert));
    setLoading(false);
    setDisplay(false);
    // setShow(false)
  };

  const handleDelete = (selectedEdu) => {
    alert(
      'Are sure you want to delete ' + selectedEdu.title + ' ?' + selectedEdu.id
    );
    dispatch(deleteCertification(selectedEdu.id));
  };

  return (
    <div>
      <div className='total-cert'>
        {displayContents ? (
          <div className='desc-content-edit'>
            <form onSubmit={handleSubmit}>
              <div className='form-group-des'>
                <ModalInputComponent
                  label='Enter name of certificate or award:'
                  name='title'
                  onChange={handleChange}
                  type='text'
                  defaultValue={editContent.title}
                />
              </div>
              <div className='form-group-des'>
                <ModalInputComponent
                  label='Certified from:'
                  name='conferring_organization'
                  onChange={handleChange}
                  type='text'
                  defaultValue={editContent.conferring_organization}
                />
              </div>
              <div className='form-group-des'>
                <label>Year of graduation:</label>
                <br />
                <select
                  name='year'
                  defaultValue={editContent.year}
                  onChange={handleChange}
                >
                  <option value='select country'>Select Year</option>
                  {getYears().map((year, idx) => {
                    return (
                      <option key={idx} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className='but'>
                <button type='button' onClick={closeCard}>
                  Cancel
                </button>
                <button type='submit'>
                  {loading ? (
                    <Loader type='Rings' color='white' height={50} width={50} />
                  ) : (
                    'Edit'
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : null}
        {userPubProfile?.certifications.map((each, i) => {
          return (
            <div key={i}>
              <div className='lang-edu'>
                <h5>{each.title}</h5>
              </div>
              <div className='lang-bottom'>
                <p>{each.conferring_organization},</p>
                <span>{each.year}</span>
              </div>
            </div>
          );
        })}
        {/* <div className="lang-edu">
          <h5>React Nanodegree</h5> 
          <div className="edit-images">
          <img src={edit} alt="edit"
          onClick={showContent}/>
          <img src={deleteIcon} alt="delete"/>
          </div>
          </div>  
          <div className="lang-bottom">
          <p>Udacity,</p>       
          <span>2021</span>        
          </div>  */}
      </div>
    </div>
  );
};

export default EditCertificationHunter;
