import React, {useState} from 'react';
import './Dropdown.styles.css';
import {Link} from 'react-router-dom';
import { skillsDropdown } from '../NavItems';



const DropdownMenu = () => {
 const [dropdown, setDropdown] = useState(false);

    return (
        <div>
        <ul
         className={dropdown ? "categories-submenu clicked" : "categories-submenu"} 
         onClick={() =>setDropdown(!dropdown)}>
        { skillsDropdown.map(item => {
            return (
                <li key={item.id}>
                <Link to="#" className={item.cName}
                onClick={() =>setDropdown(false)}>{item.title}</Link>
                </li>
            )
        }) }
        </ul>
        </div>
    )
}



export default DropdownMenu;