import React, { useEffect, useState } from 'react';
import './ProjectTrackerSettingsHunter.styles.css';
import { useHistory } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { MdPersonSearch } from 'react-icons/md';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CreateProjectHunterModal from '../../../../components/Hunter/ProjectTracker/CreateProjectHunterModal/CreateProjectHunterModal.component';
import InviteTalentModalHunter from '../../../../components/Hunter/ProjectTracker/InviteTalentModalHunter/InviteTalentModalHunter.component';
import { Link } from 'react-router-dom';
import deleteIcon from '../../../../assets/image/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getTalentTrackOders } from '../../../../store/action/hunter/addOrder';
import { useParams } from 'react-router';
import ProjectTrackerCreatedHunterTasks from '../../../../components/Hunter/ProjectTracker/ProjectTrackerCreatedTasks/ProjectTrackerCreatedHunterTasks.component';
const ProjectTrackerSettingsHunter = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // State for Invite talent modal
  const [showProject, setProject] = useState(false);
  const initiateClose = () => setProject(false);
  const initiateShow = () => setProject(true);
  const [isFetching, setIsfetching] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const getTask = useSelector((state) => state.TrackerOrderReducer.getTask);
  console.log('my taskkkk tracker', getTask);
  console.log(params);
  useEffect(() => {
    getTrackedProjects();
  }, []);
  const getTrackedProjects = async () => {
    setIsfetching(true);
    await dispatch(getTalentTrackOders(Number(params.id)));
    setIsfetching(false);
  };
  return (
    <section className='tracker_settings'>
      <h3>Project tracker setting</h3>

      {/* BODY NAVIGATION */}
      <div className='tracker_settings_button d-flex justify-content-between align-items-baseline'>
        <button
          onClick={() => history.goBack()}
          className='tracker_settings_button--back'
          type='button'
        >
          Go back
        </button>
        {/* Create project modal */}
        <CreateProjectHunterModal show={show} handleClose={handleClose} />

        {/* Invite talent Modal */}
        <InviteTalentModalHunter
          show={showProject}
          handleClose={initiateClose}
        />

        <div className='action_icons'>
          <>
            {['top'].map((placement) => (
              <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={
                  <Tooltip id={`tooltip-${placement}`}>Create project</Tooltip>
                }
              >
                {/* <a>Tooltip on {placement}</a> */}
                <Link to='#'>
                  <MdAdd className='tracker_add' onClick={handleShow} />
                </Link>
              </OverlayTrigger>
            ))}
          </>

          <>
            {['top'].map((placement) => (
              <OverlayTrigger
                key={placement}
                placementr={placement}
                overlay={
                  <Tooltip id={`tooltip-${placement}`}>Invite talent</Tooltip>
                }
              >
                {/* <a>Tooltip on {placement}</a> */}
                <Link to='#'>
                  <MdPersonSearch
                    onClick={initiateShow}
                    className='tracker_add invite'
                  />
                </Link>
              </OverlayTrigger>
            ))}
          </>

          {/* <button 
            // onClick={handleShow}
            type='button' className='tracker_settings_button--add'>
                Invite Talent
            </button> */}
        </div>
      </div>

      {/* BODY CARDS AND MAIN TRACKER SECTION*/}
      <section>
        <div className='card d-flex flex-row justify-content-between cards_wrapper_talent cards_wrapper-hunt'>
          <>
            {['top'].map((placement) => (
              <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={
                  <Tooltip id={`tooltip-${placement}`}>Delete project</Tooltip>
                }
              >
                {/* <a>Tooltip on {placement}</a> */}
                <Link to='#'>
                  <img
                    className='delete_icon_hunter'
                    src={deleteIcon}
                    alt='deleteIcon'
                  />
                </Link>
              </OverlayTrigger>
            ))}
          </>
          <div className='card todo_wrapper'>
            <div className='card cards_wrapper--main'>
              <h6 className=''>Todo</h6>
            </div>
            <ProjectTrackerCreatedHunterTasks
              getTask={getTask.filter((each) => {
                if (each.status === 'todo') {
                  return each;
                }
              })}
            />
          </div>
          <div className='card todo_wrapper'>
            <div className='card cards_wrapper--main'>
              <h6 className=''>Doing</h6>
            </div>
            <ProjectTrackerCreatedHunterTasks
              getTask={getTask.filter((each) => {
                if (each.status === 'doing') {
                  return each;
                }
              })}
            />
          </div>
          <div className='card todo_wrapper'>
            <div className='card cards_wrapper--main'>
              <h6 className=''>Done</h6>
            </div>
            <ProjectTrackerCreatedHunterTasks
              getTask={getTask.filter((each) => {
                if (each.status === 'done') {
                  return each;
                }
              })}
            />
          </div>
        </div>
      </section>
    </section>
  );
};

export default ProjectTrackerSettingsHunter;
