import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const initialState = {
  talentBids: null,
  talentSentRequest: [],
  talentSentJob: null,
};

const TALENT_BID_FOR_JOB = 'TALENT_BID_FOR_JOB';
const GET_SENT_OFFER = 'GET_SENT_OFFER';

export const talentBidReducer = (state = initialState, action) => {
  if (action.type === TALENT_BID_FOR_JOB) {
    console.log(action.payload);
    return Object.assign({}, state, {
      talentBids: action.payload,
    });
  } else if (action.type === GET_SENT_OFFER) {
    console.log(action.payload);
    return Object.assign({}, state, {
      talentSentRequest: action.payload,
    });
  } else if (action.type === 'GET_VIEWED_SENT_OFFER') {
    console.log(action.payload);
    return Object.assign({}, state, {
      talentSentJob: action.payload,
    });
  }

  return state;
};
