import React from 'react';
import './ModalInput.styles.css';




const ModalInputComponent = ({label, ...otherprops}) => {

    return (

        <div className="form-group-modal">
        <label htmlFor="">
        {label}
        </label><br></br>
        <input {...otherprops}/>
        </div>

    )
}


export default ModalInputComponent