import { combineReducers } from 'redux';
import { talentApplyForJobReducer } from './applyJobReducer';
import { authReducer } from './authReducer';
import { categoryReducer } from './categoriesReducer';
import { certificationReducer } from './CertificationReducer';
import { countriesAndStatesReducer } from './countryandstateReducer';
import { createGigReducer } from './createGigReducer';
import { educationReducer } from './EducationReducer';
import { languageReducer } from './languageReducer';
import { scoutJobReducer } from './scoutJobReducer';
import { SearchGigReducer } from './searchGigReducer';
import { skillReducer } from './skillReducer';
import { updateUserProfileReducer } from './updateCreateProfileReducer';
import { agreementReducer } from './initiateAgreementReducer';
import { talentBidReducer } from './talentBidReducer';
import { hunterReducer } from './hunterReducer';
import { TalentMessageReducer } from './talentMessageReducer';
import { userReviewReducer } from './reviewReducer';
import { TrackerOrderReducer } from './TrackerOrderReducer';
import { TalentPaymentReducer } from './talentPaymentReducer';
import { MilestoneReducer } from './milestoneReducer';
import { landingFaqReducer } from './landingFaqReducer';
import { notificationReducer } from './notificationReducer';
const rootReducer = combineReducers({
  authReducer: authReducer,
  hunterReducer: hunterReducer,
  talentReducer: TalentMessageReducer,
  countryandstate: countriesAndStatesReducer,
  updateUserProfileReducer: updateUserProfileReducer,
  educationReducer: educationReducer,
  certificateReducer: certificationReducer,
  skillReducer: skillReducer,
  languageReducer: languageReducer,
  categoriesReducer: categoryReducer,
  createGigReducer: createGigReducer,
  SearchGigReducer: SearchGigReducer,
  scoutJobReducer: scoutJobReducer,
  agreementReducer: agreementReducer,
  talentBidReducer: talentBidReducer,
  talentApplyForJobReducer: talentApplyForJobReducer,
  userReviewReducer: userReviewReducer,
  TrackerOrderReducer: TrackerOrderReducer,
  talentPaymentReducer: TalentPaymentReducer,
  milestoneReducer: MilestoneReducer,
  landingFaqReducer: landingFaqReducer,
  notificationReducer: notificationReducer
});
export default rootReducer;
