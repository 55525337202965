import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { BsChevronDown } from 'react-icons/bs';
import "./Milestones.jobmodal.css";
import placeholder from "../../../assets/image/placeholder.png"
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { SubmitMilestone } from '../../../store/action/milestoneAction/Milestone';
import { useDispatch, useSelector } from 'react-redux';
import { FiUpload } from 'react-icons/fi';
import Loader from 'react-loader-spinner';

const JobRequestMilestonemodal = ({submission,
  handleCloseMilestoneSubmission, milestones}) =>{
const dispatch = useDispatch();
 
    const [data, setData] = useState({"comment":''});
    const [upload, setUpload] = useState({});
    const [loading, setLoading] = useState(false)
    // const [displayMilestone, setDisplayMilestone] = useState(null);
    
    const uploadChange = (e, milestone_id) => {
      e.preventDefault();
      setUpload(e.target.files[0])  
    let da = document.getElementById(`mile-${e.target.id}`);
    da.textContent = `File Uploaded:  ${e.target.files[0].name}`
    }

      const handleChange = (e, id) => {
        e.preventDefault();
        const{name, value} = e.target;
        setData({...data, [name]: value, "upload":upload, milestone_id:id});
      }
  
  
     const handleSubmit = async(e) => {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("upload", upload);
      formData.append("milestone_id", data.milestone_id);
      formData.append("comment", data.comment);
      await dispatch(SubmitMilestone(formData));
      setLoading(false);
      handleCloseMilestoneSubmission()

      setUpload({})
     }
    
  return (
    <>
      <Modal show={submission} onHide={handleCloseMilestoneSubmission}>
        <Modal.Body>
       <div className='milestone__submission'> 
           { 
          milestones?.map((milestone, index) => {
          return <>
                <div className='modalmilestone'>
               <p className='jobmilestone_number'>{milestone.title}</p>
               <p className='jobmilestone_file'>(Upload job)</p>
                </div>
              <form>
                <div className='jobmilestone_sec1'>
                <div className='milestone_part1'>
              <label for='title' >Title</label><br/>
              <input  type='text' name='title' placeholder='submission title' 
              onChange={(e) => handleChange(e, milestone.id)} className='jobmilestone_titleinput'/>
              </div>
              </div>
              <label >Comment</label><br/>
              <textarea  name='comment' placeholder='comment/link to jobs' 
              onChange={(e) => handleChange(e, milestone.id)} className='jobmilestone_commentinput'/><br/>
              <input type="file" id={milestone.id} className='jobmilestone_input'
              onChange={(e) => uploadChange(e, milestone.id)} /> <br/>
              <label for={milestone.id} className='jobmilestone_label'>Upload Job <FiUpload style={{color: "#020079", fontSize:"15px"}} /></label><br/>
               <p id={`mile-${milestone.id}`}> File name: </p> <br/>
              <button type='button' className='jobmilestone_submit' onClick={(e) => handleSubmit(e)}>
              {loading ? (
              <Loader type="Rings" color="white" height={50} width={50} />
            ) : (
              "Submit"
            )}</button>
            </form>
            {/* {arrow === (index) ? " ":<><hr/>
            <div className='uploaded_milestone'>
            {displayMilestone ? <img src={displayMilestone} className="milestone_imgplaceholder"
            style={{width:"100%"}}/> : ''}
            </div>
            </>} */}
            </>
            })
  }
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMilestoneSubmission}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JobRequestMilestonemodal;

// onClick={() => handleArrow(index)} key={index}