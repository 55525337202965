import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';
import { GiTruce } from 'react-icons/gi';

export function postTalentMessages(message) {
  return function (dispatch) {
    dispatch({type:'REQUEST_TALENT_MESSAGE'});
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    return axiosInstance.post('chat/sendmessage', message)
      .then(async (response) => {
        console.log('talent message response', response);
        if (response.data.success === true) {
          // console.log(response)
          await dispatch({ type: 'ADD_TALENT_MESSAGE', payload: response.data.chat});
        
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}


export function GetTalentReceivedMessage() {
  return function (dispatch) {
    
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
   
    return axiosInstance.get('chat/getreceivedmessages')
      .then(async (response) => {
       console.log('talent received message', response)
        if (response.status >= 200) {
          await dispatch({ type: 'GET_TALENT_RECEIVED_MESSAGE', payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error.response);
         dispatch({ type: 'GET_TALENT_RECEIVEDERROR_MESSAGE', payload: error.message });

        console.log(error);
      });
  };
}

export  function GetTalentConversation(id) {
  return async function (dispatch) {
    // dispatch({type: 'GET_TALENT_CONVERSATION'});
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    
    return await axiosInstance.get(`chat/getconversation/${id}`)
      .then(async (response) => {
        if (response.status >= 200) {
        console.log('talent conversation  response', response.data);
           
          await dispatch({ type: 'GET_TALENT_CONVERSATION', payload: response.data.messages });
        
        }
      })
      .catch((error) => {
        console.log(error.response);

         dispatch({ type: 'GET_TALENTERROR_CONVERSATION', payload:error.message});

        console.log(error);
      });
  };
}

