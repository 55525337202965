import React from 'react';
import './CompleteOrderModal.styles.css';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import { useDispatch } from 'react-redux';
import {
  acceptHunterOrder,
  submitHunterOrder,
} from '../../../store/action/talent/applyJobAction';
import { useState } from 'react';
import Loader from 'react-loader-spinner';

const CompleteOrderModal = ({ handleClose, show, selectOrder }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [images, setImages] = useState(null);

  // console.log(selectOrder);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('comment', data.comment);
    formData.append('path', images);
    await dispatch(submitHunterOrder(formData, selectOrder.id));
    setLoading(false);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleImageChange = (e) => {
    setImages(e.target.files[0])
  };
  return (
    <section>
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>Submit Project.</h3>
        </div>
        <Modal.Body>
          <div className='dsc-title'>
            <p>{selectOrder?.title}</p>
          </div>
          <form onSubmit={handleSubmit} className='form-update'>
            <div className='form_job'>
              <label>Comments:</label>
              <br />
              <textarea
                placeholder='Please write a comment here'
                style={{ width: '100%' }}
                name='comment'
                rows={5}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className='form-group-update'>
              <ModalInputComponent
                label='Attach file:'
                onChange={handleImageChange}
                name='path'
                type='file'
              />
            </div>

            {/* <div className='form_job'>
              <div className='check_wrapper'>
                <input className='check_box' type='checkbox' name='name' />
                <p>
                  I hereby accept all the terms and conditions as stated in the
                  job brief
                </p>
              </div>
            </div> */}

            <div className='job_button text-center'>
              <button type='submit'>
                {loading ? (
                  <Loader type='Rings' color='white' height={50} />
                ) : (
                  'Submit project'
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default CompleteOrderModal;
