import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router';
import Loader from 'react-loader-spinner';
import './MessageDetails.styles.css';
import { postTalentMessages} from '../../../../store/action/talent/talentMessages';
import { GetConversation } from '../../../../store/action/hunter/hunterMessages';
import {IoSendSharp} from 'react-icons/io5';

const MessageDetails = () => {
    const dispatch = useDispatch();
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    
    const formData = new FormData();
    const [ messageText, setMessageText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
      
       formData.append("recipient_id", id)
       formData.append("message", messageText);
       formData.append("attachment" , selectedFile);
       setLoading(true);
       await dispatch(postTalentMessages(formData)); 
       setLoading(false);
       setReload(true);
       setMessageText('');
       setReload(false)
   };
    
    useEffect(() => {
        dispatch(GetConversation(id))
    }, [reload, id]);
    
    const conversations = useSelector(state => state.hunterReducer.getConversation);

    return (
        //     <>
        //     <div className='messageconversation__container'>
        //    {
        //     conversations !== undefined && conversations.map(converse => {     
              
        //   return  <div className='messageconversation__sender'>
        //     <p>{converse.message}</p>
        //     </div>
        //  })
        // } 
        // </div>

        // <section className='messageconversation__text'>
        //         <textarea placeholder='Start a  new message' rows='3' 
        //         onChange={e => setMessageText(e.target.value)}/>
        //         <div className='messageconversation__sendicon'>
        //           <button onClick={handleSubmit}>
        //           {
        //         loading ?
        //         <Loader
        //         type="Rings"
        //         color="white"
        //         height={50}
        //         width={50}
        //          /> :<IoSendSharp />
        //            }
        //            </button>
        //         </div> 
        // </section>
        //     </>
        <div className='messagetalent'>
      
         <div className='messagetalent__container'>
           { 
            conversations !== undefined && conversations.map(converse => {  
              if (id ==  converse.sender_id) {
                  return <div className='messagetalent__sender'>
                <p>{converse.message}</p>
                </div>
              } 
                return <div className='messagetalent__receiver'>
               <p>{converse.message}</p>
               </div>
              
            
             })
          } 
          </div>

          <section className='messagetalent__text'>
                <textarea placeholder='Start a  new message' rows='3' 
                onChange={e => setMessageText(e.target.value)}/>
                <div className='messagetalent__sendicon'>
                  <button onClick={handleSubmit}>
                  {
                loading ?
                <Loader
                type="Rings"
                color="white"
                height={30}
                width={30}
                 /> :<IoSendSharp />
                   }
                   </button>
                </div> 
        </section>

      </div>
    )
}


export default MessageDetails;