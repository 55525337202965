import React, { useState } from 'react';
import './ActiveOrderTable.styles.css';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import JobRequestModal from '../JobRequestModal/JobRequestModal.component';
import { useSelector } from 'react-redux';
import AcceptOrderModal from '../AcceptOrderModal/AcceptOrderModal.component';
import CompleteOrderModal from '../CompleteOrderModal/CompleteOrderModal.component';
import Milestonemodal from '../../../pages/Hunter/CreateJob/Milestonemodal.component';
import JobRequestMilestonemodal from '../Milestones/Milestones.modal';
import Loader from 'react-loader-spinner';


const ActiveOrderTable = ({ getActiveOrders }) => {
  const [show, setShow] = useState(false);
  const [selectOrder, setSelectOrder] = useState(false);
  const [showmilestone, setShowmilestone] = useState(false);
  const [submission, setSubmission] = useState(false);
  const [milestones, setMilestones] = useState([])
  const handleClose = () => setShow(false);

  const handleShow = (order) => {
    
    setSelectOrder(order);
    setShow(true);
  };

  const category = useSelector((state) => state.categoriesReducer.categories);



  const handleCloseMilestoneSubmission = () => {
    setSubmission(false);
  }
  const handleShowMilestoneSubmission = ({milestones}) => {
   
    setMilestones(milestones);
    setSubmission(true);
  };
  
  // const handleShowMilestoneSubmission = (each) => {
  //  console.log('each now', each)
  //   setMilestones(milestones);
  //   setSubmission(true);
  // };

  return (
    <div>
      <>
         
         <h6 className="openedjobs">Active jobs
         <div className='toolholder'>
        
             <div className='overtooltip'> 
             <div class="triangle-down"></div>
             <span className='tooltipopen'>Jobs paid for</span>
         </div> 
         </div></h6>
     </>
      <Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Hunter Name</th>
            <th className='active_head_bg'>Job brief </th>
            <th className='active_head_bg'>Net pay</th>
            <th className='active_head_bg'>start date</th>
            <th className='active_head_bg'>due date</th>
            <th className='active_head_bg'>Milestone Submission</th>
            <th className='active_head_bg'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Modal */}
          <CompleteOrderModal
            handleClose={handleClose}
            selectOrder={selectOrder}
            show={show}
          />

          {/* End of Modal */}
          {/* <tr>
            <td>1</td>
            <td>Jan 10, 2022</td>
            <td>I need someone who can deliver the best
            of article writing</td>
            <td>Copywriting</td>
            <td>5,000</td>
            <td>4 milestones</td>   
      
        <td className="action_icons">
       <button
        onClick={handleShow} type='button'>Apply for job</button>
        </td>
          </tr>   */}
          {getActiveOrders?.map((each, index) => {
            return (
              <tr key={index}>
                <td>{1 + index}</td>
                <td>{each.user.name}</td>
                <td dangerouslySetInnerHTML={{__html: each?.job_description}}></td>
                <td>{each?.net_pay}</td>
                <td>{each.start_date}</td>
                <td>{each.end_date}</td>
                <td className='action_icons'>
                  <button type='button' onClick={() => handleShowMilestoneSubmission(each)}>
                    milestone</button>
                    </td>
               {/*add milestone submission  */}
               {/* <JobRequestMilestonemodal submission={submission} 
              handleCloseMilestoneSubmission={handleCloseMilestoneSubmission}
                   milestones={milestones}/> */}
                <td className='action_icons'>
                  <button type='button' onClick={() => handleShow(each)}>
                    Submit Project
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <JobRequestMilestonemodal submission={submission} 
              handleCloseMilestoneSubmission={handleCloseMilestoneSubmission}
                   milestones={milestones}/>
    </div>
  );
};

export default ActiveOrderTable;
