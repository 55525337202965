import React from 'react';
import './trysolvate.css';
import {Link} from "react-router-dom";

const Trysolvate = ()=> {
    return(
        <div className="try" style={{backgroundColor: '#ADD8E6'}}>
            <div className='trygroup'>
                <h2 className='try_h2'><strong>Try Solvate Africa because you deserve the best.</strong></h2>
                <Link to="/login"><button>Get started</button></Link>
            </div>
        </div>
    )
}

export default Trysolvate;