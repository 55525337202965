import React from 'react';
import './ReviewsTab.styles.css';
import tick from '../../../../../assets/image/tick.svg';
import star from '../../../../../assets/image/star.png';
import profile from '../../../../../assets/image/profile.svg';

const ReviewsTab = ({ myGig }) => {
  return (
    <section>
      <div className='container-fluid overview-cont'>
        <div className='row overview-wrapper'>
          <div className='col-md-7 left-side'>
            <div className='reviews_copy d-flex justify-content-between'>
              <div className='reviews_copy--left d-flex align-items-baseline'>
                <p>500 Reviews</p>
                <img src={star} alt='starRating' />
                (3)
              </div>
              <select name='selection' id=''>
                <option value='top'>Sort by most relevant</option>
                <option value=''>Most recent</option>
              </select>
            </div>
            {/* What people are saying */}
            <div className='card review-words'>
              <div className='image-copy d-flex align-content-center'>
                <img src={profile} alt='ProfileImage' />
                <div className='card_title'>
                  <div className='card_title--wrapper d-flex'>
                    <p>Mr Paulson </p>
                    <img src={star} alt='starRating' />
                    <p>(5)</p>
                  </div>
                  <p data='country'>Nigeria</p>
                </div>
              </div>
              <p className='words'>
                Absolutely Brilliant, words can't describe how much I appreciate
                his work
              </p>
              <p className='year-pub'>Published 2 years ago</p>

              <div className='response'>
                <div className='image-copy d-flex'>
                  <img src={profile} alt='ProfileImage' />
                  <p data='seller'>Seller's response</p>
                </div>
                <p className='words'>Many thanks for your order. Goodluck</p>
              </div>
            </div>
          </div>

          <div className='col-md-5 right-side'>
            <div className='right-gigcontent card'>
              <div className='basic-content'>
                <div className='heading_copy d-flex justify-content-between'>
                  <h6>Gig offers</h6>
                  <div>Verified</div>
                </div>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].offers}
                  </p>
                </li>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].revisions}{' '}
                    Revisions
                  </p>
                </li>
              </div>
            </div>
            <div className='d-flex button_wrapper justify-content-center'>
              <button type='button' className='contact_button'>
                Contact creative talent
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewsTab;
