import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';
import { store } from '../../reduxStore/store';

export function postApplyJob(userDetails, history) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(userDetails);
    return axiosInstance
      .post('job/applyforjob', userDetails)
      .then(async (response) => {
        console.log(response);
        if (response.status >= 200) {
          console.log(response.data.job_user);
          await dispatch({
            type: 'APPLY_FOR_JOB',
            payload: response.data.job_user,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getAllIncomingOrder(userId) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`job/getacceptedbids/${userId}`)
      .then(async (response) => {
        console.log('accepted bid response', response, userId);
        if (response.status === 200) {
          // console.log(response)
          await dispatch({
            type: 'GET_ACCEPTED_BIDS',
            payload: response.data.acceptedBids,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
export function getACtiveOrder(userId) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`job/getactivejobs/${userId}`)
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          await dispatch({
            type: 'GET_TALENT_ACTIVE_ORDER',
            payload: response.data.activeJobs,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

// https://hotelanywhere.ng/solvateafrica/api/job/getpendingjobs

export function getPendingOrder() {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('job/getpendingjobs')
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          console.log('pending hunter order', response);
          await dispatch({
            type: 'GET_HUNTER_PENDING_ORDER',
            payload: response.data.pendingJobs,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
export function getAllSubmittedOrder() {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    return axiosInstance
      .get(`job/getsubmittedjobs`)
      .then(async (response) => {
        console.log(' submitted projectttts', response);
        if (response.status === 200) {
          console.log(response);
          await dispatch({
            type: 'GET_TALENT_SUBMITTED_ORDER',
            payload: response.data.submittedjobs,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getSubmittedOrderDetail(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    return axiosInstance
      .get(`job/getsubmittedjob/${id}`)
      .then(async (response) => {
        console.log('submitted detail subatance',response);
        if (response.status === 200) {
          await dispatch({
            type: 'GET_SUBMITTED_ORDER_DETAIL',
            payload: response.data.submission,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function getUserCompletedOrder(userId) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    return axiosInstance
      .get(`job/getacceptedjobs`)
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          console.log('hunter payment completed job', response.data);
          await dispatch({
            type: 'GET_COMPLETED_ORDER',
            payload: response.data.acceptedjobs,
          });
          await dispatch({
            type: 'GET_COMPLETED_PAYHISTORY',
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function acceptHunterOrder(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(id);
    return axiosInstance
      .put(`job/startjob/${id}`)
      .then(async (response) => {
          console.log('my acceptance response', response);
        
        if (response.data.success === true) {
          // console.log('my acceptance responser', response.data);C?
          await dispatch({
            type: 'ACCEPT_HUNTER_JOB',
            payload: response.data
            // type: 'APPLY_FOR_JOB',
            // payload: response.data.job_user,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function acceptSubmittedProject(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(id);
    return axiosInstance
      .put(`job/acceptsubmittedjob/${id}`)
      .then(async (response) => {
        console.log(response);
        if (response.status >= 200) {
          // console.log(response.data.job_user);
          await dispatch({
            type: 'SUBMITTED_JOB_ACCEPTED',
            payload: response.data.job,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function submitHunterOrder(formData, id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'multipart/form-data',
        "Accept": 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    console.log(id);
    return axiosInstance
      .post(`job/submitjob/${id}`, formData)
      .then(async (response) => {
        // console.log(response);
        if (response.status == 200) {
          console.log(response.data.job_user);
          let activeJobs =
            store.getState().talentApplyForJobReducer.getActiveOrder;
          let index = activeJobs.findIndex((x) => x.id == id);
          // console.log(index);
          // console.log(activeJobs[index]);
          let newData = activeJobs.filter(
            (each) => each.id != response.data.submission.job_id
          );
          // console.log(newData);
          await dispatch({
            type: 'SUBMITTED_JOBS',
            payload: newData,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}

export function postReview(data) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    // console.log(id);
    return axiosInstance
      .post('review/sendreview', data)
      .then(async (response) => {
        console.log(response);
        if (response.status == 200) {
          console.log(response);

          // console.log(newData);
          // await dispatch({
          //   type: 'SUBMITTED_JOBS',
          //   payload: newData,
          // });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}



export function uploadPastJobs(data) {
  console.log('data passed', data)
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        // 'Content-Type': 'application/json',
        // Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    
    return axiosInstance
      .post('usergallery', data)
      .then(async (response) => {
        console.log('uploaded past jobs response', response);
        if (response.data.success === true) {
          console.log('uploaded past', response);
          await dispatch({
            type: 'UPLOAD_PAST_JOBS',
            payload: response.data.media,
          });
        }

      //   elseif (response.data.success == false) {
      //     dispatch({
      //      type: 'UPLOAD_PAST_ERRORJOBS',
      //      payload: response.data,
      //    });
      //  }
      })
      .catch((error) => {
        if (error.response === undefined) {
          dispatch({
           type: 'UPLOAD_PAST_ERRORJOBS',
          //  payload: {}
         });
       }
      });
  };
}

export function getPastJobs(pageNum) {
 
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    
    return axiosInstance
      .get('usergallery')
      // .get(`https://api.solvateafrica.com/api/api/usergallery?page=${pageNum}`)
      .then(async (response) => {
        console.log(response);
        if (response.status >= 200) {
          console.log('gotten past job', response.data.gallery.data);
          await dispatch({
            type: 'GET_PAST_JOBS',
            payload: response.data.gallery,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}
