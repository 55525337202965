// import React, { useState }from 'react';
// import './GigCreated.styles.css';
// import cardImage from '../../../assets/image/card-image.svg';
// import { useDispatch, useSelector } from 'react-redux';

// const GigCreated = () => {
//     const userGigs = useSelector(state=>state.createGigReducer.userGigs)

//     console.log(userGigs)

//     return (
//         <section className="container">
//             {
//                 userGigs.length < 1 ?
//                 <p>No Gig yet, Gig data will display here</p> :
//                 <div>
//                     <h2>List of Created gigs</h2>
//                     {
//                         userGigs.map((each,id) =>{
//                             return(
//                                 <div key={id} className="left-card">
//                                 <img className="img-fluid" src={cardImage} alt="cardFlyer"/>
//                                 <div className="card-copy">
//                                     <h5>{each.title}</h5>
//                                     <p>Starting at <span> ₦{
//                                         each.scopepackage.map(each =>{
//                                             if(each.package === "Basic"){
//                                                 return each.price
//                                             }
//                                         }
//                                             )
//                                         }</span></p>
//                                 </div>
//                                 </div>
//                             )
//                         })
//                     }
//                 </div>
//             }
import React, { useState, useEffect } from 'react';
import edit from '../../../assets/image/edit-icon.svg';
import remove from '../../../assets/image/delete.svg';
import cardImage from '../../../assets/image/card-image.svg';
import { useHistory, useParams } from 'react-router';
import info from '../../../assets/image/info.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './GigCreated.styles.css';
import logoimage from '../../../assets/image/announce.png';
import { deleteGig } from '../../../store/action/creategigAction/overview';
import { getGigDetailAction } from '../../../store/action/searchAction/searchGigAction';

const GigCreated = () => {
  const userGigs = useSelector((state) => state.createGigReducer.userGigs);
  const [showIcon, setShow] = useState(false);
  const dispath = useDispatch();
  console.log('my created gigsss', userGigs);
  const history = useHistory();
  const displayIcon = (id) => {
    // <<<<<<< HEAD
    //         setShow(true, id);
    // =======
    setShow(id);
  };
  const hideIcon = (id) => {
    setShow(false);
  };

  // useEffect(
  //     () => {
  //     let gigDetails = userGigs.map((user)=> {
  //             return user
  //         })
  //     console.log(gigDetails);
  //     }
  // )
  const handleClickGig = async (id) => {
    await dispath(getGigDetailAction(id));
    history.push('/gigDetails');
  }

  const handleDeleteGig = async(gigToDelete) => {
    console.log(gigToDelete);
    await dispath(deleteGig(gigToDelete.id));
    // dispa
  };
  return (
    <section className='container'>
      {userGigs.length < 1 ? (
        <p>No portfolio yet, portfolio will be displayed here</p>
      ) : (
        <div className='container-fluid top-cards-wrapper'>
          <div className='title'>
            <>
              {['top'].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Tooltip id={`tooltip-${placement}`}>
                      Move your mouse over a gig card to edit or delete gig
                    </Tooltip>
                  }
                >
                  {/* <a>Tooltip on {placement}</a> */}
                  <img src={info} alt='infoIcon' />
                </OverlayTrigger>
              ))}
            </>
            {/* <h2>List of created gigs</h2> */}
            <h2>Talent's Gig/Portfolio</h2>

          </div>
          {/* <div className='row card-wrapper-gig'>
            {userGigs.map((each, id) => {
              return (
                <Link
                  key={id}
                  className='card card-group col-md-4'
                  onMouseEnter={() => displayIcon(each.id)}
                  onMouseLeave={hideIcon}
                  to={`/gigDetails/${each.id}`}
                >
                  <div>
                    <div
                      className='image_wrapper' 
                    >
                      {/* <div className="background-image" 
                         style={{
                         backgroundImage: `url(${cardImage})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover' }}
                     >
                     <img className="img-card" src={logoimage} alt="cardImg"/>
                         </div> */}
                      {/* <img className='img-card' src={cardImage} alt='cardImg' /> */}
                    {/* </div>

                    <div className='card-copy'>
                      <h5>{each.title}</h5>
                      {showIcon === each.id ? (
                        <div className='edit-delete d-flex'>
                          <Link to={`editGig/${each.id}`}>
                            <img src={edit} alt='EditImage' />
                          </Link>
                          <img
                            src={remove}
                            onClick={() => handleDeleteGig(each)}
                            alt='deleteImage'
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div> */}
          <div className="d-flex image_holder"  style={{display: "flex", flexWrap:"wrap"}}>
              {userGigs?.map((each, id) => {
              return (
              <div className="imag_cardslot col-lg-6"
              onMouseEnter={() => displayIcon(each?.id)}
              >
                <Link to={`gigDetails/${each?.id}`}
                  key={id}
                  onMouseLeave={hideIcon} 
                >
                  
                {/* <img className="imag-card" src={each?.gallery && each?.gallery[0]?.image !== undefined ? `https://api.solvateafrica.com/public/images/galleries/${each?.gallery[0]?.image}` : `${cardImage}`} alt="cardImg" min-width="200px" height="250"/> */}
                {each.gallery !== undefined  && ( each?.gallery[0]?.image.includes(".jpg") 
                || each?.gallery[0]?.image.includes(".png")) ?
                        <img className="imag-card" src={`https://api.solvateafrica.com/public/images/galleries/${each.gallery[0]?.image}`} alt="cardImg" min-width="200px" height="250"/> 
                     :
                     each.gallery !== undefined&& each?.gallery[0]?.image.includes(".mp4") ?
                    <video width="100%" height="250" controls>
                    <source src={`https://api.solvateafrica.com/public/images/galleries/${each?.gallery[0]?.image}`} type="video/mp4"></source>
                    </video> :  
                    <audio controls >
                      <source src={each.gallery !== undefined ? `https://api.solvateafrica.com/public/images/galleries/${each?.gallery[0]?.image}` : ''} type="audio/mpeg"></source>
                    </audio>
            }
                <h6 className="card_header" style={{color: "black"}}>{each?.title}</h6>
            </Link>
            
                    {/* {showIcon === each.id ? ( */}
                        

                        <div className='edit-delete d-flex'>
                        
                          <Link to={`editGig/${each?.id}`}>
                            <img src={edit} alt='EditImage' />
                          </Link>
                          <img
                            src={remove}
                            onClick={() => handleDeleteGig(each)}
                            alt='deleteImage'
                          />
                         
                        </div>
                      
                      {/* ) 
                     : null} */}
            {/* </Link> */}
                    
            </div> 
          
            )})}
          </div>
        </div>
      )}
    </section>
  );
};

export default GigCreated;
