import { axiosInstance } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { store } from '../../reduxStore/store';

// "scripts": {
  //   "start": "react-scripts start",
  //   "build": "react-scripts build",
  //   "test": "react-scripts test",
  //   "eject": "react-scripts eject",
  //   "heroku-postbuild": "npm run build"
  // },

function GetToken() {
  console.log(store);
  // return getStorage()
  // return store.getState().root.token == null ? sessionStorage.getItem('token') : store.getState().root.token
}
// GetToken()

export function login(userDetails, props) {
  axiosInstance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });
  return function (dispatch) {
    // console.log(userDetails)
    return axiosInstance
      .post('login', userDetails)
      .then(async (response) => {
        console.log(response.data);
        if (response.data.success === true) {
          sessionStorage.setItem('token', response.data.token);
          await dispatch({ type: 'LOGIN', payload: response.data });
          sessionStorage.setItem('token', response.data.token);
          if (response.data.user_profile == null) {
            props.push('/updateProfile');
          } else if (response.data.user_profile) {
            if (response.data.user_account_types.length === 2) {
              props.push('/publicProfile');
            } else {
              response.data.user_account_types.map((each) => {
                if (each.accounttypeId === 1) {
                  props.push('/hunterDashboard');
                } else if (each.accounttypeId === 2) {
                  props.push('/publicProfile');
                }
              });
            }

          //   if (response.data.user_account_types.length === 2) {
              
          //     response.data.user_account_types.map((each) => {
          //       if (each.accounttypeId === 1) {
          //         props.push('/hunterDashboard');
          //       } else if (each.accounttypeId === 2) {
          //         props.push('/publicProfile');
          //       }
          //     });
            
          // }
          }
          // await dispatch({ type: "MESSAGE", payload: { status : true, message: 'Registration Successful', type: 'success'} })
        } else {
          // console.log(response.data)
          toast.error('Invalid Credentials', {
            className: css({
              background: '#00FF00 !important',
              color: 'white !important',
              fontWeight: 'bold',
            }),
            position: toast.POSITION.TOP_CENTER,
          });
          // await dispatch({ type: "LOGIN_ERROR", payload: response.data });
        }
      })
      .catch((error) => {
        // console.log(error.response)
        // console.log(error)
        if (error.response !== undefined) {
          if (error.response.status === 422) {
            if (error.response.data.errors.email) {
              toast.error(error.response.data.errors.email[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (error.response.data.errors.password) {
              toast.error(error.response.data.errors.password[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        }
      });
  };
}

export function loginSocial(userDetails, props) {
  axiosInstance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });
  return function (dispatch) {
    console.log(userDetails);
    return axiosInstance
      .post('socialmedialogin', userDetails)
      .then(async (response) => {
        console.log(response.data);
        if (response.data.success === true) {
          sessionStorage.setItem('token', response.data.token);
          await dispatch({ type: 'LOGIN', payload: response.data });
          sessionStorage.setItem('token', response.data.token);
          if (response.data.user_profile == null) {
            props.push('/updateProfile');
          } else if (response.data.user_profile) {
            props.push('/publicProfile');
          }
          // props.push('/updateProfile')
          // await dispatch({ type: "MESSAGE", payload: { status : true, message: 'Registration Successful', type: 'success'} })
        } else {
          // console.log(response.data)
          toast.error('Invalid Credentials', {
            className: css({
              background: '#00FF00 !important',
              color: 'white !important',
              fontWeight: 'bold',
            }),
            position: toast.POSITION.TOP_CENTER,
          });
          // await dispatch({ type: "LOGIN_ERROR", payload: response.data });
        }
      })
      .catch((error) => {
        // console.log(error.response)
        // console.log(error)
        if (error.response !== undefined) {
          if (error.response.status === 422) {
            if (error.response.data.errors.email) {
              toast.error(error.response.data.errors.email[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (error.response.data.errors.password) {
              toast.error(error.response.data.errors.password[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        }
      });
  };
}





export function Logout (props) {
  axiosInstance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });
  return function (dispatch) {
    // console.log(userDetails)
    return axiosInstance
      .post('logout')
      .then(async (response) => {
        console.log(response.data);
        if (response.data.success === true) {
          sessionStorage.removeItem('token');
          localStorage.removeItem('token');
                  props.push('/');
                } 
              })
          // await dispatch({ type: "MESSAGE", payload: { status : true, message: 'Registration Successful', type: 'success'} })
    
      .catch((error) => {
        // console.log(error.response)
        // console.log(error)
        if (error.response !== undefined) {
          if (error.response.status === 422) {
            if (error.response.data.errors.email) {
              toast.error(error.response.data.errors.email[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (error.response.data.errors.password) {
              toast.error(error.response.data.errors.password[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        }
      });
  };
}
