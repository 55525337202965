import {axiosInstance} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';

export function register (userDetails,props) {
    axiosInstance.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem('jwt');
        config.headers.Authorization = `Bearer ${token}`
        // config.headers = { ...config.headers, auth_token: getAuthToken() };
        
        return config;
      })
      return function(dispatch){
        // console.log(userDetails)
         return axiosInstance.post('register' ,userDetails).then ( async (response) => {
           console.log(response.data)
            if(response.data.success === true){
              await dispatch({ type: "REGISTER", payload: response.data });
              props.push('/login');
          }
          else{  
            await dispatch({ type: "REGISTER_ERROR", payload: response.data });        }
          })
          .catch(
              error => {
                // console.log(error.response)
                // console.log(error)
                if(error.response !== undefined){

                  if(error.response.status === 422){
                    if(error.response.data.errors.email){
                      toast.error(error.response.data.errors.email[0], {
                          className: css({
                            background: "#00FF00 !important",
                            color: "white !important",
                            fontWeight: "bold"
                          }),
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                    else if(error.response.data.errors.password){
                      toast.error(error.response.data.errors.password[0], {
                          className: css({
                            background: "#00FF00 !important",
                            color: "white !important",
                            fontWeight: "bold"
                          }),
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                  }

                }
              }
          )
      }

}


export function registerSocial (userDetails,props) {
  axiosInstance.interceptors.request.use(function (config) {
      // const token = sessionStorage.getItem('jwt');
      // config.headers.Authorization = `Bearer ${token}`
      // config.headers = { ...config.headers, auth_token: getAuthToken() };
      
      return config;
    })
    return function(dispatch){
      console.log(userDetails)
       return axiosInstance.post('socialmediaregister' ,userDetails).then ( async (response) => {
        //  console.log(response.data)
          if(response.data.success === true){
            await dispatch({ type: "REGISTER", payload: response.data });
            props.push('/login');
            // await dispatch({ type: "MESSAGE", payload: { status : true, message: 'Registration Successful', type: 'success'} })
        }
        else{  
          await dispatch({ type: "REGISTER_ERROR", payload: response.data });        }
        })
        .catch(
            error => {
              // console.log(error.response)
              // console.log(error)
              if(error.response !== undefined){

                if(error.response.status === 422){
                  if(error.response.data.errors.email){
                    toast.error(error.response.data.errors.email[0], {
                        className: css({
                          background: "#00FF00 !important",
                          color: "white !important",
                          fontWeight: "bold"
                        }),
                        position: toast.POSITION.TOP_CENTER,
                      });
                  }
                  else if(error.response.data.errors.password){
                    toast.error(error.response.data.errors.password[0], {
                        className: css({
                          background: "#00FF00 !important",
                          color: "white !important",
                          fontWeight: "bold"
                        }),
                        position: toast.POSITION.TOP_CENTER,
                      });
                  }
                }

              }
            }
        )

    }

}
