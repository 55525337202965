import React, { useEffect, useState } from 'react';
// import './ProjectTrackerTable.styles.css';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdVisibility } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import close from "../../../assets/image/close.svg";
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getACtiveOrder } from '../../../store/action/talent/applyJobAction';
import moment from 'moment';
// import ProjectTrackerJobsModal from '../ProjectTrackerJobsModal/ProjectTrackerJobsModal.component';

const ActiveProjectTable = ({ show, handleClose, handleShow }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);

  const getActiveOrders = useSelector(
    (state) => state.talentApplyForJobReducer.getActiveOrder
  );
  const [isFetching, setIsFetching] = useState(false);
  console.log("ella's active orders", getActiveOrders);
  useEffect(() => {
    // getOrder();
    getActiveOrder();
    return () => {};
  }, []);

  const [milestone, setMilestone] = useState(false);
  const [milestonedata, setMilestonedata] = useState([]);

  const handleClosemilestone = () => setMilestone(false);
 

  const handleMilestone = (milestones) => {
    setMilestonedata(milestones)
    setMilestone(true);
  };

  const getActiveOrder = async () => {
    setIsFetching(true);
    await dispatch(getACtiveOrder(user.id));
    setIsFetching(false);
    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };

  return (
    <section>
      {isFetching ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        <Table className='table_tracker'>
          <thead>
            <tr>
              <th className='active_head_bg'>#</th>
              <th className='active_head_bg'>Seller</th>
              <th className='active_head_bg'>Job Description</th>
              <th className='active_head_bg'>Milestone</th>
              <th className='active_head_bg'>Due On</th>
              <th className='active_head_bg'>Status</th>
              <th className='active_head_bg'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* <ProjectTrackerJobsModal
            handleClose={handleClose}
            show={show}
            handleShow={handleShow}
          /> */}
            {getActiveOrders && getActiveOrders.map((each, id) => {
              return (
            <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{each?.user.name}</td>
                  {/* <td>{each?.creative.name}</td> */}
                  <td dangerouslySetInnerHTML={{__html: each.job_description}}>
                  </td>
                  <Modal
                size='md'
                show={milestone}
            onHide={handleClosemilestone}
              dialogClassName='modal-500w'
                >
            <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClosemilestone} />
          <h3> Submitted milestone</h3>
        </div>
        <Modal.Body>
        {milestonedata?.map((milestone) => {
        return <div className='editjob_milestone' key={milestone?.id} >
          <h4>Title: {milestone.title}</h4><hr/>
          <Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            title
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}    
                        <div>Title</div>
                      </OverlayTrigger>
                    ))}
                  </></th>
                  <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            comment
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                                          
                        <div>Comment</div>
                        {/* <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">View</button> */}
                      </OverlayTrigger>
                    ))}
                  </></th>
                  {/* attachment */}
                  <th className='active_head_bg'>
                <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            attachment
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                                          
                        <div>Attachment</div>
                        {/* <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">View</button> */}
                      </OverlayTrigger>
                    ))}
                  </></th>
          </tr>
        </thead>
        <tbody>
          
            { milestone.submissions?.map((sub, index) => {
          return <>
               <tr>
                <td>{index+1}</td>
                <td>{sub?.title}</td>
                <td>{sub?.comment}</td>
                <td>
                  {sub?.upload === null ? 'none' :
                  <a target='_blank' href={`https://api.solvateafrica.com/public/jobs/milestonesubmissions/${sub?.upload}`} className="milestone_add">View</a>
                  }
                  </td>
                {/* className="milestone_add" */}
                </tr>
                </>
                  }) 
      }
        </tbody>
        </Table>
        {/* {milestone.submissions?.map((sub) => {
          console.log('subbb', sub)
        return <div key={sub?.id}>
          <div className='job_field_card'>
        <label className='milestone_label'>Submission title:</label><br/>
        <p style={{color:"rgb(0,0,0)"}}>{sub?.title}</p>
        </div>
        <div className='job_field_card'>
        <label className='milestone_label'>Comment:</label><br/>
        <p style={{color:"rgb(0,0,0)"}}>{sub.comment}</p>
        </div>
        <a  target='_blank' href={`https://api.solvateafrica.com/public/jobs/milestonesubmissions/${sub?.upload}`} className="milestone_add">View Image</a>
        </div>
        })} */}
       </div> 
         })} 
        </Modal.Body>
      </Modal>
                  <td><>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            View Milestone
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">milestone</button>
                      </OverlayTrigger>
                    ))}
                  </></td>
                  <td>{moment(each.end_date).format('ll')}</td>

                  <td>
                    {Number(moment(Date()).format('l').split('/')[0]) >=
                      Number(moment(each.end_date).format('l').split('/')[0]) &&
                    Number(moment(Date()).format('l').split('/')[1]) >
                      Number(moment(each.end_date).format('l').split('/')[1]) &&
                    Number(moment(Date()).format('l').split('/')[2]) >=
                      Number(
                        moment(each.end_date).format('l').split('/')[2]
                      ) ? (
                      <div
                        style={{
                          backgroundColor: 'rgba(2, 0, 121, 0.65)',
                          color: 'red',
                        }}
                      >
                        Late Delivery
                      </div>
                    ) : (
                      each.status
                    )}
                  </td>
                  <td>
                    <>
                      {['top'].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              View tracker
                            </Tooltip>
                          }
                        >
                          {/* <a>Tooltip on {placement}</a> */}
                          {/* /hunterDashboard/trackersettingshunter */}
                          <Link to='/hunterDashboard/projecttrackerhunter'>
                            <MdVisibility className='view_icon_tracker' />
                          </Link>
                        </OverlayTrigger>
                      ))}
                    </>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </section>
  );
};

export default ActiveProjectTable;
