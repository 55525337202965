import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import axios from 'axios';

export function AllUserGigs(userDetails) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('gig/get')
      .then(async (response) => {
        console.log(response);
        await dispatch({
          type: 'GET_ALL_USER_GIGS',
          payload: response.data.gigs,
        });
      })
      .catch((error) => {
        // console.log(error.response)
        // console.log(error.response)
        if (error.response !== undefined) {
          if (error.response.status === 422) {
            if (error.response.data.errors.email) {
              toast.error(error.response.data.errors.email[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (error.response.data.errors.password) {
              toast.error(error.response.data.errors.password[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        }
      });
  };
}

export function getEachUserGigs(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`gig/getusergigs/${id}`)
      .then(async (response) => {
        console.log(response);
        await dispatch({
          type: 'GET_EACH_USER_GIGS',
          payload: response.data.gigs,
        });
      })
      .catch((error) => {
        // console.log(error.response)
        // console.log(error.response)
        if (error.response !== undefined) {
          if (error.response.status === 422) {
            if (error.response.data.errors.email) {
              toast.error(error.response.data.errors.email[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (error.response.data.errors.password) {
              toast.error(error.response.data.errors.password[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        }
      });
  };
}

export function getUserPubProfile(id) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`getuser/${id}`)
      .then(async (response) => {
        console.log('user profile response', response);
        await dispatch({
          type: 'GET_USER_PUB_PROFILE',
          payload: response.data.users,
        });
      })
      .catch((error) => {
        // console.log(error.response)
        // console.log(error.response)
        if (error.response !== undefined) {
          if (error.response.status === 422) {
            if (error.response.data.errors.email) {
              toast.error(error.response.data.errors.email[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (error.response.data.errors.password) {
              toast.error(error.response.data.errors.password[0], {
                className: css({
                  background: '#00FF00 !important',
                  color: 'white !important',
                  fontWeight: 'bold',
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        }
      });
  };
}
