import React, { useState } from 'react';
import './Gallery.styles.css';
import GigsInput from '../../../../../components/GigsInput/GigsInput.component';
import info from '../../../../../assets/image/info.svg';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { postgallery } from '../../../../../store/action/creategigAction/gallery';
import Loader from 'react-loader-spinner';


const Gallery = ({backFirstStep}) => {
    const gig = useSelector(state=>state.createGigReducer.gig)
    const [gigImg, setGigImg] = useState(null);
    const [gigpic, setGigPix] = useState(null);
    const [loading, setLoading] = useState(false)


    const histroy = useHistory()
    const dispatch = useDispatch()

    const handleChangeImage = (e)=>{
        let img = e.target.files[0];
        setGigImg(img)
        setGigPix(URL.createObjectURL(img))

    }
    const handleSubmit = async (e)=>{
        e.preventDefault()
        // console.log(gig.id)
        setLoading(true);
        await dispatch(postgallery(gigImg,gig.id));
        setLoading(false);
        histroy.push('/publicProfile')
    }

    return (
        <div>
    {/*<h6>Images (Up to 3 )</h6>*/}
        <form onSubmit={handleSubmit}>
        <div className="form-group-gigs">
        <GigsInput
        label="Upload image.Format- png,jpeg, mp3,mp4(Not greater than 15MB)"
        name="profile_image"
        onChange={handleChangeImage}
        type="file"/>
        
        </div>
        {/* {
            gigPix===null ?
            <img className="img-fluid" src={elipse} alt="elipse"/> :
            <img className="img-fluid" style={{borderRadius:"50%",height:"10rem",width:"10rem"}} src={gigPix} alt="elipse"/>
          } */}
        <div className="image-box">
        <div className="box-1">
        {
            gigpic===null ?
            <p>Browser to upload image</p> :
            <img className="img-fluid" style={{height:"auto",width:"100%"}} src={gigpic} alt="elipse"/>
        }
        
        </div>
        {/* <div className="box-2">
        <p>Browser to upload image</p>
        </div>
        <div className="box-3">
        <p>Browser to upload image</p>
        </div> */}       

        </div>
        <div className="gigs-button">
        <button onClick={backFirstStep} type="button">Back</button>
        <button type="submit"
        >
        {
              loading ?
              <Loader
                type="Rings"
                color="white"
                height={50}
                width={50}
              /> :"Create portfolio"
            }
        </button>
        </div>
        </form>

        </div>
    )
}


export default Gallery;