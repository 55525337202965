import React, { useEffect, useState } from 'react';
import './AboutSellerTab.styles.css';
import Tab from 'react-bootstrap/Tabs';
import tick from '../../../../../assets/image/tick.svg';
import profile from '../../../../../assets/image/profile.svg';
import { useDispatch, useSelector } from 'react-redux';
import { country } from '../../../../../store/action/authAction/countryandstate';
// import tick from '../../../../../assets/image/tick.svg';
import star from '../../../../../assets/image/star.png';

const AboutSellerTab = ({ myGig }) => {
  const countries = useSelector((state) => state.countryandstate.countries);
  console.log(myGig);

  return (
    <section>
      <div className='container-fluid overview-cont'>
        <div className='row overview-wrapper'>
          <div className='col-md-7 left-side'>
            <h3>About the seller</h3>
            <div className='d-flex profile-content-wrapper'>
              <img src={profile} alt='profileImage' />
              <div className='profile-content'>
                <h6>
                  {myGig === null ? null : myGig.userdetail.lastname}{' '}
                  {myGig === null ? null : myGig.userdetail.firstname}
                </h6>
                <p style={{marginBottom:"10px"}}>{myGig === null ? null : myGig.userdetail.storyline}</p>
                <div className='d-flex five_star'>
                  <img src={star} alt='starRating' />
                  <img src={star} alt='starRating' />
                  <img src={star} alt='starRating' />
                  <img src={star} alt='starRating' />
                  <img src={star} alt='starRating' />
                  <p>5(100)</p>
                </div>
                <button type='button'>Contact Me</button>
              </div>
            </div>
            <div className='card card-copy'>
              <div className='d-flex justify-content-between'>
                <div className=''>
                  <p>From</p>
                  <h6>
                    {myGig === null
                      ? null
                      : countries.map((each, id) => {
                          if (each.id === myGig.userdetail.countryId) {
                            return each.name;
                          }
                        })}
                  </h6>
                </div>
                <div className=''>
                  <p>Member since</p>
                  <h6>
                    {myGig === null
                      ? null
                      : new Date(myGig.user.created_at).toDateString()}
                  </h6>
                </div>
              </div>
              <hr />
              <p>{myGig === null ? null : myGig.userdetail.description}</p>
            </div>

            {/* <div className="about-gig">
  <h3>About the Gig</h3>
  </div> */}
          </div>
          {/*Right*/}
          <div className='col-md-5 right-side'>
            <div className='right-gigcontent card'>
              <div className='basic-content'>
                <div className='heading_copy d-flex justify-content-between'>
                  <h6>Gig offers</h6>
                  <div>Verified</div>
                </div>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].offers}
                  </p>
                </li>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].revisions}{' '}
                    Revisions
                  </p>
                </li>
              </div>
            </div>
            <div className='d-flex button_wrapper justify-content-center'>
              <button type='button' className='contact_button'>
                Contact creative talent
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSellerTab;
