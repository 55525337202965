import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import SubmittedOrderHunterTable from '../../../components/Hunter/SubmittedOrderTable/SubmittedOrderHunterTable.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptSubmittedProject,
  getAllSubmittedOrder,
  getSubmittedOrderDetail,
} from '../../../store/action/talent/applyJobAction';

import { SiInformatica } from 'react-icons/si';
import { BsPersonCircle } from 'react-icons/bs';
import { AiFillFileText } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';
import Loader from 'react-loader-spinner';
import { useParams } from 'react-router';
import moment from 'moment';
import { getReviewForJob } from '../../../store/action/publicProfileAction/addReview';
import { Rating } from '@mui/material';
import { getTalentAppliedJob } from '../../../store/action/hunter/postJob';
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';

const AppliedProjectDetailPage = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsIsloading] = useState(false);
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const user = useSelector((state) => state.authReducer.user);
  const talentBids = useSelector(
    (state) => state.talentBidReducer.talentSentJob
  );
  const getSubmittedDetail = useSelector(
    (state) => state.talentApplyForJobReducer.getSubmittedDetail
  );
  // const getSentRequest = useSelector(
  //   (state) => state.talentBidReducer.talentSentRequest
  // );
  console.log('====================================');
  console.log(talentBids);
  console.log('====================================');
  const params = useParams();
  console.log(params.id);
  // console.log(getSubmittedDetail);
  const history = useHistory();
  useEffect(() => {
    getTalentSubmittedOrder();
    // fetchReviews();

    return () => {};
  }, []);
  const getTalentSubmittedOrder = async () => {
    setIsFetching(true);
    await dispatch(getTalentAppliedJob(params.id));
    setIsFetching(false);
    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };
  // const fetchReviews = async () => {
  //   setIsFetching(true);
  //   await dispatch(getReviewForJob(params.id));
  //   setIsFetching(false);
  // };
  const handleAcceptProject = async () => {
    setIsIsloading(true);
    await dispatch(acceptSubmittedProject(params.id));
    setIsIsloading(false);
  };

  return (
    <div className='Projects_wrapper container-fluid'>
      <NavTalent />
      <h3>submitted projects Detail</h3>

      <div className='projects_button d-flex justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='projects_button--back'
          type='button'
        >
          Go back
        </button>
      </div>

      {isFetching || talentBids === null ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        <div className='submitted'>
          <div className='submitted__left'>
            <div
              className={
                talentBids.rejection_reason !== null
                  ? 'rejct__left--task'
                  : 'sent-offer__left--task'
              }
            >
              {talentBids.rejection_reason !== null ? (
                <h4>Sent Offer Rejected!</h4>
              ) : (
                <h4>Offer Sent!</h4>
              )}
              {/* <p>You earned $5 for this order. Great, job.</p> */}
              {talentBids.rejection_reason !== null ? (
                <p>
                  Ops!!! Sorry this job Request was Rejected by Hunter, Nice
                  try.
                </p>
              ) : (
                <p>
                  Job offer sent, Please wait for Hunter to Review and get back
                  to you
                </p>
              )}
            </div>

            <div className='submitted__details'>
              <div className='submitted__details--left'>
                <div className='details-icon'>
                  <SiInformatica />
                </div>
              </div>
              <div className='submitted__details--right'>
                <h5>Your order details!</h5>
                <div>
                  <p>
                    Talent:{' '}
                    <span className='ordered__talent'>
                      {talentBids.user.name}
                    </span>
                    <span className='date__ordered'>
                      Date applied:{' '}
                      {moment(talentBids.created_at).format('lll')}
                    </span>
                  </p>
                </div>
                <p>{talentBids.job.job_description}</p>
                <div className='submitted__table-container'>
                  <table className='submitted__table'>
                    <thead>
                      <tr>
                        <th>ITEM</th>
                        <th>DATE CREATED</th>
                        <th>MILESTONE.</th>
                        <th>DUE DATE</th>
                        <th>PRICE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='tr-body'>{talentBids.job.title}</td>
                        <td className='tr-body'>{talentBids.job.created_at}</td>
                        <td className='tr-body'>{talentBids.job.milestone}</td>
                        <td className='tr-body'>
                          {moment(talentBids.job.end_date).format('ll')}
                        </td>
                        <td className='tr-body'>
                          {talentBids.job.price_budget}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className='tr-foot'>TOTAL</td>
                        <td className='tr-foot'></td>
                        <td className='tr-foot'></td>
                        <td className='tr-foot'></td>
                        <td className='tr-foot'>
                          {talentBids.job.price_budget}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            {talentBids.rejection_reason !== null ? (
              <div className='delivered__details'>
                <div className='delivered__details--left'>
                  <div className='details-icon'>
                    <SiInformatica />
                  </div>
                </div>

                <div className='delivered__details--right'>
                  <h5>
                    Rejection Comment{' '}
                    <span>{moment(talentBids.updated_at).format('lll')}</span>
                  </h5>
                  <div className='delivery__sub'>
                    <div className='delivery__sub-left'>
                      <BsPersonCircle className='delivery__user' />
                    </div>
                    <div className='delivery__sub-right'>
                      <p>{talentBids.rejection_reason}</p>

                      <div className='attachments'>
                        <h6>Attachments</h6>
                        <div className='delivered__attachment'>
                          <div className='delivered__attached'>
                            <div className='attached-icon'>
                              <AiFillFileText />
                            </div>
                            <div className='attachment__download'>
                              <p>
                                TERM...doc (38kB){' '}
                                <span>
                                  <HiDownload />
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className='delivered__attached'>
                            <div className='attached-icon'>
                              <AiFillFileText />
                            </div>
                            <div className='attachment__download'>
                              <p>
                                PRIVACY...doc (38kB){' '}
                                <span>
                                  <HiDownload />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className='submitted__right'>
            <div className='submitted__right--order'>
              <h5>Order Details</h5>
              <div className='order'>
                {/* <h6>Ordered by</h6> */}
                <h6>Delivered by</h6>
                <p>{talentBids.user.name}</p>
              </div>

              <div className='delivery'>
                <h6>Application date</h6>
                <p> {moment(talentBids.created_at).format('lll')}</p>
              </div>

              <div className='total'>
                <h6>Total price</h6>
                <p>{talentBids.job.price_budget}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppliedProjectDetailPage;
