// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    loading: false,
    errorMessages: null, 
    postHunterMessage:null,
    getConversations:[],
    getConversation:[]   
}

    export const hunterReducer = (state=initialState, action)=>{

    if (action.type === 'REQUEST_HUNTER_MESSAGE') {
    return {...state, loading: true}
    }
    else if(action.type === "ADD_HUNTER_MESSAGE"){
        toast.success("Sent",
        {
            position: toast.POSITION.BOTTOM_CENTER}
         )
        return {...state, loading:false, postHunterMessage:action.payload}
    }

    else if(action.type === "GET_HUNTER_SENT_MESSAGE"){
       
        return {...state,  getHunterSentMessages:Object.entries(action.payload.categories)}
    }

    else if(action.type === "GET_HUNTER_SENTERROR_MESSAGE"){
       
        return {...state,  errorMessages:action.payload}
    }

    else if(action.type === "GET_CONVERSATIONS"){

        return {...state,  getConversations:action.payload}
    }

    else if(action.type === "GET_CONVERSATIONS_ERRORMSG"){
       
        return {...state,  errorMessages:action.payload}
    }

    else if(action.type === "GET_CONVERSATION"){
       
        return {...state,  getConversation: action.payload.sort((a,b) =>{ return a.id-b.id})}
    }

    else if(action.type === "GET_CONVERSATION_ERRORMSG"){
       
        return {...state,  errorMessages: action.payload}
    }
    
    else{
        return state;
    }
    }    