import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import axios from 'axios';

export function getAllLanguages () {
    
      return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
         return axiosInstance.get('language').then ( async (response) => {
           console.log(response.data.data)
              await dispatch({ type: "GET_LANGUAGES", payload: response.data })
          })
          .catch(
              error => {
                // console.log(error.response)
                // console.log(error.response)
                // if(error.response !== undefined){

                //   if(error.response.status === 422){
                //     if(error.response.data.errors.email){
                //       toast.error(error.response.data.errors.email[0], {
                //           className: css({
                //             background: "#00FF00 !important",
                //             color: "white !important",
                //             fontWeight: "bold"
                //           }),
                //           position: toast.POSITION.TOP_CENTER,
                //         });
                //     }
                //     else if(error.response.data.errors.password){
                //       toast.error(error.response.data.errors.password[0], {
                //           className: css({
                //             background: "#00FF00 !important",
                //             color: "white !important",
                //             fontWeight: "bold"
                //           }),
                //           position: toast.POSITION.TOP_CENTER,
                //         });
                //     }
                //   }

                // }
              }
          )

      }

}

export function getUserLanguages () {
  
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
       return axiosInstance.get('language/getalluserlanguages').then ( async (response) => {
         console.log(response.data)
            await dispatch({ type: "GET_USER_LANGUAGES", payload: response.data })
        })
        .catch(
            error => {
              // console.log(error.response)
              // console.log(error.response)

            }
        )

    }

}

export function addLanguage (userDetails) {
  
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
       return axiosInstance.post('language/attachlanguagetouser' ,userDetails).then ( async (response) => {
        if(response.data.success === true){
         console.log(response)
            await dispatch({ type: "ADD_LANGUAGE", payload: userDetails })
        }
        })
        .catch(
            error => {
              console.log(error.response)
              console.log(error)
            }
        )

    }

}

export function updateLanguage (userDetails) {
  
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
      console.log(userDetails)
       return axiosInstance.post('language/attachlanguagetouser' ,userDetails).then ( async (response) => {
        if(response.data.success === true){
         console.log(response)
            await dispatch({ type: "EDIT_USER_LANGUAGE", payload: userDetails })
        }
        })
        .catch(
            error => {
              console.log(error.response)
              console.log(error)
            }
        )

    }

}

export function deleteLanguage (langId) {
  
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
      let data ={languageId:langId}
      console.log(data)
       return axiosInstance.post(`language/detachlanguagetouser`,data).then ( async (response) => {
         console.log(response)
            await dispatch({ type: "DELETE_LANGUAGE", payload: response.data,languageId: data.languageId})
        })
        // .catch(
        //     error => {
        //       // console.log(error.response)
        //       // console.log(error.response)
        //       // if(error.response !== undefined){

        //       //   if(error.response.status === 422){
        //       //     if(error.response.data.errors.email){
        //       //       toast.error(error.response.data.errors.email[0], {
        //       //           className: css({
        //       //             background: "#00FF00 !important",
        //       //             color: "white !important",
        //       //             fontWeight: "bold"
        //       //           }),
        //       //           position: toast.POSITION.TOP_CENTER,
        //       //         });
        //       //     }
        //       //     else if(error.response.data.errors.password){
        //       //       toast.error(error.response.data.errors.password[0], {
        //       //           className: css({
        //       //             background: "#00FF00 !important",
        //       //             color: "white !important",
        //       //             fontWeight: "bold"
        //       //           }),
        //       //           position: toast.POSITION.TOP_CENTER,
        //       //         });
        //       //     }
        //       //   }

        //       // }
        //     }
        // )

    }

}