import React, { useState } from 'react';
import './ProjectTrackerJobsModal.component.styles.css';
import close from '../../../../assets/image/close.svg';
import ModalInputComponent from '../../../ModalInput/ModalInput.component';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addOrder } from '../../../../store/action/hunter/addOrder';
import Loader from 'react-loader-spinner';

const ProjectTrackerJobsModal = ({
  handleClose,
  show,
  getActiveOrders,
  handleShow,
}) => {
  console.log(getActiveOrders);
  let activeJobTitle = getActiveOrders.map((jobTitle) => jobTitle.title);

  // let inititalState = {
  //   id: ""
  // }

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const handleChange = (e, id) => {
    return setData({ ...data, [e.target.name]: e.target.value });
  };
  // console.log(data.id);

  // console.log(activeJobTitle)

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await dispatch(addOrder(data.id));
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      {/* The modal */}
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>Create order</h3>
        </div>
        <Modal.Body>
          <form className='form-update' onSubmit={handleSubmit}>
            <div className='form_job'>
              <label>Select the Order to track</label>
              <br />
              <select name='id' onChange={handleChange}>
                <option>Select Project to Track</option>
                {getActiveOrders.map((job) => (
                  <option key={job} value={job.id}>
                    {job.title}
                  </option>
                ))}
              </select>
            </div>
            <div className='job_button text-center'>
              <button type='submit'>
                {loading ? (
                  <Loader type='Rings' color='white' height={50} width={50} />
                ) : (
                  'Add order'
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProjectTrackerJobsModal;
