import React from 'react';
import './NavBar.styles.css';
import logo from '../../../assets/image/logo-white.svg';
const NavBar = () =>{

    return(
        <div>
        {/*The header*/}
        <header>
         <img className="img" src={logo} alt="Logo" />
        </header>
        </div>
    )
}


export default NavBar;
