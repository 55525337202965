// import { css } from "glamor";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const initialState = {
  getScoutOpenJobs: null,
  getScoutCloseJobs: [],
  gethHunterOpenJobs: [],
  getHunterCloseJobs: [],
  closedJobInvite:null, 
  getOpenJobWithBid: null,
  closedJobErrorInvite: null
};

export const scoutJobReducer = (state = initialState, action) => {
  if (action.type === 'GET_SCOUT_OPEN_JOBS') {
    console.log(action.payload);
    return Object.assign({}, state, {
      getScoutOpenJobs: action.payload,
    });
  }  else if (action.type === 'GET_SCOUT_CLOSE_JOBS') {
    console.log(action.payload);
    return Object.assign({}, state, {
      getScoutCloseJobs: action.payload,
    });
  }
  else if (action.type === 'ADD_OPEN_JOB') {
    console.log(action.payload);
    toast.success('Added Successfully.', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    return Object.assign({}, state, {
      getScoutOpenJobs: [...state.getScoutOpenJobs, action.payload],
    });
  } else if (action.type === 'EDIT_POST_JOB') {
    
    toast.info('Job Edited Successfully.', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    // console.log(action.payload)
    return Object.assign({}, state, {
      getScoutOpenJobs: state.getScoutOpenJobs.map((each) => {
        if (each.id === action.payload.id) {
          return action.payload;
        }
        return each;
      }),
    });
  } else if (action.type === 'DELETE_POST_JOB') {
    console.log(action.payload);
    console.log(state.getScoutOpenJobs);
    const newArray = state.getScoutOpenJobs.filter(
      (each, i) => each.id !== action.payload
    );
    console.log(newArray);
    toast.info('Deleted Successfully..', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    return Object.assign({}, state, {
      getScoutOpenJobs: newArray,
    });
  }

  if (action.type === 'GET_HUNTER_OPEN_JOBS') {
    console.log(action.payload);
    return Object.assign({}, state, {
      gethHunterOpenJobs: action.payload,
    });
  }

  if (action.type === 'GET_HUNTER_CLOSE_JOBS') {
    console.log('close invite action reducer', action.payload);
    return Object.assign({}, state, {
      getHunterCloseJobs: action.payload,
    });
  }

  if (action.type === 'GET_OPENJOB_WITH_BID') {
    console.log(action.payload);
    return Object.assign({}, state, {
      getOpenJobWithBid: action.payload,
    });
  }
    // 'CLOSE_JOB_INVITE'

    if (action.type === 'CLOSE_JOB_INVITE') {
    console.log(action.payload);
    toast.success('Invite successful', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    return Object.assign({}, state, {
      closedJobInvite:  action.payload,
    });
  } 
  // CLOSE_JOB_INVITE_ERROR

  if (action.type === 'CLOSE_JOB_INVITE_ERROR') {
    
    toast.error('email not available', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    return Object.assign({}, state, {
      closedJobErrorInvite: action.payload,
    });
  }
  return state;
};
