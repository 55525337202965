import React, {useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import '../MessageHunterList/MessageHunterList.component';
import './MessageHunterDetail.styles.css';
import { useParams } from 'react-router';
// import fileAttachment from '../../../../assets/image/fileattachment.png';
import { postHunterMessages, GetConversation } from '../../../../store/action/hunter/hunterMessages';
import moment from 'moment';
import {IoSendSharp} from 'react-icons/io5';

const MessageHunterDetail= () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [ messageText, setMessageText] = useState('');
  
    console.log('params id changing', id)
    const handleSubmit = async() => {
    const data ={
      "recipient_id": id,
      "message": messageText
    }
           
    if(messageText == ''){
      return
    }

    else{
      setLoading(true);
      await dispatch(postHunterMessages(data));
       setLoading(false);
       setReload(true);
       setMessageText('');
       setReload(false);
    }
  }
    useEffect(() => {
        dispatch(GetConversation(id))
    }, [reload, id]);
    

    const conversations = useSelector(state => state.hunterReducer.getConversation);

    return (
        <div className='messagehunter'>
      
         <div className='messagehunter__container'>
           { 
            conversations !== undefined && conversations.map(converse => {  
              if (id ==  converse.sender_id) {
                  return <div className='messagehunter__sender'>
                <p>{converse.message}</p>
                </div>  
              } 
                return <div className='messagehunter__receiver'>
               <p>{converse.message}</p>
               </div>
             
              
            
             })
          } 
          </div>

          <section className='messagehunter__text'>
                <textarea placeholder='Start a  new message' rows='3' 
                onChange={e => setMessageText(e.target.value)}/>
                <div className='messagehunter__sendicon'>
                  <button onClick={handleSubmit}>
                  {
                loading ?
                <Loader
                type="Rings"
                color="white"
                height={30}
                width={30}
                 /> :<IoSendSharp />
                   }
                   </button>
                </div> 
        </section>

      </div>
    )
}

export default MessageHunterDetail;