import React from 'react';
import './JobRequestModal.styles.css';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postApplyJob } from '../../../store/action/talent/applyJobAction';
import Loader from 'react-loader-spinner';

const JobRequestModal = ({ handleClose, show, applyJob }) => {
  const [data, setData] = useState({});
  const [checkPrice, setCheckPrice] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'accept_price_check' && value === 'false') {
      setCheckPrice(true);
      setData({
        ...data,
        [name]: value,
        accept_price: applyJob.price_budget,
        job_id: applyJob.id,
      });
    } else if (name === 'accept_price_check' && value === 'true') {
      setCheckPrice(false);
      setData({
        ...data,
        [name]: value,
        accept_price: applyJob.price_budget,
        job_id: applyJob.id,
      });
    } else setData({ ...data, [name]: value, job_id: applyJob.id });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    if (data.accept_price_check == 'true') {
      const newdata = {
        pitch: data.pitch,
        accept_price: data.accept_price,
        job_id: applyJob.id,
      };

      setLoading(true);
      await dispatch(postApplyJob(newdata));
      setLoading(false);
      handleClose();
    } else if (data.accept_price_check == 'false') {
      const newdata2 = {
        pitch: data.pitch,
        accept_price: data.accept_price,
        price_to_accept: data.price_to_accept,
        job_id: applyJob.id,
      };
      setLoading(true);
      await dispatch(postApplyJob(newdata2));
      setLoading(false);
      handleClose();
    }
  };

  return (
    <section>
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>To apply for this job, kindly fill the form below</h3>
        </div>
        <Modal.Body>
          <form className='form-update' onSubmit={handleSubmit}>
            <div className='form-group-update'>
            <label>Can you complete the job within the 
                set milestone? Pitch</label>
              <br />
              <textarea
              style={{width:"98%", borderRadius:"5px", outline:"none", height:"80px"}}
                onChange={handleChange}
                name='pitch'
                type='text'
              />
            </div>
            <div className='form_job'>
              {/* <ModalInputComponent
                onChange={handleChange}
                label='Do you accept the price budget?'
                name='price_to_accept '
                type='text'
              /> */}
              <label>Do you accept the price budget?</label>
              <br />
              <select name='accept_price_check' onChange={handleChange}>
                <option value='yes'>Select Option </option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
            {!checkPrice ? null : (
              <div className='form-group-update'>
                <ModalInputComponent
                  onChange={handleChange}
                  label='If no, what price will you accept?'
                  name='price_to_accept'
                  type='text'
                />
              </div>
            )}
            <div className='job_button text-center'>
              <button type='submit'>
                {loading ? (
                  <Loader type='Rings' color='white' height={50} width={50} />
                ) : (
                  'Apply'
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default JobRequestModal;
