import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
   getnotification: [],
   detailnotification: null,
   marknotification: null,
   notificationcount: null,
   loading: false
}
// SUBMITED_MILESTONE
export const notificationReducer =(state=initialState, action)=>{
    
    if(action.type === "USER_NOTIFICATION"){
       
        return Object.assign({}, state, {
            getnotification: {...action.payload}
        });
    }

    if(action.type === "DETAIL_NOTIFICATION"){
       
        return Object.assign({}, state, {
            detailnotification: {...action.payload},
           
        });
    }

    if(action.type === "MARK_NOTIFICATION"){
       
        return Object.assign({}, state, {
            mark: {...action.payload},
           
        });
    }

    if(action.type === "NOTIFICATION_COUNT"){
       console.log('count payload', action.payload)
        return Object.assign({}, state, {
            notificationcount: {...action.payload},
           
        });
    }
return state;
}
