import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import axios from 'axios';

export function updateProfile (userDetails,props) {
  return function(dispatch){
    const axiosInstance = axios.create({
      baseURL : PATH,
      timeout: 200000,
      headers: {
                'Content-Type': 'application/json', 
                 'Accept': 'application/json',
              //    'X-CSRF-TOKEN: " "'
                 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
              }
  })
        // console.log(userDetails)
         return axiosInstance.post('updateorcreateprofile' ,userDetails).then ( async (response) => {
           console.log('update response just now', response.data)
            if(response.data.success === true){
              await dispatch({ type: "UPDATE_CREATE_PROFILE", payload: response.data });
              if(response.data.useraccounttype.accounttypeId == "1"){
                props.push('/hunterDashboard');
              }
              if(response.data.useraccounttype.accounttypeId == "2"){
              props.push('/publicProfile');
              }
              // await dispatch({ type: "MESSAGE", payload: { status : true, message: 'Registration Successful', type: 'success'} })
          }
        //   else{  
        //     await dispatch({ type: "REGISTER_ERROR", payload: response.data });        }
          })
          .catch(
              error => {
                console.log(error)
                // console.log(error)
                if(error.response !== undefined){

                  if(error.response.status === 422){
                    if(error.response.data.errors.email){
                      toast.error(error.response.data.errors.email[0], {
                          className: css({
                            background: "#00FF00 !important",
                            color: "white !important",
                            fontWeight: "bold"
                          }),
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                    else if(error.response.data.errors.password){
                      toast.error(error.response.data.errors.password[0], {
                          className: css({
                            background: "#00FF00 !important",
                            color: "white !important",
                            fontWeight: "bold"
                          }),
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                  }

                }
              }
          )

      }

}
