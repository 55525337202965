import React, { useState } from 'react';
import './PendingOrderTable.styles.css';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import JobRequestModal from '../JobRequestModal/JobRequestModal.component';
import { MilestoneTalentView } from '../JobRequestTable/MilestoneTalentView.component';

import { useSelector } from 'react-redux';
import AcceptOrderModal from '../AcceptOrderModal/AcceptOrderModal.component';
import JobRequestMilestonemodal from '../Milestones/Milestones.modal';
import Milestonemodal from '../../../pages/Hunter/CreateJob/Milestonemodal.component';

const PendingOrderTable = ({ getPendingOrder }) => {
  console.log('getting pending order in comp', getPendingOrder)
  const [show, setShow] = useState(false);
  const [showmilestone, setShowmilestone] = useState(false);
  const [submission, setSubmission] = useState(false);

  const [milestones, setMilestones] = useState([])
  
  const [selectOrder, setSelectOrder] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (order) => {
    console.log(order);
    setSelectOrder(order);
    setShow(true);
  };
  

  const handleCloseMilestone = () => {
    setShowmilestone(false);
  }
  const   handleShowMilestone = ({milestones}) => {
    console.log('each point', milestones)
    setMilestones(milestones);
    setShowmilestone(true);
  };
//  milestone submission 
  const handleCloseMilestoneSubmission = () => {
    setSubmission(false);
  }
  const handleShowMilestoneSubmission = ({milestones}) => {
    console.log('each point', milestones)
    setMilestones(milestones);
    setSubmission(true);
  };
  

  const category = useSelector((state) => state.categoriesReducer.categories);

  return (
    <div>
      <>
         
         <h6 className="openedjobs">Pending jobs
         <div className='toolholder'>
        
             <div className='overtooltip'> 
             <div class="triangle-down"></div>
             <span className='tooltipopen'>Jobs not paid for</span>
         </div> 
         </div></h6>
     </>
      <Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
            <th  className='active_head_bg'>#</th>
            <th  className='active_head_bg'>Hunter Name</th>
            <th  className='active_head_bg'>Job brief </th>
            <th  className='active_head_bg'>Milestones</th>
            <th  className='active_head_bg'>Net pay</th>
            <th  className='active_head_bg'>start date</th>
            <th  className='active_head_bg'>due date</th>
            <th  className='active_head_bg'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Modal */}
          <AcceptOrderModal
            handleClose={handleClose}
            selectOrder={selectOrder}
            show={show}
          />
          {/* submitting milestones */}

         <JobRequestMilestonemodal submission={submission} 
         handleCloseMilestoneSubmission={handleCloseMilestoneSubmission}
         milestones={milestones}/> 

          {/* End of Modal */}
          {/* <tr>
            <td>1</td>
            <td>Jan 10, 2022</td>
            <td>I need someone who can deliver the best
            of article writing</td>
            <td>Copywriting</td>
            <td>5,000</td>
            <td>4 milestones</td>   
      
        <td className="action_icons">
       <button
        onClick={handleShow} type='button'>Apply for job</button>
        </td>
          </tr>   */}
          {getPendingOrder?.map((each, index) => {
            return (
              <>
              <tr key={index}>
                <td>{1 + index}</td>
                <td>{each.user?.name}</td>
                <td dangerouslySetInnerHTML={{__html: each.job_description}}>
                {/* {each.job_description} */}
                  {/* <div dangerouslySetInnerHTML={ {__html: selectOrder.terms} }>{each.job_description}</div> */}
                  </td>
                <td className='action_icons'>
                  <button type='button' onClick={() => handleShowMilestone(each)}>
                    View
                  </button>
                  </td>
                  <td>{each?.net_pay}</td>
                <td>{each.start_date}</td>
                <td>{each.end_date}</td>
                {/* <td>{category.map(categ=>{
                    if (categ.id == each.category) {
                      return categ.skill.map(skil=>{
                        if (skil.id == each.skill) {
                          // skillTable = skil.name
                          return skil.name
                        }
                      })
                    }
                  } )}</td>
              <td>{each.price_budget}</td>
              <td>{each.milestone}</td>    */}

                {/* <td className='action_icons'>
                  <button
                    type='button'
                   onClick={handleShow}
                  >
                    View Order
                  </button>
                </td> */}
                <td className='action_icons'>
                  <button type='button' onClick={() => handleShow(each)}>
                    Accept order
                  </button>
                </td>
              </tr>
              <Milestonemodal  handleCloseMilestone={handleCloseMilestone}
             handleShowMilestone={handleShowMilestone} showmilestone={showmilestone}
             milestones={milestones}
              />
              {/* <MilestoneTalentView handleMilestoneTalentClose={handleMilestoneTalentClose}
              handleMilestoneTalentShow={handleMilestoneTalentShow} milestoneshow={milestoneshow} /> */}
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PendingOrderTable;
