import React from 'react';
import './OverviewWrapper.styles.css';
import coloredind from '../../../../../assets/image/coloredindigig.svg';
import indicagig from '../../../../../assets/image/indigig.svg';
import Overview from '../Overview/Overview.component';
import announce from '../../../../../assets/image/announce.png';

const OverviewWrapper = ({showSecondStep,selectedGig,param}) => {
    return (

        <section>
        <div className="indicator-container">       
      <div className="overview">
      <p>Overview</p>
      <img src={coloredind}
       alt="coloredIndicator"/>
      </div>
      {/* <div className="overview add-space">
      <p>Description</p>
      <img src={coloredind}
       alt="Indicator"/>
      </div> */}
      {/* <div className="overview add-space">
      <p>FAQ</p>
      <img src={indicagig}
       alt="Indicator"/>
      </div> */}
      {/* <div className="overview add-space">
      <p>Requirements</p>
      <img src={indicagig}
       alt="Indicator"/>
      </div> */}
      {/* <div className="overview add-space">
      <p>Pricing</p>
      <img src={indicagig}
       alt="Indicator"/>
      </div> */}
      <div className="overview add-space">
      <p>Gallery</p>
      <img src={indicagig}
       alt="Indicator"/>
      </div>
      </div> 

        
     {/*Form heading*/}
    <div className="overview-heading">
    <h3>Overview</h3>
     <h6>Give an overview of your skill or talent.
     Let us know some details</h6>
    </div>         
       <hr/>
   
        <div className="container-fluid overview-cont">
        {/* <div className="row overview-wrapper"> */}
        <div className="overview-wrapper">
        {/* <div className="col-md-7"> */}
        <div className="overview_part1">

        
        {/*Form*/} 
        <Overview showSecondStep={showSecondStep} selectedGig={selectedGig} param={param}/>
        </div>
        {/* <div className="col-md-5"> */}
        <div className="overview_part2">

        <div className="right-overview">
        <img src={announce} alt="announceIcon"/>
        <p>A well written overview goes a long way to 
        help your talent or skill stand out.</p>
           </div>
        </div>
        </div>
        </div>
        </section>
    )
}



export default OverviewWrapper;