import React, {useState} from 'react';
import edit from '../../../assets/image/edit-icon.svg';
import './UpdateStory.styles.css'
import elipse from '../../../assets/image/elipse.svg';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import location from '../../../assets/image/location.svg';
import maleProfile from '../../../assets/image/male-profile.svg';
import { useDispatch } from 'react-redux';
import { updateStory } from '../../../store/action/publicProfileAction/StoryProfileImage';
import Loader from 'react-loader-spinner';
import {Link} from "react-router-dom";



const UpdateStory = ({firstname,lastname,country,joinedSince,storyLine,profile_image,userCountry}) => {
  const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let initialState = {
      storyline:storyLine
  }

  const [loading, setLoading] = useState(false)
  const [state, setstate] = useState(initialState)
  const [gigPix, setGigPix] = React.useState(null);
  const [image, setImage] = React.useState(null);

    const handleSubmit= async (e) =>{
          e.preventDefault();
          setLoading(true)
          // console.log(profile_image)
          await dispatch(updateStory(state,image,profile_image))
          setLoading(false)
          setShow(false)
  
      }

  
      const handleChange = (e) => {
          const {name, value} = e.target;
          setstate({...state,[name]: value})
          // console.log(state)
      }
      const handleChangeImage = (e) => {
        let img = e.target.files[0]
        // console.log(img)
        setImage(img)
        setGigPix(URL.createObjectURL(img))
      }
  
    return (
        <div className="description">
        <div className="status-online ms-auto">online</div>
        <div className="text-center elipse-img"
        onClick={handleShow}>
         {
            profile_image===null ?
            <img className="img-fluid" src={elipse} alt="elipse"/> :
            <img className="img-fluid" style={{borderRadius:"50%",height:"10rem",width:"10rem"}} src={
              profile_image === null ?gigPix:
              `https://api.solvateafrica.com/public/images/profiles/${profile_image}`
            } alt="elipse"/>
          }
        </div>
        <h6>{firstname} {lastname}</h6>
        <div className="text-center edit"
        onClick={handleShow}>
          {storyLine}
          <img src={edit} alt="editIcon"/>
          </div>
        <div className="text-center preview-story">
        <Link to="/viewpastjobs" style={{textDecoration:"none"}}>
        <button type="button">          
        View past jobs        
        </button>
        </Link>
        </div>
        <Modal 
        size="md"
        show={show} 
        onHide={handleClose}
        dialogClassName="modal-500w"
        >
        <div className="title-cus">
        <img src={close} alt="closeimage"
        onClick={handleClose} />
        <h3>Kindly fill in your short story and Profile picture</h3>
        </div>
        <Modal.Body>
        <form className="form-update" onSubmit={handleSubmit}>
        <div className="form-group-update">
        <ModalInputComponent
         label="Your short story in one line:"
         name="storyline"
         onChange={handleChange}
         type="text"
         defaultValue={storyLine}
        />
        </div>
        <div className="form-group-update">
        <ModalInputComponent
         label="Upload profile pic:"
         name="profile_image"
         type="file"
        //  defaultValue={profile_image}
         onChange={handleChangeImage}
        />
        </div>
        <button type="submit">
        {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={50}
           width={50}
         /> :"Update story"

            }
          </button>
        </form>
        </Modal.Body>
        </Modal>
        <hr/>
        <div className="location">
        <li>
        <img src={location} alt="location"/>
        <p>From</p>
        </li>
        {
          userCountry === undefined ? "Na" :
          userCountry
        }
        </div>
         <div className="location pro">
        <li>
        <img src={maleProfile} alt="Profile"/>
        <p>Joined since:</p>
        </li>
         {new Date(joinedSince).toDateString()}
        </div>
        </div>
    )
}

export default UpdateStory;