import React, { useEffect, useState } from 'react';
// import ProjectTrackerTable from '../../../../components/Hunter/ProjectTracker/ProjectTrackerTable/ProjectTrackerTable.component';
import './CompletedProject.styles.css';
import { useHistory } from 'react-router-dom';
// import ProjectTrackerTable from '../../../components/Hunter/ProjectTracker/ProjectTrackerTable/ProjectTrackerTable.component';
// import ActiveProjectTable from '../../../components/Hunter/JobsTable/ActiveProjectTable';
import CompletedProjectTable from '../../../components/Hunter/JobsTable/CompletedProjectTable';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllSubmittedOrder,
  getUserCompletedOrder,
} from '../../../store/action/talent/applyJobAction';
import Hunterwithdrawl from './Hunterswithdrawl';
import { getHunterNetPayCheck } from '../../../store/action/hunter/initiateAgreementAction';


const CompletedProject = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isFetching, setIsFetching] = useState(false);
  const [widthdrawl, setWidthdrawl] = useState(false);
  const [widthdrawldata, setWidthdrawldata] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const getCompletedJob= useSelector(
    (state) => state.talentApplyForJobReducer.getCompletedJob
  );
  const getHunterPayment= useSelector(
    (state) => state.talentApplyForJobReducer.getHunterPayment
  );
  const getHunterCheck = useSelector((state) => state.agreementReducer.hunterWithdrawlCheck)
  console.log('get hunter check', getHunterCheck)
  useEffect(() => {
    getTalentCompletedOrder();
    
    return () => {};
  }, []);
  const getTalentCompletedOrder = async () => {
    setIsFetching(true);
    await dispatch(getUserCompletedOrder());
    await dispatch(getHunterNetPayCheck());
    setIsFetching(false);
    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };
  // withdrawl
  const handleShowWithdrawl =(getHunterPayment) =>{
    
    setWidthdrawldata(getHunterPayment)
      setWidthdrawl(true);
  }
const handleCloseWithdrawl =() =>{
  setWidthdrawl(false);
    
  }
  return (
    <div className='Projects_wrapper container-fluid'>
      <h3>List of completed projects</h3>

      <div className='projects_button d-flex justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='projects_button--back'
          type='button'
        >
          Go back
        </button>
        {/* fund  */}
       { getHunterCheck?.pendingpayout !== 0  && <button
          onClick={() => handleShowWithdrawl(getHunterPayment)}
          className='projects_button--back'
          type='button'
        >
          Withdrawal
        </button>
}
        <Hunterwithdrawl handleCloseWithdrawl={handleCloseWithdrawl} widthdrawl={widthdrawl}
        widthdrawldata={widthdrawldata} getHunterPayment={getHunterPayment}/>
        {/* <button
          onClick={handleShow}
          type='button'
          className='projects_button--add'
        >
          Add Job
        </button> */}
      </div>

      {/* Table */}
      {/* Pass all the important modal method as props to the child(table)*/}
      {isFetching ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        // <SubmittedOrderHunterTable getSubmittedOrder={getSubmittedOrder} />
        <CompletedProjectTable getCompletedJob={getCompletedJob} getHunterPayment={getHunterPayment} />
      )}
    </div>
  );
};

export default CompletedProject;
