import React from "react";
import { Modal } from 'react-bootstrap';
import close from '../../../assets/image/close.svg';
import Table from 'react-bootstrap/Table';
import moment from 'moment';




const Invitees= ({handleShowInvitees,
    handleCloseInvitees, invitees,
    openInvitee}) => {
    return(
        <Modal
        size='md'
        show={openInvitee}
        onHide={handleCloseInvitees}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleCloseInvitees} />
          <h3>List of invited talent(s) for the job.</h3>
        </div>
        <Modal.Body>
        <Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Date</th>
            <th className='active_head_bg'>Email</th>
          </tr>
        </thead>
        <tbody>
          
            { Invitees && invitees.map((data, index) => {
          return <>
               <tr>
                <td>{index+1}</td>
                <td>{moment(data.created_at).format('ll')}</td>
                <td>{data?.talent.email}</td>
                </tr>
                </>
                  })
      }
        </tbody>
        </Table>
        </Modal.Body>
      </Modal>
    )
}

export default Invitees;