import React from 'react';
import NavTalent from '../../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import MessageHunterList from '../MessageHunterList/MessageHunterList.component';
import './MessageHunterDashboard.styles.css';


const MessageHunterDashboard = () => {

    return (
        <div className='messagehunter_title'>
            <MessageHunterList />
        </div>
    )
}

export default MessageHunterDashboard;