import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import axios from 'axios';

export function addEducation (userDetails) {
    
      return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
         return axiosInstance.post('education/store' ,userDetails).then ( async (response) => {
           console.log( 'just now response', response)
              await dispatch({ type: "ADD_EDUCATION", payload: response.data })
          })
          .catch(
              error => {
                // console.log(error.response)
                // console.log(error)
                // if(error.response !== undefined){
                //     console.log(error)

                //   if(error.response.status == 422){
                //     if(error.response.data.errors.email){
                //       toast.error(error.response.data.errors.email[0], {
                //           className: css({
                //             background: "#00FF00 !important",
                //             color: "white !important",
                //             fontWeight: "bold"
                //           }),
                //           position: toast.POSITION.TOP_CENTER,
                //         });
                //     }
                //     else if(error.response.data.errors.password){
                //       toast.error(error.response.data.errors.password[0], {
                //           className: css({
                //             background: "#00FF00 !important",
                //             color: "white !important",
                //             fontWeight: "bold"
                //           }),
                //           position: toast.POSITION.TOP_CENTER,
                //         });
                //     }
                //   }

                // }
              }
          )

      }

}


export function UserEducation () {
    
      return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
         return axiosInstance.get('education').then ( async (response) => {
           console.log(response)
              await dispatch({ type: "GET_EDUCATION", payload: response.data })
          })
          .catch(
              error => {
                // console.log(error.response)
                // console.log(error.response)
                if(error.response !== undefined){

                  if(error.response.status === 422){
                    if(error.response.data.errors.email){
                      toast.error(error.response.data.errors.email[0], {
                          className: css({
                            background: "#00FF00 !important",
                            color: "white !important",
                            fontWeight: "bold"
                          }),
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                    else if(error.response.data.errors.password){
                      toast.error(error.response.data.errors.password[0], {
                          className: css({
                            background: "#00FF00 !important",
                            color: "white !important",
                            fontWeight: "bold"
                          }),
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                  }

                }
              }
          )

      }

}

export function deleteEducation (eduId) {
  
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
       return axiosInstance.delete(`education/${eduId}`).then ( async (response) => {
         console.log(response)
            await dispatch({ type: "DELETE_EDUCATION", payload: response.data,educationId: eduId })
        })
        .catch(
            error => {
              // console.log(error.response)
              // console.log(error.response)
              if(error.response !== undefined){

                if(error.response.status === 422){
                  if(error.response.data.errors.email){
                    toast.error(error.response.data.errors.email[0], {
                        className: css({
                          background: "#00FF00 !important",
                          color: "white !important",
                          fontWeight: "bold"
                        }),
                        position: toast.POSITION.TOP_CENTER,
                      });
                  }
                  else if(error.response.data.errors.password){
                    toast.error(error.response.data.errors.password[0], {
                        className: css({
                          background: "#00FF00 !important",
                          color: "white !important",
                          fontWeight: "bold"
                        }),
                        position: toast.POSITION.TOP_CENTER,
                      });
                  }
                }

              }
            }
        )

    }

}

export function editUserEducation (userDetail,eduId) {
  
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
       return axiosInstance.put(`education/${eduId}`,userDetail).then ( async (response) => {
         console.log(response)
            await dispatch({ type: "EDIT_EDUCATION", payload: response.data,educationId: eduId })
        })
        .catch(
            error => {
              // console.log(error.response)
              // console.log(error.response)
              if(error.response !== undefined){

                if(error.response.status === 422){
                  if(error.response.data.errors.email){
                    toast.error(error.response.data.errors.email[0], {
                        className: css({
                          background: "#00FF00 !important",
                          color: "white !important",
                          fontWeight: "bold"
                        }),
                        position: toast.POSITION.TOP_CENTER,
                      });
                  }
                  else if(error.response.data.errors.password){
                    toast.error(error.response.data.errors.password[0], {
                        className: css({
                          background: "#00FF00 !important",
                          color: "white !important",
                          fontWeight: "bold"
                        }),
                        position: toast.POSITION.TOP_CENTER,
                      });
                  }
                }

              }
            }
        )

    }

}
