import React from 'react';
import './TalentBid.styles.css';
import TalentBidTable from './TalentBidTableComponent';

const TalentBid = () => {

    return (
        <section className='dashboard_home'>
            <div className='d-flex justify-content-between dash_heading'>
                {/* <h3>Proof reading Job</h3> */}
            </div>
            <div className='bid_description'>
                <h4>Go through the list of talent's bids for the job, compare and accept the best</h4>
            </div>
            <div className='bid_list_text'>
            {/* <h4>List of Talent's bid for "proof reading" job</h4> */}
            </div>
            <div className='table_wrapper'>

            {/* Table */}
            <TalentBidTable/>
            </div>
        </section>
       
    )
}


export default TalentBid;
