import React from 'react';
import NavTalent from '../../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import MessageList from '../../../../components/Talents/MessageList/MessageTalentList.component';

import './MessageDashboard.styles.css';
import MessageTalentList from '../../../../components/Talents/MessageList/MessageTalentList.component';

const MessageDashboard = () => {
  return (
    <>
      
      <div className='message__talent'>
        <NavTalent />
       
        <MessageTalentList />
      </div>
    </>
  );
};

export default MessageDashboard;
