import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import './ProjectTrackerTable.styles.css';
import './SubmittedOrderTable.styles.css';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdAdd, MdReviews, MdVisibility } from 'react-icons/md';
// import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
// import RatingReviewModal from './RatingReviewModal';
import moment from 'moment';
import { Rating } from '@mui/material';
import TalentPaymentRequest from '../TalentPaymentRequest/TalentPaymentRequest';
// import ProjectTrackerJobsModal from '../ProjectTrackerJobsModal/ProjectTrackerJobsModal.component';

const CompletedProjectTalentTable = ({ getCompletedJob, getBanks }) => {
  const dispatch = useDispatch();
  const [saveProj, setSaveProj] = useState(false);
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState(null)

  const handleClose = () => setShow(false);
  // const [talentBids, setTalentBid] = useState(null);
  const handleShow = (each) => {
    // setSaveProj(item);
    setInfo(each)
    setShow(true);
  };

  return (
    <section>
      <>
         
         <h6 className="openedjobs">Completed jobs
         <div className='toolholder'>
        
             <div className='overtooltip'> 
             <div class="triangle-down"></div>
             <span className='tooltipopen'>Projects accepted by hunter</span>
         </div> 
         </div></h6>
     </>
      <Table className='table_tracker'>
        <thead>
          <tr>
            <th>#</th>
            <th>Hunter Name</th>
            <th>Job Title</th>
            {/* <th>Milestone</th> */}
            <th>Due On</th>
            <th>Delivered At</th>
            <th>Net pay</th>
            <th>Rating</th>
            <th>Status</th>
            <th>Payment</th>
          </tr>
        </thead>
        <tbody>
          {getCompletedJob.map((each, id) => {
            return (
              <tr key={id}>
                <td>{1 + id}</td>
                <td>{each.user.name}</td>
                <td>
                  <Link to={`/order/completedProject/${each.id}`}>
                    {each.title}
                  </Link>
                </td>
                {/* <td>{each?.milestone}</td> */}
                <td>{each?.end_date}</td>
                <td>{moment(each.updated_at).format('ll')}</td>
                <td>{each?.net_pay}</td>
                <td>
                  {each.review === null ? (
                    <>Awaiting Review</>
                  ) : (
                    <span className='review-wrapper'>
                      <Rating
                        name='simple-controlled'
                        size='small'
                        readOnly
                        value={each.review.rating}
                      />
                    </span>
                  )}
                </td>
                <td style={{ textTransform: 'capitalize' }}>{each.status}</td>
                <td><>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Receive payment
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <button
                  onClick={() => handleShow(each)} className="order_button">
                     Payment
                </button>
                      </OverlayTrigger>
                    ))}
                  </>
                
                        
                        
                        </td>
                  
              </tr>
            );
          })}
        </tbody>
      </Table>
      <TalentPaymentRequest
        handleClose={handleClose}
        saveProj={saveProj}
        show={show}
        info={info}
        getBanks={getBanks}
      />
      {/* <RatingReviewModal
        handleClose={handleClose}
        saveProj={saveProj}
        show={show}
      /> */}
    </section>
  );
};

export default CompletedProjectTalentTable;
