
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {register, registerSocial} from '../../../store/action/authAction/register'

import NavBar from '../../../components/Navs/NavBar/NavBar.component';
// import leftLogo from '../../../assets/image/left-img.svg';
import leftLogo from '../../../assets/image/auth-image.svg';

import './SignUp.styles.css'
import { useHistory } from 'react-router';

import FormInput from '../../../components/FormInput/FormInput.component';
import CustomButton from '../../../components/CustomButton/CustomButton.component';
import { Link } from 'react-router-dom';

import google from '../../../assets/image/google.svg';
import facebook from '../../../assets/image/facebook.svg';
import logo from '../../../assets/image/logo-white.svg';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import { toast } from "react-toastify";
import { css } from 'glamor';
const SignUp = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    let initialState = {
        name:"",
        email : "",
        password : "",
        password_confirmation : "",
    }

    const [loading, setLoading] = useState(false)
    const [state, setstate] = useState(initialState)

    const responseFacebook = (response) => {
        console.log(response);
    }
    
    const responseGoogle = (response) => {
        // console.log(response);
        if (response.profileObj) {
            const data = {name: response.profileObj.name, email: response.profileObj.email,
                password: response.profileObj.googleId,
                 password_confirmation:response.profileObj.googleId,signup_channel:"Google"}
                 console.log(data)
            dispatch(registerSocial(data,history))
        } else {
            toast.error("Registration Not Successful, Please Try Again", {
                className: css({
                  background: "#00FF00 !important",
                  color: "white !important",
                  fontWeight: "bold"
                }),
                position: toast.POSITION.TOP_CENTER,
              });
        }
    }
      
    // useEffect(() => {
    //  handleSubmit(state)
    // }, [state])
    
    // const handleSubmit= async (e) =>{
    //     e.preventDefault();
    //     console.log(state);
    //     setLoading(true)
    //     await dispatch(register(state,history))
    //     setLoading(false)

    // }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setstate({...state,[name]: value})
        // console.log(state)
    }

    return (
        <div className="container-fluid signup-wrapper">
        <div className="row signup-wrapper-child-1">  
        <div className="left-signup col-md-5">
        <NavBar/>
        <div>
       {/*The heading*/}
        <h1>WHERE GREAT MINDS CONNECT</h1>
        <img className="img-fluid" src={leftLogo} alt="workImage"/>
        </div>
        </div>
        <div className="right-signup col-md-7">
        <h1>Create account</h1>
        <h6>Kindly enter your details to get started</h6>
        <div className="social-buttons">
       
        {/* <GoogleLogin
            clientId="418061939047-ci1buflq7iqpq77obv2j7dh2j7172lcl.apps.googleusercontent.com"
            buttonText="Login with google"
            onSuccess={responseGoogle}
            // onFailure={responseGoogle}
            render={renderProps => (
                <button type="button" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <img src={google} alt="googleImage"/>
                    Signup with google
                </button>
                )}
            cookiePolicy={'single_host_origin'}
        /> */}

        {/* <FacebookLogin
            appId="542844437010923"
            // autoLoad={true}
            fields="name,email,picture"
            callback={responseFacebook}
            render={renderProps => (
                <button type="button" onClick={renderProps.onClick} >
                <img src={facebook} alt="facebookImage"/>
                    Signup with  facebook
                </button>
              )}
            /> */}
            
        </div>
        {/* <div className="line"><hr/>OR<hr/></div> */}
        <Formik initialValues= {{

            name: '',

            email: '',

            password:'',

            password_confirmation:'',

            }} 
            validationSchema={yup.object({

            name:yup.string().max(25,'Name should not exceed 20 Characters').

            required('Please Enter Your Name'),

            email: yup.string()

            .email('Invalid email address')

            .required('Please Enter Email Address'),
            password: yup.string()
            .required("please enter password")
            .min(8, "Password must be at least 8 character"),
            password_confirmation: yup.string()
            .required("please enter password")
            .min(8, "Password must be at least 8 character")
            .oneOf([yup.ref("password"), null], 'Password must match')
            })} 
            onSubmit= {async values => {

                setLoading(true)
                await dispatch(register(values,history))
                setLoading(false)

            }}>
            {
                props=>(

                <Form >
                <div>
                <FormInput
                className="signup_name"
                label="Username:"
                name="name"
                type="text"
                // value = {state.name}
                // onChange = {handleChange}
                />
                <ErrorMessage  name="name">
                    {msg =><div className="invalid-entry">{msg}</div>}
                </ErrorMessage>
                </div>
                <div>
                <FormInput
                label="Email:"
                name="email"
                type="email"
                // value = {state.email}
                // onChange = {handleChange}
                />
                <ErrorMessage  name="email">
                    {msg =><div className="invalid-entry">{msg}</div>}
                </ErrorMessage>
                </div>
                <div>
                <FormInput
                label="Password:"
                name="password"
                type="password"
                // value = {state.password}
                // onChange = {handleChange}
                />
                <ErrorMessage  name="password">
                    {msg =><div className="invalid-entry">{msg}</div>}
                </ErrorMessage>
                </div>
                <div>
                <FormInput
                label="Confirm password:"
                name="password_confirmation"
                type="password"
                // value = {state.password_confirmation}
                // onChange = {handleChange}
                />
                <ErrorMessage  name="password_confirmation">
                    {msg =><div className="invalid-entry">{msg}</div>}
                </ErrorMessage>
                </div>
                <CustomButton >  
                    {
                        loading ?
                        <Loader
                type="Rings"
                color="white"
                height={50}
                width={50}
                //    timeout={3000} //3 secs
                /> :null

                    }
            Create account</CustomButton>
                </Form>

                )
            }
        </Formik>
        <p className="mobile-center">Already have an account? <span><Link to="/login">Login</Link></span></p>
        </div>       
        </div>
        </div>
    )
}




export default SignUp;