import React from 'react';
import './GigsInput.styles.css';
import { Field } from 'formik';

const GigsInput = ({label, ...otherprops}) => {


    return (
    <div className="gig-input">
    <label htmlFor="">
        {label}
        </label><br></br>
        <input {...otherprops}/>
    </div>
    )


}

export default GigsInput;