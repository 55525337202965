import React from 'react';
import './Rating.style.css';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import { Rating } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { postReview } from '../../../store/action/talent/applyJobAction';
import Loader from 'react-loader-spinner';

const RatingReviewModal = ({ handleClose, show, saveProj }) => {
  const dispatch = useDispatch();
  console.log(saveProj);
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
    console.log(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newData = {
      ...data,
      reviewer_id: saveProj.user_id,
      reviewee_id: saveProj.creative_id,
      job_id: saveProj.id,
      rating: value,
    };
    await dispatch(postReview(newData));
    setIsLoading(true);
    handleClose();
  };

  return (
    <section>
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>Rate this talent work</h3>
        </div>
        <Modal.Body>
          {/* <div className="form-update"> */}
          <div className='rating-body_wrapper'>
            <form onSubmit={handleSubmit}>
              {/* <div className='form-group-update'>
                <ModalInputComponent
                  label="Talent's name"
                  name='Talent_name'
                  type='text'
                />
              </div> */}
              <div className='form_job'>
                <label>Recommend this talent for future jobs?</label>
                <br />
                <select
                  className='rating_select'
                  onChange={handleChange}
                  name='recommend_user'
                >
                  <option value=''>select Option</option>
                  <option value='Not likely'>Not likely</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </div>
              <div className='form_job-rating'>
                <label>Give this talent a star rating</label>
                <br />
                <div className='rating-style'>
                  <Rating
                    name='simple-controlled'
                    size='medium'
                    // style={{ color: 'red' }}
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                </div>
              </div>
              <div className='form_job'>
                <label>Comments:</label>
                <br />
                <textarea
                  name='comment'
                  onChange={handleChange}
                  cols={40}
                  rows={5}
                ></textarea>
              </div>
              <div className='job_button text-center'>
                <button type='submit'>
                  {isLoading ? (
                    <Loader type='Rings' color='white' height={50} />
                  ) : (
                    'Submit rating'
                  )}
                </button>
              </div>
            </form>
          </div>
          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default RatingReviewModal;
