import React from 'react';
import './Footer.styles.css';
import { Link } from 'react-router-dom';
import copyright from '../../../assets/image/copyright (2).svg';
import facebook from '../../../assets/image/facebook (2).svg';
import linkedin from '../../../assets/image/linkedlin.svg';
import twitter from '../../../assets/image/twitter (2).svg';
import Policy from '../../../pages/Home/HomeLanding/Policy.component';

const TalentFooter = () => {

    return (

        <footer className="container-fluid">
        <div className="foot-wrapper">
    {/*First column*/}
    <div className='row'>
    <div className="col-lg-3 col-md-6 col-sm-6" style={{paddingLeft: "3%"}}>
    <h5>For talents</h5>
    <Link to='#' ><p>How to find gigs</p></Link>
    <Link to='#'><p>About us</p></Link>
    <Link to='#'><p>Contact</p></Link>
    <Link to='#'><p>FAQs</p></Link>
    </div>
    {/*Second column*/}
    <div className="col-lg-3 col-md-6 col-sm-6">
    <h5>For clients</h5>
    <Link to='#'><p>How to hire creative talent</p></Link>
    <Link to='#'><p>Talent marketplace</p></Link>
    <Link to='#'><p>Project catalogue</p></Link>
    <Link to='#'><p>FAQs</p></Link>
    
    </div>
    {/*Third column*/}
    <ul className="col-lg-3 col-md-6 col-sm-6">
    <Link to='#'><h5>Legal</h5></Link>
    <Link to='/policy' target="_blank"><p>Private policy</p></Link>
    {/* <Policy /> */}
    <Link to='/agreement' target="_blank"><p>User agreement</p></Link>
    </ul>
     {/*Fourth column*/}
     <div className="col-lg-3 col-md-6 col-sm-6">
     <h5>Contact</h5>
     <p>
     solvateafrica@gmail.com.</p>
     <p>Phone: +234-903-575-6431.</p>
     </div>
     </div>
     </div> 
     <hr className="line"/>
     <div className="footer-bottom-wrapper d-flex justify-content-evenly">
     <div className="left-footer">
     <img src={copyright} alt="copyrightIcon"/>
     <p>2021. Solvate Africa. All rights reserved.</p>
     </div>
    <div className="right-footer">
    <h6>Follow us:</h6>
    <div className="social-icons">
    <img src={facebook} alt="facebookImage"/>
    <img src={linkedin} alt="linkedinImage"/>
    <img src={twitter} alt="TwitterImage"/>
    </div>
    </div>
    </div>
        </footer>
    )
}


export default TalentFooter; 