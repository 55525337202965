import React, { useState, useEffect } from 'react';
// import './JobRequestTable.styles.css';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
// import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
// import JobRequestModal from '../JobRequestModal/JobRequestModal.component';
import Loader from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import TalentPitchModalModal from '../../../components/Hunter/TalentPitchModal/TalentPitchModal.component';
import AcceptBidModal from '../../../components/Hunter/AcceptBidModal/AcceptBidModal.component';
import { useHistory, useParams } from 'react-router';
import './TalentBidTable.css';
import { talentBidForJobAction } from '../../../store/action/hunter/talentBidForJobAction';
import { each } from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getGigDetailAction } from '../../../store/action/searchAction/searchGigAction';
import RejectBidModal from '../../../components/Hunter/RejectBidModal/RejectBidModal.component';

const TalentBidTable = () => {
  const [show, setShow] = useState(false);
  const [savePitch, setSavePitch] = useState(false);
  const handleClose = () => setShow(false);
  const [talentBids, setTalentBid] = useState(null);
  let histroy = useHistory();
  const handleShow = (pitch) => {
    setSavePitch(pitch);
    setShow(true);
  };
  const params = useParams();
  console.log(params);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const handleCloseAccept = () => setShowAccept(false);
  const handleCloseReject = () => setShowReject(false);
  const handleShowAccept = (Bid) => {
    setTalentBid(Bid);
    setShowAccept(true);
  };

  const handleShowReject = (Bid) => {
    setTalentBid(Bid);
    setShowReject(true);
  };

  const getTalentBids = useSelector(
    (state) => state.talentBidReducer.talentBids
  );
  console.log("my current talent bid", getTalentBids)

  // getTalentBids.joboffers.map((bid, id)=>{

  // })
  // const [talentBids, setTalentBids] = useState(getTalentBids);

  const dispatch = useDispatch();
  // const handleClickGig = async (id) => {
  //   await dispatch(getGigDetailAction(id));
  //   histroy.push('/hunterDashboard/gigdetail');
  // };

  useEffect(() => {
    console.log(talentBids);
    console.log(getTalentBids);
    getTalentBidsJob();
    return () => {};
  }, []);

  const getTalentBidsJob = async () => {
    await dispatch(talentBidForJobAction(params.id));
    // setTalentBids(getTalentBids);
  };

  // console.log(talentBids.joboffers);
  // let allTalentBids = talentBids.joboffers;
  // console.log(allTalentBids);

  return (
    <div>
      <Table className='table_size bid_table' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Talent name</th>
            <th className='active_head_bg'>Did talent agreed to price?</th>
            <th className='active_head_bg'>Talent's Price</th>
            <th className='active_head_bg'>Accept milestone?</th>
            <th className='active_head_bg'>Date Applied</th>
            <th className='active_head_bg'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {getTalentBids == null || getTalentBids === undefined
            ? 'Loading'
            : getTalentBids?.joboffers.map((bid, id) => {
                return (
                  <>
                  <tr key={id}>
                    <td>{1 + id}</td>
                    <td>
                      <Link to={`/hunterDashboard/user/${bid.user_id}`}>
                        {bid.user.name}
                      </Link>
                    </td>
                    <td>{bid.price_to_accept == null ? 'Yes' : 'No'}</td>
                    <td>{bid.job?.price_budget}</td>
                    <td>{bid.price_to_accept == null ? 'Yes' : 'No'}</td>
                    <td>{moment(bid.created_at).format('lll')}</td>
                    <td className='action_icons'>
                      <button
                        className='action_icons--bidbutton'
                        onClick={() => handleShow(bid.pitch)}
                        type='button'
                      >
                        View Pitch
                      </button>
                    </td>
                    <td className='action_icons'>
                    {/* <Link to={`/hunterDashboard/paymentbid/${bid.id}`}> */}
                      <button
                        className='action_icons--bidbutton'
                        onClick={() => handleShowAccept(bid)}
                        type='button'
                      >
                        Accept Bid
                      </button>
                      {/* </Link> */}
                    </td>
                    <td className='action_icons'>
                      <button
                        className='action_icons--bidbutton'
                        onClick={() => handleShowReject(bid)}
                        type='button'
                      >
                        Reject Bid
                      </button>
                    </td>
                  </tr>
                  <AcceptBidModal
            bid={bid}
            handleClose={handleCloseAccept}
            show={showAccept} />
                  </>
                );
              })}
          {/* Modal */}
          <TalentPitchModalModal
            handleClose={handleClose}
            allTalentBids={savePitch}
            show={show}
          />
          {/* <AcceptBidModal
            talentBids={talentBids}
            handleClose={handleCloseAccept}
            show={showAccept}
          /> */}
          <RejectBidModal
            talentBids={talentBids}
            handleClose={handleCloseReject}
            show={showReject}
          />

          {/* End of Modal */}

          {/* {
      gethHunterOpenJobs.map((each,index)=>{
        return(
          
            <tr key={index}>
              <td>{1+index}</td>
              <td>Jan 10, 2022</td>
              <td>
                {each.user.name}
              </td>
              <td>{each.job_description}</td>
              <td>{category.map(categ=>{
                    if (categ.id == each.category) {
                      return categ.skill.map(skil=>{
                        if (skil.id == each.skill) {
                          // skillTable = skil.name
                          return skil.name
                        }
                      })
                    }
                  } )}</td>
              <td>{each.price_budget}</td>
              <td>{each.milestone}</td>   
        
            <td className="action_icons">
              <button type='button' onClick={handleShow}>Apply for job</button>
            </td>
            </tr>  
        

        )
      })
    } */}
        </tbody>
      </Table>
    </div>
  );
};

export default TalentBidTable;
