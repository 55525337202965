import React, { useState, useEffect } from 'react';
import './RejectBidModal.styles.css';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import {
  initiateAgreement,
  rejectAgreement,
} from '../../../store/action/hunter/initiateAgreementAction';

const RejectBidModal = ({ handleClose, talentBids, show }) => {
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  let previousState = {
    rejection_reason: '',
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setstate] = useState(previousState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setstate({ ...state, [name]: value });
    console.log(state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // let newstate = {
    //   ...state,
    //   id: talentBids.id,
    //   creative_id: talentBids.user_id,
    //   job_type: 'open',
    // };
    setLoading(true);
    console.log(state);
    await dispatch(rejectAgreement(state, talentBids.id));
    setLoading(false);
    handleClose();
  };

  return (
    <section>
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>Reject agreement</h3>
        </div>
        <Modal.Body>
          <form className='form-update' onSubmit={handleSubmit}>
            <div className='form-group-update'>
              <label>Comments:</label>
              <br />
              <textarea
                style={{ width: '100%' }}
                rows={5}
                label='Start date'
                name='rejection_reason'
                type='date'
                onChange={handleChange}
              ></textarea>
            </div>
            {/* 
            <div className='form-group-update'>
              <ModalInputComponent
                label='End date'
                name='end_date'
                type='date'
                onChange={handleChange}
              />
            </div> */}
            {/* <div className="form_job">
         <label>Do you accept the price budget?</label><br/>
         <select name="price">
         <option value="yes">Yes</option>
         <option value="no">No</option>
         </select>
        </div>
        <div className="form-group-update">
        <ModalInputComponent
         label="If no, what price will you accept?"
         name="price"
         type="text"
        />
        </div> */}
            <div className='job_button text-center'>
              <button type='submit'>
                {loading ? (
                  <Loader type='Rings' color='white' height={50} />
                ) : (
                  'Reject Bid'
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default RejectBidModal;
