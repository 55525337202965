// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    Languages:[],
    userLanguages:[],

}

export const languageReducer =(state=initialState, action)=>{
    if(action.type === "ADD_LANGUAGE"){
        // console.log(action.payload)
        toast.success("Successful...",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
            console.log(action.payload)
        return Object.assign({}, state, {
            userLanguages: [...state.userLanguages, action.payload ]
        });
    }

    else if(action.type === "GET_LANGUAGES"){
        console.log(action.payload.data)
        return Object.assign({}, state, {
            Languages: action.payload.data 
        });
    }

    else if(action.type === "GET_USER_LANGUAGES"){
        console.log(action.payload)
        return Object.assign({}, state, {
            userLanguages: action.payload.languages 
        });
    }

    else if(action.type === "DELETE_LANGUAGE"){
        console.log(action.languageId)
         const newArray =state.userLanguages.filter((each,i)=>each.id !== action.languageId)
         console.log(newArray)
         toast.info("Deleted Successfully..",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
        return Object.assign({}, state, {
            userLanguages: newArray ,

        });
    }



    else if(action.type === "EDIT_USER_LANGUAGE"){
        // action.payload.data 
        toast.info("Language Profile Edited Successfully.",
       {
           position: toast.POSITION.BOTTOM_CENTER}
           )
       return Object.assign({}, state, {
        userLanguages: state.userLanguages.map(each => {
               if (each.id === action.payload.languageId ){
                   return action.payload 
               }
               return each
           })

       });
   }
 

    return state;
}
