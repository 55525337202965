import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getTalentCategory } from "../../../store/action/categoryAction/category";
import { postJob } from "../../../store/action/hunter/postJob";
import Milestonemodal from "./Milestonemodal.component";
import "./CreateJob.styles.css";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";

import CurrencyFormat from "react-currency-format";

const CreateJob = () => {
  const [data, setData] = useState({});
  const [price_budget, setPrice_budget] = useState("");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState();

  console.log(convertedContent);

  const handleEditorChange = (state) => {
    // console.log("pre state", state);
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categoriesReducer.categories);
  const [loading, setLoading] = useState(false);
  const [availableSkill, setAvailableSkill] = useState([]);
  const [miles, setMiles] = useState(0);
  const [milestonedata, setMilestoneData] = useState({
    title: "",
    due: "",
    description: "",
    penalty: "",
  });
  const [milestonecount, setMilestoneCount] = useState(0);
  const [milestones, setMilestones] = useState([]);

  const [showmilestone, setShowmilestone] = useState(false);

  const handleCloseMilestone = () => setShowmilestone(false);
  const handleShowMilestone = (e) => {
    e.preventDefault();
    setShowmilestone(true);
  };

  // console.log(categories)
  // useEffect(() => {
  //   getCategory();
  // }, []);
  // const getCategory = () => {
  //   dispatch(getTalentCategory());
  // };

  const handleNumberChangeNew = (e) => {
    const addCommas = (num) =>
      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    setPrice_budget(addCommas(removeNonNumeric(e.target.value)));
    // let data = e.target.value;

    // let data2 = data.toLocaleString();
    // console.log(data2);
    // setPrice_budget(data2);

    setData({
      ...data,
      [e.target.name]: e.target.value,
      milestones: milestones,
    });

    console.log(price_budget);
  };

  console.log(price_budget);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      milestones: milestones,
      price_budget: price_budget,
      job_description: convertedContent,
    });
    console.log(data);
  };

  // const handleNumberChange = (e) => {
  //   const addCommas = (num) =>
  //     num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  //   setPrice_budget(addCommas(removeNonNumeric(e.target.value)));

  //   setData({
  //     ...data,
  //     [e.target.name]: e.target.value,
  //     milestones: milestones,
  //     price_budget: price_budget,
  //   });

  //   // const re = /^[0-9\b]+$/;
  //   // console.log("tre test", re.test(e.target.value));
  //   // if (e.target.value === "" || re.test(e.target.value)) {
  //   //   setData({
  //   //     ...data,
  //   //     [e.target.name]: e.target.value,
  //   //     milestones: milestones,
  //   //   });
  //   // }
  // };

  const handleSelectChange = (e) => {
    setMiles(Number(e.target.value));
  };
  const handlemy = (e) => {
    const { name, value } = e.target;
    setMilestoneData({ ...milestonedata, [name]: value });
  };

  const addMilestone = (e) => {
    e.preventDefault();
    if (
      !milestonedata.penalty ||
      !milestonedata.title ||
      !milestonedata.description ||
      !milestonedata.due
    ) {
      toast.error("All fields must be filled", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "newTest",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    }
    // else if(milestones.length > 0 && milestones.length === milestonecount){
    //   return
    // }
    else {
      setMilestones((prev) => [...prev, milestonedata]);
      setMilestoneCount(milestonecount + 1);
      setMilestoneData({ title: "", due: "", description: "", penalty: "" });
    }
  };
  console.log("data milestonbesss", milestones);
  const mill = () => {
    return (
      <div>
        <form className="job_milestone" onSubmit={addMilestone}>
          <div className="job_field_card">
            <label className="milestone_label" for="milestone_label">
              Milestone Title- This can read like ("Job or Project Delivery)
            </label>
            <br />
            <input
              type="text"
              placeholder="enter a milestone title"
              name="title"
              id="milestone_label"
              onChange={handlemy}
              value={milestonedata.title}
              className="milestone_input"
              required
            />
          </div>
          <div className="job_field_card">
            <label className="milestone_label">
              Please enter the total number of days per milestone as indicated
              in the milestone description box
            </label>
            <br />
            <input
              type="number"
              placeholder="enter number days required"
              name="due"
              onChange={handlemy}
              value={milestonedata.due}
              className="milestone_input"
              required
            />
          </div>
          <div className="job_field_card ">
            <label className="milestone_label2">
              <span className=" fs-6  fw-bold">Milestone Description</span>
              <br />
              <span>
                This can read like (share first design for review in 2days )
              </span>
              <br />
              <span className="">
                <span className="fw-bold"> N.B </span>The milestone description
                Is to help you state your Project deliverables & the number of
                days you want them Completed.
              </span>
            </label>
            <br />
            <textarea
              placeholder="milestone description"
              required
              name="description"
              onChange={handlemy}
              value={milestonedata.description}
              className="milestone_input"
            />
          </div>
          <div className="job_field_card">
            <label className="milestone_label2">
              Penalty ( % Amount to be deducted when milestone is missed)
            </label>
            <br />
            <input
              type="number"
              placeholder="enter amount to be deducted in percentage"
              required
              name="penalty"
              onChange={handlemy}
              value={milestonedata.penalty}
              className="milestone_input"
              min={1}
              max={10}
            />
          </div>
          {/* {notify ? <div>fill all fields</div> : ''} */}
          <button onClick={addMilestone} className="milestone_add">
            Add
          </button>
        </form>
      </div>
    );
  };

  // const handleChangeCate = (e) => {

  //   const skillList = categories.find(
  //     (each) => each.id === Number(e.target.value)
  //   ).skill;

  //   setAvailableSkill(skillList);
  //   setData({ ...data, [e.target.name]: e.target.value });
  //   console.log(data);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data);
    if (milestones.length === 0) {
      toast.error("Milestones must be filled", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "newTest",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    } else {
      setLoading(true);
      await dispatch(postJob(data, history));
      setLoading(false);
    }
  };
  return (
    <section className="job_wrapper">
      <h4>To create a job, please fill all the fields</h4>

      <form onSubmit={handleSubmit}>
        <div className="job_field">
          <label>Job title:</label>
          <br />
          <input type="text" name="title" onChange={handleChange} required />
        </div>

        <div className="job_field">
          <label className="">Describe job briefly</label>

          <div className=" flunterwave ">
            <br />
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
          </div>
        </div>
        {/* <div className="job_field">
          <label>Describe job briefly</label>
          <br />
          <textarea
            name="job_description"
            onChange={handleChange}
            required
            placeholder="job description and skills required"
          ></textarea>
        </div> */}
        <div className="job_field">
          <label for="milestones">Select number of milestones</label>
          <br />
          <select
            name="milestones"
            id="milestones"
            onChange={handleSelectChange}
            autocomplete="off"
            required
          >
            <option value="">Select a milestone</option>
            <option value="1">1</option>
            <option value="2">2 </option>
            <option value="3">3 </option>
            <option value="4">4 </option>
          </select>
        </div>
        {/* task boxes */}
        {miles !== 0 ? (
          <div>
            {mill()}
            <p className="milestone_created">{`you have created ${milestones.length} of ${miles}`}</p>
            {milestones.length !== 0 ? (
              <button onClick={handleShowMilestone} className="milestone_add">
                view
              </button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {/* <div className='job_milestone'>
          <div className='job_field_card'>
            <label>Milestone title</label><br/>
            <input type='text' placeholder='enter a milestone title'/>
          </div>
          <div className='job_field_card'>
            <label>Due date(days)</label><br/>
            <input type='text' placeholder='enter number days required'/>
          </div>
          <div className='job_field_card'>
            <label>Description</label><br/>
            <textarea type='text' placeholder='milestone description'/>
          </div>
          <div className='job_field_card'>
            <label>Penalty (amount to be deducted)</label><br/>
            <input type='text' placeholder='enter amount to be deducted'/>
          </div>
        </div> */}
        {/* <div className='job_field'>
          <label>Select a category</label>
          <br />
          <select name='category' onChange={handleChangeCate}>
            <option>Select a category</option>
            {categories.map((each, id) => {
              return (
                <option key={id} value={each.id}>
                  {each.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className='job_field'>
          <label>Select skills</label>
          <br />
          <select name='skill' onChange={handleChange}>
            <option>Select skills</option>
            {availableSkill.map((each, id) => {
              return (
                <option key={id} value={each.id}>
                  {each.name}
                </option>
              );
            })}
          </select>
        </div> */}
        {/* {
        milestones.length !==0 ? 
         <> */}
        <div className="job_field">
          <label>Skills</label>
          <br />
          <textarea
            name="skill"
            onChange={handleChange}
            required
            placeholder="skill required to get the job done"
          ></textarea>
        </div>
        <div className="job_field">
          <label>Select job type</label>
          <br />
          <select name="job_type" onChange={handleChange}>
            <option value="">Select job type</option>
            <option value="open">Open job</option>
            <option value='close'>Closed job</option>
          </select>
        </div>
        <div className="job_field">
          <label>Price budget (Input value in numbers):</label>
          <br />

          {/* <input type='text' name='price_budget' required
          maxLength={12} pattern = "[0-9]{1,12}" onChange={handleChange} placeholder="Stipulated amount"/> */}

          {/* <input
            name="price_budget"
            required
            value={data.price_budget}
            maxLength={12}
            onChange={handleNumberChange}
            placeholder="Stipulated amount"
          /> */}

          <input
            name="price_budget"
            required
            value={price_budget}
            maxLength={12}
            onChange={handleNumberChangeNew}
            placeholder="Stipulated amount"
          />
          {/* onChange(e){
    const re = /^[0-9\b]+$/;

   

    if (e.target.value === '' || re.test(e.target.value)) {
       this.setState({value: e.target.value})
    }
} */}
        </div>
        {/* </> 
         : '' } */}
        {/* disabled={
          !data.title || !data.job_description ||
          !data.job_type || !data.price_budget || 
          !data.skill || milestones.length === 0} */}
        <div className="button_job">
          <button type="submit">
            {loading ? (
              <Loader type="Rings" color="white" height={50} width={50} />
            ) : (
              "Create job"
            )}
          </button>
        </div>
      </form>
      <Milestonemodal
        handleCloseMilestone={handleCloseMilestone}
        handleShowMilestone={handleShowMilestone}
        showmilestone={showmilestone}
        milestones={milestones}
      />
    </section>
  );
};

export default CreateJob;
