import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { VerifyPayment } from '../../../store/action/hunter/initiateAgreementAction';
import verifyImage from '../../../assets/image/verimg.jpg';
import './verify.styles.css';

export const VerifyingPayment = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const data = history.location.search;
  const extract = data.split('=').pop().split('&')[0];
  console.log('histotryyyyy', data.split('=').pop().split('&')[0]);

useEffect(  async () => {
  if(extract){
  await (dispatch(VerifyPayment(extract)))
  }
  },[])
  return (
    <div className='verify__page'>
      <p>Payment Verified</p>
    <div className='verify__image'>
      <img src={verifyImage} alt='verification image' style={{width: '100%'}}/>
    </div>

    </div>
  )
}
