import React, {useEffect} from 'react';
import './DashboardHomeContent.styles.css';
import JobsTable from '../JobsTable/JobsTable.component';
import Tab from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getScoutOpenJobs, getScoutCloseJobs } from '../../../store/action/hunter/postJob';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import { getTalentCategory } from '../../../store/action/categoryAction/category';
import NavHunter from '../../Navs/NavHunter/NavHunter.component';
import CloseJobTable from '../JobsTable/CloseJobTable.component';
import { useCallback } from 'react';

const DashboardHomeContent = () => {
    const getScoutOpenJob = useSelector(state=>state.scoutJobReducer.getScoutOpenJobs);
    const getScoutCloseJob = useSelector(state=>state.scoutJobReducer.getScoutCloseJobs);

    const [openJobs, setOpenJobs] = useState(getScoutOpenJob);
    const [closeJobs, setCloseJobs] = useState(getScoutCloseJob);

console.log(getScoutOpenJob)

    const sta = useSelector(state=>state)
    // console.log(sta)
    // console.log(getScoutOpenJob)
    // console.log(openJobs)
    const dispatch = useDispatch()

    // useEffect(() => {
    //     getOpenJobs()
    //     return () => {
            
    //     };
    // }, [getScoutOpenJob])
    
    useEffect(() => {
      getOpenJobs()
    // await dispatch(getScoutOpenJobs());
    // await dispatch(getTalentCategory());
    // await dispatch(getScoutCloseJobs());
    // setOpenJobs(getScoutOpenJob)
        return () => {   
        };
    }, []);
    // useEffect(() => {
    //     getOpenJobs()
    //     return () => {
            
    //     };
    // }, [])

     const getOpenJobs = async() =>{
        
        await dispatch(getTalentCategory());
        await dispatch(getScoutOpenJobs());
        await dispatch(getScoutCloseJobs())
        console.log(getScoutOpenJob)
        // setOpenJobs(getScoutOpenJob)
        setCloseJobs(getScoutCloseJob)
     }
     useEffect(() => {
        setOpenJobs(getScoutOpenJob)
     }, [getOpenJobs])

    return (
        <section className='dashboard_home'>
        {/* <NavHunter/> */}
        <div className='d-flex justify-content-between dash_heading'>
        <h3>List of created jobs</h3>
        <Link to="hunterDashboard/createJob">
        <button type='button'>
            Create job
        </button>
        </Link>
        </div>

        <div className="tab_wrapper">
            
        <Tab defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
            
        <Tab eventKey="home" title="Open Jobs">
          {/* Table presentational component */}
          {openJobs === null ? 
          <div className='d-flex justify-content-center'>
              <Loader
              type="Rings"
              color="blue"
              height={50}
              width={50}
            /> 
          </div>:<>
            <h6 className="openedjobs">Open jobs
            <div className='toolholder'>
           
                <div className='overtooltip'> 
                <div class="triangle-down"></div>
                <span className='tooltipopen'>Jobs available for all talents</span>
            </div> 
            </div></h6>
        <JobsTable  getScoutOpenJob={getScoutOpenJob} key={getScoutOpenJob} />
        </>
          
        }
        </Tab>
            {/*Description tab*/}  
             <Tab eventKey="description" title="Closed Jobs">
                {closeJobs === null ? 
          <div className='d-flex justify-content-center'>
              <Loader
              type="Rings"
              color="blue"
              height={50}
              width={50}
            /> 
          </div>:<>
                <h6 className="closedjobs">Closed jobs
                <div className='toolholder'>
           
           <div className='overtooltip'> 
           <div class="triangle-down"></div>
           <span className='tooltipopen'>Jobs available for selected talents</span>
       </div> 
       </div></h6>
           
        <CloseJobTable getScoutCloseJob={getScoutCloseJob} key={getScoutCloseJob}  />
          
        </>
        }
            </Tab> 

        </Tab>
        
        </div>   

        {/* Suggested talent Profile */}
        {/* <h4>Suggested talent profiles to checkout</h4> */}
        </section>
    )
}


export default DashboardHomeContent;