import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import axios from 'axios';

export function postOverview (userDetails) {
      return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
          console.log(userDetails)
          return axiosInstance.post('gig/store' ,userDetails).then ( async (response) => {
            console.log(response)
          if(response.data.success === true){
            // console.log(response)
            await dispatch({ type: "ADD_GIG_OVERVIEW", payload: response.data.gig })
          }
          })
          .catch(
              error => {
                console.log(error.response)
                console.log(error)
              }
          )
  
      }
  
  }

  export function editOverview (userDetails,gigId) {
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
        console.log(userDetails)
        return axiosInstance.put(`gig/${gigId}`,userDetails).then ( async (response) => {
          console.log(response)
        if(response.data.success === true){
          // console.log(response)
          // await dispatch({ type: "ADD_GIG_OVERVIEW", payload: response.data.gig })
        }
        })
        .catch(
            error => {
              console.log(error.response)
              console.log(error)
            }
        )

    }

}

export function deleteGig (gigId) {
  return function(dispatch){
    const axiosInstance = axios.create({
      baseURL : PATH,
      timeout: 200000,
      headers: {
                'Content-Type': 'application/json', 
                 'Accept': 'application/json',
              //    'X-CSRF-TOKEN: " "'
                 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
              }
  })
      // console.log(userDetails)
      return axiosInstance.delete(`gig/${gigId}`).then ( async (response) => {
        console.log('gig delete response', response)
      if(response.data.success === true){
        // console.log(response)
        await dispatch({ type: "DELETE_GIG", payload: gigId })
      }
     })
      .catch(
          error => {
            console.log(error.response)
            console.log(error)
          }
      )

  }

}