import React from 'react';
import './AcceptOrderModal.styles.css';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import { useDispatch } from 'react-redux';
import { acceptHunterOrder } from '../../../store/action/talent/applyJobAction';
import { useState } from 'react';
import Loader from 'react-loader-spinner';

const AcceptOrderModal = ({ handleClose, show, selectOrder }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const[isChecked, setIsChecked] = useState('')

  const handleCheck =() => {
    setIsChecked(!isChecked);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(acceptHunterOrder(selectOrder.id));
    setLoading(false);
  };
  return (
    <section>
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>Accept agreement by filling the form below.</h3>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit} className='form-update'>
            {/* <div className='form-group-update'>
              <ModalInputComponent
                label='Hunter’s name:'
                name='name'
                type='text'
                readOnly
                value={selectOrder.user_id}
              />
            </div> */}
            <div className='form-group-update'>
              <ModalInputComponent
                label='Start date::'
                name='name'
                readOnly
                value={selectOrder.start_date}
                type='text'
              />
            </div>
            <div className='form-group-update'>
              <ModalInputComponent
                label='End date:'
                name='name'
                readOnly
                type='text'
                value={selectOrder.end_date}
              />
            </div>

            <div className='form-agreement' name='agreement' dangerouslySetInnerHTML={ {__html: selectOrder.terms} }>
            </div>

            <div className='form_job'>
              <div className='check_wrapper'>
                <input className='check_box' type='checkbox' name='checks'
                onChange={handleCheck} />
                <p>
                  I hereby accept all the terms and conditions as stated in the
                  job brief
                </p>
              </div>
            </div>

            <div className='job_button text-center'>
              <button type='submit' disabled={!isChecked} >
                {loading ? (
                  <Loader type='Rings' color='white' height={50} />
                ) : (
                  'Accept agreement'
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default AcceptOrderModal;
