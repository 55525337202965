import React, { useState } from 'react';
import './AddSkils.styles.css';
import ModalInputComponent from '../../../ModalInput/ModalInput.component';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/image/close.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { addUserSkill } from '../../../../store/action/publicProfileAction/skillAction';

const AddSkillsHunter = () => {
  const [displayContents, setDisplay] = useState(false);
  const showContent = () => setDisplay(true);
  const closeCard = () => setDisplay(false);
  const allSkill = useSelector((state) => state.skillReducer.allSkills);
  const userSkill = useSelector((state) => state.skillReducer.userSkills);

  // console.log(userSkill)

  let initialState = {
    skillId: '',
    proficiency: '',
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setstate] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value)
    setstate({ ...state, [name]: value });
    // console.log(state)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(state);
    const name = allSkill.find((skill) => skill.id === Number(state.skillId));
    // console.log(name)
    const newDate = { ...state, name: name.name };
    // console.log(newDate)
    setLoading(true);
    await dispatch(addUserSkill(newDate));
    setLoading(false);
    setDisplay(false);
    // setShow(false)
  };

  return (
    <div>
      <div className='edit-desc'>
        <p>Skills</p>
        {/* <button type='button' onClick={showContent}>
          Add
        </button> */}
      </div>

      {displayContents ? (
        <div className='desc-content'>
          <form onSubmit={handleSubmit}>
            <div className='form-group-des'>
              <label>Select language:</label>
              <br />
              <select name='skillId' onChange={handleChange}>
                <option value='select language'>Select language</option>
                {allSkill.map((each, idx) => {
                  return (
                    <option key={idx} value={each.id}>
                      {each.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='form-group-des'>
              <label>Select level of proficiency:</label>
              <br />
              <select name='proficiency' onChange={handleChange}>
                <option value='level'>Select proficiency Level</option>
                <option>Beginner</option>
                <option>Intermediate</option>
                <option>Advance</option>
              </select>
            </div>
            <div className='but'>
              <button type='button' onClick={closeCard}>
                Cancel
              </button>
              <button type='submit'>
                {loading ? (
                  <Loader type='Rings' color='white' height={50} width={50} />
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default AddSkillsHunter;
