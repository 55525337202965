import React, { useEffect } from 'react';
import './PublicWorkProfile.styles.css';
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import UpdateStory from '../../../components/Talents/UpdateStory/UpdateStory.component';
import AddDescription from '../../../components/Talents/Description/AddDescription/AddDescription.component';
import AddEducation from '../../../components/Talents/Education/AddEducation/AddEducation.component';
import AddLanguage from '../../../components/Talents/Language/AddLanguage/AddLanguage.component';
import AddSkills from '../../../components/Talents/Skills/AddSkills/AddSkills.component';
import AddCertification from '../../../components/Talents/Certification/AddCertification/AddCertification.component';
// import { getUpdateStatus } from '../../Auth/getUpdateProfile';
import { useDispatch, useSelector } from 'react-redux';
import { country } from '../../../store/action/authAction/countryandstate';
import {
  addEducation,
  UserEducation,
} from '../../../store/action/publicProfileAction/addEducation';
// import { country } from '../../../store/action/authAction/countryandstate';
import TalentFooter from '../../../components/Footer/TalentFooter/Footer.component';
import EditLanguage from '../../../components/Talents/Language/EditLanguage/EditLanguage.component';
// import EditEducation from '../../../components/Talents/Education/EditEducation/EditEducation.component';
import EditSkills from '../../../components/Talents/Skills/EditSkills/EditSkills.component';
import EditCertification from '../../../components/Talents/Certification/EditCertification/EditCertification.component';
import { UserCertification } from '../../../store/action/publicProfileAction/addCertification';
import {
  allSkills,
  getUserAttachedSkill,
} from '../../../store/action/publicProfileAction/skillAction';

import { Link } from 'react-router-dom';
import { getUserLanguages } from '../../../store/action/publicProfileAction/addLanguage';
import { AllUserGigs } from '../../../store/action/creategigAction/getUserGigs';
import GigCreated from '../../../components/Talents/GigCreated/GigCreated.component';
import ReviewsAdded from '../../../components/Talents/ReviewsAdded/ReviewsAdded.component';

const PublicWorkProfile = () => {
  const dispatch = useDispatch();
  const userprofile = useSelector((state) => state.authReducer.userProfile);
  const usercountry = useSelector((state) => state.countryandstate.userCountry);
  const countries = useSelector((state) => state.countryandstate.countries);
  const EducationDetail = useSelector(
    (state) => state.educationReducer.userEducation
  );
  const userGigs = useSelector((state) => state.createGigReducer.userGigs);
  // const userLanguageDetail = useSelector(state=>state.languageReducer.userLanguages)
  console.log(userGigs);
  // console.log(userLanguageDetail)

  useEffect(() => {
    getUserGigs();
    getCountry();
    getUserEducation();
    // getUserCertification()
  }, []);

  useEffect(() => {
    // getCountry()
    // getUserEducation()
    getUserCertification();
    getAllSkills();
  }, []);

  useEffect(() => {
    // getCountry()
    // getUserEducation()
    getUserLang();
    getUserSkill();
  }, []);

  const getCountry = async () => {
    await dispatch(country(userprofile.countryId));
  };
  const getUserEducation = async () => {
    await dispatch(UserEducation());
  };
  const getUserCertification = async () => {
    await dispatch(UserCertification());
  };

  const getUserLang = async () => {
    await dispatch(getUserLanguages());
  };

  const getAllSkills = async () => {
    await dispatch(allSkills());
  };
  // console.log(usercountry)

  const getUserSkill = async () => {
    await dispatch(getUserAttachedSkill());
  };

  const getUserGigs = async () => {
    await dispatch(AllUserGigs());
  };
  console.log(userprofile)

  return (
    <div>
      <NavTalent />
      {/* <GigCreated/> */}

      <main className='container-fluid'>
        <div className='row cont-wrapper'>
          <div className='col-md-5 left-desc'>
            {/*Add Update story component*/}
            <UpdateStory
              firstname={userprofile.firstname}
              lastname={userprofile.lastname}
              userCountry={userprofile.countryId}
              country={usercountry}
              joinedSince={userprofile.created_at}
              storyLine={userprofile.storyline}
              profile_image={userprofile.profile_image}
            />

            {/*Description, language, skills, education and cert card*/}
            <div className='other-feat-cards'>
              <AddDescription description={userprofile.description} />
              <hr />

              {/*Languages*/}
              {/* <AddLanguage />
              <EditLanguage /> */}
              {/* <hr /> */}

              {/*Skills*/}
              <AddSkills />
              <EditSkills />
              <hr />
              <AddEducation
                countries={countries}
                userEducation={EducationDetail}
              />

              {/*Education*/}
              {/* <AddEducation/> */}
              {/* <EditEducation userEducation={EducationDetail}/> */}
              <hr />

              {/*Certification*/}
              <AddCertification />
              <EditCertification />
            </div>
          </div>
          <div className='col-md-7 right-desc'>
            <div className='create-gig'>
              <div className='gig-content'>
                {userGigs.length < 1 ? (
                  <p>You don't have any portfolio yet. Create one</p>
                ) : (
                  <p>Active Portfolio </p>
                )}
                <Link to='/creategig'>
                  <button type='button'>Create Portfolio</button>
                </Link>
              </div>
            </div>
            {/* <h4 data="no-gig">No gig yet. Created gigs will display here.</h4> */}

            {/* Gigs created */}
            <div>
              <GigCreated />
            </div>
            {/* End of Gigs created */}

            {/* Reviews added */}
            <div>
              <ReviewsAdded />
            </div>
            {/* End of reviews */}
          </div>
        </div>
      </main>
      <TalentFooter />
    </div>
  );
};

export default PublicWorkProfile;
