// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    user:null,
    userProfile:null,
    token:null,
    IsRegister:false,
    IsLoggedIn:false,
    company:null,
    responses:null,
    inviteduser:null,
    countries:null,
    states:[],
    cities:[],
    generalAccountType:[],
    userAccountType:null,

}

export const authReducer =(state=initialState, action)=>{
    if(action.type === "REGISTER"){
        toast.success("Registration Successful,Please Signin",{ delay: 2000 },
        {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'newTest',
            bodyClassName: "grow-font-size",
            progressClassName: 'fancy-progress-bar'
        }
            
        )
        console.log(action)
        return Object.assign({}, state, {
            user: {...action.payload.data },
            IsRegister:true,
            token:action.payload.token,
            // userProfile:action.payload.user_profile
        });
    }

    else if(action.type === "LOGIN"){
        toast.success("Login Successful",
        {
            position: toast.POSITION.BOTTOM_CENTER}
        )
        // console.log(action.payload)
        return Object.assign({}, state, {
            user: {...action.payload.user },
            IsLoggedIn:true,
            token:action.payload.token,
            generalAccountType:action.payload.general_account_types,
            userProfile:action.payload.user_profile,
            userAccountType:action.payload.user_account_types,
        });
    }

    else if(action.type === "UPDATE_CREATE_PROFILE"){
        // console.log(action.payload)
        // toast("User Profile Update Successful.",
        // {
        //     position: toast.POSITION.TOP_CENTER}
        // )
        return Object.assign({}, state, {
            userProfile: {...action.payload.userdetail },
            userAccountType:{...action.payload.useraccounttype},
            // token:action.payload.token
        });
    }

    else if(action.type === "FORGETPASSWORD"){
        toast(action.payload.message,
        {
            position: toast.POSITION.BOTTOM_CENTER}
        )
        return Object.assign({}, state, {
            user: {...action.payload.data },
        });
    }

    

    return state;
}
