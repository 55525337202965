import React, { useEffect, useState } from 'react';
import './Paymentbid.styles.css';
import HunterDashboard from '../../../pages/Hunter/dashboard-home/hunterDashboard';
import NavHunter from '../../Navs/NavHunter/NavHunter.component';
import { BiUserCircle } from 'react-icons/bi';
import {RiPaypalFill} from 'react-icons/ri'
import NavTalent from '../../Navs/NavTalentComp/NavTalent/NavTalent.component';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getOfferForPayment, offerPayment } from '../../../store/action/hunter/initiateAgreementAction';
import Loader from 'react-loader-spinner';
import qs from 'qs';
import moment from 'moment'

const PaymentBid = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const bidForPayment = useSelector(state=>state.agreementReducer.bidForPayment)
  const user = useSelector(state=>state.authReducer.user)
  console.log('bid payment', bidForPayment)

  const [isloading, setIsLoading] = useState(false)
  const [isPaying, setIsPaying] = useState(false)
  console.log(params)
  useEffect(() => {
    getOfferDetail()
  }, [])
  const getOfferDetail = async()=>{
    setIsLoading(true)
    await dispatch(getOfferForPayment(params.id))
    setIsLoading(false)
  }

  const handlePay = async()=>{
    setIsPaying(true)
    let data = {"email":user.email,
    "amount": bidForPayment.accept_price,
    "job_id": bidForPayment.job_id,
    "hunter_id": bidForPayment.job.user_id,
    "talent_id": bidForPayment.user_id,
    'callback_url':"https://solvateafrica.com/hunterDashboard"
    // 'callback_url':"http://localhost:3001/hunterDashboard"


    // 'callback_url':"http://localhost:3000/hunterDashboard"
    // https://solvateafrica.com/hunterDashboard"

    // 'callback_url':"http://solvate.herokuapp.com/hunterDashboard/payment/verify"

    // 'callback_url':"http://solvate.herokuapp.com/hunterDashboard",
    // /hunterDashboard/payment/verify

    // 'callback_url':"http://solvate.herokuapp.com/hunterDashboard/ActiveProjects"

    // 'callback_url':`http://localhost:3000${history.location.pathname}`
  }
    console.log('data passed for payment', data);
    await dispatch(offerPayment(data,history))
    setIsPaying(false)
  }
   let search = window.location.search
   let output = search.split('?').pop();
  console.log('soutputttt', output.split('=')[2])
  return (
    <div style={{width: "100%"}}>
     {/* <NavHunter/> */}
     {
       isloading?
       <Loader
            type="Rings"
            color="blue"
            height={50}
          />:
          bidForPayment === null? 'No data':
     <section className='paymentbid'>
         <section className='paymentbid__info'>
            {/* <div className='paymentbid__image'>
            <BiUserCircle />
            </div> */}
            <div className='paymentbid__table'>
                <table>
                    <thead>
                    <tr>
                        <th className='active_head_bg'>DESCRIPTION</th>
                        {/* <th className='active_head_bg'>MILESTONE.</th> */}
                        <th className='active_head_bg'>DUE DATE</th>
                        <th className='active_head_bg'>PRICE</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td dangerouslySetInnerHTML={{__html: bidForPayment?.job.job_description}}></td>
                            {/* <td>{bidForPayment?.job.milestone}</td> */}
                            <td>{moment(bidForPayment?.created_at).format('ll')}</td>
                            <td>{bidForPayment?.accept_price}</td>

                        </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className='tr-foot'>TOTAL</td>
                        {/* <td className='tr-foot'></td> */}
                        <td className='tr-foot'></td>
                        <td className='tr-foot'>
                        {bidForPayment?.accept_price}
                        </td>
                      </tr>
                    </tfoot>
                </table>
            </div>
         </section>
         <section className='paymentbid__summary'>
             <div className='paymentbid__title'>
             <h5>Payment Summary</h5>
             </div>
             <div className='paymentbid__summary-detail'>
                 <div className='paymentbid__summary-text'>Order includes</div>
                 <div className='paymentbid__summary-price'>{bidForPayment?.accept_price}</div>
             </div>
             <div className='paymentbid__summary-detail'>
                 <div className='paymentbid__summary-text'>Talent Name</div>
                 <div className='paymentbid__summary-price'>{bidForPayment?.user.name}</div>
             </div>
             <div className='paymentbid__summary-detail'>
                 <div className='paymentbid__summary-text'>Milestone</div>
                 <div className='paymentbid__summary-price'>{bidForPayment?.job.milestone}</div>
             </div>

             <button type='button' onClick={handlePay} className='paymentbid__button'>
                <RiPaypalFill />
                {
                  isPaying?
                  <Loader
            type="Rings"
            color="white"
            height={50}
          />:"checkout"
                } 
             </button>
            
         </section>

     </section>
     }
    </div>
  )
}

export default PaymentBid;