import React, { useState } from "react";
import "./JobRequestTable.styles.css";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import ModalInputComponent from "../../ModalInput/ModalInput.component";
import moment from "moment";
import close from "../../../assets/image/close.svg";
import JobRequestModal from "../JobRequestModal/JobRequestModal.component";
import { useSelector } from "react-redux";
import { bool } from "yup";
import JobRequestMilestonemodal from "../Milestones/Milestones.modal";
import Milestonemodal from "../../../pages/Hunter/CreateJob/Milestonemodal.component";
const CloseJobRequestTable = ({ getHunterCloseJobs }) => {
  const [show, setShow] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [showmilestone, setShowmilestone] = useState(false);
  const handleClose = () => setShow(false);
  const [applyJobId, setApplyJobId] = useState(null);
  const handleShow = (job) => {
    setShow(true);
    setApplyJobId(job);
  };

  const handleCloseMilestone = () => {
    setShowmilestone(false);
  };
  const handleShowMilestone = ({ milestones }) => {
    console.log("each point in job closed", milestones);
    setMilestones(milestones);
    setShowmilestone(true);
  };

  const category = useSelector((state) => state.categoriesReducer.categories);
  console.log("get jobs hunted for", getHunterCloseJobs);

  return (
    <div>
      <Table className="table_size" striped bordered hover responsive>
        <thead>
          <tr>
            <th className="active_head_bg">#</th>
            <th className="active_head_bg">Date</th>
            <th className="active_head_bg">Username</th>
            <th className="active_head_bg">Job Description</th>
            <th className="active_head_bg">Price budget</th>
            <th className="active_head_bg">Net pay</th>
            <th className="active_head_bg">Milestone</th>
            <th className="active_head_bg">Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Modal */}
          <JobRequestModal
            handleClose={handleClose}
            show={show}
            applyJob={applyJobId}
          />
          <Milestonemodal
            showmilestone={showmilestone}
            milestones={milestones}
            handleCloseMilestone={handleCloseMilestone}
          />

          {/* <JobRequestMilestonemodal showmilestone={showmilestone} handleCloseMilestone={handleCloseMilestone}/> */}

          {getHunterCloseJobs?.map((each, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{moment(each.created_at).format("ll")}</td>
                <td>{each.hunter.name}</td>
                <td>{each.job.job_description}</td>
                <td>{each.job.price_budget}</td>
                <td>{each.job.net_pay}</td>
                <td className="action_icons">
                  <button
                    type="button"
                    onClick={() => handleShowMilestone(each.job)}
                  >
                    Milestones
                  </button>
                </td>

                <td className="action_icons">
                  <button type="button" onClick={() => handleShow(each.job)}>
                    Apply for job
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default CloseJobRequestTable;
