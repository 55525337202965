// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    userEducation:[]
}

export const educationReducer =(state=initialState, action)=>{
    if(action.type === "ADD_EDUCATION"){
        console.log(action.payload.data)
        toast.success("Successful.",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
            // console.log(action.payload.data)
        return Object.assign({}, state, {
            userEducation: [...state.userEducation, action.payload.data ],

        });
    }

    else if(action.type === "GET_EDUCATION"){
        // console.log(action.payload.data)
        return Object.assign({}, state, {
            userEducation: action.payload.data ,

        });
    }

    else if(action.type === "DELETE_EDUCATION"){
         const newArray =state.userEducation.filter((each,i)=>each.id !== action.educationId)
         toast.info("Education Profile Deleted Successfully.",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
        return Object.assign({}, state, {
            userEducation: newArray ,

        });
    }

    else if(action.type === "EDIT_EDUCATION"){
        // action.payload.data 
        toast.info("Education Profile Edited Successfully.",
       {
           position: toast.POSITION.BOTTOM_CENTER}
           )
       return Object.assign({}, state, {
           userEducation: state.userEducation.map(each => {
               if (each.id === action.payload.data.id ){
                   return action.payload.data 
               }
               return each
           })

       });
   }
 

    return state;
}
