import React, { useEffect, useState } from 'react';
import './ProjectTrackerTable.styles.css';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdVisibility } from 'react-icons/md';
// import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ProjectTrackerJobsModal from '../ProjectTrackerJobsModal/ProjectTrackerJobsModal.component';
import { useDispatch, useSelector } from 'react-redux';
import { getAddedOrder } from '../../../../store/action/hunter/addOrder';
import moment from 'moment';
import Loader from 'react-loader-spinner';

const ProjectTrackerTable = ({
  show,
  handleClose,
  handleShow,
  getActiveOrders,
}) => {
  const [isloading, setIsLoading] = useState(false);
  const getAddedTrackedOrders = useSelector(
    (state) => state.TrackerOrderReducer.getAddedOrders
  );
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // console.log(getScoutOpenJob);
  const dispatch = useDispatch();
  useEffect(() => {
    getTrackedProjects();
  }, []);
  // console.log(getAddedTrackedOrders);
  const getTrackedProjects = async () => {
    setIsLoading(true);
    await dispatch(getAddedOrder());
    setIsLoading(false);
  };

  return (
    <section>
      <ProjectTrackerJobsModal
        handleClose={handleClose}
        show={show}
        handleShow={handleShow}
        getActiveOrders={getActiveOrders}
      />
      <Table className='table_tracker'>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Order</th>
            <th className='active_head_bg'>Category</th>
            <th className='active_head_bg'>Milestone</th>
            <th className='active_head_bg'>Date created</th>
            {/* <th></th> */}
            <th className='active_head_bg'>Actions</th>
          </tr>
        </thead>
        {isloading ? (
          <div className='d-flex justify-content-center'>
            <Loader type='Rings' color='blue' height={50} />
          </div>
        ) : (
          <tbody>
            {getAddedTrackedOrders.map((each, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{each.job.title}</td>
                  <td>{each.job.milestone}</td>
                  <td>{moment(each.job.start_date).format('lll')}</td>
                  <td>{moment(each.job.end_date).format('lll')}</td>
                  <td>
                    <>
                      {['top'].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              View tracker
                            </Tooltip>
                          }
                        >
                          {/* <a>Tooltip on {placement}</a> */}
                          <Link
                            to={`/hunterDashboard/trackersettingshunter/${each.id}`}
                          >
                            <MdVisibility className='view_icon_tracker' />
                          </Link>
                        </OverlayTrigger>
                      ))}
                    </>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </section>
  );
};

export default ProjectTrackerTable;
