// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    categories:[],


}

export const categoryReducer =(state=initialState, action)=>{
    if(action.type === "GET_TALENT_CATEGORIES"){
        // console.log(action.payload.categories)
        return Object.assign({}, state, {
            categories: action.payload.categories
        });
    }


    return state;
}
