import React from 'react';
import TalentFooter from '../../../../components/Footer/TalentFooter/Footer.component';
import NavTalent from '../../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import './EscrowHome.styles.css';



const EscrowHome = () => {

    return (
        <div className='escrow_wrapper'>
        {/* <NavTalent/> */}
        <h2>Copywriting</h2>
        <h4>See below your Order summary, To enable talent to begin work,
        click the checkout button to pay</h4>

        {/* Brief details about the Order */}
        <div className="escrow_details card">
        <h3 className='text-center'>Order summary</h3>

        <div className='d-flex justify-content-evenly'>
        <div className="escrow_date d-flex align-items-baseline">
            <h6>Start date:</h6> 
            <span>20-04-2022</span>
        </div>
        <div className="escrow_date d-flex align-items-baseline">
            <h6>End date:</h6> 
            <span>24-04-2022</span>
        </div>
        
        </div>
        {/* Charges */}
        <div className="escrow_date price_escrow d-flex align-items-baseline">
            <h6>Agreed price:</h6> 
            <span>8,000</span>
        </div>

        <div className='escrow_button text-center'>
            <button type="submit"> Checkout to pay</button>
        </div>
        </div>


{/* 
        <TalentFooter/> */}
        </div>
    )
}


export default EscrowHome;