import React, { useState } from 'react';
import './AddDescription.styles.css';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/image/close.svg';
import { useDispatch } from 'react-redux';
import { updateStory } from '../../../../store/action/publicProfileAction/StoryProfileImage';
import { updateDescription } from '../../../../store/action/publicProfileAction/updateDescription';
import Loader from 'react-loader-spinner';

const AddDescriptionHunter = ({ userPubProfile, description }) => {
  let initialState = {
    description: '',
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setstate] = useState(initialState);

  const [displayContents, setDisplay] = useState(false);
  const showContent = () => setDisplay(true);
  const closeCard = () => setDisplay(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setstate({ ...state, [name]: value });
    console.log(state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(state);
    setLoading(true);
    await dispatch(updateDescription(state));
    setLoading(false);
    setDisplay(false);
    // setShow(false)
  };

  return (
    <div>
      <div className='edit-desc'>
        <p>Description</p>
        {/* {!displayContents ? (
          <button type='button' onClick={showContent}>
            Edit
          </button>
        ) : null} */}
      </div>
      <p style={{ marginLeft: '1rem' }}>
        {userPubProfile?.userdetail.description}
      </p>
      {/* Description content*/}

      {displayContents ? (
        <div className='desc-content'>
          <form onSubmit={handleSubmit}>
            <div className='form-group-des'>
              <label>Your description here:</label>
              <br />
              <textarea
                name='description'
                defaultValue={description}
                onChange={handleChange}
              ></textarea>
            </div>
            <div class='but'>
              <button type='button' onClick={closeCard}>
                Cancel
              </button>
              <button type='submit'>
                {loading ? (
                  <Loader type='Rings' color='white' height={50} width={50} />
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default AddDescriptionHunter;
