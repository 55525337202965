import React, { useState } from 'react';
import './ProjectTrackerCreatedHunterTasks.styles.css';
import editIcon from '../../../../assets/image/edit-icon.svg';
import deleteIcon from '../../../../assets/image/delete.svg';
import moveIcon from '../../../../assets/image/move-icon.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
// import MoveTaskModal from '../../MoveTaskModal/MoveTaskModal.component';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  changeTaskStatusToDone,
  deleteTask,
} from '../../../../store/action/hunter/addOrder';
const ProjectTrackerCreatedHunterTasks = ({ getTask }) => {
  const [show, setShow] = useState(false);
  const [taskId, setTaskId] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setTaskId(id);
  };
  // const getTask = useSelector((state) => state.TrackerOrderReducer.getTask);
  console.log(getTask);
  const handleDelete = async (id) => {
    console.log('hello');
    await dispatch(deleteTask(Number(id)));
  };

  return (
    <div>
      {getTask.map((each, id) => {
        return (
          <div key={id} className='card created_card'>
            <p>{each.name}</p>
            <span className={each.status === 'done' ? 'done' : 'doing'}>
              {' '}
              {moment(each.end_date).format('ll')}{' '}
            </span>
            {/* <div className='action_tracker_icon'>
              <>
                
                {['top'].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>Move task</Tooltip>
                    }
                  >
                   
                    <Link to='#'>
                      <img
                        onClick={() => handleShow(each.id)}
                        src={moveIcon}
                        alt='MoveIcon'
                      />
                    </Link>
                  </OverlayTrigger>
                ))}
              </>

              <>
                {['top'].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placementr={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>Edit task</Tooltip>
                    }
                  >

                    <Link to='#'>
                      <img src={editIcon} alt='EditIcon' />
                    </Link>
                  </OverlayTrigger>
                ))}
              </>

              <>
                {['top'].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placementr={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>Delete task</Tooltip>
                    }
                  >
                    
                    <Link to='#'>
                      <img
                        src={deleteIcon}
                        onClick={() => handleDelete(each.id)}
                        alt='deleteIcon'
                      />
                    </Link>
                  </OverlayTrigger>
                ))}
              </>
            </div> */}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectTrackerCreatedHunterTasks;
