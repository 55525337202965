import React from 'react';
import './TalentPitchModal.styles.css';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';

const TalentPitchModalModal = ({ handleClose, show, allTalentBids }) => {
  // console.log(allTalentBids);
  // let talentPitch = allTalentBids.map((bid, id) => {
  //   return bid.pitch;
  // });
  // console.log(talentPitch);
  return (
    <section>
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>Talent's pitch</h3>
        </div>
        <Modal.Body>
          {/* <div className="form-update"> */}
          <div className='pitch_text_wrapper'>{allTalentBids}</div>
          <div className='job_button text-center'>
            <button type='submit' onClick={handleClose}>
              Close
            </button>
          </div>
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default TalentPitchModalModal;
