import React, { useState } from "react";
import "./JobsTable.styles.css";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import editIcon from "../../../assets/image/edit-icon.svg";
import deleteIcon from "../../../assets/image/delete.svg";
import bellIcon from "../../../assets/image/bell.svg";
import { Link } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import close from "../../../assets/image/close.svg";
import ModalInputComponent from "../../ModalInput/ModalInput.component";
import Loader from "react-loader-spinner";
import {
  deletePostedJob,
  editPostJob,
} from "../../../store/action/hunter/postJob";
import { AiFillEdit } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import ViewMilestone from "./Viewmilestone.modal";

const JobsTable = ({ getScoutOpenJob }) => {
  const category = useSelector((state) => state.categoriesReducer.categories);
  const dispatch = useDispatch();
  const [availableSkill, setAvailableSkill] = useState([]);
  const [showedit, setShowedit] = useState(false);
  const [milestone, setMilestone] = useState(false);
  const [milestonedata, setMilestonedata] = useState(false);

  // const [data, setData] = useState({});

  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShowedit(false);
  const handleClosemilestone = () => setMilestone(false);
  const handleShow = (jobToEdit) => {
    setShowedit(true);
    // console.log(jobToEdit);
    // console.log(category);
    // const skillList = category.find(
    //   (each) => each.id === Number(jobToEdit.category)
    // ).skill;
    // console.log(skillList)
    // setAvailableSkill(skillList);

    setEditData(jobToEdit);
  };
  const handleMilestone = (milestone) => {
    setMilestonedata(milestone);
    setMilestone(true);
  };

  // const handleChangeCate = (e) => {
  //   // alert(e.target.value)
  //   const skillList = category.find(
  //     (each) => each.id === Number(e.target.value)
  //   ).skill;
  //   // console.log(skillList)
  //   setAvailableSkill(skillList);
  //   setEditData({ ...editData, [e.target.name]: e.target.value });
  //   // console.log(data)
  // };
  console.log("getScoutOpenJob inside component", getScoutOpenJob);
  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
    // console.log(editData)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(editData)
    let newData = {
      job_description: editData.job_description,
      milestone: editData.milestone,
      category: editData.category,
      skill: editData.skill,
      job_type: editData.job_type,
      price_budget: editData.price_budget,
    };
    setLoading(true);
    await dispatch(editPostJob(newData, editData.id));
    setLoading(false);
    handleClose();
  };

  const handleDelete = (jobToDelete) => {
    console.log(jobToDelete);
    dispatch(deletePostedJob(jobToDelete.id));
  };

  return (
    <section className="openandclosedjobs">
      <Table className="table_size" striped bordered hover responsive>
        <thead>
          <tr>
            <th className="active_head_bg">#</th>
            <th className="active_head_bg">Title</th>
            <th className="active_head_bg">Skills</th>
            <th className="active_head_bg">Milestone</th>
            <th className="active_head_bg">Price budget</th>
            <th className="active_head_bg">Job type</th>
            <th className="active_head_bg">Actions</th>
          </tr>
        </thead>
        <tbody>
          {getScoutOpenJob?.map((each, index) => {
            const milestones = each?.milestones;

            const { price_budget } = each;

            let convet_price_budget_num = parseInt(price_budget);

            convet_price_budget_num = convet_price_budget_num.toLocaleString();

            console.log(convet_price_budget_num);
            return (
              <>
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {each?.title}
                    {/* {category.map((categ) => {
                    if (categ.id == each.category) {
                      return categ.skill.map((skil) => {
                        if (skil.id == each.skill) {
                          // skillTable = skil.name
                          return skil.name;
                        }
                      });
                    }
                  })} */}
                  </td>
                  <td>{each?.skill}</td>
                  <td>
                    <>
                      {["top"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              View Milestone
                            </Tooltip>
                          }
                        >
                          {/* <a>Tooltip on {placement}</a> */}
                          {/* <button onClick={() => handleMilestone(each.milestones)} className="milestone_add">View</button> */}
                          <Link
                            to={{
                              pathname: "/hunterDashboard/milestoneedit",
                              state: { milestones },
                            }}
                            className="milestone_add"
                          >
                            View
                          </Link>
                        </OverlayTrigger>
                      ))}
                    </>
                  </td>
                  {/* <td>{each.price_budget}</td> */}
                  <td>{convet_price_budget_num}</td>

                  <td>{each.job_type}</td>
                  {/* <ViewMilestone milestone={milestone} handleClosemilestone={handleClosemilestone}
                milestonedata={milestonedata}/> */}
                  {/* <td></td>             */}
                  {/* <td>
                  <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Click to view job
                          </Tooltip>
                        }
                      >
                        <Link to='#'>
                          <MdVisibility className='view_icon' />
                        </Link>
                      </OverlayTrigger>
                    ))}
                  </>
                </td>  */}

                  <Modal
                    size="md"
                    show={showedit}
                    onHide={handleClose}
                    dialogClassName="modal-500w"
                  >
                    <div className="title-cus">
                      <img src={close} alt="closeimage" onClick={handleClose} />
                      <h3>Edit job</h3>
                    </div>
                    <Modal.Body>
                      <form className="form-update" onSubmit={handleSubmit}>
                        <div className="job_form">
                          <label>Describe job briefly:</label>
                          <br />
                          <textarea
                            name="job_description"
                            defaultValue={editData.job_description}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        {/* <div className='job_form'>
                        <label>Select a Milestone:</label>
                        <br />
                         <select
                          name='milestone'
                          defaultValue={editData.milestone}
                          onChange={handleChange}
                        >
                          <option>Select a Milestone</option>
                          <option>1 day</option>
                          <option>2 day</option>
                          <option>3 day</option>
                          <option>4 day</option>
                          <option>5 day</option>
                        </select>
  ``                      </div>  */}
                        {/*  <div className='job_form'>
                        <label>Select a Category:</label>
                        <br />
                        <select
                          name='category'
                          defaultValue={editData.category}
                          onChange={handleChangeCate}
                        >
                          <option>Select a category</option>
                          {category.map((each, id) => {
                            return (
                              <option key={id} value={each.id}>
                                {each.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>*/}
                        <div className="job_form">
                          <label>Skills:</label>
                          <br />
                          <textarea
                            name="skill"
                            defaultValue={editData?.skill}
                            onChange={handleChange}
                          />

                          {/* <option value='articlewriting'>
                            Article writing
                          </option>
                          {availableSkill.map((each, id) => {
                            return (
                              <option key={id} value={each.id}>
                                {each.name}
                              </option>
                            );
                          })}
                        </select> */}
                        </div>
                        <div className="job_form">
                          <label>Select Job type:</label>
                          <br />
                          <select
                            name="job_type"
                            defaultValue={editData?.job_type}
                            onChange={handleChange}
                          >
                            <option value="">Select Job type</option>
                            <option value="open">Open job</option>
                            {/* <option value='close'>Closed job</option> */}
                          </select>
                        </div>
                        <div className="job_form">
                          <ModalInputComponent
                            label="Price budget:"
                            name="price_budget"
                            type="text"
                            onChange={handleChange}
                            defaultValue={editData.price_budget}
                          />
                        </div>

                        <button className="update_job_button" type="submit">
                          {loading ? (
                            <Loader
                              type="Rings"
                              color="white"
                              height={50}
                              width={50}
                            />
                          ) : (
                            " Update job"
                          )}
                        </button>
                      </form>
                    </Modal.Body>
                  </Modal>
                  <td className="action_icons">
                    <>
                      {["top"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              Edit job
                            </Tooltip>
                          }
                        >
                          {/* <a>Tooltip on {placement}</a> */}
                          <img
                            onClick={() => handleShow(each)}
                            className="edit_icon"
                            src={editIcon}
                            alt="editIcon"
                          />
                        </OverlayTrigger>
                      ))}
                    </>
                  </td>
                  <td className="action_icons">
                    <>
                      {["top"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              Delete job
                            </Tooltip>
                          }
                        >
                          {/* <a>Tooltip on {placement}</a> */}
                          <img
                            className="delete_icon"
                            onClick={() => handleDelete(each)}
                            src={deleteIcon}
                            alt="deleteIcon"
                          />
                        </OverlayTrigger>
                      ))}
                    </>
                  </td>
                  <td className="action_icons">
                    <>
                      {["top"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              View Bids From Talent For this Job
                            </Tooltip>
                          }
                        >
                          {/* <a>Tooltip on {placement}</a> */}
                          <Link to={`/hunterDashboard/talentBid/${each.id}`}>
                            <img
                              className="bell_icon"
                              // onClick={ ()=>handleDelete(each)}
                              src={bellIcon}
                              alt="bellIcon"
                            />
                          </Link>
                        </OverlayTrigger>
                      ))}
                    </>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
};

export default JobsTable;
