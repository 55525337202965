import React, { useEffect, useState } from 'react';
import './SentJobRequestTable.styles.css';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import JobRequestModal from '../JobRequestModal/JobRequestModal.component';
import Milestonemodal from '../../../pages/Hunter/CreateJob/Milestonemodal.component';

import { useSelector } from 'react-redux';
import { bool } from 'yup';
import { useDispatch } from 'react-redux';
import { getTalentSentOffer } from '../../../store/action/hunter/postJob';
import moment from 'moment';
import { Link } from 'react-router-dom';

const SentJobRequestTable = ({ gethHunterOpenJobs }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // show milestone
  const [showmilestone, setShowmilestone] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [applyJobId, setApplyJobId] = useState(null);
  const dispatdch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);
  const category = useSelector((state) => state.categoriesReducer.categories);
  const getSentRequest = useSelector(
    (state) => state.talentBidReducer.talentSentRequest
  );
  
  const handleShow = (job) => {
    setShow(true);
    setApplyJobId(job);
  };
  useEffect(() => {
    getSentOffer();
    return () => {};
  }, []);

  // milestone view
  const handleCloseMilestone = () => {
    setShowmilestone(false);
  }
  const   handleShowMilestone = ({milestones}) => {
   
    setMilestones(milestones);
    setShowmilestone(true);
  };

  const getSentOffer = async () => {
    const aa = await dispatdch(getTalentSentOffer(user.id));
  };

  return (
    <div>
      <Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Date</th>
            <th className='active_head_bg'>Username</th>
            <th className='active_head_bg'>Job Description</th>
            <th className='active_head_bg'>Price budget</th>
            <th className='active_head_bg'>Net pay</th>
            <th className='active_head_bg'>Milestone</th>
            <th className='active_head_bg'>Pitch</th>
            <th className='active_head_bg'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Modal */}
          <JobRequestModal
            handleClose={handleClose}
            show={show}
            applyJob={applyJobId}
          />

{/* open milestone modal */}

      <Milestonemodal  handleCloseMilestone={handleCloseMilestone}
             handleShowMilestone={handleShowMilestone} showmilestone={showmilestone}
             milestones={milestones}
              />
          {/* End of Modal */}

          {getSentRequest?.map((each, index) => {
            return (
              <tr
                className={each.rejection_reason !== null ? 'rjct-table ' : ''}
                key={index}
              >
                <td>{1+index}</td>
                <td>{moment(each.created_at).format('ll')}</td>
                <td >{each?.job.user.name}</td>
                <td dangerouslySetInnerHTML={ {__html: each?.job.job_description } }></td>
                <td>{each.job.price_budget}</td>
                <td>{each.job.net_pay}</td>
                <td className='action_icons'>
                  <button  type='button' onClick={() => handleShowMilestone(each.job)}>
                      Milestones
                  </button>
                </td>
                <td>{each.pitch}</td>
                <td className='action_icons'>
                  <Link to={`/jobrequest/detail/${each.id}`}>
                    <button type='button'>view job</button>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SentJobRequestTable;
