import React, { useState } from 'react';
import './ForgotPassword.styles.css'
import NavBar from '../../../components/Navs/NavBar/NavBar.component';
import leftLogo from '../../../assets/image/auth-image.svg';
import FormInput from '../../../components/FormInput/FormInput.component';
import CustomButton from '../../../components/CustomButton/CustomButton.component';
import { Link } from 'react-router-dom';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { forgetPassword } from '../../../store/action/authAction/forgetPassword';
import Loader from 'react-loader-spinner';


const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()


    return (
        <div className="container-fluid signup-wrapper">
        <div className="row signup-wrapper-child-1">  
        <div className="left-forgot col-md-5">
        <NavBar/>
        <div>
       {/*The heading*/}
       <h1>Join the GREAT MINDS REPUBLIC</h1>
        <img className="img-fluid" src={leftLogo} alt="workImage"/>
        </div>
        </div>
        <div className="right-forgot col-md-7">
        <h1>Forgot password</h1>
        <h6>Kindly enter the email you registered with, to recieve a reset link</h6>
        <Formik initialValues= {{

            email: '',

            }} 
            validationSchema={yup.object({

            email: yup.string()

            .email('Invalid Email Address, Please Enter a Valid Email Address')

            .required('Please Enter Email Address'),

            })} 
            onSubmit= {async values => {
                console.log(values)
                setLoading(true)
                await dispatch(forgetPassword(values,history))
                setLoading(false)

            }}>
        {
            props=>(

            <Form >
        <div className="form-input">
        <FormInput
         label="Email:"
         name="email"
         type="email"
        />
        <ErrorMessage  name="email">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
        <CustomButton>
        {
                        loading ?
                        <Loader
                type="Rings"
                color="white"
                height={50}
                width={50}
                //    timeout={3000} //3 secs
                /> :null

                    }
            Get link</CustomButton>
        </Form>
            )
        }
        </Formik>
        <p className="mobile-center">Remember your password? <span><Link to="/login">Login</Link></span></p>
        </div>       
        </div>
        </div>
    )
}



export default ForgotPassword;