import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import axios from 'axios';

export function allSkills () {
  const axiosInstance = axios.create({
    baseURL : PATH,
    timeout: 200000,
    headers: {
              'Content-Type': 'application/json', 
               'Accept': 'application/json',
            //    'X-CSRF-TOKEN: " "'
               'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
})
      return function(dispatch){
         return axiosInstance.get('skill').then ( async (response) => {
           console.log(response)
              await dispatch({ type: "GET_ALL_SKILL", payload: response.data })
          })
          .catch(
              error => {
                // console.log(error.response)
                // console.log(error.response)
                // if(error.response !== undefined){

                //   if(error.response.status === 422){
                //     if(error.response.data.errors.email){
                //       toast.error(error.response.data.errors.email[0], {
                //           className: css({
                //             background: "#00FF00 !important",
                //             color: "white !important",
                //             fontWeight: "bold"
                //           }),
                //           position: toast.POSITION.TOP_CENTER,
                //         });
                //     }
                //     else if(error.response.data.errors.password){
                //       toast.error(error.response.data.errors.password[0], {
                //           className: css({
                //             background: "#00FF00 !important",
                //             color: "white !important",
                //             fontWeight: "bold"
                //           }),
                //           position: toast.POSITION.TOP_CENTER,
                //         });
                //     }
                //   }

                // }
              }
          )

      }

}

export function addUserSkill (userDetails) {
  console.log('skills user detail', userDetails)
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
       return axiosInstance.post('skill/attachskilltouser',userDetails).then ( async (response) => {
         console.log('user skill response', response)
         if (response.data.success === true){
           await dispatch({ type: "ADD_USER_SKILL", payload: response.data.skills })
         }
        })
        .catch(
            error => {
              // console.log(error.response)
              // console.log(error.response)
              // if(error.response !== undefined){

              //   if(error.response.status === 422){
              //     if(error.response.data.errors.email){
              //       toast.error(error.response.data.errors.email[0], {
              //           className: css({
              //             background: "#00FF00 !important",
              //             color: "white !important",
              //             fontWeight: "bold"
              //           }),
              //           position: toast.POSITION.TOP_CENTER,
              //         });
              //     }
              //     else if(error.response.data.errors.password){
              //       toast.error(error.response.data.errors.password[0], {
              //           className: css({
              //             background: "#00FF00 !important",
              //             color: "white !important",
              //             fontWeight: "bold"
              //           }),
              //           position: toast.POSITION.TOP_CENTER,
              //         });
              //     }
              //   }

              // }
            }
        )

    }

}


export function getUserAttachedSkill () {
  
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
       return axiosInstance.get('skill/getalluserskills').then ( async (response) => {
         console.log(response)
            await dispatch({ type: "GET_ALL_USER_SKILL", payload: response.data })
        })
        .catch(
            error => {
              // console.log(error.response)
              // console.log(error.response)
              // if(error.response !== undefined){

              //   if(error.response.status === 422){
              //     if(error.response.data.errors.email){
              //       toast.error(error.response.data.errors.email[0], {
              //           className: css({
              //             background: "#00FF00 !important",
              //             color: "white !important",
              //             fontWeight: "bold"
              //           }),
              //           position: toast.POSITION.TOP_CENTER,
              //         });
              //     }
              //     else if(error.response.data.errors.password){
              //       toast.error(error.response.data.errors.password[0], {
              //           className: css({
              //             background: "#00FF00 !important",
              //             color: "white !important",
              //             fontWeight: "bold"
              //           }),
              //           position: toast.POSITION.TOP_CENTER,
              //         });
              //     }
              //   }

              // }
            }
        )

    }

}


export function deleteUserSkill () {
  
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
       return axiosInstance.delete('skill/detachskilltouser').then ( async (response) => {
         console.log('user skill response', response)
        //  if (response.data.success === true){
        //    await dispatch({ type: "ADD_USER_SKILL", payload: response.data.skills })
        //  }
        })
        .catch(
            error => {
              // console.log(error.response)
              // console.log(error.response)
              // if(error.response !== undefined){

              //   if(error.response.status === 422){
              //     if(error.response.data.errors.email){
              //       toast.error(error.response.data.errors.email[0], {
              //           className: css({
              //             background: "#00FF00 !important",
              //             color: "white !important",
              //             fontWeight: "bold"
              //           }),
              //           position: toast.POSITION.TOP_CENTER,
              //         });
              //     }
              //     else if(error.response.data.errors.password){
              //       toast.error(error.response.data.errors.password[0], {
              //           className: css({
              //             background: "#00FF00 !important",
              //             color: "white !important",
              //             fontWeight: "bold"
              //           }),
              //           position: toast.POSITION.TOP_CENTER,
              //         });
              //     }
              //   }

              // }
            }
        )

    }

}
