import React, {useState} from 'react';
import './EditGalleryForm.styles.css';
import GigsInput from '../../../../../components/GigsInput/GigsInput.component';
import info from '../../../../../assets/image/info.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { editGallery } from '../../../../../store/action/creategigAction/gallery';

const EditGalleryForm = ({backFirstStep, param}) => {

    const gig = useSelector(state=>state.createGigReducer.gig)
    const [gigImg, setGigImg] = useState(null);
    const [gigpic, setGigPix] = useState(null);
    const [loading, setLoading] = useState(false)

    const histroy = useHistory()
    const dispatch = useDispatch()

    const handleChangeImage = (e)=>{
        let img = e.target.files[0];
        setGigImg({...gigImg, img: img, gigId:gig.id})
        setGigPix(URL.createObjectURL(img))
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        console.log('gig image', gigImg)
        const data = {
            img: gigImg,
            gigId : gig.id
        }
        console.log('parammmmm', param)
        setLoading(true);
        await dispatch(editGallery(gigImg, param));
        setLoading(false);
        histroy.push('/publicProfile')
    }
    return (

        <div>
        
            <form >
            <div className="form-group-gigs">
            <GigsInput
            label="Upload image.Format- png,jpeg,mp3,mp4(Not greater than 15MB)"
            name="img"
            onChange={handleChangeImage}
            type="file"/>
            </div>
            <div className="image-box">
            <div className="box-1">
            {/* <p>Browser to upload image</p> */}
            {
            gigpic===null ?
            <p>Browser to upload image</p> :
            <img className="img-fluid" style={{height:"auto",width:"100%"}} src={gigpic} alt="elipse"/>
        }
            </div>
            {/* <div className="box-2">
            <p>Browser to upload image</p>
            </div>
            <div className="box-3">
            <p>Browser to upload image</p>
            </div> */}       
    
            </div>
            <div className="gigs-button">
            <button onClick={backFirstStep} type="button">Back</button>
            <button type="submit" onClick={handleSubmit}
            >Update portfolio
            </button>
            </div>
            </form>
    
            </div>
    )
}


export default EditGalleryForm;