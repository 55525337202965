import React, { useState, useEffect } from 'react';
// import ProjectTrackerTable from '../../../../components/Hunter/ProjectTracker/ProjectTrackerTable/ProjectTrackerTable.component';
import './ActiveProject.styles.css';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProjectTrackerTable from '../../../components/Hunter/ProjectTracker/ProjectTrackerTable/ProjectTrackerTable.component';
import ActiveProjectTable from '../../../components/Hunter/JobsTable/ActiveProjectTable';



const ActiveProjects = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();


  return (
    <div className='Projects_wrapper container-fluid'>
      <h3>List of active projects</h3>

      <div className='projects_button d-flex justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='projects_button--back'
          type='button'
        >
          Go back
        </button>
        {/* <button
          onClick={handleShow}
          type='button'
          className='projects_button--add'
        >
          Add Job
        </button> */}
      </div>

      {/* Table */}
      {/* Pass all the important modal method as props to the child(table)*/}
      <ActiveProjectTable
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </div>
  );
};

export default ActiveProjects;
