// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    userGigs:[],
    gig:null,
    faq:[],
    questions:[],
    scoscopepackagepe:[],
    editFaq:[],
    gigcreated: null
}

export const createGigReducer =(state=initialState, action)=>{
    if(action.type === "ADD_GIG_OVERVIEW"){
        console.log(action.payload)
        toast.success("Saved",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
        return Object.assign({}, state, {
            userGigs: [...state.userGigs, action.payload ],
            gig:action.payload
        });
    }
    // ADD_GIG_CREATED

    if(action.type === "ADD_GIG_CREATED"){
        console.log(action.payload)
        toast.success("Saved", {delay:2000},
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
        return Object.assign({}, state, {
           
            gigcreated:action.payload
        });
    }
    else if(action.type === "ADD_GIG_QUESTION"){
        console.log(action.payload)
        // console.log(state.gig)
        const newObj = {...state.gig, question:action.payload}
        toast.success("Saved",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
        // console.log(newObj)
        return Object.assign({}, state, {
            gig: newObj
        });
    }

    else if(action.type === "ADD_GIG_FAQ"){
        console.log(action.payload)
        toast.success("Saved",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
        // console.log(state.gig)
        if(state.gig.faq === undefined){
            const newObj = {...state.gig, faq:[action.payload]}
            console.log(newObj)
            return Object.assign({}, state, {
                gig: newObj,
                faq : [...state.faq,action.payload]
            });

        }else{
            const newObj = {...state.gig, faq:[...state.gig.faq,action.payload]}
            console.log(newObj)
            return Object.assign({}, state, {
                gig: newObj,
                faq : [...state.faq,action.payload]
            });
        }
    }

    else if(action.type === "ADD_EDIT_GIG_FAQ"){
        console.log(action.payload)
        console.log(state.faq)
        return Object.assign({}, state, {
            editFaq : action.payload.faqs
        });
    }
    
    else if(action.type === "EDIT_GIG_FAQ"){
        console.log(action.payload)
        toast.success("Edited",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )

        // const data = state.gig.faq.map(each => {
        //     if (each.id === action.payload.id ){
        //         return action.payload 
        //     }
        //     return each
        // })
        console.log(state.faq)
        return Object.assign({}, state, {
            editFaq: state.editFaq.map(each => {
                if (each.id === action.payload.id){
                    return action.payload
                }
                return each
            })
 
        });
    }
    
    else if(action.type === "ADD_GIG_PRICE_SCOPE"){
        console.log(action.payload)
        // console.log(state.gig)
        toast.success("Saved",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
        // if(state.gig.faq === undefined){
            const newObj = {...state.gig, scoscopepackagepe:action.payload}
            console.log(newObj)
            return Object.assign({}, state, {
                gig: newObj,
                scopepackage:action.payload
                // userGigs: state.userGigs.map(each => {
                //     if (each.id === )
                // })
            });

        // }
        // else{
        //     const newObj = {...state.gig, faq:[...state.gig.faq,action.payload]}
        //     console.log(newObj)
        //     return Object.assign({}, state, {
        //         gig: newObj
        //     });
        // }
    }
    else if(action.type === "GET_ALL_USER_GIGS"){
        console.log(action.payload)
            return Object.assign({}, state, {
                userGigs: action.payload,
                // scopepackage: action.payload.
            });
    }

//     else if(action.type === "GET_USER_LANGUAGES"){
//         console.log(action.payload)
//         return Object.assign({}, state, {
//             userLanguages: action.payload.languages 
//         });
//     }

    else if(action.type === "DELETE_GIG"){
        console.log(action.payload);
        console.log('inside gig state', state)
         const newArray =state.userGigs.filter((each,i)=>each.id !== action.payload)
         console.log('new array passed in gig', newArray)
         toast.info("Deleted Successfully..",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
        return Object.assign({}, state, {
            userGigs: newArray,

        });
    }



//     else if(action.type === "EDIT_USER_LANGUAGE"){
//         // action.payload.data 
//         toast.info("Language Profile Edited Successfully.",
//        {
//            position: toast.POSITION.BOTTOM_CENTER}
//            )
//        return Object.assign({}, state, {
//         userLanguages: state.userLanguages.map(each => {
//                if (each.id === action.payload.languageId ){
//                    return action.payload 
//                }
//                return each
//            })

//        });
//    }
 

    return state;
}
