import React from 'react';
import NavLanding from '../../../components/Navs/NavLanding/NavLanding.component';
import './SearchGigResult.styles.css';
import cardImage from '../../../assets/image/card-image.svg';
import profile from '../../../assets/image/profile.svg';
import star from '../../../assets/image/star.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { getGigDetailAction } from '../../../store/action/searchAction/searchGigAction';
// import { useSelector } from 'react-redux';
import TalentFooter from '../../../components/Footer/TalentFooter/Footer.component';

const SearchGigResult = () => {
  const SearchGigReducer = useSelector(
    (state) => state.SearchGigReducer.searchResult
  );
  const SearchTitle = useSelector(
    (state) => state.SearchGigReducer.searchTitle
  );
  const dispatch = useDispatch();
  let params = useParams();
  let histroy = useHistory();
  console.log(SearchGigReducer);
  console.log(SearchTitle);
  // console.log(params.search_query)

  const handleClickGig = async (id) => {
    await dispatch(getGigDetailAction(id));
    histroy.push('/gigDetails');
  };

  return (
    <section className='searchgig_result'>
      <NavLanding />
      <h3>
        Here is the result for <span>"{params.search_query}"</span>
      </h3>
      {/* Results card */}
      <div className='container-fluid'>
        <div className='row card_row justify-content-evenly'>
          {SearchGigReducer.map((each, id) => {
            if (each.userdetail !== null) {
              return (
                <div
                  key={id}
                  className='col-md-4 card_row--main'
                  //   onClick={() => handleClickGig(each.id)}
                >
                  <Link to={`/gigDetails/${each.id}`}>
                    <img
                      className='img-fluid img-gig'
                      src={cardImage}
                      alt='cardImg'
                    />
                    <div className='profile_talent'>
                      <p>
                        {each.userdetail.firstname} {each.userdetail.lastname}
                      </p>
                    </div>
                    <div className='gig_copy'>
                      <h5>{each.title}</h5>
                      <hr className='gig_line' />
                      {/* <div className='rating_result d-flex
                        align-items-baseline'>
                            <img className='img-fluid'
                            src={star} alt="starRating"/>
                            <p>5.0(120)</p>
                        </div> */}
                    </div>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      </div>
      <TalentFooter />
    </section>
  );
};

export default SearchGigResult;
