import React, {useEffect, useState} from 'react';
import './TalentEarning.styles.css';
import {RiPaypalFill} from 'react-icons/ri';
import {GiCircleClaws} from 'react-icons/gi'
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import TalentFooter from '../../../components/Footer/TalentFooter/Footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { GetEarning } from '../../../store/action/talent/talentPayment';
import Loader from 'react-loader-spinner';
import moment from 'moment';


const TalentEarning = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    useEffect(async () => {
        setLoading(true)
        await dispatch(GetEarning());
        setLoading(false)
    }, [])

    const earnings = useSelector(state => state.talentPaymentReducer.getearnings);
    console.log('earninggggs', earnings)
  return (
    <>
     <NavTalent />
    
     {loading ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) 
      :
        <>
        <div className='earning'>
       
        <div className='earning__head'>
            <div className='earning__head--left'><h1>Earning</h1></div>
            <div className='earning__head--right'><p>Expected earning: <span>{`# ${earnings?.pending}`}</span></p></div>
        </div>

        <div className='earning__summarycontainer'>
            <div className='earning__summary'>
                <div className='earning__summary--title'><h6>Net Income</h6></div>
                <div className='earning__summary--value'><p>{`# ${earnings?.netincome}`}</p></div>
            </div>
            <div className='earning__summary'>
                <div className='earning__summary--title'><h6>Withdrawn</h6></div>
                <div className='earning__summary--value'><p>{`# ${earnings?.withdrawn}`}</p></div>
            </div>
            <div className='earning__summary'>
                <div className='earning__summary--title'><h6>Pending Clearance</h6></div>
                <div className='earning__summary--value'><p>{`# ${earnings?.pending}`}</p></div>
            </div>
            <div className='earning__summary'>
                <div className='earning__summary--title'><h6>Available for withdrawl</h6></div>
                <div className='earning__summary--value'><p>{`# ${earnings?.available}`}</p></div>
            </div>
        </div>

        <div className='earning__table'>
            <table>
                <thead className='earning__table--head'>
                    <tr>
                        <th>DATE</th>
                        <th>FOR</th>
                        <th>STATUS</th>
                        <th>AMOUNT</th>
                    </tr>
                </thead>
                <tbody className='earning__table--body'>
                    {
                        earnings?.alltransactions.map(earning => {
                       return <>     
                        <tr>
                        <td>{moment(earning?.created_at).format('ll')}</td>
                        <td>{earning.job?.job_description}</td>
                        <td>{earning?.status}</td>

                        <td>{`#${earning.job?.net_pay}`}</td>
                        </tr>
                    </>
                        })
                    }
                    
                </tbody>
            </table>

        </div>
        
    </div> 
    </>
}
    <TalentFooter />
     
    </>
  )
}

export default TalentEarning