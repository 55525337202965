import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import axios from 'axios';

export function postgallery (userDetails,gigId) {
      return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
          console.log(userDetails)
          const formData = new FormData()
          formData.append('profile_image',userDetails)
          formData.append('gigId',gigId)
          return axiosInstance.post('gallery/store' ,formData).then ( async (response) => {
            console.log('posted gallery gig button', response);
          if(response.data.success === true){
            await dispatch({ type: "ADD_GIG_CREATED", payload: response.data })
          }
          })
          .catch(
              error => {
                console.log(error.response)
                console.log(error)
              }
          )
  
      }
  
  }




  // gallery update


  export function editGallery (gigImg,gigId) {
    console.log('in the action', gigImg)
    const formData = new FormData()
    formData.append('img', gigImg.img)
    formData.append('gigId', gigImg.gigId)

    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  // 'Content-Type': 'application/json', 
                  //  'Accept': 'application/json',
                  'Content-Type': 'multipart/form-data', 
                   'Accept': 'multipart/form-data',
                  //  'X-CSRF-TOKEN': " ",
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
        
        return axiosInstance.post(`gallery/${gigId}`, formData).then ( async (response) => {
          console.log('gallery edit response', response)
        if(response.data.success === true){
          // console.log(response)
          // await dispatch({ type: "ADD_GIG_OVERVIEW", payload: response.data.gig })
        }
        })
        .catch(
            error => {
              console.log(error.response)
              console.log(error)
            }
        )

    }

}

