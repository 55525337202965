import axios from 'axios';
import { store } from '../reduxStore/store';

export const axiosInstance = axios.create({
  // baseURL : "https://purple-africa.herokuapp.com/api/",
  baseURL: 'https://api.solvateafrica.com/api/',
  timeout: 200000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    //    'X-CSRF-TOKEN: " "'
    Authorization: `Bearer ${store.getState().authReducer.token}`,
  },
});
const PATH = 'https://api.solvateafrica.com/api/';
export { PATH };
// https://api.solvateafrica.com/api/
// http://hotelanywhere.ng/solvateafrica/api/