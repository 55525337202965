
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const initialState = {
 getearnings: null,
 getbanks:[],
 getbankdetails:null,
 transferReceipt: null,
 initiateTransfer: null,
 initiateHunterTransfer: null,
 error:null
};

export const TalentPaymentReducer = (state = initialState, action) => {

  if (action.type === 'GET_BANKS') {
    return Object.assign({}, state, {
      getbanks: action.payload,
    });
  }
  else if(action.type === 'GET_VERIFYBANKDETAILS'){
    toast.success('bank details submitted',
    {
        
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'newTest',
            bodyClassName: "grow-font-size",
            progressClassName: 'fancy-progress-bar',
            timeout:10000
        }
        )
        
    return Object.assign({}, state, {
        getbankdetails: action.payload,
    });
}

    else if(action.type === 'POST_TRANSFERRECEIPT'){

        toast.success('details submitted',
    {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'newTest',
            bodyClassName: "grow-font-size",
            progressClassName: 'fancy-progress-bar',
            timeout:5000
        }
        )
        
    return Object.assign({}, state, {
        transferReceipt: action.payload,
    });
}


else if(action.type === 'INITIATE_TRANSFER'){
  
    toast.success(action.payload.message,
{
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'newTest',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        timeout:20000
    }
    )
    
return Object.assign({}, state, {
    initiateTransfer: action.payload,
});
}
// "INITIATE_TRANSFER_ERROR"

else if(action.type === 'INITIATE_TRANSFER_ERROR'){
  
    toast.error("withdrawl not successful",
{
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'newTest',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        timeout:20000
    }
    )
    
// return Object.assign({}, state, {
//     initiateTransfer: action.payload,
// });
}

else if(action.type === 'INITIATE_HUNTER_TRANSFER'){
  
    toast.success(action.payload.message,
{
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'newTest',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        timeout:20000
    }
    )
    
return Object.assign({}, state, {
    initiateHunterTransfer: action.payload,
});
}
// INITIATE_HUNTER_ERROR_TRANSFER

else if(action.type === 'INITIATE_HUNTER_ERROR_TRANSFER'){
  
    toast.error(action.payload.message,
{
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'newTest',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        timeout:20000
    }
    )
    
return Object.assign({}, state, {
    error: action.payload,
});
}
else if(action.type === 'GET_EARNING'){
   
    toast.success(action.payload.message,
{
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'newTest',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        timeout:20000
    }
    )
    
    return Object.assign({}, state, {
    getearnings: action.payload,
}); 
}

else if(action.type === 'PAYMENT_ERROR'){
  
    toast.error('Payment not successful',
{
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'newTest',
        bodyClassName: "grow-font-size",
        progressClassName: 'fancy-progress-bar',
        timeout:20000
    }
    )
    
// return Object.assign({}, state, {
//     initiateTransfer: action.payload,
// });
}
  return state;
};
