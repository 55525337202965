import React from 'react';
import './InviteTalentModalHunter.styles.css';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/image/close.svg';





const InviteTalentModalHunter = ({ show, handleClose }) => {


    return (
        
        <div>
        <Modal 
        size="md"
        show={show} 
        onHide={handleClose}
        dialogClassName="modal-500w"
        >
        <div className="title-cus">
        <img src={close} alt="closeimage"
        onClick={handleClose} />
       <h3>Invite a Creative talent for the project.</h3>
        </div>
        <Modal.Body>
        <form className="form-update">
        
        <div className="form_job">
         <label>Select the creative talent:</label><br/>
         <select name="price">
         <option value="johndoe">John doe</option>
         <option value="afolabi">Afolabi</option>
         </select>
        </div>
        <div className='job_button text-center'>
        <button type="submit">
         Invite talent
          </button>
        </div>
        </form>
        </Modal.Body>
        </Modal> 
        </div>
    )
}


export default InviteTalentModalHunter;