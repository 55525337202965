import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './MessageList.styles.css';
import profileImage from '../../../assets/image/message_profile_pic.png';
import { GetConversations } from '../../../store/action/hunter/hunterMessages';
import moment from 'moment';
import {Link, Route, BrowserRouter, Switch} from 'react-router-dom';
import {IoSearchOutline} from 'react-icons/io5'; 
import {BiMessageSquareDetail} from 'react-icons/bi';
import MessageDetails from '../../../pages/Talent/Message/MessageDetails/MessageDetails.component';

const MessageTalentList = () => {
    const dispatch = useDispatch();
    useEffect(() => {
       dispatch(GetConversations())
   }, []);

const sentMessages = useSelector(state => state.hunterReducer.getConversations);
console.log('song messagesss', sentMessages)
   
    return (
        <>
        <BrowserRouter>
          <section className='messages'>
              <section className='messagestalent__listcontainer'>
              {/* <form className='messages__form'>
                  <input type='search' placeholder='message' />
                  <span><IoSearchOutline /></span>
              </form> */}
              <ul className='messages__list'>
                  {sentMessages.length === 0 ?  <div className='nomessage'>No message available</div>
                  : sentMessages.map((message) => {
  
                      return  <Link to={`/messagedashboard/talentMessageDetails/${message.anotheruser}`}>
                          <li className='messages__item'>
                          <div className='messages__itemcontent'>
                              <div className='messages__userimage'>
                              <img src={profileImage} alt='profileIcon'/>
                               </div>
                              <div className='messages__preview'>
                              <p>{message.username}</p>
                              <p>{message.message}</p>
                              </div>
                          </div>
                          <div className='messages__date'>
                          <p>{moment(message?.created_at).format('LLL')}</p>
                          </div>
                      </li>
                      </Link>
                  }
                )}
                  
              </ul>
              </section>
              <section className='messagestalent__conversationcontainer'>
              
               <Switch>
              <Route exact path='/messagedashboard' render={() =>
                   <div className='messages__content'>
                  <BiMessageSquareDetail />
                  </div>} 
              />
              <Route exact path='/messagedashboard/talentMessageDetails/:id' component={MessageDetails} />
               </Switch>
              </section>
                </section>
               </BrowserRouter>
      </>
        
    )
}


export default MessageTalentList;