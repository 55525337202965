import React, { useEffect, useState } from 'react';
// import ProjectTrackerTable from '../../../../components/Hunter/ProjectTracker/ProjectTrackerTable/ProjectTrackerTable.component';
import './SubmittedProject.styles.css';
import { useHistory } from 'react-router-dom';
// import ProjectTrackerTable from '../../../components/Hunter/ProjectTracker/ProjectTrackerTable/ProjectTrackerTable.component';
// import ActiveProjectTable from '../../../components/Hunter/JobsTable/ActiveProjectTable';
// import CompletedProjectTable from '../../../components/Hunter/JobsTable/CompletedProjectTable';
import SubmittedOrderHunterTable from '../../../components/Hunter/SubmittedOrderTable/SubmittedOrderHunterTable.component';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubmittedOrder } from '../../../store/action/talent/applyJobAction';
import Loader from 'react-loader-spinner';

const SubmittedProject = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const user = useSelector((state) => state.authReducer.user);
  const getSubmittedOrder = useSelector(
    (state) => state.talentApplyForJobReducer.getSubmittedOrder
  );
  console.log('submitted rder pre',getSubmittedOrder);
  const history = useHistory();
  useEffect(() => {
    getTalentSubmittedOrder();
    return () => {};
  }, []);
  const getTalentSubmittedOrder = async () => {
    setIsFetching(true);
    await dispatch(getAllSubmittedOrder(user.id));
    setIsFetching(false);
    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };

  return (
    <div className='Projects_wrapper container-fluid'>
      <h3>List of submitted projects</h3>

      <div className='projects_button d-flex justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='projects_button--back'
          type='button'
        >
          Go back
        </button>
        {/* <button
          onClick={handleShow}
          type='button'
          className='projects_button--add'
        >
          Add Job
        </button> */}
      </div>

      {/* Table */}
      {/* Pass all the important modal method as props to the child(table)*/}
      {isFetching ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        <SubmittedOrderHunterTable getSubmittedOrder={getSubmittedOrder} />
      )}
    </div>
  );
};

export default SubmittedProject;
