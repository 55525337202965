import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getOpenJobWithBid,
  getScoutOpenJobs,
} from '../../../store/action/hunter/postJob';
import MainTalentBidTable from './MainTalentBidTable';
import './TalentBid.styles.css';
// import TalentBidTable from './TalentBidTableComponent'

const MainTalentBid = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getOpenJobs();
    return () => {};
  }, []);

  const getOpenJobs = async () => {
    // await dispatch(getTalentCategory())
    await dispatch(getOpenJobWithBid());
    // console.log(getScoutOpenJob)
    // setOpenJobs(getScoutOpenJob)
  };

  return (
    <section className='dashboard_home'>
      <div className='bid_description'>
        <h4>List of Open jobs with bids</h4>
      </div>

      <div className='table_wrapper'>
        <MainTalentBidTable />
      </div>
    </section>
  );
};

export default MainTalentBid;
