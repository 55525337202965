import React, { useEffect, useState } from 'react';
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import './Order.styles.css';
import Tab from 'react-bootstrap/Tabs';
import JobRequestTable from '../../../components/Talents/JobRequestTable/JobRequestTable.component';
import { useDispatch, useSelector } from 'react-redux';
import { getAllJobForTalent } from '../../../store/action/hunter/postJob';
import Loader from 'react-loader-spinner';
import PendingOrderTable from '../../../components/Talents/PendingOrderTable/PendingOrderTable.component';
import TalentFooter from '../../../components/Footer/TalentFooter/Footer.component';
import {
  getACtiveOrder,
  getAllIncomingOrder,
  getAllSubmittedOrder,
  getUserCompletedOrder,
} from '../../../store/action/talent/applyJobAction';
import { getAllBanks } from '../../../store/action/talent/talentPayment';
import ActiveOrderTable from '../../../components/Talents/ActiveOrderTable/ActiveOrderTable.component';
import SubmittedOrderTable from '../../../components/Talents/SubmittedOrderTable/SubmittedOrderTable.component';
import CompletedProjectTalentTable from '../../../components/Talents/SubmittedOrderTable/CompletedProjectTalentTable';

const TalentOrders = () => {
  const dispatch = useDispatch();
  const gethHunterOpenJobs = useSelector(
    (state) => state.scoutJobReducer.gethHunterOpenJobs
  );
  const getPendingOrder = useSelector(
    (state) => state.talentApplyForJobReducer.getPendingOrder
  );
  const getActiveOrders = useSelector(
    (state) => state.talentApplyForJobReducer.getActiveOrder
  );
  const getSubmittedOrder = useSelector(
    (state) => state.talentApplyForJobReducer.getSubmittedOrder
  );
  const getCompletedJob = useSelector(
    (state) => state.talentApplyForJobReducer.getCompletedJob
  );

  const getBanks = useSelector(
    (state) => state.talentPaymentReducer.getbanks
  );
  
  const user = useSelector((state) => state.authReducer.user);
  const [openJobs, setOpenJobs] = useState(gethHunterOpenJobs);
  const [isFetching, setIsFetching] = useState(false);
  // console.log(gethHunterOpenJobs);
  // console.log('get submitter on', getSubmittedOrder);

  useEffect(async () => {
    getOrder();
    getActiveOrder();
    getTalentSubmittedOrder();
    getTalentCompletedOrder();
   await dispatch(getAllBanks());
    return () => {};
  }, []);

  const getOrder = async () => {
    setIsFetching(true);
console.log('user in', user)
    await dispatch(getAllIncomingOrder(user.id));
    setIsFetching(false);

    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };
  const getActiveOrder = async () => {
    setIsFetching(true);
    await dispatch(getACtiveOrder(user.id));
    setIsFetching(false);
    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };

  const getTalentSubmittedOrder = async () => {
    setIsFetching(true);
    await dispatch(getAllSubmittedOrder(user.id));
    setIsFetching(false);
    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };
  const getTalentCompletedOrder = async () => {
    setIsFetching(true);
    await dispatch(getUserCompletedOrder());
    setIsFetching(false);
    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };
  console.log('get all bank in order component', getBanks);

  return (
    <div className='job_request'>
      <NavTalent />
      <h3>Orders</h3>

      {/* The content */}
      {isFetching ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        <div className='tab_job_wrapper'>
          <Tab
            defaultActiveKey='home'
            id='uncontrolled-tab-example'
            className='mb-3'
          >
            <Tab eventKey='home' title='Pending'>
              {/* Table presentational component */}
              {openJobs === null ? (
                <div className='d-flex justify-content-center'>
                  <Loader type='Rings' color='blue' height={50} width={50} />
                </div>
              ) : (
                <PendingOrderTable getPendingOrder={getPendingOrder} />
              )}
            </Tab>
            {/*Description tab*/}
            <Tab eventKey='active_order' title='Active'>
              <ActiveOrderTable getActiveOrders={getActiveOrders} />

              {/*Form*/}
            </Tab>

            <Tab eventKey='submitted_order' title='Submitted'>
              <SubmittedOrderTable getSubmittedOrder={getSubmittedOrder} />

              {/*Form*/}
            </Tab>

            <Tab eventKey='completed_order' title='Completed'>
              <CompletedProjectTalentTable getCompletedJob={getCompletedJob} getBanks={getBanks} />
              {/*Form*/}
            </Tab>
          </Tab>
        </div>
      )}

      {/* Footer */}
      <TalentFooter />
    </div>
  );
};

export default TalentOrders;
