import React from 'react';
import './CreateProjectHunterModal.styles.css';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/image/close.svg';



const CreateProjectHunterModal = ({ show, handleClose }) => {

    return (
        <div>
    <Modal 
        size="md"
        show={show} 
        onHide={handleClose}
        dialogClassName="modal-500w"
        >
        <div className="title-cus">
        <img src={close} alt="closeimage"
        onClick={handleClose} />
       <h3>To create a project, fill the form.</h3>
        </div>
        <Modal.Body>
        <form className="form-update">
        <div className="form_job">
         <label>Enter project name:</label><br/>
         <input
          type="text"
          name="project_name"
          />
        </div>
        <div className="form_job">
         <label>Enter Start date:</label><br/>
         <input
          type="date"
          name="project_name"
          />
        </div>
        <div className="form_job">
         <label>Enter End date:</label><br/>
         <input
          type="date"
          name="project_name"
          />
        </div>
        <div className="form_job">
         <label>Select the creative talent:</label><br/>
         <select name="price">
         <option value="johndoe">John doe</option>
         <option value="afolabi">Afolabi</option>
         </select>
        </div>
        <div className='job_button text-center'>
        <button type="submit">
         Create project
          </button>
        </div>
        </form>
        </Modal.Body>
        </Modal> 
        </div>
    )
}


export default CreateProjectHunterModal;