import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import axios from 'axios';

export function getTalentCategory() {
    return function(dispatch){
          const axiosInstance = axios.create({
              baseURL : PATH,
              timeout: 200000,
              headers: {
                        'Content-Type': 'application/json', 
                         'Accept': 'application/json',
                      //    'X-CSRF-TOKEN: " "'
                         'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                      }
          })
         return axiosInstance.get('category/categories').then ( async (response) => {
        //    console.log(response.data)
              await dispatch({ type: "GET_TALENT_CATEGORIES", payload: response.data })
          })
          .catch(
              error => {
                // console.log(error.response)
                // console.log(error.response)
  
              }
          )
  
      }
  
  }