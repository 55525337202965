import React, {useState, useEffect} from 'react';
import { useRef } from 'react';
import './NavLanding.styles.css';
import { Link } from 'react-router-dom';
import logo from '../../../assets/image/brand-logo.svg';
import profile from '../../../assets/image/profile.svg';

const NavLanding = () => {

  // const [isHovering, setIsHovering] = useState(false);
const [open, setOpen] = useState(false);
const ref = useRef(null);


  // const handleMouseOver = () => {
  //   setIsHovering(true);
  // };

  // const handleMouseOut = () => {
  //   setIsHovering(false);
  // };
    const handleSignOut = ()=>{
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      }
      useEffect(() => {
        const checkIfClickedOutside = e => {
          if (open && ref.current && !ref.current.contains(e.target)) {
            setOpen(false)
          }}
    
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [open])


    return (

        <header className='homenav_landingheader'>
      <nav className="navbar nav_wrapper navbar-expand-lg navbar-light">
      <div className="container-fluid">
      <button className="navbar-toggler" type="button" 
                data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03"
                aria-controls="#navbarTogglerDemo03" aria-expanded="false" 
                aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
     <img src={logo} alt="BrandLogo"/>
     {/* <input 
      className='nav_input'
      type="search"
      placeholder="search for gigs"
     /> */}
     <div className="collapse navbar-collapse " id="navbarTogglerDemo03">
     <ul className="links_wrapper navbar-nav ms-auto mb-2 mb-lg-0">
     {/* <li className="nav-item">
     
     </li>
     <li className="nav-item">
     <Link className="nav-link" to='#'>Find talent</Link>
     </li>
     <li className="nav-item">
     <Link className="nav-link " to='#'>Find gig</Link>
     </li>
     <li className="nav-item">
     <Link className="nav-link " to='#'>Explore</Link>
     </li>
     <li className="nav-item">
     <Link className="nav-link " to='#'>Orders</Link>
     </li>
     <li className="nav-item">
     <Link className="nav-link " to='#'>Messages</Link>
     </li> */}
     <li className="nav-item">
     <Link className="nav-link " to='/login'>Login</Link>
     </li>
     <li className="nav-item img-profile">
     </li>
{/* <li className="profile_drop btn-group nav-item" style={{cursor: "pointer"}}>
<img className="dropdown-toggle img-fluid" src={profile} alt="profileImage"
data-bs-toggle="dropdown" aria-expanded="false"/> 
  <ul className="dropdown-menu">
    <li><Link className="dropdown-item" to="#">Dashboard</Link></li>
    <li><Link className="dropdown-item" to="#">Profile</Link></li>
    <li><Link className="dropdown-item" to="#">Become a Creative hunter</Link></li>
    <li><Link className="dropdown-item" to="#">Settings</Link></li>   
    <li><hr className="dropdown-divider"/></li>
    <li><Link className="dropdown-item" to="#" onClick={handleSignOut}>Logout</Link></li>
  </ul>
</li> */}
<li className="profile_drop btn-group nav-item registration__options" 
 style={{cursor: "pointer"}} ref={ref}
>
{/* <Link to='/signup'> */}
<button type='button'  className='nav_button' onClick={() => setOpen(oldState => !oldState)}>
  Get started
  </button>
{/* </Link> */}

{open && (
          <ul className='list-showup'>
           <li className='list-showup-item'>
            <Link to='/signup' onClick={() => setOpen(oldState => !oldState)}>As Hunter</Link>
            </li>
           <li className='list-showup-item'>
            <Link to ='/signup'>As Talent</Link>
            </li>
          </ul>
        )}
        </li>
     </ul>
     
     </div>
       </div>
      </nav>
        </header>
    )
}


export default NavLanding;