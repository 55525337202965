import React, { useEffect, useState } from 'react';
// import './ProjectTrackerTable.styles.css';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {SiSamsungpay} from 'react-icons/si';
import '../../../pages/Hunter/PendingProject/PendingProject.styles.css';
// import { MdVisibility } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingOrder} from '../../../store/action/talent/applyJobAction';
import moment from 'moment';
// import ProjectTrackerJobsModal from '../ProjectTrackerJobsModal/ProjectTrackerJobsModal.component';

const PendingProjectTable = ({ show, handleClose, handleShow }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);

  const getPendingOrders = useSelector(
    (state) => state.talentApplyForJobReducer.getHunterPendingOrder
  );
  const [isFetching, setIsFetching] = useState(false);
  useEffect(async() => {
    setIsFetching(true);
    await dispatch(getPendingOrder());
    setIsFetching(false);
    
    return () => {};
  }, []);

//   const getActiveOrder = async () => {
//     setIsFetching(true);
//     await dispatch(getACtiveOrder(user.id));
//     setIsFetching(false);
//   };

  return (
    <section>
      {isFetching ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        <Table className='table_tracker'>
          <thead>
            <tr>
              <th className='active_head_bg'>#</th>
              <th className='active_head_bg'>Title</th>
              <th className='active_head_bg'>Job Description</th>
              {/* <th className='active_head_bg'>Milestone</th> */}
              <th className='active_head_bg'>Due On</th>
              <th className='active_head_bg'>Status</th>
              <th className='active_head_bg'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {getPendingOrders && getPendingOrders.map((each, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  {/* <td>{each?.user.name}</td> */}
                  <td>{each?.title}</td>
                  <td dangerouslySetInnerHTML={{__html: each?.job_description}}>
                    </td>
                  {/* <td>{each.milestone}</td> */}
                  <td>{moment(each.end_date).format('ll')}</td>
                  <td>
                    {Number(moment(Date()).format('l').split('/')[0]) >=
                      Number(moment(each.end_date).format('l').split('/')[0]) &&
                    Number(moment(Date()).format('l').split('/')[1]) >
                      Number(moment(each.end_date).format('l').split('/')[1]) &&
                    Number(moment(Date()).format('l').split('/')[2]) >=
                      Number(
                        moment(each.end_date).format('l').split('/')[2]
                      ) ? (
                      <div
                        style={{
                          backgroundColor: 'rgba(2, 0, 121, 0.65)',
                          color: 'red',
                        }}
                      >
                        Late Delivery
                      </div>
                    ) : (
                      each.status
                    )}
                  </td>
                  <td>
                   { 
                    each?.order_accepted === 1 ? 
                    <>
                      {['top'].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              make payment
                            </Tooltip>
                          }
                        >
                           <Link to={`/hunterDashboard/paymentbid/${each.bid_id}`}> 
                           <button className='projects_button--back'
                        type='button'
                            >
                      Payment
                      </button>
                          </Link>
                        </OverlayTrigger>
                      ))}
                    </> : 
                    <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            order is yet to be  accepted by talent
                          </Tooltip>
                        }
                      >
                         <Link to={`/hunterDashboard/paymentbid/${each.bid_id}`}> 
                         <button
                     className= 'disabling'
                     type='button'
                     disabled={true}
                            >
                       Payment
                        </button>
                          {/* <SiSamsungpay className='view_icon_tracker' /> */}
                        </Link>
                      </OverlayTrigger>
                    ))}
                  </>
            }
                  </td>
                </tr>
              );
            })}
          </tbody> 
        </Table>
      )}
    </section>
  );
};

export default PendingProjectTable;
