// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    hunterAgreement:[],
    bidForPayment:null,
    hunterWithdrawlCheck: null
}

export const agreementReducer = (state=initialState, action) => {
    // action type here
    const INITIATE_AGREEMENT = 'INITIATE_AGREEMENT';
    
    if(action.type === INITIATE_AGREEMENT){
        console.log('Successful')
        toast.success("Successful..!",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
            // console.log(action.payload.data)
        return Object.assign({}, state, {
            hunterAgreement: [...state.hunterAgreement, action.payload.data],

        });
    }
    else if(action.type === 'GET_EACH_BID_OFFER'){
        
            // console.log(action.payload.data)
        return Object.assign({}, state, {
            bidForPayment: action.payload,

        });
    }
    else if(action.type === 'GET_HUNTER_WITHDRAWL'){
        return Object.assign({}, {
            hunterWithdrawlCheck: action.payload
        })
    }

    return state
}