import React, { useEffect, useState } from 'react';
import './UpdateProfile.styles.css'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import SelectInput from '../../../components/SelectInput/SelectInput.component';
import coloredIndicator from '../../../assets/image/personal-line.svg';
import indicator from '../../../assets/image/indicator.svg';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import { allState, country } from '../../../store/action/authAction/countryandstate';
import * as yup from 'yup';
import { updateProfile } from '../../../store/action/authAction/updateProfile';
import Loader from 'react-loader-spinner';
import TalentFooter from '../../../components/Footer/TalentFooter/Footer.component';
import elipse from '../../../assets/image/elipse.svg';


const VerifyAccount = () =>{
    const [loading, setLoading] = useState(false)
    // const [countryId, setCountryId] = useState()
    const countries = useSelector(state=>state.countryandstate.countries)
    const states = useSelector(state=>state.countryandstate.states)
    const generalAccountType = useSelector(state=>state.authReducer.generalAccountType)
    const userprofile = useSelector(state=>state)
    const dispatch = useDispatch()
    const history = useHistory()
    // console.log(userprofile)
    useEffect(() => {
        getCountry()
        
    },[])

    const getCountry = ()=>{
        dispatch(country())
    }
    const handleChangeCountry = (id)=>{
        dispatch(allState(id))
        // setCountryId(id)
    }

   const [showResults, setDisplay] = useState (false);
   const showSecondStep = () => setDisplay(true)
   const showFirstStep = () => setDisplay(false)

    return (
        <div>
        <NavTalent/>
        <section className="head">
        <h1>Update Profile</h1>
    {/*<img src={elipse} alt="elipseImage"/>*/}

        <h6>Kindly complete the form to update your profile</h6>
      
       {!showResults ?  <div className="indicator-container-1">
       <div>
       <p style={{marginBottom:"5px"}}>Personal info.</p><img src={coloredIndicator}
        alt="coloredIndicator"/>
        </div>
        <div>
        <p style={{marginBottom:"5px"}}>Address and Status</p><img src={indicator}
        alt="Indicator"/>
        </div>
       </div>
        : null
        }
       
        <div className="body-wrapper">
        {!showResults ? <div className="sub-heading">
        <p style={{marginBottom:"5px"}}>Personal information</p>
        <hr className="profile-line"/>
        </div> : null
        }

<Formik initialValues= {{
            
            firstname: '',

            lastname: '',

            gender:'',

            phone_number:'',

            countryId:'',

            stateId:'',

            accounttypeId:'',

            }} 
            validationSchema={yup.object({

            firstname:yup.string().

            required('Please Enter Your First Name'),

            lastname: yup.string()
            .required('Please Enter Your Last Name'),
            gender: yup.string()
            .required("Please Select Sex"),
            
            phone_number:yup.number().
            // min(11,'Maximum Number of Character').

            required('Please Enter Your Your Phone Number'),

            countryId:yup.string().max(25,'Name should not exceed 20 Characters')

            .required('Please Select Your Country'),

            stateId:yup.string().max(25,'Name should not exceed 20 Characters').

            required('Please Select Your State'),

            accounttypeId:yup.string().

            required('Please Select Account Type'),
            })} 
            onSubmit= {async values => {
                // console.log(values)
                setLoading(true)
                await dispatch(updateProfile(values,history))
                setLoading(false)

            }}>
            {
            ({props,handleChange,setFieldValue,values})=>(
        <Form>

        {!showResults ? <div className="personal-info">
        
<div className="form-group-update">
        <SelectInput
        label="First Name:"
        name="firstname"
        type="text"
        />        
        <ErrorMessage  name="firstname">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
        <div className="form-group-update">
        <SelectInput
        label="Last Name:"
        name="lastname"
        type="text"
        />       
        <ErrorMessage  name="lastname">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage> 
        </div>
        <div className="form-group-update">
        <label>Select gender:</label><br/>
        <Field name="gender" as="select" >
        <option value="Select Sex">Select gender</option> 
        <option value="Male">Male</option> 
        <option value="Female">Female</option>        
        </Field>
        <ErrorMessage  name="gender">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
        <div className="form-group-update">
        <SelectInput
        label="Phone number:"
        name="phone_number"
        type="tel"
        />        
        <ErrorMessage  name="phone_number">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>

        <div className="buttons">
        <button type="button">Cancel</button>
        <button type="button" onClick={showSecondStep}>Next</button>
        </div>
        </div>
        : null
        }
        
        {showResults ? <div className="address-status" 
        >
        <div className="indicator-container-2">
        <div>
        <p>Personal info.</p><img src={indicator}
         alt="Indicator"/>
         </div>
         <div>
         <p>Address and Status</p><img src={coloredIndicator}
         alt="Indicator"/>
         </div>
        </div>
        <div className="sub-heading address-form">
        <p>Address and status</p>
        <hr className="profile-line"/>
        </div>
       
<div className="form-group-update">
        <label>Select country:</label><br/>
        <select name="countryId" onChange={(e)=>{handleChange(e); setFieldValue("countrys",handleChangeCountry(e.target.value));}} as="select" >
        <option value="Nigeria">Select Country</option>        
        {
            countries?.map((country,idx) => {
                return <option key={country.key} value={country.key} >{country.name}</option>
            })
        }      
        </select>
        <ErrorMessage  name="countryId">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
        <div className="form-group-update">
        <label>Select state:</label><br/>
        <Field name="stateId" as="select" >
        <option value="Lagosstate">Select State</option>        
        {
            states?.map((state,idx) => {
                return <option key={state.key} value={state.key}>{state.name}</option>
            })
        }           
        </Field>
        <ErrorMessage  name="stateId">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>
       
        <div className="form-group-update">
        <label>Creative talent or Talent hunters</label><br/>
        <Field name="accounttypeId" as="select">
        <option value="Select Account">Select Account Type</option>
        {
            generalAccountType?.map((acctType,idx) => {
                return <option key={acctType.id} value={acctType.id}>{acctType.name}</option>
            })
        }        
        </Field>
        <ErrorMessage  name="accounttypeId">
            {msg =><div className="invalid-entry">{msg}</div>}
        </ErrorMessage>
        </div>

        <div className="buttons">
        <button type="button" onClick={showFirstStep}>Previous</button>
        <button type="submit" style={{marginBottom:"5px"}}>
        {
                loading ?
                <Loader
           type="Rings"
           color="white"
           height={50}
           width={50}
         /> :null

            }
            Update</button>
        </div>
        </div>
        : null
        }
        
         </Form>
        
        )
    }
</Formik>
</div>
<TalentFooter/>
</section>
</div>
    )
}



export default VerifyAccount;