// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    userCertification:[]
}

export const certificationReducer =(state=initialState, action)=>{
    if(action.type === "ADD_CERTIFICATION"){
        console.log(action.payload.data)
        toast.success("Certification Added Successful.",
        {
            position: toast.POSITION.BOTTOM_CENTER}
            )
            console.log(action.payload.data)
        return Object.assign({}, state, {
            userCertification: [...state.userCertification, action.payload.data ],

        });
    }

    else if(action.type === "GET_CERTIFICATION"){
        console.log(action.payload.data)
        return Object.assign({}, state, {
            userCertification: action.payload.data ,

        });
    }

    else if(action.type === "DELETE_CERTIFICATION"){
        const newArray =state.userCertification.filter((each,i)=>each.id !== action.certificationId)
        toast.info("Deleted Successfully.",
       {
           position: toast.POSITION.BOTTOM_CENTER}
           )
       return Object.assign({}, state, {
            userCertification: newArray ,

       });
   }

   else if(action.type === "EDIT_CERTIFICATION"){
    // action.payload.data 
    toast.success("Certification Updated Successfully.",
   {
       position: toast.POSITION.BOTTOM_CENTER}
       )
   return Object.assign({}, state, {
    userCertification: state.userCertification.map(each => {
           if (each.id === action.payload.data.id ){
               return action.payload.data 
           }
           return each
       })

   });
}
 

    return state;
}
