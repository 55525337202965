import React, {useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../assets/image/close.svg';
import './Hunterwidthrawl.styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBanks } from '../../../store/action/talent/talentPayment';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { verifyBankDetails,InitiateHunterTransfer, TransferReceipt } from '../../../store/action/talent/talentPayment';
import Loader from 'react-loader-spinner';


const Hunterwithdrawl = ({handleCloseWithdrawl, withdrawldata, getHunterPayment, widthdrawl}) => {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
   useEffect(async() => {
    await dispatch(getAllBanks())
  }, []);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value});
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    await dispatch(verifyBankDetails(data))
    .then(async (response) => {
        setIsLoading(false);
        console.log('response paymenttt', response.data.status)
        if (response.data.status === true) {
          await dispatch({
            type: 'GET_VERIFYBANKDETAILS',
            payload: response.data,
          });

          await dispatch(TransferReceipt(data)).
          then(async(response) =>{

            if(response.data.status === true){
                await dispatch({
                    type: 'POST_TRANSFERRECEIPT',
                    payload: response.data
                });
                let receipientcode = response.data.data.recipient_code
                await dispatch(InitiateHunterTransfer(
                  {"transfer_recipient_code": receipientcode,
                  "job_id": '' }))
            }
          })
        }

        else {
            toast.error('Invalid Credentials', {
              className: css({
                background: '#00FF00 !important',
                color: 'white !important',
                fontWeight: 'bold',
              }),
              position: toast.POSITION.TOP_CENTER,
            });
          }
      })
  };
  const banks = useSelector(state => state.talentPaymentReducer.getbanks)
  console.log('banksssss', banks)
    return (
        <section>
          {
        <Modal 
        size="md"
        show={widthdrawl} 
        onHide={handleCloseWithdrawl}
        dialogClassName="modal-500w"
        >
         <div className="title-cus">
        <img src={close} alt="closeimage"
        onClick={handleCloseWithdrawl} />
       <h3>Widthdrawal</h3>
        </div>
        <Modal.Body>
        <form className="form-updated_hunt">
          <div className='widthdrawl'>
        <div className='withdrawl__left'>
         <p>Amount withdrawn: <span>{getHunterPayment?.withdrawnPayout}</span></p>
         <p className='withdrawal_pnd'>Pending payout: <span>{getHunterPayment?.pendingPayout}</span></p>
          

        </div>

        <div className='withdrawl__right'>
           <label>Select bank name</label><br />
                <select className='withdrawal_bank'
                  onChange={handleChange}
                  name='bank_code'
                >
                  <option value=''>select Option</option>
                  {banks?.map(bank => {
                  return <>
                  <option value={bank.code} key={bank.id}>{bank.name}</option>
                  </>
                  }
                )}
                </select>
                <label>Account number</label>
                <br />
                <input type='text'  placeholder='Enter account number'
                onChange={handleChange}
                name='account_number' className='withdrawal_acc'/>
             
            <button type="submit" onClick={handleSubmit}>
        {
          isLoading ?
          <Loader
            type="Rings"
            color="white"
            height={50}
          /> : 
        "Initiate agreement"
        }
          </button>
        </div>
        </div>
        </form>
        </Modal.Body>
        </Modal> 
          }
        </section>
    )
}

export default Hunterwithdrawl;