import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';
import { css } from 'glamor';
import { store } from '../../reduxStore/store';


export function getAllBanks() {
    console.log('bank action')
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get('payment/getbanks')
      .then(async (response) => {
        if (response.status === 200) {
          console.log('list of all bankss', response.data.data)
          await dispatch({
            type: 'GET_BANKS',
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}



export function verifyBankDetails(data) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .get(`payment/verifyaccount?account_number=${data.account_number}&bank_code=${data.bank_code}`)
    //   .then(async (response) => {
    //     console.log('response paymenttt', response.data.status)
    //     if (response.data.status === true) {
    //       await dispatch({
    //         type: 'GET_VERIFYBANKDETAILS',
    //         payload: response.data,
    //       });
    //     }

    //     else {
            
    //         toast.error('Invalid Credentials', {
    //           className: css({
    //             background: '#00FF00 !important',
    //             color: 'white !important',
    //             fontWeight: 'bold',
    //           }),
    //           position: toast.POSITION.TOP_CENTER,
    //         });
          
    //       }
    //   })
      .catch((error) => {
        console.log('error response', error.response);
      });
  };
}



export function TransferReceipt (jobdetail) {
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
           return axiosInstance.post('payment/transferrecipient', jobdetail)
           .then ( async (response) => {
            console.log('paymen t transfer receipt', response.data)
            return response
            //  console.log('transfer receipt', response.data)
            //   if(response.data.success === true){
            //     await dispatch({ type: "POST_TRANSFERRECEIPT", payload: response.data });
            // }
       
            })
            .catch(
                error => {
                  console.log(error)
                }
            )
  
        }
  
  }
  
  

  export function InitiateTransfer (data) {
    console.log('initiate transfer initial data', data)
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
           return axiosInstance.post('payment/initiatetransfer', data)
           .then ( async (response) => {
            console.log('initiate transfer true', response.data)
             
              if(response.data.status === true){
                
                await dispatch({ type: "INITIATE_TRANSFER", payload: response.data });
            }

            if(response.data.status === false){
                
              await dispatch({ type: "INITIATE_TRANSFER_ERROR", payload: response.data });
          }
       
            })
            .catch(
                error => {
                  console.log(error.response)
                })
        }}



  export function GetEarning () {
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
           return axiosInstance.get('payment/earnings')
           .then ( async (response) => {
            console.log('get earning response', response.data)
             
              if(response.data.success === true){
                await dispatch({ type: "GET_EARNING", payload: response.data });
            }
            })
            .catch(
                error => {
                  console.log(error)
                }
            )
  
        }
  
  }
  
  

// initate hunter transfer
export function InitiateHunterTransfer (data) {
  console.log('hunter initiate transfer data', data)
  return function(dispatch){
    const axiosInstance = axios.create({
      baseURL : PATH,
      timeout: 200000,
      headers: {
                'Content-Type': 'application/json', 
                 'Accept': 'application/json',
                 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
              }
  })
         return axiosInstance.post('payment/initiatetransfer-hunter', data)
         .then ( async (response) => {
          console.log('initiate transfer true', response)
           
            if(response.data.status === true){
              await dispatch({ type: "INITIATE_HUNTER_TRANSFER", payload: response.data });
          }
          else if(response.data.status === false){
            await dispatch({ type: "INITIATE_HUNTER_ERROR_TRANSFER", payload: response.data });
           }
     
          })
          .catch(
              error => {
                console.log('the error message', error)
              })
      }}
