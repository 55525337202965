// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    loading: false,
    errorMessages: null, 
    postTalentMessage:null,
    getTalentSentMessages:[],
    getTalentReceivedMessages:null,
    getTalentConversation:[]
}

    export const TalentMessageReducer = (state=initialState, action)=>{
    if (action.type === 'REQUEST_TALENT_MESSAGE') {
    return {...state, loading: true}
    }
    else if(action.type === "ADD_TALENT_MESSAGE"){
        toast.success("message sent",
        {
            position: toast.POSITION.BOTTOM_CENTER}
         )
        return {...state, loading:false, postTalentMessage:action.payload}
    }

    else if(action.type === "GET_TALENT_SENT_MESSAGE"){
       
        return {...state,  getTalentSentMessages:Object.entries(action.payload.categories)}
    }

    else if(action.type === "GET_TALENT_SENTERROR_MESSAGE"){
       
        return {...state,  errorMessages:action.payload}
    }

    else if(action.type === "GET_TALENT_RECEIVED_MESSAGE"){
        // console.log(Array.isArray(action.payload.categories)
        // ? action.payload.categories
        // : Object.keys(action.payload.categories).map(key => {
        //     return action.payload.categories[key]}));
         return {...state,  getTalentReceivedMessages:action.payload.categories};
    }

    else if(action.type === "GET_TALENT_RECEIVEDERROR_MESSAGE"){
       
        return {...state,  errorMessages:action.payload}
    }

    else if(action.type === "GET_TALENT_CONVERSATION"){
        // return {...state,  getTalentConversation: action.payload.sort((a,b) =>{ return a.id-b.id})}
       
        return {...state,  getTalentConversation: action.payload}
        
    }

    else if(action.type === "GET_TALENTERROR_CONVERSATION"){
       
        return {...state,  errorMessages: action.payload}
    }
    
    else{
        return state;
    }
    }    