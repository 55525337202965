import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    loading: false,
    errorMessages: null, 
    landingfaq:[] 
}

    export const landingFaqReducer = (state=initialState, action)=>{

    
     if(action.type === "GET_LANDING_FAQ"){
      
        return {...state, loading:false, landingfaq:action.payload}
    }
    
    
    else{
        return state;
    }
    }    