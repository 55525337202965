import { axiosInstance, PATH } from '../../apiconfig/axiosconfig';
import { toast } from 'react-toastify';
import axios from 'axios';
// milestone-submission

export function SubmitMilestone(formData) {
  return function (dispatch) {
    const axiosInstance = axios.create({
      baseURL: PATH,
      timeout: 200000,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
        //    'X-CSRF-TOKEN: " "'
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
    return axiosInstance
      .post('milestone-submission', formData)
      .then(async (response) => {
        console.log('milestone submission project', response);
        if (response.status >= 200) {
          await dispatch({ type: 'SUBMIT_MILESTONE', payload: response.data.submission });
          
         }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error);
      });
  };
}


export function GetAllMilestones() {
    return function (dispatch) {
      const axiosInstance = axios.create({
        baseURL: PATH,
        timeout: 200000,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          //    'X-CSRF-TOKEN: " "'
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
    //   console.log(milestone);
      return axiosInstance
        .get('milestone-submission')
        .then(async (response) => {
          console.log(' submitted milestone submission project', response);
          // if (response.status >= 200) {
          //   await dispatch({ type: 'SUBMITED_MILESTONE', payload: response.data.data });
            
          // }
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error);
        });
    };
  }

  // edit milestone

  export function EditMilestone(data, history) {
    return function (dispatch) {
      const axiosInstance = axios.create({
        baseURL: PATH,
        timeout: 200000,
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // Accept: 'multipart/form-data',
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      return axiosInstance
        .put(`milestone/${data.id}`, data)
        .then(async (response) => {
          console.log('milestone edited project', response);
          if (response.data.success === true) {
          await dispatch({ type: 'EDITED_MILESTONE', payload: response.data.milestone });
          history.push('/hunterDashboard')
           }
        })
        .catch((error) => {
          console.log(error.response);
        });
    };
  }