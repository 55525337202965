import React, { useEffect, useState } from 'react';
import './OverviewTab.styles.css';
import Tab from 'react-bootstrap/Tabs';
import { MdKeyboardArrowRight, MdSchedule } from 'react-icons/md';
import cardImage from '../../../../../assets/image/card-image.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import tick from '../../../../../assets/image/tick.svg';
import Carousel from 'react-bootstrap/Carousel';
import star from '../../../../../assets/image/star.png';
import profile from '../../../../../assets/image/profile.svg';
import { getTalentReview } from '../../../../../store/action/publicProfileAction/addReview';
import { postTalentMessages } from '../../../../../store/action/talent/talentMessages';
import Loader from 'react-loader-spinner';

const OverviewTab = ({ myGig, galleryGig }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [msgData, setMsgData] = useState("");
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const userprofile = useSelector((state) => state.authReducer.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending,setIsSending] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  console.log('mu gig today', myGig);
  const user = useSelector((state) => state.authReducer.user);
  const getReviews = useSelector((state) => state.userReviewReducer.getReviews);
  const formData = new FormData();
  useEffect(() => {
    fetchReviews();
    return () => {};
  }, []);
  const fetchReviews = async () => {
    setIsLoading(true);
    await dispatch(getTalentReview(myGig?.userId));
    setIsLoading(false);
  };

  const handleSubmitMsg = async (e) =>{
    e.preventDefault();
    formData.append("recipient_id", myGig?.userId)
    formData.append("message", msgData);
    formData.append("attachment" , selectedFile);
    setIsSending(true);
    await dispatch(postTalentMessages(formData)); 
    setIsSending(false);
    setMsgData("")
}
const handleChange = (e)=>{
  setMsgData(e.target.value)
}

console.log('the gallery giggllleeessss', myGig)
  return (
    <section>

<Modal
        size="md"
        show={show} 
        onHide={handleClose}
        dialogClassName="modal-500w"
        >
        <div className="title-cus">
        {/* <img src={close} alt="closeimage"
        onClick={handleClose} /> */}
        <h3 className="modal-message" >Enter your message and click on send</h3>
        </div>
        <Modal.Body className='modal-body1'>
        <form className="form-update1"
        onSubmit={handleSubmitMsg}
         >
        {/* <div className="form-group-update">
        <TalentFormInput
         label="Full Name:"
         name="fullname"
         type="text"
        />
        </div> */}
        <div className="form-group-update1">
        <label>Your message:</label><br/>
          <textarea 
          placeholder="Enter a message"
          onChange={handleChange}
          style={{outline:"none", padding:"8px 10px", width:'100%'}}
          >

          </textarea>
     
        </div>
        <div className="modal-button">
        <button type="submit" 
            // onClick={handleSubmitMsg}
        > 
        {
                isSending ?
                <Loader
                type="Rings"
                color="white"
                height={50}
                width={50}
                 /> :"Send message"
                   }        
          </button>
          </div>
        </form>
        </Modal.Body>
        </Modal>

      <div className='container-fluid overview-cont'>
        <div className='row overview-wrapper'>
          <div className='col-md-7 left-side'>
            <div className='gig-category d-flex'>
              <p>{myGig === null ? '' : myGig.category?.name} </p>
              <MdKeyboardArrowRight className='cate-divider' />
              <p>{myGig && myGig.skillId}</p>
            </div>
            <h2>{myGig === null ? '' : myGig?.title}</h2>
            <div className='sub-title-rating'>
              {myGig === null ? '' : myGig.userdetail.lastname}{' '}
              {myGig === null  ? '' : myGig.userdetail.firstname}
            </div>
            {/* <div
              className='card left-card-image'
              style={{
                backgroundImage: myGig !== null &&  myGig?.gallery.length !== 0 ? `url(https://api.solvateafrica.com/public/images/galleries/${myGig?.gallery[0].image})` :`url(${cardImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundSize: 'cover',
              }}
            ></div> */}
            <div className='card left-card-image'>
            {myGig !==null  && ((myGig.gallery !==null && myGig?.gallery[0]?.image.includes(".jpg") )
                || (myGig.gallery !==null  && myGig?.gallery[0]?.image.includes(".png"))) ?
                        <img className="imag-card" src={`https://api.solvateafrica.com/public/images/galleries/${myGig?.gallery[0]?.image}`} alt="cardImg" min-width="200px" height="250"/> 
                     :
                     myGig !== null && myGig.gallery !== null && myGig?.gallery[0]?.image.includes(".mp4") ?
                    <video width="100%" height="250" controls>
                    <source src={`https://api.solvateafrica.com/public/images/galleries/${myGig?.gallery[0]?.image}`} type="video/mp4"></source>
                    </video> :  
                    <audio controls >
                      <source src={myGig !== null && myGig.gallery !== null ? `https://api.solvateafrica.com/public/images/galleries/${myGig?.gallery[0]?.image}` : ''} type="audio/mpeg"></source>
                    </audio>
            }
            </div>

            {/* Reviews */}
            <section className='reviews'>
              <h5>What people loved about this creative talent:</h5>
              <div className='review-slider'>
                {getReviews.map((each, id) => {
                  return (
                    <div className='card rating-card'>
                      {/* Carousel */}

                      <div
                        
                      >
                        <div class=''>
                          <div class=' active d-flex justify-content-start card_container'>
                            <img
                              className='prof_img'
                              src={profile}
                              alt='profile'
                            />

                            <div className='card_container--copy'>
                              <div className='card_container--copyWrapper d-flex align-items-baseline'>
                                <h6>{each.reviewer.name}</h6>{' '}
                                <p data='country'>Nigeria</p>
                                <img
                                  src={star}
                                  className='star_img'
                                  alt='star'
                                />
                                <p data='star_no'>{each.rating}</p>
                              </div>
                              <p data='body_cop'>{each.comment}</p>
                            </div>
                          </div>

                          <div class='carousel-item'></div>

                          <div class='carousel-item'>
                            {/* <img src="..." class="d-block w-100" alt="..."> */}
                          </div>
                        </div>
                        {/* <button
                          className='carousel-control-prev prev_btn'
                          type='button'
                          data-bs-target='#carouselExampleIndicators'
                          data-bs-slide='prev'
                        >
                          <span
                            className='carousel-control-prev-icon'
                            aria-hidden='true'
                          ></span>
                          <span className='visually-hidden'>Previous</span>
                        </button>
                        <button
                          className='carousel-control-next'
                          type='button'
                          data-bs-target='#carouselExampleIndicators'
                          data-bs-slide='next'
                        >
                          <span
                            className='carousel-control-next-icon'
                            aria-hidden='true'
                          ></span>
                          <span className='visually-hidden'>Next</span>
                        </button> */}
                      </div>
                    </div>
                  );
                })}
              </div>
              <button type='button' className='review_button' style={{marginBottom:"10px"}}>
                View all reviews
              </button>
            </section>

            {/* <div className="about-gig">
      <h3>About the Gig</h3>
      </div> */}
          </div>
          {/*Right*/}
          {/* <div className="col-md-5 right-side">
      <div className="right-gigcontent card">
      <Tab defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="home" title="Basic">
        <div className="basic-content">
        <div className="heading-copy d-flex justify-content-between">
        <h6>Basic copywriting</h6>
        <p>$10</p>
      </div>
      <li>1000, words</li>
      <li>Basic proofreading</li>
      <li>3 revisions</li>
      <button type="button">Proceed ($10)</button>

        </div>  
      </Tab>
        <Tab eventKey="Standard" title="Standard">
        <div className="basic-content">
        <div className="heading-copy d-flex justify-content-between">
        <h6>Basic copywriting</h6>
        <p>$20</p>
      </div>
      <li>2,000 words</li>
      <li>Basic proofreading</li>
      <li>3 revisions</li>
      <button type="button">Proceed ($20)</button>

        </div>  
        </Tab>
        <Tab eventKey="premium" title="Premium">
        <div className="basic-content">
        <div className="heading-copy d-flex justify-content-between">
        <h6>Basic copywriting</h6>
        <p>$30</p>
      </div>
      <li>3,000 words</li>
      <li>Basic proofreading</li>
      <li>3 revisions</li>
      <button type="button">Proceed ($50)</button>
        </div> 
        </Tab>
    </Tab>

      
      </div>
      <button type="button">Contact creative talent</button>
      </div> */}
          <div className='col-md-5 right-side'>
            <div className='right-gigcontent card'>
              <div className='basic-content'>
                <div className='heading_copy d-flex justify-content-between'>
                  <h6>Gig offers</h6>
                  <div>Verified</div>
                </div>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].offers}
                  </p>
                </li>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].revisions}{' '}
                    Revisions
                  </p>
                </li>
              </div>
            </div>
            <div className='d-flex button_wrapper justify-content-center'>
              <button type='button' className='contact_button' onClick={handleShow}>
                Contact creative talent
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverviewTab;
