import React, { useEffect, useState } from 'react';
import './ReviewsAdded.styles.css';
import star from '../../../assets/image/star.png';
import profile from '../../../assets/image/profile.svg';
import { useDispatch } from 'react-redux';
import { getTalentReview } from '../../../store/action/publicProfileAction/addReview';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { Rating } from '@mui/material';

const ReviewsAdded = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.authReducer.user);
  const getReviews = useSelector((state) => state.userReviewReducer.getReviews);
  console.log(getReviews);
  useEffect(() => {
    fetchReviews();
    return () => {};
  }, []);
  const fetchReviews = async () => {
    setIsLoading(true);
    await dispatch(getTalentReview(user.id));
    setIsLoading(false);
  };

  return (
    <section>
      {isLoading ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        <div className='card heading_title'>
          <div
            className='heading_title--wrapper'
          >
            <div className='d-flex align-items-baseline heading_title--review'>
              <h6>Reviews</h6>
              <img src={star} alt='starRating' />
              <p>5 ({getReviews.length})</p>
            </div>
            <select name='selection' className='select_gigs'>
              <option value='top'>Sort by most relevant</option>
              <option value=''>Most recent</option>
            </select>
          </div>

          <div
            className='d-flex align-items-baseline heading_title--review
       describe'
          >
            <p>Service as described</p>
            <img src={star} alt='starRating' />
            <p>5</p>
          </div>
          <hr className='review_line' />

          {/* What people are saying */}
          {getReviews.map((each, id) => {
            return (
              <div key={id} className='review-words review_added'>
                <div className='image-copy d-flex align-content-center'>
                  <img src={profile} alt='ProfileImage' />
                  <p className='d-flex align-content-center'>
                    {each.reviewer.name}{' '}
                    <span className='review-wrapper'>
                      <Rating
                        name='simple-controlled'
                        size='small'
                        readOnly
                        // style={{ color: 'red' }}
                        value={each.rating}
                      />
                    </span>
                  </p>
                </div>
                <p className='words'>{each.comment}</p>
                <p className='year-pub'>
                  Published {moment(each.created_at).startOf('day').fromNow()}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default ReviewsAdded;
