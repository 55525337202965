import { store } from "../../store/reduxStore/store"
export const getUpdateStatus = ()=>{
    const userProfileNew = store.getState().updateUserProfileReducer.userDetail;
    const userProfileReturning = store.getState().authReducer.userProfile;
    if (userProfileNew) {
        const updateUserProfile = {firstname:userProfileNew.firstname,lastname:userProfileNew.lastname,
            gender:userProfileNew.gender,phone_number:userProfileNew.phone_number,countryId:userProfileNew.countryId,
            stateId:userProfileNew.stateId,accounttypeId:1}
        console.log(updateUserProfile)
        return updateUserProfile
    }
    else if (userProfileNew===null){
        const updateUserProfile = {firstname:userProfileReturning.firstname,lastname:userProfileReturning.lastname,
            gender:userProfileReturning.gender,phone_number:userProfileReturning.phone_number,countryId:userProfileReturning.countryId,
            stateId:userProfileReturning.stateId,accounttypeId:1}
         console.log(updateUserProfile)
         return updateUserProfile

    }
    console.log(store.getState())
}