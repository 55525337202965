// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    allSkills:[],
    userSkills:[]
}

export const skillReducer =(state=initialState, action)=>{
    if(action.type === "GET_ALL_SKILL"){
        return Object.assign({}, state, {
            allSkills: action.payload.data
        });
    }

    else if(action.type === "GET_ALL_USER_SKILL"){
        return Object.assign({}, state, {
            userSkills: action.payload.skills
        });
    }


    else if(action.type === "ADD_USER_SKILL"){
        console.log(action.payload)
        toast.success("Successful.",
        {
            position: toast.POSITION.BOTTOM_CENTER}
        )
        return Object.assign({}, state, {
            // userSkills: [...state.userSkills, action.payload ],
            userSkills: action.payload ,

            

           


        });
    }
 

    return state;
}
