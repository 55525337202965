import React, { useState } from "react";
import "./JobsTable.styles.css";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import editIcon from "../../../assets/image/edit-icon.svg";
import deleteIcon from "../../../assets/image/delete.svg";
import { FcInvite } from "react-icons/fc";
import bellIcon from "../../../assets/image/bell.svg";
import { Link } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import close from "../../../assets/image/close.svg";
import ModalInputComponent from "../../ModalInput/ModalInput.component";
import Loader from "react-loader-spinner";
import { GrView } from "react-icons/gr";
import {
  deletePostedJob,
  editPostJob,
  closeJobInvite,
} from "../../../store/action/hunter/postJob";
import ViewMilestone from "./Viewmilestone.modal";
import Invitees from "./Invitees";

const CloseJobTable = ({ getScoutCloseJob }) => {
  const category = useSelector((state) => state.categoriesReducer.categories);
  const dispatch = useDispatch();

  const [availableSkill, setAvailableSkill] = useState([]);
  const [show, setShow] = useState(false);
  const [milestone, setMilestone] = useState(false);
  const [milestonedata, setMilestonedata] = useState([]);

  const [invitedata, setInviteData] = useState({});
  const [showinvite, setShowinvite] = useState(false);
  const handleClosemilestone = () => setMilestone(false);
  const [jobid, setJobid] = useState({});

  // const [data, setData] = useState({});
  const [openInvitee, setOpenInvitee] = useState(false);
  const [invitees, setInvitees] = useState([]);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInviteShow = ({ id }) => {
    setJobid(id);
    setShowinvite(true);
  };
  const handleInviteClose = () => {
    setShowinvite(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = (jobToEdit) => {
    setShow(true);
    // const skillList = category.find(
    //   (each) => each.id === Number(jobToEdit.category)
    // ).skill;
    // setAvailableSkill(skillList);

    setEditData(jobToEdit);
  };

  const handleMilestone = (milestones) => {
    setMilestonedata(milestones);
    setMilestone(true);
  };

  // const handleChangeCate = (e) => {
  //   const skillList = category.find(
  //     (each) => each.id === Number(e.target.value)
  //   ).skill;
  //   setAvailableSkill(skillList);
  //   setEditData({ ...editData, [e.target.name]: e.target.value });

  // };

  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };
  const handleInviteChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setInviteData({ ...invitedata, [name]: value });
  };
  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    const newdata = {
      talent_email: invitedata?.talent_email,
      job_id: jobid,
    };
    setLoading(true);
    await dispatch(closeJobInvite(newdata));
    setLoading(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(editData)
    let newData = {
      job_description: editData.job_description,
      milestone: editData.milestone,
      category: editData.category,
      skill: editData.skill,
      job_type: editData.job_type,
      price_budget: editData.price_budget,
    };

    setLoading(true);
    await dispatch(editPostJob(newData, editData.id));
    setLoading(false);
    handleClose();
  };

  const handleDelete = (jobToDelete) => {
    dispatch(deletePostedJob(jobToDelete.id));
  };
  const handleShowInvitees = (invitees) => {
    setInvitees(invitees);
    setOpenInvitee(true);
  };

  const handleCloseInvitees = () => {
    setOpenInvitee(false);
  };

  console.log("getScoutCloseJob inside component", getScoutCloseJob);

  return (
    <section>
      <Table className="table_size" striped bordered hover responsive>
        <thead>
          <tr>
            <th className="active_head_bg">#</th>
            <th className="active_head_bg">Title</th>
            <th className="active_head_bg">Skills</th>
            <th className="active_head_bg">Milestone</th>
            <th className="active_head_bg">Price budget</th>
            <th className="active_head_bg">Job type</th>
            <th className="active_head_bg">Actions</th>
          </tr>
        </thead>
        <tbody>
          {getScoutCloseJob?.map((each, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{each.title}</td>
                {/* {category.map((categ) => {
                    if (categ.id == each.category) {
                      return categ.skill.map((skil) => {
                        if (skil.id == each.skill) {
                          // skillTable = skil.name
                          return skil.name;
                        }
                      });
                    }
                  })} */}
                <td>{each.skill}</td>
                <td>
                  <>
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            View Milestone
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <button
                          onClick={() => handleMilestone(each.milestones)}
                          className="milestone_add"
                        >
                          View
                        </button>
                      </OverlayTrigger>
                    ))}
                  </>
                </td>
                <td>{each.price_budget}</td>
                <td>{each.job_type}</td>
                <ViewMilestone
                  milestone={milestone}
                  handleClosemilestone={handleClosemilestone}
                  milestonedata={milestonedata}
                />
                <Modal
                  size="md"
                  show={show}
                  onHide={handleClose}
                  dialogClassName="modal-500w"
                >
                  <div className="title-cus">
                    <img src={close} alt="closeimage" onClick={handleClose} />
                    <h3>Edit job</h3>
                  </div>
                  <Modal.Body>
                    <form className="form-update" onSubmit={handleSubmit}>
                      <div className="job_form">
                        <label className="">Describe job briefly:</label>
                        <br />
                        <textarea
                          name="job_description"
                          defaultValue={editData?.job_description}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      {/* <div className='job_form'>
                        <label>Select a Milestone:</label>
                        <br />
                        <select
                          name='milestone'
                          defaultValue={editData.milestone}
                          onChange={handleChange}
                        >
                          <option>Select a Milestone</option>
                          <option>1 </option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                     </div> */}
                      {/*<div className='job_form'>
                        <label>Select a Category:</label>
                        <br />
                        <select
                          name='category'
                          defaultValue={editData.category}
                          onChange={handleChangeCate}
                        >
                          <option>Select a category</option>
                          {category.map((each, id) => {
                            return (
                              <option key={id} value={each.id}>
                                {each.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>*/}
                      <div className="job_form">
                        <label>Skills:</label>
                        <br />
                        <textarea
                          name="skill"
                          defaultValue={editData?.skill}
                          onChange={handleChange}
                        />
                        {/* <option value='articlewriting'>
                            Article writing
                          </option>
                          {availableSkill.map((each, id) => {
                            return (
                              <option key={id} value={each.id}>
                                {each.name}
                              </option>
                            );
                          })}
                        </select> */}
                      </div>
                      <div className="job_form">
                        <label>Select Job type:</label>
                        <br />
                        <select
                          name="job_type"
                          defaultValue={editData?.job_type}
                          onChange={handleChange}
                        >
                          <option value="">Select Job type</option>
                          <option value="open">Open job</option>
                          <option value="close">Closed job</option>
                        </select>
                      </div>
                      <div className="job_form">
                        <ModalInputComponent
                          label="Price budget:"
                          name="price_budget"
                          type="text"
                          onChange={handleChange}
                          defaultValue={editData?.price_budget}
                        />
                      </div>

                      <button className="update_job_button" type="submit">
                        {loading ? (
                          <Loader
                            type="Rings"
                            color="white"
                            height={50}
                            width={50}
                          />
                        ) : (
                          " Update job"
                        )}
                      </button>
                    </form>
                  </Modal.Body>
                </Modal>
                {/* invitation of talent */}

                <td className="action_icons">
                  <>
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Invite a talent
                          </Tooltip>
                        }
                      >
                        <i onClick={() => handleInviteShow(each)}>
                          <FcInvite className="edit_icon" />
                        </i>
                      </OverlayTrigger>
                    ))}
                  </>
                  <Modal
                    show={showinvite}
                    onHide={handleInviteClose}
                    //   size='md'
                    dialogClassName="modal-500w"
                  >
                    <div className="title-cus">
                      <img
                        src={close}
                        alt="closeimage"
                        onClick={handleInviteClose}
                      />
                      <h3>Invite a talent</h3>
                    </div>
                    <Modal.Body>
                      <form
                        className="form-update"
                        onSubmit={handleInviteSubmit}
                      >
                        <div className="job_form">
                          <label>Talent email:</label>
                          <br />
                          <input
                            placeholder="please enter talent's email"
                            name="talent_email"
                            type="email"
                            style={{
                              padding: "1rem",
                              width: "100%",
                              borderRadius: "10px",
                              border: "1px solid grey",
                              outline: "none",
                            }}
                            onChange={(e) => handleInviteChange(e)}
                          />
                        </div>

                        <button
                          type="submit"
                          className="update_job_button"
                          onClick={handleInviteSubmit}
                          disabled={!invitedata.talent_email}
                        >
                          {loading ? (
                            <Loader
                              type="Rings"
                              color="white"
                              height={50}
                              width={50}
                            />
                          ) : (
                            " Send invite"
                          )}
                        </button>
                      </form>
                    </Modal.Body>
                  </Modal>
                </td>
                {/* view invited talents */}
                <td className="action_icons">
                  <>
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Invited talent(s)
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <i
                          className="edit_icon"
                          onClick={() => handleShowInvitees(each?.invitees)}
                        >
                          <GrView />
                        </i>

                        {/* <img
                          onClick={() => handleShow(each)}
                          className='edit_icon'
                          src={editIcon}
                          alt='editIcon'
                        /> */}
                      </OverlayTrigger>
                    ))}
                  </>
                  <Invitees
                    handleShowInvitees={handleShowInvitees}
                    handleCloseInvitees={handleCloseInvitees}
                    invitees={invitees}
                    openInvitee={openInvitee}
                  />
                </td>

                <td className="action_icons">
                  <>
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Edit job
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <img
                          onClick={() => handleShow(each)}
                          className="edit_icon"
                          src={editIcon}
                          alt="editIcon"
                        />
                      </OverlayTrigger>
                    ))}
                  </>
                </td>
                <td className="action_icons">
                  <>
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Delete job
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <img
                          className="delete_icon"
                          onClick={() => handleDelete(each)}
                          src={deleteIcon}
                          alt="deleteIcon"
                        />
                      </OverlayTrigger>
                    ))}
                  </>
                </td>
                <td className="action_icons">
                  <>
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            View Bids From Talent For this Job
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <Link to={`/hunterDashboard/talentBid/${each.id}`}>
                          <img
                            className="bell_icon"
                            // onClick={ ()=>handleDelete(each)}
                            src={bellIcon}
                            alt="bellIcon"
                          />
                        </Link>
                      </OverlayTrigger>
                    ))}
                  </>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
};

export default CloseJobTable;
