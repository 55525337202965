import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import axios from 'axios';

export function searchGig (userDetails) {
      return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
          console.log(userDetails)
          return axiosInstance.get('gig/search' ,userDetails).then ( async (response) => {
            console.log(response)
          if(response.data.success === true){
            // console.log(response)
            await dispatch({ type: "SEARCH_RESULT", payload: response.data.gigs,search:userDetails })
          }
          })
          .catch(
              error => {
                console.log(error.response)
                console.log(error)
              }
          )
  
      }
  
  }
  
  export function getGigDetailAction (id) {
    return function(dispatch){
      const axiosInstance = axios.create({
        baseURL : PATH,
        timeout: 200000,
        headers: {
                  'Content-Type': 'application/json', 
                   'Accept': 'application/json',
                //    'X-CSRF-TOKEN: " "'
                   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
    })
        console.log(id)
        return axiosInstance.get(`gig/${id}` ).then ( async (response) => {
          console.log(response)
        if(response.data.success === true){
          // console.log(response)
          await dispatch({ type: "SEARCH_RESULT_DETAIL", payload: response.data.gig[0]})
        }
        })
        .catch(
            error => {
              console.log(error.response)
              console.log(error)
            }
        )

    }

}