import React from 'react';
import "./policy.styles.css";

const Agreement= () => {
  return (
    <div className='policy' id='#agreement'>
      <h1 className='policy_header'>SOLVATE AFRICA – USER AGREEMENT DOCUMENTATION </h1>
      <p className='agreement_explanation'>PLEASE READ THIS USER AGREEMENT AND ALL OTHER AGREEMENTS AND POLICIES REFERENCED HEREIN COLLECTIVELY DEFINED BELOW AS THE "TERMS OF SERVICE" 
          CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, 
          REMEDIES, AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS 
          AND A BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER.</p>
          <p className='policy__paragraph'>This User Agreement (this “Agreement”) is a contract between you (“you” or “User”) & Solvate 
            Craft Company. (“We,” or “us”) and our affiliate Solvate Africa. You must read, agree to, and 
            accept all of the terms and conditions contained in this Agreement to be a User of our website 
            located at www.solvateafrica.com or any part of the rest of the Site (defined in the Site Terms 
            of Use) or the Site Services (defined in the Site Terms of Use).</p>
            <p className='policy__paragraph'>This Agreement includes and hereby incorporates by reference the following important 
agreements, as they may be in effect and modified from time to time: Site Terms of Use; Cookie 
Policy; Privacy Policy; Mark Use Guidelines; Freelancer Membership Agreement; Proprietary 
Rights Infringement Reporting Procedures; and the escrow instructions as applicable to any 
Service Contract you enter into with another User These agreements are collectively, with this 
Agreement, called the “Terms of Service”. Subject to the conditions set forth herein, Solvate 
Africa may, in its sole discretion, amend this Agreement and any of the other agreements that 
comprise the Terms of Service at any time by posting a revised version on the Site.</p>
<p className='policy__paragraph'>Solvate Africa will provide reasonable advance notice of any amendment that includes a 
Substantial Change (defined below), by posting the updated Terms of Service on the Site, 
providing notice on the Site, and/or sending you notice by email. If the Substantial Change 
includes an increase to Fees charged by Solvate Africa, Solvate Africa will provide at least 30 
days’ advance notice of the change, but may not provide any advance notice for changes 
resulting in a reduction in Fees or any temporary or promotional Fee change. Any revisions to 
the Terms of Service will take effect on the noted effective date (each, as applicable, the 
“Effective Date”).</p>
<p className='policy__paragraph'>YOU UNDERSTAND THAT BY USING THE SITE OR SITE SERVICES AFTER THE EFFECTIVE DATE, 
YOU AGREE TO BE BOUND BY THE TERMS OF SERVICE, IF YOU DO NOT ACCEPT THE TERMS OF 
SERVICE IN ITS ENTIRETY, YOU MUST NOT ACCESS OR USE THE SITE OR THE SITE SERVICES 
AFTER THE EFFECTIVE DATE EXCEPT AS PERMITTED BY THE SITE TERMS OF USE. IF YOU 
AGREE TO THE TERMS OF SERVICE ON BEHALF OF AN ENTITY OR AGENCY, OR IN CONNECTION 
WITH PROVIDING OR RECEIVING SERVICES ON BEHALF OF AN ENTITY OR AGENCY, YOU 
REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND THAT ENTITY OR 
AGENCY TO THE TERMS OF SERVICE AND AGREE THAT YOU ARE BINDING BOTH YOU AND THAT 
ENTITY OR AGENCY TO THE TERMS OF SERVICE. IN THAT EVENT, “YOU” AND “YOUR” WILL 
REFER AND APPLY TO YOU AND THAT ENTITY .</p>
<h4 className='agreement_subhead'>1.1 <span className='policy_subhead'>REGISTRATION AND ACCEPTANCE</span></h4>
<p className='agreement_words'>By registering for an account to use the Site or Site Services (an “Account”), by using the 
Site or Site Services after the Effective Date if you had an Account on the Effective Date, or 
by clicking to accept the Terms of Service when prompted on the Site, you agree to abide 
by this Agreement and the other Terms of Service. To access and use certain portions of 
the Site and the Site Services, you must register for an Account. Subject to the Site Terms 
of Use, certain portions of the Site are available to Site Visitors, including those portions 
before your Account registration is accepted. Solvate Africa reserves the right to decline a 
registration to join the platform or to add an Account of any type (i.e., as a Client or 
Freelancer), for any lawful reason, including supply and demand, cost to maintain data, or 
other business considerations. If you create an Account as a proxy or agent on behalf of a 
company or individual, you represent and warrant that you are authorized to enter into 
binding contracts, including the Terms of Service, on behalf of yourself and the company. 
Your privacy is important to Solvate Africa and your information will be handled in 
accordance with our Privacy Policy, which is part of the Terms of Service, and applicable 
law.</p>
<h4 className='agreement_subhead'>1.2 <span className='policy_subhead'>ACCOUNT ELIGIBILITY</span></h4>
<p className='agreement_words'>Solvate Africa offers the Site and Site Services for your business purposes only and not for 
personal, household, or consumer use. To register for an Account or use the Site and Site 
Services, you must, and hereby represent that you: (a) are an employee or agent of and 
authorized to act for and bind an independent business (whether it be as a self-employed 
individual/sole proprietor or as a corporation, limited liability company, or other entity); (b) 
will use the Site and Site Services for business purposes only; (c) will comply with any 
licensing, registration, or other requirements with respect to your business, or the 
business for which you are acting, and the provision of Freelancer Services; and (d) are 
either a legal entity or an individual who is 18 years or older (or have otherwise reached 
the age of majority in the jurisdiction in which you conduct business) in each case who can 
form legally binding contracts.</p>
    
    <h4 className='agreement_subhead'>1.3 <span className='policy_subhead'>ACCOUNT PROFILE</span></h4>
    <p className='agreement_words'>To register for an Account to join the Site, you must complete a User profile (“Profile”), 
which you consent to be shown to other Users and, unless you change your privacy 
settings, to be shown to the public. You agree to provide true, accurate, and complete 
information on your Profile and all registration and other forms you access on the Site or 
provide to us and to update your information to maintain its truthfulness, accuracy, and 
completeness. You agree not to provide any false or misleading information about your 
identity or location, your business, your skills, or the services your business provides and 
to correct any such information that is or becomes false or misleading.</p>
<h4 className='agreement_subhead'>1.4 <span className='policy_subhead'>ACCOUNT TYPES</span></h4>
    <p className='agreement_words'>As described in this Section, there are 2 main types of Account types. You either register 
as a Creative Freelance (Talent) or a Client (Talent Hunter). if you already have a 
Freelancer Account (defined below), you can add a Client Account (defined below) by 
opening a separate account type as Client. However you are not allowed to open more than 
one account type under the same profile without express written permission from us. We 
reserve the right to revoke the privileges of the Account or access to or use of the Site or 
Site Services, and those of any and all linked Accounts without warning if, in our sole 
discretion, false or misleading information has been provided in creating, marketing, or 
maintaining your Profile etc.</p>
<h4 className='agreement_subhead'>1.5 <span className='policy_subhead'>TALENT HUNTER (CLIENT) ACCOUNT</span></h4>
    <p className='agreement_words'>You can register for an Account or add an Account type to use the Site and Site Services 
as a Client (a “Client Account”). Each User under a Client Account (“Team Member”) can be 
given different permissions to act on behalf of the Client Account.</p>
<h4 className='agreement_subhead'>1.6 <span className='policy_subhead'>FREELANCER,ACCOUNT</span></h4>
    <p className='agreement_words'>Freelancer: You can register for an Account or add an Account type to use the Site and Site 
Services as a Freelancer (a “Freelancer Account”).</p>
<h4 className='agreement_subhead'>1.7 <span className='policy_subhead'>ACCOUNT PERMISSIONS</span></h4>
    <p className='agreement_words'>You agree not to request or allow another person to create an Account on your behalf, for 
your use, or for your benefit, except that an authorized employee or agent may create an 
Account on behalf of your business. By granting other Users permissions under your 
Account, including as a Partner / Team Member or Agency Member, you represent and 
warrant that: (a) the User is authorized to act on your behalf; and (b) you are fully 
responsible and liable for the User’s acts and omissions, including for obligations and 
liabilities relating to making payments and entering into Service Contracts and the Terms 
of Service. If any such User violates the Terms of Service, it may affect your ability to use 
the Site and Site Services. Upon closure of an Account, Solvate Africa may close any or all 
related Accounts.</p>
<h4 className='agreement_subhead'>1.8 <span className='policy_subhead'>USERNAMES AND PASSWORDS </span></h4>
    <p className='agreement_words'>Each person who uses the Site must register for their own Account. When you register for 
an Account, you will be asked to choose a username and password for the Account. You 
are entirely responsible for safeguarding and maintaining the confidentiality of your 
username and password. You agree not to share your username or password with any 
person, and, if you are a legal entity who is not a natural person, to only share your 
username and password with a person who is authorized to use your Account. You 
authorize Solvate Africa to assume that any person using the Site with your username and 
password, either is you or is authorized to act for you. You agree to notify us immediately if 
you suspect or become aware of any unauthorized use of your Account or any 
unauthorized access to the password for any Account. You further agree not to use the 
Account or log in with the username and password of another User of the Site if (a) you are 
not authorized to use both or (b) the use would violate the Terms of Service.
</p>
<h4 className='agreement_subhead'>1.9 <span className='policy_subhead'>SOLVATE AFRICA PURPOSE & ROLE </span></h4>
    <p className='agreement_words'>The Site is a marketplace where Talent Hunters/Clients and Talent / Freelancers can 
identify each other, connect engage and get creative work done. Subject to the Terms of 
Service, Solvate Africa provides the Site Services to Users, including hosting and 
maintaining the Site, facilitating the formation of Service Contracts, and may (Not 
Mandatory) assist Users in resolving disputes which may arise in connection with those 
Service Contracts. When a User enters a Service Agreement, it is mandatory both parties 
respect and adhere to clear terms stated in the agreement – <span className='policy__bold'>Milestones, Timing, work 
delivery etc.</span>
</p>
<p className='agreement_words1'>Solvate Africa only makes the Site and Site Services available to enable Freelancers and 
Clients to find and transact directly with each other. Solvate Africa does and will not 
introduce Freelancers to Clients, select Projects for Freelancers, or select Freelancers for 
Clients. Through the Site and Site Services, Freelancers may be notified of Clients that may 
be seeking the services they offer, and Clients may be notified of Freelancers that may offer 
the services they seek; at all times, however, Users are responsible for evaluating and 
determining the suitability of any Project, Client or Freelancer on their own.
</p>
<p className='agreement_words1'>If Users decide to enter into a Service Contract, the Service Contract is directly between 
the Users (both the Talent user & Talent /Client & Freelancer) and Solvate Africa is not a 
party to that Service Contract. You acknowledge, agree, and understand that Solvate Africa 
is not a party to the relationship or any dealings between <span className='policy__bold'>Client and Freelancer/Talent</span> and <span className='policy__bold'>Talent Hunter</span>
</p>
<p className='agreement_words1'>Without limitation, Users are solely responsible for:</p>
<p>(a) Ensuring the accuracy and legality of any User Content;</p>
<p>(b) Determining the suitability of other Users for a Service Contract (message interaction, 
vetting of profiles, or similar actions);</p>
<p>(c) Negotiating, with Talent agreeing to set milestones and agreement by Client and
executing any terms or conditions of Service Contracts;</p>
<p>(d) Performing Freelancer Services; and/or</p>
<p>(e) Paying for Freelancer Services. You further acknowledge, agree, and understand that 
you are solely responsible for assessing whether to enter into a Service Contract with 
another User. Solvate Africa does not make any representations about or guarantee the 
truth or accuracy of any Freelancer’s or Client’s listings or other User Content on the Site; 
does not verify any feedback or information provided by Users about Freelancers or 
Clients; and does not perform background checks on or guarantee the work of Freelancers 
or Clients.</p>
<p className='agreement_words1'>You acknowledge, agree, and understand that Solvate Africa does not, in any way, 
supervise, direct, control, or evaluate Freelancers or their work and is not responsible for 
any Project, Project terms or Work Product. Solvate Africa makes no representations 
about and does not guarantee, and you agree not to hold Solvate Africa responsible for, the 
quality, safety, or legality of Freelancer Services; the qualifications, background, or 
identities of Users; the ability of Freelancers to deliver Freelancer Services; the ability of 
Clients to pay for Freelancer Services; User Content and statements or posts made by 
Users; or the ability or willingness of a Client or Freelancer to actually complete a
transaction. </p>
<p className='agreement_words1'>While Solvate Africa may provide certain badges on Freelancer or Client profiles, such 
badges are not a guarantee or warranty of quality or ability or willingness of the badged 
Freelancer or Client to complete a Service Contract and is not a guarantee of any kind, 
including, the quality of Freelancer Services or Client Project. You also acknowledge, agree, 
and understand that Freelancers are solely responsible for determining, and have the sole 
right to determine, which Projects to accept; the time, place, manner, and means of providing 
any Freelancer Services; the type of services they provide; and the price they charge for their 
services or how that pricing is determined or set. You further acknowledge, agree, and 
understand that:</p>
<p className='agreement_words1'>Solvate Africa will not have any liability or obligations, including under or related to Service 
Contracts and/or Freelancer Services for any acts or omissions by you or other Users;</p>
<p className='agreement_words1'>Solvate Africa does not, in any way, supervise, direct, or control any Freelancer or 
Freelancer Services; does not impose quality standards or a deadline for completion of any 
Freelancer Services; this can only be determined, scored or measured particularly by the 
Client/Talent user.</p>
<p className='agreement_words1'>Solvate Africa has the full right to initiate & deduct penalty fees based on cumulative 
performance ratings by the Talent Hunter/Client, if the freelancer is found wanting in the 
areas of speed , delivery and quality of work.</p>
<p className='agreement_words1'>Solvate Africa does not set or have any control over Freelancer’s pricing, work hours, 
work schedules, or work location, nor is involved in any other way in determining the 
nature and amount of any compensation that may be charged by or paid to Freelancer for a 
Project; (v) Freelancer will be paid at such times and amounts as agreed with a Client in a 
given Service Contract, and Solvate Africa does not, in any way, provide or guarantee 
Freelancer a regular salary or any minimum, regular payment;</p>
<h4 className='agreement_subhead'>2.0 <span className='policy_subhead'>AGREEMENT /SERVICE CONTRACTS   </span></h4>
    <p className='agreement_words'>If a Client and Freelancer decide to enter into an Agreement (Timelines, delivery dates,
fees etc.), the agreement is a contractual relationship directly between the Client and 
Freelancer. You acknowledge, agree, and understand that Solvate Africa is not a party to 
any agreement or Service Contract, but may intervene if relatable issues arise between 
both users.
</p>
<h4 className='agreement_subhead'>2.1 <span className='policy_subhead'> SOLVATE AFRICA FEE STRUCTURE  </span></h4>
    <p className='agreement_words'>When a Client pays a Freelancer for a Project or when funds related to a Project are 
otherwise released to a Freelancer as required by the applicable Escrow Instructions 
Solvate Africa Escrow will credit the Talent Freelancer Escrow Account for the full amount 
paid or released by the Client, and then subtract and disburse to Solvate Africa a 10% 
Service Fee. Talent /Freelancer & Talent Hunter /Client hereby irrevocably authorizes and 
instructs Solvate Africa Escrow to deduct the 10% Service Fee inclusive of other site 
maintenance charges where necessary from the work fees to be paid to the freelancer by 
the Client on successful completion of the project.
</p>
<p className='agreement_words1'>Solvate Africa would also return funds back without deduction to Client /Talent hunter in 
case the project is not activated or changes his or her mind after funds have been released 
for a particular job. This is however excludes transfer charges that can be incurred on the 
said amount.
</p>
<h4 className='policy_subhead'>OTHER SURCHARGE TERMS</h4>
<ul className='policy_info'>
            <li>Failure on not more than 2 milestones by the Talent at any stage the 
Talent Hunter/Client has the sole right to cancel the project without any 
payment made to the Talent/Freelance </li>
            <li>If Project is cancelled by Client at the middle stage of a commissioned job 
            <span className='policy__bold'>(gone beyond first 2 milestones)</span> a rejection <span className='policy__bold'>fee 7.5% of the project budget 
is charged and paid to the Talent</span> and a 2.5% cancellation fee would also 
be charged by Solvate Africa with the payment gateway service fee 
charge and balance returned back to Client.</li>
            <li>Delay in response from Client in line with agreed timelines and 
milestones after job is shared attracts a deduction of 1% from the actual 
project budget.
</li>
            <li>Talent Users must deposit Money into the gateway/escrowing system.</li>
         </ul>
         <h4 className='agreement_subhead'>2.2 <span className='policy_subhead'> CONFIDENTIAL INFORMATION  </span></h4>
    <p className='agreement_words'>Users may agree to any terms they deem appropriate with respect to 
confidentiality, User provides Confidential Information on the project to another 
User, the recipient will protect the secrecy of the discloser’s Confidential 
Information with the same degree of care as it uses to protect its own Confidential 
Information, but in no event with less than due care. On a User’s written request, 
the party that received Confidential Information will promptly destroy or return the 
disclosing party’s Confidential Information and any copies thereof contained in or 
on its premises, systems, or any other equipment otherwise under its control.
</p>
<h4 className='agreement_subhead'>2.3 <span className='policy_subhead'> NON- CIRCUMVENTION  </span></h4>
    <p className='agreement_words'>You agree to communicate through the Site and make and receive payments only through the Site. 
Violations of this Section constitute a serious breach and may result in permanent suspension of 
your Account.
</p>
<h4 className='policy_subhead'>MAKING PAYMENTS THROUGH SOLVATE AFRICA</h4>
<p className='policy__paragraph'>You agree to exclusively use Solvate Africa to make payments for work that arises out 
of a relationship you made through Solvate Africa from the date you first established the 
relationship.
</p>
<p className='policy__paragraph'>
You acknowledge and agree that a substantial portion of the compensation Solvate 
Africa receives for making the Site available to you is collected through the Service Fee 
and that in exchange a substantial value to you is the relationships you make with other 
Users identified through the Services (the “<span className='policy__bold'>Solvate Africa Relationship</span>”).
         </p>
         <p className='policy__paragraph'>
         Solvate Africa only receives the Service Fee when a Client (The Talent User) pay and
Talent receive payment through the Site. you agree to use the Site as your exclusive 
method to request, make, and receive all payments for work directly or indirectly with 
that person or arising from that relationship and not to circumvent the Payment Methods 
offered on the Site.
         </p>
         <p className='policy__paragraph'>
         You agree to notify Solvate Africa immediately if a person suggests making or receiving 
payments other than through the Site or if you receive unsolicited contact outside of the 
Site. If you are aware of a breach or potential breach of this non-circumvention 
agreement, please submit a confidential report to <span className='policy__bold'> solvateafrica@gmail.com
</span>.
         </p>
         <p className='policy__paragraph'>
         You acknowledge and agree that a violation of the above is a material breach of the 
Terms of Service, and may result in your Account being permanently suspended.
         </p>
         <h4 className='policy_subhead'>COMMUNICATING THROUGH THE SITE; NOT SHARING CONTACT DETAILS</h4>
<p className='policy__paragraph'>Prior to entering into a Service Contract, you agree to communicate with other Users 
exclusively through Solvate Africa.
</p>
<p className='policy__paragraph'>Users agree to use the communication services available on the Site to communicate with 
other Users prior to entering into a Service Contract. You agree that prior to entering into a 
Service Contract, you (a) will use Solvate Africa as the sole manner to communicate with 
other Users; (b) will not provide your Means of Direct Contact (defined below) to any other 
User or another person that you identified or were identified by through the Site; (c) will not 
use Means of Direct Contact of another user to attempt to or to communicate with, solicit, 
contact, or find the contact information of a User outside of Solvate Africa; (d) will not ask for, 
provide, or attempt to identify through public means the contact information of another User; 
and (e) will not include any Means of Direct Contact or means by which your contact 
information could be discovered in any profile, proposal, job posting, invitation, or pre-hire 
communication through the Site’s communications services (including in each case in any 
attached file), except as otherwise provided on the Site.
</p>
<p className='policy__paragraph'>For purposes of the Terms of Service, “<span className='policy__bold'>Means of Direct Contact</span>” means any information that 
would allow another person to contact you directly, including, without limitation, phone 
number, email address, physical address, a link to a contact form or form requesting contact 
information, any link to an applicant management system or means to submit a proposal or 
application outside of the Site, or any information that would enable a user to contact you on 
social media or other website or platform or application that includes a communications tool, 
such as Skype, Slack, WeChat, or Telegram, Facebook, Instagram, WhatsApp etc. Information is 
a Means of Direct Contact if it would enable another user to identify any of the information 
above through other sources, such as going to a website that included an email address or 
identifying you through social media.You acknowledge and agree that this is a violation and a 
material breach of the Terms of Service and your Account may be permanently suspended for 
such violations.
</p>
<h4 className='agreement_subhead'>2.4 <span className='policy_subhead'>LIMITATION OF LIABILITY  </span></h4>
<p className='policy__paragraph'>Any liability we may have to you is limited.</p>
<p className='policy__paragraph'>Solvate Africa is not liable, and you agree not to hold us responsible, for any damages or 
losses arising out of or in connection with the Terms of Service, including, but not limited to: (i) 
your use of or your inability to use our Site or Site Services; (ii) delays or disruptions in our 
Site or Site Services; (iii) viruses or other malicious software obtained by accessing, or linking 
to, our Site or Site Services; (iv) glitches, bugs, errors, or inaccuracies of any kind in our Site 
or Site Services; (v) damage to your hardware device from the use of the Site or Site Services; 
(vi) the content, actions, or inactions of third parties’ use of the Site or Site Services; (vii) a 
suspension or other action taken with respect to your Account; (viii) your reliance on the 
quality, accuracy, or reliability of job postings, Profiles, ratings, recommendations, and 
feedback (including their content, order, and display), Composite Information, or metrics found 
on, used on, or made available through the Site; and (ix) your need to modify practices, content, 
or behaviour or your loss of or inability to do business, as a result of changes to the Terms of 
Service.</p>
<p className='policy__paragraph'>Additionally, in no event will Solvate Africa, our affiliates, our licensors, or our third-party 
service providers be liable for any special, consequential, incidental, punitive, exemplary, or 
indirect costs or damages, including, but not limited to, litigation costs, installation and 
removal costs, or loss of data, production, profit, or business opportunities. </p>
<h4 className='agreement_subhead'>2.5 <span className='policy_subhead'>RELEASE  </span></h4>
<p className='policy__paragraph'>You agree not to hold Solvate Africa responsible for any dispute you may have with another 
User.
</p>
<p className='policy__paragraph'>In recognition of the fact that Solvate Africa is not a party to any contract between Users, you 
hereby release Solvate Africa and our other Affiliates from claims, demands, and damages 
(actual and consequential) of every kind and nature, known and unknown, arising out of or in 
any way connected with any dispute you have with another User, whether it be at law or in 
equity that exist as of the time you enter into this Agreement. This release includes, for 
example and without limitation, any disputes regarding the performance, functions, and quality 
of the Freelancer Services provided to Client by a Freelancer and requests for refunds based 
upon disputes.</p>
<h4 className='agreement_subhead'>2.6 <span className='policy_subhead'>TERMINATION  </span></h4>
<p className='policy__paragraph'>You and Solvate Africa both have the right to end this Agreement, but certain rights and 
obligations will survive after this Agreement ends.
</p>
<p className='policy__paragraph'>Unless both you and Upwork expressly agree otherwise in writing, either of us may terminate 
this Agreement in our sole discretion at any time, without explanation, upon written notice to 
the other, which will result in the termination of the other Terms of Service as well, except as
otherwise provided in this Agreement. You may provide written notice to 
<span className='policy__bold'>solvateafrica@gmail.com</span>. In the event you properly terminate this Agreement, 
your right to use the Services is automatically revoked, and your Account will be closed.
</p>
<p className='policy__paragraph'>You agree that Solvate Africa is not a party to any Service Contract between Users. 
Consequently, you understand and acknowledge that termination of this Agreement (or 
attempt to terminate this Agreement) does not terminate or otherwise impact any Service 
Contract or Project entered into between Users. If you attempt to terminate this Agreement 
while having one or more open Projects, you agree (a) you hereby instruct Solvate Africa will 
close any open contracts; (b) you will continue to be bound by this Agreement and the other 
Terms of Service until all such Projects have closed on the Site and your access to the Site has 
been terminated; 
</p>
<p className='policy__paragraph'>Without limiting Solvate Africa’s other rights or remedies, we may revoke or limit access to 
the Services, deny your registration, or revoke your access to the Site and refuse to provide 
any or all Services to you if: (i) you breach any terms and conditions of this Agreement or any 
portion of the Terms of Service; (ii) we have reasonable reason to believe that you have 
provided false or misleading information to us; (iii) we conclude that your actions may cause 
legal liability for you or others; may be contrary to the interests of the Site or the User 
community; or may involve illicit or illegal activity; or (iv) we are required to by law, legal 
process, or law enforcement. If your Account is temporarily or permanently closed, you may 
not use the Site under the same Account or a different Account or re-register for a new 
Account without Upwork’s prior written consent. If you attempt to use the Site under a different 
Account, we reserve the right to reclaim available funds in that Account and/or use an 
available Payment Method to pay for any amounts owed by you to the extent permitted by 
applicable law.
</p>
<p className='policy__paragraph'>You acknowledge and agree that the value, reputation, and goodwill of the Site depend on 
transparency of Users’ Account status to all Users, including you and other Users who have 
entered into Service Contracts with you. You therefore agree that: if Solvate Africa decides to 
temporarily or permanently close your account, Solvate has the right (but no obligation) where 
allowed by law to: (y) notify other users that have entered into Service Contracts with you of 
your closed account status, and (z) provide those users with a summary of the reasons for 
your account closure. You agree that Solvate Africa will have no liability arising from or 
relating to any notice that it may or may not provide to any user regarding closed account 
status or the reason(s) for the closure.
</p>
<h4 className='agreement_subhead'>2.7 <span className='policy_subhead'>ACCOUNT DATA ON CLOSURE  </span></h4>
<p className='policy__paragraph1'>Except as otherwise required by law, if your Account is closed, you will no longer have access 
to information or material you kept on the Site and any content stored in your Account may be 
deleted, for which Solvate Africa expressly disclaims liability. Solvate Africa may retain some 
or all of your Account information as permitted or required by law and the Privacy Policy.
</p>
        </div>
  )
}

export default Agreement