// import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const initialState = {
    userAccountType:null,
    // token:null,
    userDetail:null,
    // IsLoggedIn:false,
    // company:null,
    // responses:null,
    // inviteduser:null,
    // countries:null,
    // states:[],
    // cities:[],
    // generalAccountType:[]

}

export const updateUserProfileReducer =(state=initialState, action)=>{
    if(action.type === "UPDATE_CREATE_PROFILE"){
        // console.log(action.payload)
        toast.success("User Profile Update Successful.",
        {
            position: toast.POSITION.BOTTOM_CENTER}
        )
        return Object.assign({}, state, {
            userDetail: {...action.payload.userdetail },
            userAccountType:{...action.payload.useraccounttype},
            // token:action.payload.token
        });
    }
 

    return state;
}
