// import React, { useState }from 'react';
// import './GigCreated.styles.css';
// import cardImage from '../../../assets/image/card-image.svg';
// import { useDispatch, useSelector } from 'react-redux';

// const GigCreated = () => {
//     const userGigs = useSelector(state=>state.createGigReducer.userGigs)

//     console.log(userGigs)

//     return (
//         <section className="container">
//             {
//                 userGigs.length < 1 ?
//                 <p>No Gig yet, Gig data will display here</p> :
//                 <div>
//                     <h2>List of Created gigs</h2>
//                     {
//                         userGigs.map((each,id) =>{
//                             return(
//                                 <div key={id} className="left-card">
//                                 <img className="img-fluid" src={cardImage} alt="cardFlyer"/>
//                                 <div className="card-copy">
//                                     <h5>{each.title}</h5>
//                                     <p>Starting at <span> ₦{
//                                         each.scopepackage.map(each =>{
//                                             if(each.package === "Basic"){
//                                                 return each.price
//                                             }
//                                         }
//                                             )
//                                         }</span></p>
//                                 </div>
//                                 </div>
//                             )
//                         })
//                     }
//                 </div>
//             }
import React, { useState, useEffect } from 'react';
import edit from '../../../assets/image/edit-icon.svg';
import remove from '../../../assets/image/delete.svg';
import cardImage from '../../../assets/image/card-image.svg';
import { useHistory, useParams } from 'react-router';
import info from '../../../assets/image/info.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './GigCreated.styles.css';
import logoimage from '../../../assets/image/announce.png';
import { deleteGig } from '../../../store/action/creategigAction/overview';
import { getGigDetailAction } from '../../../store/action/searchAction/searchGigAction';
import {
  AllUserGigs,
  getEachUserGigs,
} from '../../../store/action/creategigAction/getUserGigs';
import Loader from 'react-loader-spinner';

const GigCreatedUser = ({ userPubProfile }) => {
  const userGigs = useSelector((state) => state.createGigReducer.userGigs);
  const eachUserGig = useSelector(
    (state) => state.SearchGigReducer.eachUserGig
  );
  const [showIcon, setShow] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const dispath = useDispatch();
  console.log('each of my user gid', eachUserGig);
  const history = useHistory();
  const displayIcon = (id) => {
    setShow(id);
  };
  const hideIcon = (id) => {
    setShow(false);
  };

  useEffect(() => {
    getUserGigs();
  }, []);
  // console.log('user prob profile in', userPubProfile)

  const getUserGigs = async () => {
    setIsFetching(true);
    await dispath(getEachUserGigs(userPubProfile?.id));
    setIsFetching(false);
  };
  // const handleClickGig = async (id) => {
  //   await dispath(getGigDetailAction(id));
  //   history.push('/gigDetails');
  // };
  // const handleDeleteGig = (gigToDelete) => {
  //   console.log(gigToDelete);
  //   dispath(deleteGig(gigToDelete.id));

  // };

  console.log('user giggggg', eachUserGig);

  return (
    <section className='container'>
      {eachUserGig === null ? (
        <p>No Gig yet, Gig data will display here</p>
      ) : eachUserGig.length < 1 ? (
        <p>No Gig yet, Gig data will display here</p>
      ) : (
        <div className='container-fluid top-cards-wrapper'>
          <div className='title'>
            {/* <>
              {['top'].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Tooltip id={`tooltip-${placement}`}>
                      Move your mouse over a gig card to edit or delete gig
                    </Tooltip>
                  }
                >
                  
                  <img src={info} alt='infoIcon' />
                </OverlayTrigger>
              ))}
            </> */}
            <h2>List of Created gigs</h2>
          </div>
          {isFetching || eachUserGig === null ? (
            <div className='d-flex justify-content-center'>
              <Loader type='Rings' color='blue' height={50} width={50} />
            </div>
          ) : (
            <div className='row card-wrapper-gig'>
              {eachUserGig.map((each, id) => {
                return (
                  <Link
                    key={id}
                    className='card card-group col-md-4'
                    // onMouseEnter={() => displayIcon(each.id)}
                    // onMouseLeave={hideIcon}
                    to={`/hunterDashboard/gigDetails/${each.id}`}
                  >
                    <div>
                      <div
                        className='image_wrapper'
                        //  onClick={() => handleClickGig(each.id)}
                      >
                        
                        {/* <img
                          className='img-card'
                          src={each?.gallery ? `https://api.solvateafrica.com/public/images/galleries/${each?.gallery[0]?.image}`: cardImage}
                          alt='cardImg'
                        /> */}
                        {each.gallery !== undefined  && ( each?.gallery[0]?.image.includes(".jpg") 
                || each?.gallery[0]?.image.includes(".png")) ?
                        <img className="imag-card" src={`https://api.solvateafrica.com/public/images/galleries/${each.gallery[0]?.image}`} alt="cardImg" min-width="200px" height="250"/> 
                     :
                     each.gallery !== undefined&& each?.gallery[0]?.image.includes(".mp4") ?
                    <video width="100%" height="250" controls>
                    <source src={`https://api.solvateafrica.com/public/images/galleries/${each?.gallery[0]?.image}`} type="video/mp4"></source>
                    </video> :  
                    <audio controls >
                      <source src={each.gallery !== undefined ? `https://api.solvateafrica.com/public/images/galleries/${each?.gallery[0]?.image}` : ''} type="audio/mpeg"></source>
                    </audio>
            }
                        <h5>{each.title}</h5>
                      </div>

                      <div className='card-copy'>
                        {/* <h5>{each.title}</h5> */}
                        {/* {showIcon === each.id ? (
                          <div className='edit-delete d-flex'>
                            <Link to={`editGig/${each.id}`}>
                              <img src={edit} alt='EditImage' />
                            </Link>
                            <img
                              src={remove}
                              onClick={() => handleDeleteGig(each)}
                              alt='deleteImage'
                            />
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default GigCreatedUser;
