import React, { useState } from 'react';
import './MoveTaskModal.styles.css';
import { Modal } from 'react-bootstrap';
import close from '../../../assets/image/close.svg';
import { useDispatch } from 'react-redux';
import {
  changeTaskStatus,
  changeTaskStatusToDone,
} from '../../../store/action/hunter/addOrder';
import Loader from 'react-loader-spinner';

const MoveTaskModal = ({ taskId, show, handleClose }) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(false);
  const handleChange = (e) => {
    setData({ [e.target.name]: e.target.value });
    console.log(data);
  };
  const handleSubmit = async (e) => {
    setIsFetching(true);
    e.preventDefault();
    if (data.status === 'doing') {
      await dispatch(changeTaskStatus(Number(taskId)));
    } else if (data.status === 'done') {
      await dispatch(changeTaskStatusToDone(Number(taskId)));
    }
    setIsFetching(false);
    handleClose();
  };
  return (
    <div>
      <Modal
        size='md'
        show={show}
        onHide={handleClose}
        dialogClassName='modal-500w'
      >
        <div className='title-cus'>
          <img src={close} alt='closeimage' onClick={handleClose} />
          <h3>
            Move task to a new status. {taskId} {data.status}
          </h3>
        </div>
        <Modal.Body>
          <form onSubmit={handleSubmit} className='form-update'>
            <div className='form_job'>
              <label>Select new status:</label>
              <br />
              <select name='status' onChange={handleChange}>
                <option value=''>Select Status</option>
                <option value='doing'>Doing</option>
                <option value='done'>Done</option>
              </select>
            </div>
            <div className='job_button text-center'>
              <button type='submit'>
                {isFetching ? (
                  <div className='d-flex justify-content-center'>
                    <Loader type='Rings' color='blue' height={50} />
                  </div>
                ) : (
                  'Change task'
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MoveTaskModal;
