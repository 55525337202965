// import { css } from "glamor";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const initialState = {
  applyForJob: null,
  getHunterPendingOrder:[],
  getPendingOrder: [],
  getActiveOrder: [],
  getSubmittedOrder: [],
  getCompletedJob: [],
  getSubmittedDetail: null,
  uploadPastJobs: null,
  getHunterPayment: null,
  getPastJobs: [],
  accepthunterjob: null
};

export const talentApplyForJobReducer = (state = initialState, action) => {
  if (action.type === 'APPLY_FOR_JOB') {
    console.log(action.payload);
    toast.success('Application Successful...', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
      closeOnClick: true
    });
    console.log(action.payload);
    return Object.assign({}, state, {
      applyForJob: action.payload,
    });
  }

  if (action.type === 'ACCEPT_HUNTER_JOB') {
    console.log(action.payload);
    toast.success('Application Successful...', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
      closeOnClick: true
    });
    console.log(action.payload);
    return Object.assign({}, state, {
      accepthunterjob: action.payload,
    });
  }
  if (action.type === 'GET_ACCEPTED_BIDS') {
    console.log(action.payload);
    return Object.assign({}, state, {
      getPendingOrder: action.payload,
    });
  }

  if (action.type === 'GET_HUNTER_PENDING_ORDER') {
    console.log(action.payload);
    state.getActiveOrder = [];
    return Object.assign({}, state, {
      getHunterPendingOrder: action.payload,
    });
  }
  if (action.type === 'GET_TALENT_ACTIVE_ORDER') {
    console.log(action.payload);
    state.getActiveOrder = [];
    return Object.assign({}, state, {
      getActiveOrder: state.getActiveOrder.concat(action.payload),
    });
  }
  if (action.type === 'SUBMITTED_JOBS') {
    console.log(action.payload);
    toast.success('submission successful.', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
      closeOnClick: true
    });
    state.getActiveOrder = [];
    return Object.assign({}, state, {
      getActiveOrder: state.getActiveOrder.concat(action.payload),
    });
  }
  if (action.type === 'GET_TALENT_SUBMITTED_ORDER') {
    console.log(action.payload);
    state.getSubmittedOrder = [];
    return Object.assign({}, state, {
      getSubmittedOrder: state.getSubmittedOrder.concat(action.payload),
    });
  }
  if (action.type === 'GET_COMPLETED_ORDER') {
    console.log(action.payload);
    state.getCompletedJob = [];
    return Object.assign({}, state, {
      getCompletedJob: state.getCompletedJob.concat(action.payload),
    });
  }
  
  if (action.type === 'GET_COMPLETED_PAYHISTORY') {
    console.log(action.payload);
    
    return Object.assign({}, state, {
      getHunterPayment: action.payload,
    });
  }
  if (action.type === 'SUBMITTED_JOB_ACCEPTED') {
    console.log(action.payload);
    // state.getSubmittedOrder = [];
    toast.success('Project Accepted', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    // return Object.assign({}, state, {
    //   getSubmittedOrder: state.getSubmittedOrder.concat(action.payload),
    // });
  }

  if (action.type === 'GET_SUBMITTED_ORDER_DETAIL') {
  
    // state.getSubmittedOrder = [];
    return Object.assign({}, state, {
      getSubmittedDetail: action.payload[0],
    });
  }

  if (action.type === 'UPLOAD_PAST_JOBS') {
    
    toast.success('upload successful.', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
      closeOnClick: true
    });
    // state.getSubmittedOrder = [];
    return Object.assign({}, state, {
     uploadPastJobs: action.payload,
     getPastJobs: state.getPastJobs.concat(action.payload)
    });
  }
  // UPLOAD_PAST_ERRORJOBS

  if (action.type === 'UPLOAD_PAST_ERRORJOBS') {
    console.log(action.payload);
    toast.error('file must not be greater than 5MB...', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
      closeOnClick: true
    });
  }

  if (action.type === 'GET_PAST_JOBS') {
    console.log(action.payload);
    return Object.assign({}, state, {
     getPastJobs: action.payload.data,

    //  getPastJobs: new Set([...state.getPastJobs,  ...action.payload.data]),
    });
  }

  return state;
};
