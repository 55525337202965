import React, {useState} from 'react';
import './CreateGig.styles.css';
import NavTalent from '../../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import coloredind from '../../../../assets/image/coloredindigig.svg';
import indicagig from '../../../../assets/image/indigig.svg';
import { useFormik, Formik, Form, Field, ErrorMessage } from 'formik';
import GigsInput from '../../../../components/GigsInput/GigsInput.component';
import info from '../../../../assets/image/info.svg';
// import Chips, { Chip } from '../src'
// import announce from '../../../../assets/image/announce.svg';
import announce from '../../../../assets/image/announce.png';
import Overview from '../../../../components/Talents/GigSetup/CreateGig/Overview/Overview.component';
import DescriptionGig from '../../../../components/Talents/GigSetup/CreateGig/DescriptionGig/DescriptionGig.component';
import FAQ from '../../../../components/Talents/GigSetup/CreateGig/FAQAddEdit/FAQ/FAQ.components';
import Requirements from '../../../../components/Talents/GigSetup/CreateGig/Requirements/Requirements.component';
import ScopePricing from '../../../../components/Talents/GigSetup/CreateGig/ScopePricing/ScopePricing.component';
import PricingPackages from '../../../../components/Talents/GigSetup/CreateGig/PricingPackages/PricingPackages.component';
import Tab from 'react-bootstrap/Tabs'
import Gallery from '../../../../components/Talents/GigSetup/CreateGig/Gallery/Gallery.component';
import OverviewWrapper from '../../../../components/Talents/GigSetup/CreateGig/OverviewWrapper/OverviewWrapper.component';
import FAQWrapper from '../../../../components/Talents/GigSetup/CreateGig/FAQWrapper/FAQWrapper.component';
import ScopePricingWrapper from '../../../../components/Talents/GigSetup/CreateGig/ScopePricingWrapper/ScopePricingWrapper.component';
import ScopePricingPackage from '../../../../components/Talents/GigSetup/CreateGig/ScopePricingPackage/ScopePricingPackage.component';
import GalleryWrapper from '../../../../components/Talents/GigSetup/CreateGig/GalleryWrapper/GalleryWrapper.component';
import RequirementsWrapper from '../../../../components/Talents/GigSetup/CreateGig/RequirementsWrapper/RequirementsWrapper.component';





const CreateGig = () => {
const [chips, setChips] = useState([]);

const [showOverview, setOverview] = useState (true);
const [showFAQ, setFAQ] = useState (false);
const [showQuestion, setQuestion] = useState (false);
const [showScope, setScope] = useState (false);
const [showGallery, setGallery] = useState (false);
// const showSecondStep = (value) =>{ 
//   setOverview(false)
//   setFAQ(true)
// }
const showSecondStep = (value) =>{ 
  setOverview(false)
  setGallery(true)
}
const showThirdStep = (value) =>{ 
  setFAQ(false)
  setQuestion(true)
}
const showFourthStep = (value) =>{ 
  setQuestion(false)
  setScope(true)
}
const showFifthStep = (value) =>{ 
  setScope(false)
  setGallery(true)
}

// METHODS TO GO BACK THE MULTISTEP FORM
const backFourthStep = (value) => {
  setGallery(false)
  setScope(true)
}
const backThirdStep = (value) => {
  setQuestion(true)
  setScope(false)
}
const backSecondStep = (value) => {
  setFAQ(true)
  setQuestion(false)
}
// const backFirstStep = (value) => {
//   setOverview(true)
//   setFAQ(false)
// }
const backFirstStep = (value) => {
  setOverview(true)
  setGallery(false)
}

// const showFirstStep = () => setDisplay(false)

// const onChange = chips => {
//   useState( chips );
// }



    return (
        <div>
        <NavTalent/>
        <section>
        <div className="gig-title">
        <h1>Create gig/portfolio</h1>
        <h6>
        Kindly take out time to 
        fill this form
        </h6>
        </div>
        {/*Overview and description*/}
        {
          showOverview ? 
          <OverviewWrapper
          showSecondStep ={showSecondStep}
          backFirstStep={backFirstStep}
          />     
          
          :""
        }
    
         {/*FAQ*/}
         {/* {
          showFAQ ? 
          <FAQWrapper 
          showThirdStep = {showThirdStep}
          backFirstStep= {backFirstStep}
          /> :
          ""  
         } */}

         {/*REQUIREMENTS*/}
         {/* {
          showQuestion ?
          <RequirementsWrapper 
          showFourthStep={showFourthStep}
          backSecondStep={backSecondStep}/> :""
         } */}
        {/*END OF REQUIREMENTS*/}

        {/*SCOPE AND PRICING*/}
        {/* {
          showScope?
          <ScopePricingWrapper 
          showFifthStep={showFifthStep}
          backThirdStep={backThirdStep}/>:""
        } */}
        
          {/*PRICING PACKAGES*/}
          {/*<ScopePricingPackage/>*/}

          
          {/*GALLERY*/}
          {
            showGallery?
            <GalleryWrapper backFourthStep={backFourthStep}/> :""

          }

          {/*END GALLERY*/}

 


        </section>
        </div>
    )
}


export default CreateGig;