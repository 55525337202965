const initialState = {
  searchResult: [],
  searchTitle: null,
  gigDetail: null,
  eachUserGig: null,
  userPubProfile: null,
};

export const SearchGigReducer = (state = initialState, action) => {
  if (action.type === 'SEARCH_RESULT') {
    // console.log(userCountry)
    return Object.assign({}, state, {
      searchResult: action.payload.data,
      searchTitle: action.search.title,
      // userCountry:userCountry
    });
  } else if (action.type === 'SEARCH_RESULT_DETAIL') {
    console.log(action.payload);
    return Object.assign({}, state, {
      gigDetail: action.payload,
      // searchTitle: action.search.title
      // userCountry:userCountry
    });
  } else if (action.type === 'GET_USER_PUB_PROFILE') {
    console.log(action.payload);
    return Object.assign({}, state, {
      userPubProfile: action.payload,
      // searchTitle: action.search.title
      // userCountry:userCountry
    });
  } else if (action.type === 'GET_EACH_USER_GIGS') {
    console.log(action.payload);
    return Object.assign({}, state, {
      eachUserGig: action.payload,
      // searchTitle: action.search.title
      // userCountry:userCountry
    });
  }

  return state;
};
