import React from 'react';
import './DescriptionTab.styles.css';
import Tab from 'react-bootstrap/Tabs';
import { MdKeyboardArrowRight, MdSchedule } from 'react-icons/md';
import tick from '../../../../../assets/image/tick.svg';

const DescriptionTab = ({ myGig }) => {
  return (
    <section>
      <div className='container-fluid overview-cont'>
        <div className='row overview-wrapper'>
          <div className='col-md-7 left-side'>
            <h3>Gig's description</h3>
            <p>{myGig === null ? null : myGig.description}</p>

            {/* <div className="about-gig">
  <h3>About the Gig</h3>
  </div> */}
          </div>
          {/*Right*/}
          <div className='col-md-5 right-side'>
            <div className='right-gigcontent card'>
              <div className='basic-content'>
                <div className='heading_copy d-flex justify-content-between'>
                  <h6>Gig offers</h6>
                  <div>Verified</div>
                </div>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].offers}
                  </p>
                </li>
                <li className='d-flex align-items-baseline'>
                  <img src={tick} alt='tick' />
                  <p>
                    {myGig === null || myGig.scopepackage.length < 1
                      ? null
                      : myGig.scopepackage[0].revisions}{' '}
                    Revisions
                  </p>
                </li>
              </div>
            </div>
            <div className='d-flex button_wrapper justify-content-center'>
              <button type='button' className='contact_button'>
                Contact creative talent
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DescriptionTab;
