import React, { useEffect, useState } from 'react';
import './PublicWorkProfile.styles.css';
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
// import UpdateStory from '../../../components/Talents/UpdateStory/UpdateStory.component';
// import AddDescription from '../../../components/Talents/Description/AddDescription/AddDescription.component';
// import AddEducation from '../../../components/Talents/Education/AddEducation/AddEducation.component';
// import AddLanguage from '../../../components/Talents/Language/AddLanguage/AddLanguage.component';
// import AddSkills from '../../../components/Talents/Skills/AddSkills/AddSkills.component';
// import AddCertification from '../../../components/Talents/Certification/AddCertification/AddCertification.component';
// import { getUpdateStatus } from '../../Auth/getUpdateProfile';
import { useDispatch, useSelector } from 'react-redux';
import { country } from '../../../store/action/authAction/countryandstate';
import {
  addEducation,
  UserEducation,
} from '../../../store/action/publicProfileAction/addEducation';

import { UserCertification } from '../../../store/action/publicProfileAction/addCertification';
import {
  allSkills,
  getUserAttachedSkill,
} from '../../../store/action/publicProfileAction/skillAction';

import { Link } from 'react-router-dom';
import { getUserLanguages } from '../../../store/action/publicProfileAction/addLanguage';
import {
  AllUserGigs,
  getUserPubProfile,
} from '../../../store/action/creategigAction/getUserGigs';
import GigCreated from '../../../components/Talents/GigCreated/GigCreated.component';
import ReviewsAdded from '../../../components/Talents/ReviewsAdded/ReviewsAdded.component';
import UpdateStoryHunter from '../../../components/Hunter/UpdateStory/UpdateStory.component';
import AddDescriptionHunter from '../../../components/Hunter/Description/AddDescription/AddDescription.component';
import AddLanguageHunter from '../../../components/Hunter/Language/AddLanguage/AddLanguage.component';
import EditLanguageHunter from '../../../components/Hunter/Language/EditLanguage/EditLanguage.component';
import AddSkillsHunter from '../../../components/Hunter/Skills/AddSkills/AddSkills.component';
import EditSkillsHunter from '../../../components/Hunter/Skills/EditSkills/EditSkills.component';
import AddEducationHunter from '../../../components/Hunter/Education/AddEducation/AddEducation.component';
import AddCertificationHunter from '../../../components/Hunter/Certification/AddCertification/AddCertification.component';
import EditCertificationHunter from '../../../components/Hunter/Certification/EditCertification/EditCertification.component';
import { useParams } from 'react-router';
import Loader from 'react-loader-spinner';
import { ImOpt } from 'react-icons/im';
import GigCreatedUser from '../../../components/Hunter/GigCreated/GigCreated.component';

const PublicProfileHunter = () => {
  const dispatch = useDispatch();
  const userprofile = useSelector((state) => state.authReducer.userProfile);
  const userimage = useSelector((state) => state.SearchGigReducer.userPubProfile)
  const usercountry = useSelector((state) => state.countryandstate.userCountry);
  const countries = useSelector((state) => state.countryandstate.countries);
  const [isFetching, setIsFetching] = useState(false);

  const userPubProfile = useSelector(
    (state) => state.SearchGigReducer.userPubProfile
  );


  console.log('this is my hunter public profile',userPubProfile )
  const params = useParams();
  const EducationDetail = useSelector(
    (state) => state.educationReducer.userEducation
  );
  const userGigs = useSelector((state) => state.createGigReducer.userGigs);
  // const userLanguageDetail = useSelector(state=>state.languageReducer.userLanguages)


  useEffect(() => {
    getUserProfile();
    getUserGigs();
    getCountry();
    getUserEducation();
    // getUserCertification()
  }, []);

  useEffect(() => {
    // getCountry()
    // getUserEducation()
    getUserCertification();
    getAllSkills();
  }, []);

  useEffect(() => {
    // getCountry()
    // getUserEducation()
    getUserLang();
    getUserSkill();
  }, []);

  const getCountry = async () => {
    await dispatch(country(userprofile.countryId));
  };
  const getUserEducation = async () => {
    await dispatch(UserEducation());
  };
  const getUserCertification = async () => {
    await dispatch(UserCertification());
  };

  const getUserLang = async () => {
    await dispatch(getUserLanguages());
  };

  const getAllSkills = async () => {
    await dispatch(allSkills());
  };
  // console.log(usercountry)

  const getUserSkill = async () => {
    await dispatch(getUserAttachedSkill());
  };

  const getUserGigs = async () => {
    await dispatch(AllUserGigs());
  };
  const getUserProfile = async () => {
    setIsFetching(true);
    await dispatch(getUserPubProfile(params.id));
    setIsFetching(false);
  };

  return (
    <div>
      {/* <NavTalent /> */}
      {/* <GigCreated/> */}

      <main className='container-fluid'>
        {isFetching ? (
          <div className='d-flex justify-content-center'>
            <Loader type='Rings' color='blue' height={50} width={50} />
          </div>
        ) : (
          <div className='row cont-wrapper'>
            <div className='col-md-5 left-desc'>
              {/*Add Update story component*/}
              <UpdateStoryHunter
                userPubProfile={userPubProfile}
                firstname={userprofile.firstname}
                lastname={userprofile.lastname}
                country={usercountry}
                joinedSince={userprofile.created_at}
                storyLine={userprofile.storyline}
                profile_image={userimage !==null && userimage.userdetail !== null && userimage.userdetail.profile_image}
              />

              {/*Description, language, skills, education and cert card*/}
              <div className='other-feat-cards'>
                <AddDescriptionHunter
                  userPubProfile={userPubProfile}
                  description={userprofile.description}
                />
                {/* <hr /> */}

                {/*Languages*/}
                {/* <AddLanguageHunter />
                <EditLanguageHunter userPubProfile={userPubProfile} /> */}
                <hr />

                {/*Skills*/}
                <AddSkillsHunter />
                {/* <EditSkillsHunter userPubProfile={userPubProfile} /> */}
                <hr />
                <AddEducationHunter
                  countries={countries}
                  userEducation={EducationDetail}
                  userPubProfile={userPubProfile}
                />

                {/*Education*/}
                {/* <AddEducation/> */}
                {/* <EditEducation userEducation={EducationDetail}/> */}
                <hr />

                {/*Certification*/}
                <AddCertificationHunter />
                <EditCertificationHunter userPubProfile={userPubProfile} />
              </div>
            </div>
            <div className='col-md-7 right-desc'>
              {/* <div className='create-gig'>
              <div className='gig-content'>
                {userGigs.length < 1 ? (
                  <p>You don't have any gig yet. Create one</p>
                ) : (
                  <p>Active Gigs </p>
                )}
                <Link to='/creategig'>
                  <button type='button'>Create a gig</button>
                </Link>
              </div>
            </div> */}
              {/* <h4 data="no-gig">No gig yet. Created gigs will display here.</h4> */}

              {/* Gigs created */}
              <div>
                <GigCreatedUser userPubProfile={userPubProfile} />
              </div>
              {/* End of Gigs created */}

              {/* Reviews added */}
              <div>{/* <ReviewsAdded /> */}</div>
              {/* End of reviews */}
            </div>
          </div>
        )}
      </main>
      {/* <TalentFooter /> */}
    </div>
  );
};

export default PublicProfileHunter;
