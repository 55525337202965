import React, { useState } from 'react';
// import './ProjectTrackerTable.styles.css';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdAdd, MdReviews, MdVisibility } from 'react-icons/md';
// import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import RatingReviewModal from './RatingReviewModal';
import moment from 'moment';
import { Rating } from '@mui/material';
// import ProjectTrackerJobsModal from '../ProjectTrackerJobsModal/ProjectTrackerJobsModal.component';

const CompletedProjectTable = ({ getCompletedJob, getHunterPayment }) => {
  const [saveProj, setSaveProj] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setSaveProj(item);
    setShow(true);
  };
console.log('get completed jobsss mymy', getHunterPayment)
  return (
    <section>
      <Table className='table_tracker'>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Buyer</th>
            <th className='active_head_bg'>Job Title</th>
            {/* <th className='active_head_bg'>Milestone</th> */}
            <th className='active_head_bg'>Due On</th>
            <th className='active_head_bg'>Delivered At</th>
            <th className='active_head_bg'>Price budget</th>
            <th className='active_head_bg'>Rating</th>
            <th className='active_head_bg'>Status</th>
          </tr>
        </thead>
        <tbody>
          {/* <ProjectTrackerJobsModal
            handleClose={handleClose}
            show={show}
            handleShow={handleShow}
          /> */}
          {getCompletedJob?.map((each, id) => {
            return (
              <tr key={id}>
                <td>{1 + id}</td>
                <td>{each.creative.name}</td>
                <td>
                  {' '}
                  <Link
                    to={`/hunterDashboard/CompletedProjects/detail/${each.id}`}
                  >
                    {each.title}
                  </Link>{' '}
                </td>
                {/* <td>{each.milestone}</td> */}
                <td>{each.end_date}</td>
                <td>{moment(each.updated_at).format('ll')}</td>
                <td>{each.price_budget}</td>
                <td>
                  {each.review === null ? (
                    <>
                      {['top'].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              Add Review
                            </Tooltip>
                          }
                        >
                          {/* <a>Tooltip on {placement}</a> */}

                          <div>
                            <MdReviews
                              onClick={() => handleShow(each)}
                              className='view_icon_tracker'
                            />
                          </div>
                        </OverlayTrigger>
                      ))}
                    </>
                  ) : (
                    <span className='review-wrapper'>
                      <Rating
                        name='simple-controlled'
                        size='small'
                        readOnly
                        // style={{ color: 'red' }}
                        value={each.review.rating}
                      />
                    </span>
                  )}
                </td>
                <td style={{ textTransform: 'capitalize' }}>{each.status}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <RatingReviewModal
        handleClose={handleClose}
        saveProj={saveProj}
        show={show}
      />
    </section>
  );
};

export default CompletedProjectTable;
