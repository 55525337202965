import React from 'react';
import './EditGalleryWrapper.styles.css';
import '../../../GigSetup/CreateGig/GalleryWrapper/GalleryWrapper.styles.css';
// import './GalleryWrapper.styles.css';
import coloredind from '../../../../../assets/image/coloredindigig.svg';
import indicagig from '../../../../../assets/image/indigig.svg';
import announce from '../../../../../assets/image/announce.png';
import EditGalleryForm from '../EditGalleryForm/EditGalleryForm.component';


const EditGalleryWrapper = ({backFirstStep, param}) => {

    return (

        <section>
        <div className="indicator-container d-flex">       
        <div className="overview">
        <p>Overview</p>
        <img src={coloredind}
        alt="coloredIndicator"/>
        </div>
        {/* <div className="overview add-space">
        <p>Description</p>
        <img src={indicagig}
        alt="Indicator"/>
        </div> */}
        {/* <div className="overview add-space">
        <p>FAQ</p>
        <img src={coloredind}
        alt="Indicator"/>
        </div>
        <div className="overview add-space">
        <p>Requirements</p>
        <img src={coloredind}
        alt="Indicator"/>
        </div>
        <div className="overview add-space">
        <p>Pricing</p>
        <img src={coloredind}
        alt="Indicator"/>
        </div> */}
        <div className="overview add-space">
        <p>Gallery</p>
        <img src={coloredind}
        alt="Indicator"/>
        </div>
        </div>
        {/*Form heading*/}
        <div className="overview-heading">
        <h3>Gallery</h3>
        <h6>Upload some of your
        works and portfolio to encourage creative 
        hunters to  order your gig</h6>
        </div>        
        <hr/> 
        {/*Form*/}
        <div className="container-fluid overview-cont">
        <div className="row overview-wrapper">
        <div className="col-md-7">
        {/*Gallery Form*/}
        {/*<Overview/>*/}
        <EditGalleryForm backFirstStep={backFirstStep} param={param}/>
        </div>
        <div className="col-md-5">
        <div className="right-overview">
        <img src={announce} alt="announceIcon"/>
        {/* <p>A well written overview goes a long way to 
        help your gig stands out. Taking out time to select a
        captivating Gig title, Category, skills and search 
        tags is very important. Adding suitable search tags
        that relates to your gig will help in your gig
        ranking.</p> */}
        </div>
        </div>
        </div>
        </div>

                </section>
    )
}


export default EditGalleryWrapper;