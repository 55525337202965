// import { css } from "glamor";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  DELETE_TASK,
  GET_TALENT_TASK,
  GET_TALENT_TRACk_ORDER,
  POST_TASK,
  UPDATE_TASK,
} from '../action/hunter/addOrder';

const initialState = {
  trackerOrder: [],
  getAddedOrders: [],
  getTask: [],
  task: null,
};

export const TrackerOrderReducer = (state = initialState, action) => {
  // action type here
  const ADD_ORDER = 'ADD_ORDER';
  const GET_ADDED_ORDER = 'GET_ADDED_ORDER';

  if (action.type === ADD_ORDER) {
    // console.log('Successful');
    toast.success('Successful..!', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    // console.log(action.payload.data)
    return Object.assign({}, state, {
      getAddedOrders: state.getAddedOrders.concat(action.payload),
    });
  } else if (action.type === GET_ADDED_ORDER) {
    console.log(action.payload);
    return Object.assign({}, state, {
      getAddedOrders: action.payload,
    });
  } else if (action.type === GET_TALENT_TRACk_ORDER) {
    console.log(action.payload);
    return Object.assign({}, state, {
      getTask: action.payload,
    });
  } else if (action.type === GET_TALENT_TASK) {
    console.log(action.payload);
    return Object.assign({}, state, {
      task: action.payload,
    });
  } else if (action.type === POST_TASK) {
    console.log(action.payload);
    return Object.assign({}, state, {
      getTask: state.getTask.concat(action.payload),
    });
  } else if (action.type === UPDATE_TASK) {
    toast.success('Task Moved Successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    state.getTask = [];
    console.log(action.payload);
    return Object.assign({}, state, {
      getTask: state.getTask.concat(action.payload),
    });
  } else if (action.type === DELETE_TASK) {
    toast.error('Task Deleted Successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    state.getTask = [];
    console.log(action.payload);
    return Object.assign({}, state, {
      getTask: state.getTask.concat(action.payload),
    });
  }

  return state;
};
