import React, { useState, useEffect } from 'react';
import ProjectTrackerTable from '../../../../components/Hunter/ProjectTracker/ProjectTrackerTable/ProjectTrackerTable.component';
import './ProjectTrackerHunter.styles.css';
import { useHistory } from 'react-router-dom';
import { getScoutOpenJobs } from '../../../../store/action/hunter/postJob';
import { useDispatch, useSelector } from 'react-redux';
import { getAddedOrder } from '../../../../store/action/hunter/addOrder';
import { getACtiveOrder } from '../../../../store/action/talent/applyJobAction';

const ProjectTrackerHunter = () => {
  //GET ACTIVE JOBS
  const getScoutOpenJob = useSelector(
    (state) => state.scoutJobReducer.getScoutOpenJobs
  );
  //   console.log(getScoutOpenJob);
  const user = useSelector((state) => state.authReducer.user);
  //   const [openJobs, setOpenJobs] = useState(getScoutOpenJob);
  const getActiveOrders = useSelector(
    (state) => state.talentApplyForJobReducer.getActiveOrder
  );

  const getAddedTrackedOrders = useSelector(
    (state) => state.TrackerOrderReducer.getAddedOrders
  );

  console.log(getActiveOrders);
  console.log(getAddedTrackedOrders);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const history = useHistory();

  //   useEffect(() => {
  //     return () => {

  //     };
  // }, [getScoutOpenJobs ]
  //   )

  console.log(getAddedTrackedOrders);
  //For Tracked order
  useEffect(() => {
    getTrackedProjects();
    getActiveOrder();
  }, []);
  const getTrackedProjects = async () => {
    await dispatch(getAddedOrder());
  };
  const getActiveOrder = async () => {
    await dispatch(getACtiveOrder(user.id));

    // console.log(gethHunterOpenJobs)
    // setOpenJobs(gethHunterOpenJobs)
  };

  return (
    <div className='Projects_wrapper container-fluid'>
      <h3>List of Orders to be tracked</h3>

      <div className='projects_button d-flex justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='projects_button--back'
          type='button'
        >
          Go back
        </button>
        <button
          onClick={handleShow}
          type='button'
          className='projects_button--add'
        >
          Add order
        </button>
      </div>

      {/* Table */}
      {/* Pass all the important modal method as props to the child(table)*/}
      <ProjectTrackerTable
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        getActiveOrders={getActiveOrders}
      />
    </div>
  );
};

export default ProjectTrackerHunter;
