import {axiosInstance} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';

export function resetPassword (userDetails,props) {
    axiosInstance.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`
 
        return config;
      })
      return function(dispatch){
         return axiosInstance.put('resetpassword' ,userDetails).then ( async (response) => {
           console.log(response.data)
            if(response.data.success === true){
              await dispatch({ type: "FORGETPASSWORD", payload: response.data });
              props.push('/login');
              // await dispatch({ type: "MESSAGE", payload: { status : true, message: 'Registration Successful', type: 'success'} })
          }
          else{  
            // console.log(response.data)
            toast.error(response.data.error.message, {
                className: css({
                  background: "#00FF00 !important",
                  color: "white !important",
                  fontWeight: "bold"
                }),
                position: toast.POSITION.TOP_CENTER,
              });
            // await dispatch({ type: "LOGIN_ERROR", payload: response.data });     
           }
          })
          .catch(
              error => {
                // console.log(error.response)
                // console.log(error)
                if(error.response !== undefined){

                  if(error.response.status == 422){
                    if(error.response.data.errors.email){
                      toast.error(error.response.data.errors.email[0], {
                          className: css({
                            background: "#00FF00 !important",
                            color: "white !important",
                            fontWeight: "bold"
                          }),
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                    else if(error.response.data.errors.password){
                      toast.error(error.response.data.errors.password[0], {
                          className: css({
                            background: "#00FF00 !important",
                            color: "white !important",
                            fontWeight: "bold"
                          }),
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                  }

                }
              }
          )

      }

}
