import React, { useState } from 'react';
import './SubmittedOrderTable.styles.css';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import ModalInputComponent from '../../ModalInput/ModalInput.component';
import close from '../../../assets/image/close.svg';
import JobRequestModal from '../JobRequestModal/JobRequestModal.component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import AcceptOrderModal from '../AcceptOrderModal/AcceptOrderModal.component';
// import CompleteOrderModal from '../CompleteOrderModal/CompleteOrderModal.component';

const SubmittedOrderTable = ({ getSubmittedOrder }) => {
  const [show, setShow] = useState(false);
  const [selectOrder, setSelectOrder] = useState(false);
  const handleClose = () => setShow(false);
  console.log('submitted order', getSubmittedOrder);
  const handleShow = (order) => {
    console.log(order);
    setSelectOrder(order);
    setShow(true);
  };

  const category = useSelector((state) => state.categoriesReducer.categories);

  console.log('get submitted order table', getSubmittedOrder)

  return (
    <div>
      <>
         
         <h6 className="openedjobs">Submitted jobs
         <div className='toolholder'>
        
             <div className='overtooltip'> 
             <div class="triangle-down"></div>
             <span className='tooltipopen'>Projects submitted</span>
         </div> 
         </div></h6>
     </>
      <Table className='table_size' striped bordered hover responsive>
        <thead>
          <tr>
            <th className='active_head_bg'>#</th>
            <th className='active_head_bg'>Hunter Name</th>
            {/* <th className='active_head_bg'>Job brief </th> */}
            <th className='active_head_bg'>Net pay</th>
            <th className='active_head_bg'>start date</th>
            <th className='active_head_bg'>due date</th>
            <th className='active_head_bg'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Modal */}
          {/* <CompleteOrderModal
            handleClose={handleClose}
            selectOrder={selectOrder}
            show={show}
          /> */}

          {/* End of Modal */}
          {/* <tr>
            <td>1</td>
            <td>Jan 10, 2022</td>
            <td>I need someone who can deliver the best
            of article writing</td>
            <td>Copywriting</td>
            <td>5,000</td>
            <td>4 milestones</td>   
      
        <td className="action_icons">
       <button
        onClick={handleShow} type='button'>Apply for job</button>
        </td>
          </tr>   */}
          {getSubmittedOrder.map((each, index) => {
            return (
              <tr key={index}>
                <td>{1 + index}</td>
                {/* <td>{each.hunter.name}</td> */}
                <td>{each.creative.name}</td>
                {/* <td dangerouslySetInnerHTML={{__html: each.job_description}}>
                  </td> */}
                <td>{each.job !== null && each?.job.net_pay}</td>
                <td>{each.job !== null && each?.job.start_date}</td>
                <td>{each.job !== null && each?.job.end_date}</td>

                <td className='action_icons'>
                  <Link to={`/order/submittedProject/${each.job_id}`}>
                  <>
                    {['top'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            View submitted jobs
                          </Tooltip>
                        }
                      >
                        {/* <a>Tooltip on {placement}</a> */}
                        <button type='button' onClick={() => handleShow(each)}>
                      View
                    </button>
                      </OverlayTrigger>
                    ))}
                  </>
                    
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SubmittedOrderTable;
