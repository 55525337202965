import {axiosInstance, PATH} from '../../apiconfig/axiosconfig'
import { toast } from "react-toastify";
import { css } from 'glamor';
import { getUpdateStatus } from '../../../pages/Auth/getUpdateProfile';
import axios from 'axios';

export function updateStory (userDetails,image,profile_image) {
    
      return function(dispatch){
        const axiosInstance = axios.create({
          baseURL : PATH,
          timeout: 200000,
          headers: {
                    'Content-Type': 'application/json', 
                     'Accept': 'application/json',
                  //    'X-CSRF-TOKEN: " "'
                     'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                  }
      })
        console.log(userDetails)
        console.log(profile_image)
        console.log(image)
        console.log(getUpdateStatus().firstname)
        const firstname = getUpdateStatus().firstname
        const formData = new FormData()
        formData.append("firstname",firstname)
        formData.append("lastname",getUpdateStatus().lastname)
        formData.append("gender",getUpdateStatus().gender)
        formData.append("phone_number",getUpdateStatus().phone_number)
        formData.append("countryId",getUpdateStatus().countryId)
        formData.append("stateId",getUpdateStatus().stateId)
        formData.append("accounttypeId",getUpdateStatus().accounttypeId)
        formData.append("storyline",userDetails.storyline)
        if(image===null){
          if(profile_image !== null ){
            formData.append("profile_image",profile_image)
          }
          // profile_image === null ? "" : formData.append("profile_image",profile_image)
        }
        else {formData.append("profile_image",image)}

         return axiosInstance.post('updateorcreateprofile', formData).then ( async (response) => {
           console.log('my response', response.data)
            if(response.data.success === true){
              await dispatch({ type: "UPDATE_CREATE_PROFILE", payload: response.data });
          }
        
          })
          .catch(
              error => {
                console.log(error)
                // console.log(error.response.data.errors.profile_image[0])
                if(error.response !== undefined){

                  if(error.response.status === 422){
                    if(error.response.data.errors.profile_image){
                      toast.error(error.response.data.errors.profile_image[0], {
                          position: toast.POSITION.BOTTOM_CENTER,
                        });
                    }
                    else if(error.response.data.errors.password){
                      toast.error(error.response.data.errors.password[0], {
                          position: toast.POSITION.TOP_CENTER,
                        });
                    }
                  }

                }
              }
          )

      }

}
