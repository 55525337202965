import React, { useEffect, useState } from 'react';
import NavTalent from '../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import './GigDetails.styles.css';
import Tab from 'react-bootstrap/Tabs';
import announce from '../../../assets/image/announce.png';
import OverviewTab from '../../../components/Talents/GigDetails/Tabs/OverviewTab/OverviewTab.component';
import DescriptionTab from '../../../components/Talents/GigDetails/Tabs/DescriptionTab/DescriptionTab.component';
import AboutSellerTab from '../../../components/Talents/GigDetails/Tabs/AboutSellerTab/AboutSellerTab.component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { each } from 'jquery';
import FAQTab from '../../../components/Talents/GigDetails/Tabs/FAQTab/FAQTab.component';
import ReviewsTab from '../../../components/Talents/GigDetails/Tabs/ReviewsTab/ReviewsTab.component';
import { getGigDetailAction } from '../../../store/action/searchAction/searchGigAction';
import Loader from 'react-loader-spinner';

const GigDetails = () => {
  const userGigs = useSelector((state) => state.createGigReducer.userGigs);
  const gigDetail = useSelector((state) => state.SearchGigReducer.gigDetail);
  const [isFetching, setIsFetching] = useState(false);
  const [myGig, setMyGig] = useState(null);
  const [galleryGig, setGalleryGig] = useState(null);

  const params = useParams();
  const dispatch = useDispatch();
  // console.log(params);
  // console.log(myGig);
  console.log('my user gigs', userGigs);

  useEffect(() => {
    let gig = userGigs.find((each, id) => each.id === parseInt(params.id));
    setMyGig(gig);
    setGalleryGig(gig)
    console.log('the found gallery gig', galleryGig);

    getGIgDetails();
  }, []);
  const getGIgDetails = async () => {
    setIsFetching(true);
    await dispatch(getGigDetailAction(params.id));
    setIsFetching(false);
  };
  useEffect(() => {
    // let gig = userGigs.find((each,id)=>each.id === parseInt(params.id))
    // console.log(gig)
    setMyGig(gigDetail);
    // return () => {

    // };
  }, [gigDetail]);

  console.log('gig details now', gigDetail)
  return (
    <div>
      <NavTalent />
      {/*Tabs*/}
      {isFetching ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} width={50} />
        </div>
      ) : (
        <div className='tab-wrapper'>
          <Tab
            defaultActiveKey='home'
            id='uncontrolled-tab-example'
            className='mb-3'
          >
            <Tab eventKey='home' title='Overview'>
              {/* <OverviewTab myGig={myGig} /> */}
              <OverviewTab myGig={myGig}  />

              {/*Form*/}
            </Tab>
            {/*Description tab*/}
            <Tab eventKey='description' title='Description'>
              <DescriptionTab myGig={myGig} />
              {/*Form*/}
            </Tab>

            {/*About seller tab*/}
            <Tab eventKey='abouttheseller' title='About the seller'>
              {/*Form*/}
              <AboutSellerTab myGig={myGig} />
            </Tab>

            {/*FAQ tab*/}
            <Tab eventKey='faq' title='FAQ'>
              <FAQTab myGig={myGig} />
            </Tab>

            {/*Reviews tab*/}
            <Tab eventKey='contact' title='Reviews'>
              <ReviewsTab myGig={myGig} />
            </Tab>
          </Tab>
        </div>
      )}
    </div>
  );
};

export default GigDetails;
