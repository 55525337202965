import React, { useEffect, useState } from 'react';
import NavTalent from '../../../../components/Navs/NavTalentComp/NavTalent/NavTalent.component';
import './ProjectTrackerTalentHome.styles.css';
import { useHistory } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import ProjectTrackerCreatedTasks from '../../../../components/Talents/ProjectTracker/ProjectTrackerCreatedTasks/ProjectTrackerCreatedTasks.component';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAddedOrder,
  getTalentTrackOders,
  postTrackerTask,
} from '../../../../store/action/hunter/addOrder';
import Loader from 'react-loader-spinner';
import { each } from 'jquery';

const ProjectTrackerTalentHome = () => {
  const [display, setDisplay] = useState(false);
  const handleShow = () => setDisplay(true);
  const handleHide = () => setDisplay(false);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsfetching] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  // console.log(params);
  const history = useHistory();
  const getTask = useSelector((state) => state.TrackerOrderReducer.getTask);
  // console.log(getTask);
  useEffect(() => {
    getTrackedProjects();
  }, []);
  const getTrackedProjects = async () => {
    setIsfetching(true);
    await dispatch(getTalentTrackOders(Number(params.id)));
    setIsfetching(false);
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      trackedOrderId: Number(params.id),
    });
    // console.log(data);
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    setIsLoading(true);
    await dispatch(postTrackerTask(data));
    setIsLoading(false);
    handleHide();
  };

  return (
    <div className='tracker_talent'>
      {/* Navbar */}
      <NavTalent />
      <h3>{params.title}</h3>

      {/* BODY NAVIGATION */}
      <div className='tracker_nav d-flex flex-row justify-content-between'>
        <button
          onClick={() => history.goBack()}
          className='tracker_nav--button'
          type='button'
        >
          Go back
        </button>
        <div className='d-flex flex-row date_style'>
          <h6>
            <span>Start date:</span> {params.start}
          </h6>
          <h6>
            <span>End date:</span> {params.end}
          </h6>
        </div>
      </div>

      {/* BODY CARDS AND MAIN TRACKER SECTION*/}
      {isFetching ? (
        <div className='d-flex justify-content-center'>
          <Loader type='Rings' color='blue' height={50} />
        </div>
      ) : (
        <section>
          <div className='card d-lg-flex flex-lg-row justify-content-evenly cards_wrapper_talent'>
            <div className='card todo_wrapper'>
              <div className='card d cards_wrapper_talent--main'>
                <h6 className=''>Todo</h6>
                <>
                  {/* {['top'].map((placement) => ( */}
                  <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-${'top'}`}>Add task</Tooltip>
                    }
                  >
                    {/* <a>Tooltip on {placement}</a> */}
                    <Link to='#'>
                      <MdAdd onClick={handleShow} className='add_iconTalent' />
                    </Link>
                  </OverlayTrigger>
                  {/* ))} */}
                </>
              </div>

              {/* The Form to create task */}
              {display ? (
                <div className='tracker_form'>
                  <form onSubmit={handlesubmit}>
                    <div className='form_group_tracker'>
                      <label>Name of task:</label>
                      <br />
                      <input name='name' onChange={handleChange} type='text' />
                    </div>
                    <div className='form_group_tracker'>
                      <label>Task description:</label>
                      <br />
                      <input
                        name='description'
                        onChange={handleChange}
                        type='text'
                      />
                    </div>
                    <div className='form_group_tracker'>
                      <label>Select:</label>
                      <br />
                      <select>
                        <option value='firstmilestone'>First milestone</option>
                        <option value='secondmilestone'>
                          Second milestone
                        </option>
                        <option value='secondmilestone'>Third milestone</option>
                        <option value='secondmilestone'>
                          Fourth milestone
                        </option>
                        <option value='secondmilestone'>Fifth milestone</option>
                      </select>
                    </div>
                    <div className='form_group_tracker'>
                      <label>Date to start</label>
                      <br />
                      <input
                        name='start_date'
                        onChange={handleChange}
                        type='date'
                      />
                    </div>
                    <div className='form_group_tracker'>
                      <label>Date of completion</label>
                      <br />
                      <input
                        name='end_date'
                        onChange={handleChange}
                        type='date'
                      />
                    </div>
                    <div
                      className='tracker_form_button d-flex 
               align-items-baseline'
                    >
                      <button onClick={handleHide} type='button'>
                        Cancel
                      </button>
                      <button type='submit'>
                        {isLoading ? (
                          <div className='d-flex justify-content-center'>
                            <Loader type='Rings' color='blue' height={50} />
                          </div>
                        ) : (
                          'Create task'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                ''
              )}

              {/* The Created task */}
              <ProjectTrackerCreatedTasks
                getTask={getTask.filter((each) => {
                  if (each.status === 'todo') {
                    return each;
                  }
                })}
              />
              {/* <div className='card created_card'>

            </div> */}
            </div>
            <div className='card todo_wrapper'>
              <div className='card cards_wrapper_talent--main '>
                <h6 className=''>Doing</h6>
              </div>
              <ProjectTrackerCreatedTasks
                getTask={getTask.filter((each) => {
                  if (each.status === 'doing') {
                    return each;
                  }
                })}
              />
            </div>
            <div className='card todo_wrapper'>
              <div className='card cards_wrapper_talent--main '>
                <h6 className=''>Done</h6>
              </div>
              <ProjectTrackerCreatedTasks
                getTask={getTask.filter((each) => {
                  if (each.status === 'done') {
                    return each;
                  }
                })}
              />
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default ProjectTrackerTalentHome;
